/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */



    (lib.einbrecher_2 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAAAeIAAg7");
            this.shape.setTransform(108.8726,74.4956,2.3699,2.3699);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f().s("#FFFFFF").ss(2,1,1).p("AATAAQAAAIgGAFQgFAGgIAAQgHAAgFgGQgFgFAAgIQAAgHAFgFQAFgFAHAAQAIAAAFAFQAGAFAAAHg");
            this.shape_1.setTransform(98.8003,68.0375,2.3699,2.3699);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f().s("#FFFFFF").ss(2,1,1).p("AASAAQAAAIgFAFQgFAGgIAAQgHAAgFgGQgFgFAAgIQAAgHAFgFQAFgFAHAAQAIAAAFAFQAFAFAAAHg");
            this.shape_2.setTransform(131.7426,68.0375,2.3699,2.3699);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f().s("#FFFFFF").ss(2,1,1).p("AhcAQQAAgNAbgJQAbgJAmAAQAmAAAcAJQAbAJAAAN");
            this.shape_3.setTransform(115.3307,98.0766,2.3699,2.3699);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAZgMQAAALgHAGQgIAIgKAAQgKAAgHgIQgIgGAAgL");
            this.shape_4.setTransform(114.916,84.9234,2.3699,2.3699);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAugWIhbAt");
            this.shape_5.setTransform(132.4536,61.5201,2.3699,2.3699);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAuAXIhbgt");
            this.shape_6.setTransform(97.6153,61.5201,2.3699,2.3699);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f().s("#FFFFFF").ss(4,1,1).p("APdV1IAA5KQAAiBhdhdQhbhciCAAIkFAAIAAhrQAiAAAbgZQAYgYAAgkQAAgkgYgYQgbgZgiAAIAAixQAAish6h5Qh5h6irAAQirAAh5B6Qh5B5AACsIAACxQgkAAgZAZQgYAYAAAkQAAAkAYAYQAZAZAkAAIAABrIkFAAQiCAAhdBcQhbBcAACCIAAZK");
            this.shape_7.setTransform(115.325,139.65);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f().s("#FFFFFF").ss(2).p("AvdAAIezAAIAIAA");
            this.shape_8.setTransform(116,280);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#000000").s().p("AvWV4IgGgHIAA5JQAAiCBbhcQBdhcCCAAIEFAAIAAhrQgkAAgZgZQgYgYAAgkQAAgkAYgZQAZgZAkAAIAAiwQAAisB5h5QB5h6CrAAQCrAAB5B6QB6B5AACsIAACwQAiAAAbAZQAYAZAAAkIgYA8QgbAZgiAAIAABrIEFAAQCCAABbBcQBdBdAACBIAAZJIAAAHg");
            this.shape_9.setTransform(115.325,140);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.einbrecher_2, new cjs.Rectangle(14.5,-2,201.7,283.3), null);
        
        
        (lib.einbrecher = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAAAeIAAg7");
            this.shape.setTransform(108.8726,74.4956,2.3699,2.3699);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f().s("#FFFFFF").ss(2,1,1).p("AATAAQAAAIgGAFQgFAGgIAAQgHAAgFgGQgFgFAAgIQAAgHAFgFQAFgFAHAAQAIAAAFAFQAGAFAAAHg");
            this.shape_1.setTransform(98.8003,68.0375,2.3699,2.3699);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f().s("#FFFFFF").ss(2,1,1).p("AASAAQAAAIgFAFQgFAGgIAAQgHAAgFgGQgFgFAAgIQAAgHAFgFQAFgFAHAAQAIAAAFAFQAFAFAAAHg");
            this.shape_2.setTransform(131.7426,68.0375,2.3699,2.3699);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f().s("#FFFFFF").ss(2,1,1).p("AhcAQQAAgNAbgJQAbgJAmAAQAmAAAcAJQAbAJAAAN");
            this.shape_3.setTransform(115.3307,98.0766,2.3699,2.3699);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAZgMQAAALgHAGQgIAIgKAAQgKAAgHgIQgIgGAAgL");
            this.shape_4.setTransform(114.916,84.9234,2.3699,2.3699);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAugWIhbAt");
            this.shape_5.setTransform(132.4536,61.5201,2.3699,2.3699);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAuAXIhbgt");
            this.shape_6.setTransform(97.6153,61.5201,2.3699,2.3699);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f().s("#FFFFFF").ss(2,1,1).p("AhqCFIDDi8IgOgSQgHgKABgLQACgMAKgHIAagT");
            this.shape_7.setTransform(61.4092,151.988,2.3699,2.3699);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f().s("#FFFFFF").ss(2,1,1).p("ABXjSIAbgUQAGgEAHABQAHABAGAEQAIAGAAARIAJgCQAJgBAFAGQAJAKgFAPIAKgDQALgCAGAGQAKAIgGAWIAIgCQAIgCAFAFQAJAIgHAMIkHE8QgpAxg/gHQhAgGgdg5");
            this.shape_8.setTransform(71.4411,166.7783,2.3699,2.3699);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f().s("#FFFFFF").ss(1,1).p("ABSBBIiSiSIgSAVICPCPg");
            this.shape_9.setTransform(135.0088,163.7612,2.3699,2.3699);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#FFFFFF").s().p("AhSg8IASgWICTCTIgWASg");
            this.shape_10.setTransform(134.9345,163.8373,2.3699,2.3699);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f().s("#FFFFFF").ss(1,1).p("ADLDGIjKjJQgLgMgRAAIhmAAQgUAAgOgOQgOgOAAgUQAAgUAOgOIBkhgIgWAAIAAgSIhhBeQgWAXAAAfQAAAfAWAXQAWAVAfAAIBsAAIDLDNg");
            this.shape_11.setTransform(48.5879,74.386,2.3699,2.3699);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#FFFFFF").s().p("AgUAJIhsAAQgfAAgWgVQgWgWAAgfQAAgfAWgXIBhheIAAARIAWAAIhkBgQgOAOABAVQgBAUAOAOQAPAOATAAIBmAAQARAAAMAMIDJDJIgVASg");
            this.shape_12.setTransform(48.3157,74.9671,2.3699,2.3699);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f().s("#FFFFFF").ss(2,1,1).p("APdV1IAA5KQAAiBhdhdQhbhciCAAIkFAAIAAhrQAiAAAbgZQAYgYAAgkQAAgkgYgYQgbgZgiAAIAAixQAAish6h5Qh5h6irAAQirAAh5B6Qh5B5AACsIAACxQgkAAgZAZQgYAYAAAkQAAAkAYAYQAZAZAkAAIAABrIkFAAQiCAAhdBcQhbBcAACCIAAZK");
            this.shape_13.setTransform(115.325,139.65);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f("#000000").s().p("AvWV4IgGgHIAA5JQAAiCBbhcQBdhcCCAAIEFAAIAAhrQgkAAgZgZQgYgYAAgkQAAgkAYgZQAZgZAkAAIAAiwQAAisB5h5QB5h6CrAAQCrAAB5B6QB6B5AACsIAACwQAiAAAbAZQAYAZAAAkIgYA8QgbAZgiAAIAABrIEFAAQCCAABbBcQBdBdAACBIAAZJIAAAHg");
            this.shape_14.setTransform(115.325,140);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.einbrecher, new cjs.Rectangle(-1,-1,216.2,281.3), null);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc2go = this;
                this.wait = function(){
                    mc2go.play();
                }
                
                this.goBack = function(){
                
                    mc2go.gotoAndPlay(1);
                    
                }
                //this.parent.goHome();
            }
            this.frame_16 = function() {
                this.stop();
                setTimeout(this.wait,2000);
            }
            this.frame_33 = function() {
                this.stop();
                setTimeout(this.wait,2000);
            }
            this.frame_49 = function() {
                this.stop();
                setTimeout(this.wait,1000);
            }
            this.frame_64 = function() {
                this.stop();
                setTimeout(this.wait,2000);
            }
            this.frame_79 = function() {
                this.stop();
                setTimeout(this.wait,2000);
            }
            this.frame_95 = function() {
                this.stop();
                setTimeout(this.wait,2000);
            }
            this.frame_112 = function() {
                this.stop();
                setTimeout(this.wait,2000);
            }
            this.frame_214 = function() {
                this.parent.startTwin();
            }
            this.frame_254 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(16).call(this.frame_16).wait(17).call(this.frame_33).wait(16).call(this.frame_49).wait(15).call(this.frame_64).wait(15).call(this.frame_79).wait(16).call(this.frame_95).wait(17).call(this.frame_112).wait(102).call(this.frame_214).wait(40).call(this.frame_254).wait(1));
        
            // Ebene_4
            this.instance = new lib.einbrecher();
            this.instance.setTransform(-112.75,248,1,1,0,0,0,107.7,140);
            this.instance._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(114).to({_off:false},0).to({x:390.25},100).to({x:620.25},40).wait(1));
        
            // wald
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAeAdIg6g5");
            this.shape.setTransform(224.4608,180.4393,1.896,1.896);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f().s("#FFFFFF").ss(2,1,1).p("AgmAnIBNhN");
            this.shape_1.setTransform(237.3534,196.7446,1.896,1.896);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAADTIAAmk");
            this.shape_2.setTransform(230.0539,204.8972,1.896,1.896);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#DD0261").s().p("AiKDeIAAkwQAAg6ApgpQApgoA4AAQA6AAAoAoQApApAAA6IAAEwg");
            this.shape_3.setTransform(230.0065,182.2404,1.896,1.896);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAdAdIg5g5");
            this.shape_4.setTransform(443.3964,193.329,2.37,2.37);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f().s("#FFFFFF").ss(2,1,1).p("AgmAnIBNhM");
            this.shape_5.setTransform(459.5124,213.6517,2.37,2.37);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAADSIAAmk");
            this.shape_6.setTransform(450.3286,223.8427,2.37,2.37);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#DD0261").s().p("AhdDBQgogkgEg2IgBAAIAAjCQAAg5ApgpQAogoA5AAQA5AAApAoQApApAAA5IAADCIgBAAQgEA2goAkQgoAlg2AAQg1AAgoglg");
            this.shape_7.setTransform(450.3879,197.595,2.37,2.37);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f().s("#FFFFFF").ss(2,1,1).p("AATATIglgl");
            this.shape_8.setTransform(378.2215,213.3554,2.37,2.37);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f().s("#FFFFFF").ss(2,1,1).p("AgYAaIAxgy");
            this.shape_9.setTransform(388.768,226.6867,2.37,2.37);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAACKIAAkT");
            this.shape_10.setTransform(382.7838,233.3819,2.37,2.37);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#DD0261").s().p("Ag9B/QgagYgDgjIAAAAIAAh/QAAgmAagbQAbgaAlAAQAlAAAbAaQAbAbAAAmIAAB/IgBAAQgCAjgaAYQgbAYgjAAQgjAAgagYg");
            this.shape_11.setTransform(382.7838,216.1402,2.37,2.37);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAkAkIhGhH");
            this.shape_12.setTransform(66.0339,208.3192,2.37,2.37);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f().s("#FFFFFF").ss(2,1,1).p("AgtAvIBchd");
            this.shape_13.setTransform(85.4679,232.9079,2.37,2.37);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAAD+IAAn7");
            this.shape_14.setTransform(74.4474,245.2319,2.37,2.37);
        
            this.shape_15 = new cjs.Shape();
            this.shape_15.graphics.f("#DD0261").s().p("AhwDqQgxgsgFhBIgBAAIAAjrQABhFAwgxQAygxBEAAQBFAAAyAxQAwAxAABFIAADrIAAAAQgGBBgwAsQgvAshCAAQhAAAgwgsg");
            this.shape_15.setTransform(74.4474,213.4739,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_15},{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(255));
        
            // Ebene_3
            this.instance_1 = new lib.einbrecher_2();
            this.instance_1.setTransform(230.1,179.75,0.16,0.16,51.9702,0,0,108.2,140.2);
            this.instance_1._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(18).to({_off:false},0).to({regX:108,regY:140.3,scaleX:0.2,scaleY:0.2,rotation:51.9705,x:260.1,y:188.75},12).wait(5).to({regX:108.2,regY:140.2,scaleX:0.16,scaleY:0.16,rotation:51.9702,x:230.1},11).wait(6).to({regX:107.7,regY:140.8,scaleX:0.128,scaleY:0.128,rotation:-49.5654,x:229.15,y:184.75},0).to({regX:107.8,regY:140.4,scaleX:0.2,scaleY:0.2,rotation:-49.568,x:202.15},11).wait(4).to({regX:107.7,regY:140.8,scaleX:0.128,scaleY:0.128,rotation:-49.5654,x:229.45,y:183.35},10).to({_off:true},1).wait(3).to({_off:false,regX:108.1,regY:140.2,scaleX:0.2,scaleY:0.2,rotation:64.2425,x:73.4,y:224.55},0).to({regX:107.9,regY:140,scaleX:0.25,scaleY:0.25,rotation:64.2404,x:117.4,y:214.55},12).wait(6).to({x:76.4},11).to({_off:true},1).wait(144));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-221,107,948.8,281.3);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.goHome=function(){
                    this.sub_ani_01_mc.goBack();
                }
                
                this.startTwin = function(){
                
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(114.4,160.6,1,1,0,0,0,114.4,160.6);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(34.8,140.2,448.5,166.3), null);
        
        

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}