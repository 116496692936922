import { config } from '../config'

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,OPTIONS',
}

export const powerApi = async (todo: String = "") => {

    if (!config.isLocal) {
        return
    }

    var url = `http://${config.ip}/api/power/${(todo !== '') ? todo : ''}`

    var options: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors'
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return { error: true, text: `${error}` }
    })

    const data = await response;

    if (data.status && data.status === 'error') {
        console.log('powerApi Error:', data)
    } else {
        console.log('powerApi:', data)
    }
    return data

}