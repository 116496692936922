
/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */


    (lib.molgenlöte = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.rf(["#FFFDBD","#FFF69F","#000000","#000000"],[0,0.267,0.596,1],0,0,0,0,0,366.6).s().p("EgonAn/MAAQhQmMBQ/AApMgARBQmg");
            this.shape.setTransform(260,260);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.molgenlöte, new cjs.Rectangle(0,0,520,520), null);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_444 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(444).call(this.frame_444).wait(1));
        
            // Ebene_2
            this.instance = new lib.molgenlöte();
            this.instance.setTransform(582.7,54.6,0.4019,0.4019,0,0,0,259.4,135.9);
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({regX:258.6,regY:136.6,scaleX:1,scaleY:1,x:275.6,y:102.75},444).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(17,-33.8,670.5,520);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.goHome=function(){
                    this.sub_ani_01_mc.gotoAndPlay(0);
                }
                this.isAutoplay = true;
                
            }

            this.isRight = true
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(114.4,160.6,1,1,0,0,0,114.4,160.6);
        
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("rgba(255,255,255,0.008)").ss(2).p("A6/3yMA1/AAAMAAAAvlMg1/AAAg");
            this.shape.setTransform(216.35,234.25);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.sub_ani_01_mc}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(42.6,0,644.9,387.6), null);
        
        

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}