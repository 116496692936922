import { config } from '../../config'
import { xLightApi } from './xLightApi'


export const playLedAnimation = async (playlist) => {
    
    if(!config.isLocal) {
        return
    }

    let xlight = await xLightApi('play', playlist)
    // console.log(xlight)
    return xlight
}
