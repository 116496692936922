/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../actions/animateHelper'

export const navigation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    
    function getMCSymbolPrototype ....
    */



    (lib.slogan = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#57A28D").s().p("AimAVIAAgpIFOAAIAAApg");
            this.shape.setTransform(163.9928,5.1747,2.4477,2.4439);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("Aj6AVIAAgpIH1AAIAAApg");
            this.shape_1.setTransform(61.4938,5.1747,2.4477,2.4439);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgSAQIAHgIQAGAFAFAAQAFAAAAgDQAAgBAAAAQAAgBgBAAQAAgBgBAAQAAAAgBAAIgHgCQgMgDAAgJQAAgGAFgEQAFgFAIAAQALAAAGAGIgHAJQgFgFgFAAQgEAAAAADQAAABAAAAQAAABABAAQAAAAABABQAAAAABAAIAHACQAMADAAAKQAAAOgTAAQgMAAgGgHg");
            this.shape_2.setTransform(144.6495,59.6357,2.4476,2.4435);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAGgGQAFgHAJAAQATAAAAATIgBAHIgZAAQACAIAGAAQAFAAAGgEIAGAJQgHAGgKAAQgVAAAAgXgAgGgEIANAAIAAgBQAAgGgGAAQgFAAgCAHg");
            this.shape_3.setTransform(133.9413,59.6357,2.4476,2.4435);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgIAWIgPgrIAOAAIAJAeIAJgeIAPAAIgQArg");
            this.shape_4.setTransform(122.6824,59.6357,2.4476,2.4435);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgGAiIAAgsIANAAIAAAsgAgHgZQAAgHAHgBQAIABAAAHQAAAIgIAAQgHAAAAgIg");
            this.shape_5.setTransform(113.8099,56.8867,2.4476,2.4435);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("AADAiQgMAAAAgOIAAg1IANAAIAAAzQAAABAAAAQABABAAAAQABAAAAABQABAAABAAIACAAIAAANg");
            this.shape_6.setTransform(108.1804,56.9478,2.4476,2.4435);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#FFFFFF").s().p("AgRASIAMAAQAAAEAGAAQAHAAAAgGIAAgHQgFAFgFAAQgSAAAAgXQAAgXASAAQAFAAAGAFIAAgEIAMAAIAAAuQAAASgUAAQgSAAAAgPgAgHgJQAAAKAIAAQAEAAADgBIAAgRQgCgCgFAAQgIAAAAAKg");
            this.shape_7.setTransform(94.1068,61.9571,2.4476,2.4435);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#FFFFFF").s().p("AAHAXIAAgZQAAgHgHAAQgDAAgDACIAAAeIgOAAIAAgsIAOAAIAAAFQAEgGAHAAQAPAAABASIAAAbg");
            this.shape_8.setTransform(82.0525,59.5135,2.4476,2.4435);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#FFFFFF").s().p("AgFAiIAAgsIAMAAIAAAsgAgHgZQAAgHAHgBQAIABAAAHQAAAIgIAAQgHAAAAgIg");
            this.shape_9.setTransform(72.9964,56.8867,2.4476,2.4435);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQAOAAAFAKIgKAHQgEgFgEAAQgHAAAAAKQAAALAHAAQAFAAADgFIAKAHQgFAKgOAAQgUAAAAgXg");
            this.shape_10.setTransform(65.1029,59.6357,2.4476,2.4435);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#FFFFFF").s().p("AAHAXIAAgZQAAgHgHAAQgDAAgDACIAAAeIgOAAIAAgsIAOAAIAAAFQAEgGAIAAQAPAAAAASIAAAbg");
            this.shape_11.setTransform(53.4769,59.5135,2.4476,2.4435);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#FFFFFF").s().p("AgPAUQgEgEAAgGQAAgNAVgBIAEAAIAAgCQAAgFgGAAQgFAAAAADIAAACIgMAAIAAgDQAAgGAFgEQAFgDAIAAQATAAAAAQIAAAcIgNAAIAAgFQgFAGgIAAQgFAAgEgDgAACADQgHACAAAEQAAAEAFAAQADAAADgDIAAgHg");
            this.shape_12.setTransform(41.4837,59.6357,2.4476,2.4435);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f("#FFFFFF").s().p("AAHAiIAAgaQAAgHgHAAQgEAAgCACIAAAfIgOAAIAAhDIAOAAIAAAbQAFgFAHAAQAPAAAAARIAAAcg");
            this.shape_13.setTransform(29.9801,56.8867,2.4476,2.4435);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f("#FFFFFF").s().p("AAHAXIAAgZQAAgHgHAAQgDAAgDACIAAAeIgOAAIAAgsIAOAAIAAAFQAEgGAIAAQAPAAAAASIAAAbg");
            this.shape_14.setTransform(17.7421,59.5135,2.4476,2.4435);
        
            this.shape_15 = new cjs.Shape();
            this.shape_15.graphics.f("#FFFFFF").s().p("AgVAhIAAhBIArAAIAAAMIgcAAIAAAPIAXAAIAAALIgXAAIAAAOIAcAAIAAANg");
            this.shape_15.setTransform(5.3206,57.07,2.4476,2.4435);
        
            this.shape_16 = new cjs.Shape();
            this.shape_16.graphics.f("#FFFFFF").s().p("AgSAPIAHgIQAFAGAGAAQAFAAAAgDQAAgBAAAAQAAAAgBgBQAAAAgBgBQAAAAgBgBIgHgBQgMgDAAgJQAAgPASAAQALAAAGAHIgHAIQgFgFgGAAQgDAAAAADQAAABAAAAQAAABABAAQAAABABAAQAAAAABABIAGABQANADAAAJQAAAPgTAAQgNAAgFgIg");
            this.shape_16.setTransform(200.2097,33.6732,2.4476,2.4435);
        
            this.shape_17 = new cjs.Shape();
            this.shape_17.graphics.f("#FFFFFF").s().p("AgSAPIAHgIQAFAGAGAAQAFAAAAgDQAAgBAAAAQAAAAgBgBQAAAAgBgBQAAAAgBgBIgHgBQgMgDAAgJQAAgGAEgEQAFgFAJAAQALAAAGAHIgHAIQgFgFgGAAQgDAAAAADQAAABAAAAQAAABABAAQAAABAAAAQABAAABABIAGABQANADAAAJQAAAPgTAAQgNAAgFgIg");
            this.shape_17.setTransform(189.8074,33.6732,2.4476,2.4435);
        
            this.shape_18 = new cjs.Shape();
            this.shape_18.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQATAAAAATIgBAIIgYAAQABAHAGAAQAGgBAFgDIAGAIQgHAHgKAAQgVAAAAgXgAgFgEIAMAAIAAgBQAAgGgGAAQgFAAgBAHg");
            this.shape_18.setTransform(179.0381,33.6732,2.4476,2.4435);
        
            this.shape_19 = new cjs.Shape();
            this.shape_19.graphics.f("#FFFFFF").s().p("AgOAWIAAgqIANAAIAAAGQAEgHAHgBIAFABIgBAOIgIgBQgFAAgBAEIAAAag");
            this.shape_19.setTransform(169.6149,33.5511,2.4476,2.4435);
        
            this.shape_20 = new cjs.Shape();
            this.shape_20.graphics.f("#FFFFFF").s().p("AgRARIAMAAQAAAFAGAAQAHAAAAgGIAAgHQgFAEgFAAQgSAAAAgWQAAgXASAAQAGAAAFAEIAAgDIAMAAIAAAuQAAAIgFAFQgGAFgJAAQgSAAAAgQgAgGgJQAAAKAHAAQAFAAACgBIAAgSQgDgBgEAAQgHAAAAAKg");
            this.shape_20.setTransform(158.4783,36.0557,2.4476,2.4435);
        
            this.shape_21 = new cjs.Shape();
            this.shape_21.graphics.f("#FFFFFF").s().p("AgUAAQAAgWAUAAQAVAAAAAWQAAAXgVAAQgUAAAAgXgAgGAAQAAALAGAAQAHAAAAgLQAAgKgHAAQgGAAAAAKg");
            this.shape_21.setTransform(146.6075,33.6732,2.4476,2.4435);
        
            this.shape_22 = new cjs.Shape();
            this.shape_22.graphics.f("#FFFFFF").s().p("AgOAWIAAgqIANAAIAAAGQAEgHAHgBIAFABIgBAOIgIgBQgEAAgCAEIAAAag");
            this.shape_22.setTransform(136.8784,33.5511,2.4476,2.4435);
        
            this.shape_23 = new cjs.Shape();
            this.shape_23.graphics.f("#FFFFFF").s().p("AgVAhIAAhAIAOAAIAAADQAEgEAGAAQASAAAAAXQAAAWgSAAQgFAAgFgDIAAAXgAgHgRIAAARIAHABQAIAAgBgKQABgLgIAAQgEAAgDADg");
            this.shape_23.setTransform(126.109,36.1168,2.4476,2.4435);
        
            this.shape_24 = new cjs.Shape();
            this.shape_24.graphics.f("#FFFFFF").s().p("AgRARIALAAQAAAFAHAAQAHAAAAgGIAAgHQgFAEgEAAQgUAAAAgWQAAgXAUAAQAFAAAFAEIAAgDIAMAAIAAAuQABAIgGAFQgGAFgJAAQgSAAAAgQgAgGgJQAAAKAHAAQAFAAACgBIAAgSIgHgBQgHAAAAAKg");
            this.shape_24.setTransform(108.9759,36.0557,2.4476,2.4435);
        
            this.shape_25 = new cjs.Shape();
            this.shape_25.graphics.f("#FFFFFF").s().p("AAHAWIAAgYQAAgHgHAAQgCAAgEACIAAAdIgOAAIAAgqIAOAAIAAAEQADgFAJgBQAPAAAAASIAAAag");
            this.shape_25.setTransform(96.9215,33.5511,2.4476,2.4435);
        
            this.shape_26 = new cjs.Shape();
            this.shape_26.graphics.f("#FFFFFF").s().p("AgGAhIAAgrIANAAIAAArgAgHgZQAAgHAHAAQAIAAAAAHQAAAIgIAAQgHAAAAgIg");
            this.shape_26.setTransform(87.8655,30.9243,2.4476,2.4435);
        
            this.shape_27 = new cjs.Shape();
            this.shape_27.graphics.f("#FFFFFF").s().p("AgOAWIAAgqIAOAAIAAAGQADgHAHgBIAFABIgBAOIgIgBQgFAAgBAEIAAAag");
            this.shape_27.setTransform(81.1958,33.5511,2.4476,2.4435);
        
            this.shape_28 = new cjs.Shape();
            this.shape_28.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQATAAAAATIgBAIIgYAAQABAHAHAAQAFgBAFgDIAGAIQgHAHgJAAQgWAAAAgXgAgFgEIAMAAIABgBQAAgGgHAAQgFAAgBAHg");
            this.shape_28.setTransform(70.8548,33.6732,2.4476,2.4435);
        
            this.shape_29 = new cjs.Shape();
            this.shape_29.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQAJAAAGAGQAEAGAAAHIgBAIIgYAAQAAAHAHAAQAGgBAFgDIAGAIQgHAHgKAAQgVAAAAgXgAgFgEIANAAIAAgBQgBgGgGAAQgGAAAAAHg");
            this.shape_29.setTransform(59.657,33.6732,2.4476,2.4435);
        
            this.shape_30 = new cjs.Shape();
            this.shape_30.graphics.f("#FFFFFF").s().p("AAHAWIAAgYQAAgHgHAAQgDAAgDACIAAAdIgOAAIAAgqIAOAAIAAAEQADgFAJgBQAPAAAAASIAAAag");
            this.shape_30.setTransform(47.9698,33.5511,2.4476,2.4435);
        
            this.shape_31 = new cjs.Shape();
            this.shape_31.graphics.f("#FFFFFF").s().p("AgGAhIAAgrIANAAIAAArgAgHgZQAAgHAHAAQAIAAAAAHQAAAIgIAAQgHAAAAgIg");
            this.shape_31.setTransform(38.9138,30.9243,2.4476,2.4435);
        
            this.shape_32 = new cjs.Shape();
            this.shape_32.graphics.f("#FFFFFF").s().p("AgSARIANAAQAAAFAFAAQAIAAgBgGIAAgHQgEAEgFAAQgTAAABgWQgBgXATAAQAGAAAEAEIAAgDIAOAAIAAAuQgBAIgFAFQgGAFgKAAQgSAAAAgQgAgGgJQAAAKAGAAQAGAAABgBIAAgSQgCgBgFAAQgGAAAAAKg");
            this.shape_32.setTransform(29.7965,36.0557,2.4476,2.4435);
        
            this.shape_33 = new cjs.Shape();
            this.shape_33.graphics.f("#FFFFFF").s().p("AAHAWIAAgYQAAgHgHAAQgCAAgEACIAAAdIgOAAIAAgqIAOAAIAAAEQADgFAJgBQAPAAAAASIAAAag");
            this.shape_33.setTransform(17.7421,33.5511,2.4476,2.4435);
        
            this.shape_34 = new cjs.Shape();
            this.shape_34.graphics.f("#FFFFFF").s().p("AgVAhIAAhBIArAAIAAAMIgcAAIAAAPIAXAAIAAALIgXAAIAAAPIAcAAIAAAMg");
            this.shape_34.setTransform(5.3206,31.0464,2.4476,2.4435);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_34},{t:this.shape_33},{t:this.shape_32},{t:this.shape_31},{t:this.shape_30},{t:this.shape_29},{t:this.shape_28},{t:this.shape_27},{t:this.shape_26},{t:this.shape_25},{t:this.shape_24},{t:this.shape_23},{t:this.shape_22},{t:this.shape_21},{t:this.shape_20},{t:this.shape_19},{t:this.shape_18},{t:this.shape_17},{t:this.shape_16},{t:this.shape_15},{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.slogan, new cjs.Rectangle(0,0,205,70), null);
        
        
        (lib.headline_l3 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt = new cjs.Text("Energieerzeugung und Nutzung", "30px 'Brix Sans Regular'", "#FFFFFF");
            this.txt.name = "txt";
            this.txt.lineHeight = 30;
            this.txt.lineWidth = 441;
            this.txt.parent = this;
            this.txt.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.headline_l3, new cjs.Rectangle(0,0,445,39.9), null);
        
        
        (lib.headline_l2 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt = new cjs.Text("Future Window Vision", "42px 'Brix Sans Regular'", "#FFFFFF");
            this.txt.name = "txt";
            this.txt.lineHeight = 55;
            this.txt.lineWidth = 441;
            this.txt.parent = this;
            this.txt.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.headline_l2, new cjs.Rectangle(0,0,445,55), null);
        
        
        (lib.headline_l1 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt = new cjs.Text("Willkommen", "bold 53px 'Brix Sans Black'", "#FFFFFF");
            this.txt.name = "txt";
            this.txt.lineHeight = 68;
            this.txt.lineWidth = 441;
            this.txt.parent = this;
            this.txt.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.headline_l1, new cjs.Rectangle(0,0,445,67.4), null);
        
        
        (lib.btn_language_en = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_2
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AALA7IhkAAIAAikIC0AAIAACkIgUAAIAAAvgAhIAqIBZAAIAkAcIAAgcIAUAAIAAiBIiRAAg");
            this.shape.setTransform(22.7,10.6);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            // Ebene_1
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgFAKIAAgTIALAAIAAACIgIAAIAAAHIAHAAIAAABIgHAAIAAAGIAIAAIAAADg");
            this.shape_1.setTransform(10.9589,17.7126,2.3695,2.3696);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgGAKIAAgTIAFAAQAIAAAAAJQAAAKgIAAgAgEAHIADAAQAGAAAAgHQAAgHgGAAIgDAAg");
            this.shape_2.setTransform(6.9308,17.7126,2.3695,2.3696);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AAFAKIgJgPIAAAPIgDAAIAAgTIAEAAIAIAPIAAgPIADAAIAAATg");
            this.shape_3.setTransform(24.6427,8.4713,2.3695,2.3696);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgFAKIAAgTIALAAIAAACIgIAAIAAAHIAHAAIAAABIgHAAIAAAHIAIAAIAAACg");
            this.shape_4.setTransform(20.6738,8.4713,2.3695,2.3696);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("Ag3A6IgfAAIAAijIB3AAIAAARIhlAAIAACAIAeAAIAAAfIAkgfIBJAAIAAg9IAQAAIAABPIhUAAIg6Awg");
            this.shape_5.setTransform(8.7,20.1);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_language_en, new cjs.Rectangle(0,0,31.8,30.7), null);
        
        
        (lib.btn_language_de = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgFAKIAAgTIALAAIAAACIgIAAIAAAHIAHAAIAAABIgHAAIAAAGIAIAAIAAADg");
            this.shape.setTransform(10.959,17.7132,2.3695,2.3697);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgGAKIAAgTIAFAAQAIAAAAAJQAAAKgIAAgAgEAHIADAAQAGAAAAgHQAAgHgGAAIgDAAg");
            this.shape_1.setTransform(6.9309,17.7132,2.3695,2.3697);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AAFAKIgJgPIAAAPIgDAAIAAgTIAEAAIAIAPIAAgPIADAAIAAATg");
            this.shape_2.setTransform(24.643,8.4715,2.3695,2.3697);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgFAKIAAgTIALAAIAAACIgIAAIAAAHIAHAAIAAABIgHAAIAAAHIAIAAIAAACg");
            this.shape_3.setTransform(20.6741,8.4715,2.3695,2.3697);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AAFAZIgRAAIAAgHIATAAIAPAMIAAgMIAJAAIAAg2Ig9AAIAAAXIgHAAIAAgfIBLAAIAABFIgIAAIAAAUg");
            this.shape_4.setTransform(22.6896,10.6049,2.3697,2.3698);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgXAZIgNAAIAAhFIBJAAIAABFIgkAAIgYAUgAgcARIAMAAIAAANIAQgNIAeAAIAAg1Ig6AAg");
            this.shape_5.setTransform(8.7086,20.0842,2.3697,2.3698);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_language_de, new cjs.Rectangle(0,0,31.8,30.7), null);
        
        
        (lib.btn_label_l2 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt = new cjs.Text("Fenstermaterial", "11px 'Brix Sans Regular'", "#FFFFFF");
            this.txt.name = "txt";
            this.txt.textAlign = "center";
            this.txt.lineHeight = 13;
            this.txt.lineWidth = 96;
            this.txt.parent = this;
            this.txt.setTransform(50,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_label_l2, new cjs.Rectangle(0,0,100,18.4), null);
        
        
        (lib.btn_label_l1 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt = new cjs.Text("INNOVATIVES", "bold 13px 'Brix Sans Black'", "#FFFFFF");
            this.txt.name = "txt";
            this.txt.textAlign = "center";
            this.txt.lineHeight = 20;
            this.txt.lineWidth = 96;
            this.txt.parent = this;
            this.txt.setTransform(50,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_label_l1, new cjs.Rectangle(0,0,100,19.6), null);
        
        
        (lib.btn_innovativ_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ah2B3IAAiUICUAAIAACUgAhoBoIB3AAIAAh3Ih3AAgAgnBXIAegeIAFAFIgeAegAhUBPIBLhKIAFAFIhLBKgAA8BKIAAiFIh+AAIAAAPIgPAAIAAgdICbAAIAACTgAhUAbIAegdIAFAFIgeAdgABoAeIAAiGIh+AAIAAAQIgOAAIAAgeICbAAIAACUg");
            this.shape.setTransform(29.8497,29.8497,2.5031,2.5031);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_innovativ_icon_w, new cjs.Rectangle(0,0,59.7,59.7), null);
        
        
        (lib.btn_icon_windowid_urban_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ag7B4IAAgPIB3AAIAAAPgAg/AhQgagaAAgkQAAglAagbQAbgaAkAAQAlAAAbAaQAaAbAAAlQAAAkgaAaIgpAqIgLgKIApgqQAWgWAAgeQAAgfgWgWQgWgWgfAAQgeAAgWAWQgWAWAAAfQAAAeAWAWIA/BAIgLALgAgfACQgNgNAAgSQAAgSANgOQAOgNARAAQATAAANANQANAOAAASQAAASgNANQgOANgSAAQgRAAgOgNgAgZg4QgMAMAAAPQAAAQAMAKQAKALAPAAQAQAAAKgLQAMgKAAgQQAAgPgMgMQgKgKgQgBQgPABgKAKg");
            this.shape.setTransform(22.475,30,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_urban_icon_w, new cjs.Rectangle(0,0,45,60), null);
        
        
        (lib.btn_icon_windowid_urban_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ag7B4IAAgPIB3AAIAAAPgAg/AhQgagaAAgkQAAglAagbQAbgaAkAAQAlAAAbAaQAaAbAAAlQAAAkgaAaIgpAqIgLgKIApgqQAWgWAAgeQAAgfgWgWQgWgWgfAAQgeAAgWAWQgWAWAAAfQAAAeAWAWIA/BAIgLALgAgfACQgNgNAAgSQAAgSANgOQAOgNARAAQATAAANANQANAOAAASQAAASgNANQgOANgSAAQgRAAgOgNgAgZg4QgMAMAAAPQAAAQAMAKQAKALAPAAQAQAAAKgLQAMgKAAgQQAAgPgMgMQgKgKgQgBQgPABgKAKg");
            this.shape.setTransform(22.475,30,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_urban_icon, new cjs.Rectangle(0,0,45,60), null);
        
        
        (lib.btn_icon_windowid_urban_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AgdAeQgMgMAAgSQAAgQAMgMQANgNAQAAQARAAANANQAMAMAAAQQAAASgMAMQgNAMgRAAQgQAAgNgMg");
            this.shape.setTransform(10.4375,10.4375,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_urban_hl, new cjs.Rectangle(0,0,20.9,20.9), null);
        
        
        (lib.btn_icon_windowid_schnell_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AABA6QgJgKAAgPIAAgiIgegeQgGgHAAgHQAAgKAHgGQAGgHAIAAQAKAAAFAHIA1A1IgKAJIg1g0IgFgCQgGAAAAAIQAAACACACIAhAiIAAAoQgBAIAHAHQAGAGAJAAIAAAPQgPAAgLgLg");
            this.shape.setTransform(25.6205,38.6202,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AALBYIAAhcQAAgHgEgFIgfguIAAgZIArA/QAGAJAAALIAABcg");
            this.shape_1.setTransform(38.682,35.3704,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgCAVQgRgTAAgWIAPAAQAAANAGALQAHALALAGg");
            this.shape_2.setTransform(20.9959,52.1191,2.4998,2.4998);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgGAIQgEgEAAgEQAAgEAEgDQADgDADAAQAFAAADADQADADAAAEQAAAEgDAEQgDADgFAAQgDAAgDgDg");
            this.shape_3.setTransform(14.3089,42.2449,2.4998,2.4998);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgcAEIAAgGIA5AAIAAAGg");
            this.shape_4.setTransform(8.9344,36.8703,2.4998,2.4998);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("Ag4BlIAAjJIBxAAIAAB2IgOAAIAAhoIhWAAIAACsIBYAAIAAAPg");
            this.shape_5.setTransform(14.3714,25.1838,2.4998,2.4998);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_schnell_icon_w, new cjs.Rectangle(0,0,45,57.5), null);
        
        
        (lib.btn_icon_windowid_schnell_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AACA6QgKgKAAgPIAAgiIgegeQgGgGAAgIQAAgKAGgGQAHgHAJAAQAJAAAGAHIA0A1IgKAJIg0g0QgDgCgCAAQgIAAAAAIIACAEIAhAiIAAAoQABAIAGAHQAGAGAJAAIAAAPQgPAAgKgLg");
            this.shape.setTransform(25.6455,38.6202,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AALBYIAAhcQAAgHgEgFIgfguIAAgZIArA/QAGAJAAALIAABcg");
            this.shape_1.setTransform(38.707,35.3704,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgCAVQgQgSAAgXIAOAAQAAANAGALQAGALALAGg");
            this.shape_2.setTransform(21.0834,52.1191,2.4998,2.4998);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgHAIQgDgEAAgEQAAgEADgDQADgDAEAAQAEAAAEADQADADAAAEQAAAEgDAEQgEADgEAAQgEAAgDgDg");
            this.shape_3.setTransform(14.4589,42.2449,2.4998,2.4998);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("AgcAEIAAgGIA5AAIAAAGg");
            this.shape_4.setTransform(9.0219,36.8703,2.4998,2.4998);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#020203").s().p("Ag5BlIAAjJIBzAAIAAB2IgPAAIAAhoIhVAAIAACsIBYAAIAAAPg");
            this.shape_5.setTransform(14.3964,25.1838,2.4998,2.4998);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_schnell_icon, new cjs.Rectangle(0,0,45,57.5), null);
        
        
        (lib.btn_icon_windowid_schnell_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("AgvBYIAAivIBfAAIAACvg");
            this.shape.setTransform(11.9375,22.125,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_schnell_hl, new cjs.Rectangle(0,0,23.9,44.3), null);
        
        
        (lib.btn_icon_windowid_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgkAsIAAhXIAeAAQAqAAAAArQABAXgLAKQgLALgVAAgAgPAcIAHAAQANAAAFgGQAFgGAAgQQAAgOgFgGQgFgHgNAAIgHAAg");
            this.shape.setTransform(26.8934,33.6199,2.5021,2.5029);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgJAsIAAhXIATAAIAABXg");
            this.shape_1.setTransform(11.3803,33.6199,2.5021,2.5029);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AhXBaIAAizICvgsIAAELgAhIhOIAACdICRAlIAAjng");
            this.shape_2.setTransform(21.8971,33.6291,2.5025,2.5033);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgWBdIAAgOIAfAAIAAidIgfAAIAAgPIAuAAIAAC6g");
            this.shape_3.setTransform(47.7983,34.4427,2.5025,2.5033);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_icon_w, new cjs.Rectangle(0,0,53.7,67.3), null);
        
        
        (lib.btn_icon_windowid_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#1D1D1C").s().p("AgjAsIAAhXIAcAAQArAAAAArQAAAXgKAKQgLALgWAAgAgPAbIAHAAQANAAAFgGQAGgGAAgPQAAgOgGgGQgFgHgNAAIgHAAg");
            this.shape.setTransform(26.8021,33.5524,2.4995,2.4995);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#1D1D1C").s().p("AgJAsIAAhXIATAAIAABXg");
            this.shape_1.setTransform(11.3677,33.5524,2.4995,2.4995);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#1D1D1C").s().p("AhWBaIAAizICtgsIAAELgAhIhOIAACdICRAlIAAjng");
            this.shape_2.setTransform(21.8074,33.557,2.4997,2.4997);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#1D1D1C").s().p("AgXBdIAAgOIAgAAIAAidIggAAIAAgPIAuAAIAAC6g");
            this.shape_3.setTransform(47.742,34.3694,2.4997,2.4997);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_icon, new cjs.Rectangle(0,0,53.6,67.1), null);
        
        
        (lib.btn_icon_windowid_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("AgaBbIAAi1IA1AAIAAC1g");
            this.shape.setTransform(6.725,22.6875,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_hl, new cjs.Rectangle(0,0,13.5,45.4), null);
        
        
        (lib.btn_icon_windowid_effizient_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ag3AeIAAg7IA3AAIAAAOIgoAAIAAAfIBfAAIAAAOg");
            this.shape.setTransform(39.769,52.3226,2.5028,2.5034);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AASA4IhOhNIArgrIASATIgJAKIgJgJIgWAXIBDBDQAFAEAGABQAHgBAFgEIAKAKQgKAJgMAAQgMAAgJgJg");
            this.shape_1.setTransform(43.7735,43.5608,2.5028,2.5034);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgDAEQgBAAAAgBQgBAAAAAAQAAgBAAgBQAAAAAAgBQAAgFAFAAQAGAAAAAFQAAAGgGAAQAAAAAAAAQgBAAAAAAQgBgBAAAAQgBAAAAgBg");
            this.shape_2.setTransform(53.5968,52.26,2.5028,2.5034);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgUA9QgJgIAAgMIAAhvIA7AAIAABvQAAAMgJAIQgJAKgMAAQgLAAgJgKgAgPApQAAAGAFAFQAFAEAFAAQAGAAAFgEQAFgFAAgGIAAhgIgfAAg");
            this.shape_3.setTransform(53.6594,42.184,2.5028,2.5034);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgGBNIAAiaIANAAIAACag");
            this.shape_4.setTransform(20.4392,21.1602,2.503,2.5036);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AhKAHIAAgNICVAAIAAANg");
            this.shape_5.setTransform(20.5017,17.8428,2.503,2.5036);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("AhRBpIAAjRICjAAIAABhIgPAAIAAhTIiGAAIAAC1IBDAAIAAAOg");
            this.shape_6.setTransform(20.5017,26.3552,2.503,2.5036);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_effizient_icon_w, new cjs.Rectangle(0,0,61.2,59.9), null);
        
        
        (lib.btn_icon_windowid_effizient_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AASA4IhOhNIArgrIASATIgJALIgJgJIgWAWIBDBDQAFAEAGAAQAHAAAFgEIAKAKQgKAJgMAAQgMAAgJgJg");
            this.shape.setTransform(15.1235,16.2261,2.5028,2.5035);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_effizient_icon, new cjs.Rectangle(0,0,30.3,32.5), null);
        
        
        (lib.btn_icon_windowid_effizient_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AhGAeIAAg7IBuAAQANAAAJAJQAIAJABALQgBAMgIAJQgJAJgNAAg");
            this.shape.setTransform(17.6325,7.5328,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_effizient_hl, new cjs.Rectangle(0,0,35.3,15.1), null);
        
        
        (lib.btn_icon_smart_tageslicht_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("ABPBzIAAgkIAOAAIAAAkgAAkBzIAAhBIAOAAIAABBgAgGBzIAAgdIANAAIAAAdgAgxBzIAAgrIAOAAIAAArgAhcBzIAAgOIAOAAIAAAOgAgGAkIAAgdIANAAIAAAdgAAZAAIAKgJIAVATIgKAKgAg3AKIAUgTIALAJIgVAUgAgTgOQgJgJAAgMIAAgkIgcAAIAAgcIg6AAIAAgPIDlAAIAAAPIg6AAIAAAcIhGAAIAAAkQAAAGAEAFQAEAEAFAAQAGAAAEgEQAEgFAAgGIAAgVIAPAAIAAAVQAAAMgJAJQgIAIgMAAQgLAAgIgIgAgqhVIBVAAIAAgOIhVAAgAArgcIAAgOIAdAAIAAAOgAhHgcIAAgOIAdAAIAAAOg");
            this.shape.setTransform(28.6625,28.6875,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_tageslicht_icon_w, new cjs.Rectangle(0,0,57.4,57.4), null);
        
        
        (lib.btn_icon_smart_tageslicht_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("ABPBzIAAgkIAOAAIAAAkgAAkBzIAAhBIAOAAIAABBgAgGBzIAAgdIANAAIAAAdgAgxBzIAAgrIAOAAIAAArgAhcBzIAAgOIAOAAIAAAOgAgGAkIAAgdIANAAIAAAdgAAaAAIAKgJIAUATIgKAKgAg2AKIAUgTIAKAJIgUAUgAgTgOQgJgJABgMIAAgkIgeAAIAAgcIg4AAIAAgPIDjAAIAAAPIg5AAIAAAcIhHAAIAAAkQABAGAEAFQAEAEAFAAQAGAAAFgEQADgFAAgGIAAgVIAPAAIAAAVQAAAMgJAJQgIAIgMAAQgLAAgIgIgAgqhVIBVAAIAAgOIhVAAgAArgcIAAgOIAdAAIAAAOgAhGgcIAAgOIAcAAIAAAOg");
            this.shape.setTransform(28.6,28.6875,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_tageslicht_icon, new cjs.Rectangle(0,0,57.3,57.4), null);
        
        
        (lib.btn_icon_smart_tageslicht_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AgVA0QgIgIgBgNIAAgoIgeAAIAAgzIB5AAIAAAzIgfAAIAAAoQABANgKAIQgIAJgNAAQgMAAgJgJg");
            this.shape.setTransform(15.25,15.225,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_tageslicht_hl, new cjs.Rectangle(0,0,30.5,30.5), null);
        
        
        (lib.btn_icon_smart_security_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AhFBLQgXgPAAgdIAAh5IBcghIBdAhIAAB5QAAAdgXAPIhGAxgAhNhPIAABuQAAAUARAMIA8AqIA9gqQAQgMAAgUIAAhuIhNgcgAgzgJIALgLIAdAcIA4g3IALALIhDBCg");
            this.shape.setTransform(23.25,31.0625,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_security_icon_w, new cjs.Rectangle(0,0,46.5,62.2), null);
        
        
        (lib.btn_icon_smart_security_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AhFBLQgXgQAAgcIAAh5IBcghIBdAhIAAB5QAAAdgXAPIhGAxgAhNhPIAABuQAAAUARAMIA8AqIA9gqQARgMAAgUIAAhuIhOgcgAgzgJIALgLIAdAcIA4g3IALALIhDBCg");
            this.shape.setTransform(23.2875,31.0625,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_security_icon, new cjs.Rectangle(0,0,46.6,62.2), null);
        
        
        (lib.btn_icon_smart_security_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("Ag0A6QgSgNAAgVIAAhcIBGgaIBHAaIAABcQAAAVgSANIg1Alg");
            this.shape.setTransform(17.725,23.6875,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_security_hl, new cjs.Rectangle(0,0,35.5,47.4), null);
        
        
        (lib.btn_icon_smart_praesenz_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgTBmIAAgPIBEAAIAAhlIhXhFIgPAPIgKgIIAZgZIBlBQIAAB7g");
            this.shape.setTransform(40.895,25.4965,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AAhAwIAAhJQAAgDgCgCQgDgDgDAAIhHAAIAAgOIBHAAQAJAAAHAGQAGAGAAAKIAABJg");
            this.shape_1.setTransform(20.4592,38.9329,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgWBxIAAhvIAPAAIAABgIAsAAIAAhgIAPAAIAABvgAgzBEIAAhKQABgJAGgGQAHgGAJAAIBHAAIAAAOIhHAAQgEAAgCADQgCABAAADIAABKgAgLgwQgLgLAAgPQAAgPALgLQALgLAOAAQAQAAAKALQAMALAAAPQAAAPgMALQgKALgQAAQgOAAgLgLgAAAhaQgIAHABAJQgBAKAIAGQAFAGAJAAQAKAAAGgGQAHgGAAgKQAAgJgHgHQgGgHgKAAQgJAAgFAHg");
            this.shape_2.setTransform(12.8973,33.9958,2.4998,2.4998);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_praesenz_icon_w, new cjs.Rectangle(0,0,56.8,62.1), null);
        
        
        (lib.btn_icon_smart_praesenz_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AgTBmIAAgPIBEAAIAAhlIhXhFIgOAPIgLgIIAZgZIBlBQIAAB7g");
            this.shape.setTransform(40.8931,25.4951,2.4997,2.4997);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AAyAwIAAhJQAAgIgHAAIhUAAQgEAAgCADQgCACAAADIAABJIgPAAIAAhJQABgKAGgGQAHgGAJAAIBUAAQAJAAAHAGQAFAGAAAKIAABJg");
            this.shape_1.setTransform(16.1424,38.9258,2.4995,2.4995);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgkA3IAAhtIAOAAIAABeIAsAAIAAheIAPAAIAABtg");
            this.shape_2.setTransform(16.5173,48.3614,2.4995,2.4995);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgaAaQgLgKABgQQgBgOALgLQAMgLAOAAQAQAAALALQALALgBAOQABAQgLAKQgLALgQAAQgOAAgMgLgAgPgPQgHAHAAAIQAAAKAHAGQAHAHAIAAQAKAAAGgHQAHgGgBgKQABgIgHgHQgGgHgKAAQgIAAgHAHg");
            this.shape_3.setTransform(16.5173,15.1806,2.4995,2.4995);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_praesenz_icon, new cjs.Rectangle(0,0,56.8,62.1), null);
        
        
        (lib.btn_icon_smart_praesenz_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AgjBGIAAh9IgGAAQgIAAABAHIAABKIgQAAIAAhKQABgIAGgHQAHgGAJAAIBUAAQAJAAAHAGQAFAHABAIIAABKIgPAAIAAhKQAAgHgHAAIgEAAIAAB9g");
            this.shape.setTransform(16.125,17.625,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_praesenz_hl, new cjs.Rectangle(0,0,32.3,35.3), null);
        
        
        (lib.btn_icon_smart_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("ABFCBIAAgRIArAAIAAiBIhwhZIhvBZIAACBIArAAIAAARIg8AAIAAiaICAhnICBBnIAACagAAiCBIAAgaIhDAAIAAAaIgSAAIAAgrIAaAAIAAgiQAAgLAIgIQAHgHAKAAQALAAAHAHQAIAIAAALIAAAiIAZAAIAAArgAgFAtQgDADAAAEIAAAiIAQAAIABgiQgBgJgIAAQgDAAgCACgAArA4IAAgJIAiAAIAAAJgAhMA4IAAgJIAiAAIAAAJgAAbASIAZgXIAGAFIgZAYgAg5AAIAGgFIAZAXIgGAGgAgDAJIAAgiIAHAAIAAAig");
            this.shape.setTransform(32.1625,32.1746,2.5,2.5006);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_icon_w, new cjs.Rectangle(0,0,64.4,64.4), null);
        
        
        (lib.btn_icon_smart_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#1D1D1C").s().p("ABFCBIAAgSIArAAIAAiAIhwhZIhvBZIAACAIArAAIAAASIg8AAIAAiaICAhnICBBnIAACagAAiCBIAAgaIhDAAIAAAaIgSAAIAAgrIAaAAIAAgiQAAgLAIgIQAHgHAKAAQALAAAHAHQAIAIAAALIAAAiIAZAAIAAArgAgFAtQgDADAAAEIAAAiIARAAIAAgiQAAgEgDgDQgDgCgDAAQgDAAgCACgAArA4IAAgJIAiAAIAAAJgAhMA4IAAgJIAiAAIAAAJgAAbASIAZgXIAGAFIgZAYgAg5AAIAGgFIAZAXIgGAGgAgDAIIAAghIAHAAIAAAhg");
            this.shape.setTransform(32.2485,32.2977,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_icon, new cjs.Rectangle(0,0,64.5,64.6), null);
        
        
        (lib.btn_icon_smart_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("Ag8A9IAAgzIAeAAIAAgnQABgNAIgJQAJgJAMAAQAMAAAJAJQAKAJgBANIAAAnIAfAAIAAAzg");
            this.shape.setTransform(15.2665,15.2544,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_hl, new cjs.Rectangle(0,0,30.6,30.6), null);
        
        
        (lib.btn_icon_regenerative_nutzung_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgbACIAAgDIA3AAIAAADg");
            this.shape.setTransform(40.4356,32.63,2.5026,2.5034);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgBA0IAAhnIADAAIAABng");
            this.shape_1.setTransform(40.4356,32.63,2.5026,2.5034);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgfhAIA/AMIAABoIg/ANgAgXA3IAvgJIAAhbIgvgJg");
            this.shape_2.setTransform(40.4981,32.7551,2.5026,2.5034);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgRA1IAAhpIAjAAIAAAIIgbAAIAABYIAbAAIAAAJg");
            this.shape_3.setTransform(25.8577,32.6925,2.5026,2.5034);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AhGBqIAJgNQAdAUAjAAQAcAAAYgMQAZgMAQgYQAcgmgIgtQgJgvgmgbQgegVgjAAQgbAAgYAMQgZANgRAXQgRAZgDAdQgDAdAMAcIAagMIgKA/IgxgjIATgJQgPggAEgiQAEghATgcQATgbAdgOQAbgOAfAAQAoAAAiAYQAsAeAKA1QAJA1gfArQgTAbgdAPQgcAOgfAAQgnAAgigYg");
            this.shape_4.setTransform(33.5654,32.5245,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_nutzung_icon_w, new cjs.Rectangle(0,0,67.1,65.1), null);
        
        
        (lib.btn_icon_regenerative_nutzung_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AgbACIAAgDIA3AAIAAADg");
            this.shape.setTransform(40.9286,32.1859,2.5023,2.5031);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AgBA0IAAhnIADAAIAABng");
            this.shape_1.setTransform(40.9286,32.1859,2.5023,2.5031);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgfhAIA/AMIAABpIg/AMgAgXA3IAvgJIAAhbIgvgJg");
            this.shape_2.setTransform(40.9911,32.2485,2.5023,2.5031);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgRA0IAAhnIAjAAIAAAIIgbAAIAABXIAbAAIAAAIg");
            this.shape_3.setTransform(26.3525,32.2485,2.5023,2.5031);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("AhGBrIAJgOQAmAcAugJQAugIAbgnQAcgmgIgtQgJgvgmgbQgngbgtAIQguAIgcAmQgRAZgDAdQgDAdAMAcIAagMIgKA/IgxgjIATgJQgPgfAEgjQAEghATgcQAfgrA1gKQA0gJAsAfQAsAeAKA1QAJA1gfAsQgfAsg1AJQgMACgMAAQgmAAgigXg");
            this.shape_4.setTransform(33.5559,32.5681,2.5026,2.5034);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_nutzung_icon, new cjs.Rectangle(0,0,67.1,65.2), null);
        
        
        (lib.btn_icon_regenerative_nutzung_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AgRA0IAAhnIAjAAIAABng");
            this.shape.setTransform(4.5658,13.0615,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_nutzung_hl, new cjs.Rectangle(0,0,9.2,26.2), null);
        
        
        (lib.btn_icon_regenerative_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgbgTIARgEIAIAZIAagGIAEARIgsALg");
            this.shape.setTransform(31.9961,43.4952,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgQA1IAWgqIgqAAIAohIIAQAJIgYAuIApAAIglBEg");
            this.shape_1.setTransform(33.0585,30.7462,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AhGBrIAJgOQAeAUAjAAQAbAAAZgMQAZgMAQgYQAbgmgIgtQgIgvgngbQgegVgjAAQgaAAgZAMQgZAOgQAWQgRAZgDAdQgDAdAMAcIAZgMIgJA/IgygjIATgJQgOggAEgiQADghAUgcQASgbAdgOQAcgOAeAAQApAAAiAYQAsAeAJA1QAJA1gfArQgTAbgcAPQgcAOgfAAQgpAAghgXg");
            this.shape_2.setTransform(33.5656,32.5,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_icon_w, new cjs.Rectangle(0,0,67.2,65), null);
        
        
        (lib.btn_icon_regenerative_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AgbgTIARgEIAIAZIAagGIAEAQIgrAMg");
            this.shape.setTransform(31.8401,43.5556,2.4995,2.5002);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AgRA1IAWgpIgpAAIAohJIAQAJIgZAuIAqAAIgmBEg");
            this.shape_1.setTransform(33.0274,30.7419,2.4995,2.5002);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AhGBqIAJgNQAnAbAtgIQAugIAcgnQAbgmgIgtQgJgvgmgbQgmgbguAIQguAIgcAnQgRAYgDAdQgDAdAMAcIAagMIgKA/IgxgjIATgJQgOggADgiQAEghATgcQAfgsA1gJQA0gJAsAfQAsAeAKA2QAJA0gfAsQgfAsg1AJQgMACgLAAQgnAAgigYg");
            this.shape_2.setTransform(33.4781,32.5368,2.5,2.5006);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_icon, new cjs.Rectangle(0,0,67,65.1), null);
        
        
        (lib.btn_icon_regenerative_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("AgggWIAVgGIAIAfIAegIIAGAUIgzAOg");
            this.shape.setTransform(9.4892,33.0431,2.4995,2.5002);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#56A28D").s().p("AgUA+IAagxIgwAAIAuhUIATAKIgdA2IAxAAIgsBPg");
            this.shape_1.setTransform(10.8015,18.1042,2.4995,2.5002);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_hl, new cjs.Rectangle(0,0,21.6,40.3), null);
        
        
        (lib.btn_icon_regenerative_energie_energiegewinnung_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#57A28D").s().p("AgUAVQgJgJAAgMQAAgMAJgIQAIgJAMAAQAMAAAJAJQAJAIAAAMQAAANgJAIQgIAJgNAAQgMAAgIgJg");
            this.shape.setTransform(7.1692,40.3492,2.37,2.37);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#57A28D").s().p("AgUAVQgJgJAAgMQAAgMAJgIQAIgJAMAAQAMAAAJAJQAJAIAAAMQAAANgJAIQgIAJgNAAQgMAAgIgJg");
            this.shape_1.setTransform(50.6586,40.3492,2.37,2.37);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#57A28D").s().p("AgUAVQgJgJAAgMQAAgMAJgIQAIgJAMAAQAMAAAJAJQAJAIAAAMQAAANgJAIQgIAJgNAAQgMAAgIgJg");
            this.shape_2.setTransform(28.6177,7.1692,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_energie_energiegewinnung_hl, new cjs.Rectangle(0,0,57.9,47.5), null);
        
        
        (lib.btn_icon_regenerative_energie_analyse_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AgoAVIAAgpIBRAAIAAApg");
            this.shape.setTransform(9.7762,4.9177,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_energie_analyse_hl, new cjs.Rectangle(0,0,19.6,9.9), null);
        
        
        (lib.btn_icon_regenerative_effizienz_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgUAVQgIgJgBgMQABgLAIgJQAJgJALABQAMgBAJAJQAIAJABALQgBAMgIAJQgJAIgMAAQgLAAgJgIgAgJgJQgEAEgBAFQABAGAEAEQAEAEAFAAQAGAAAFgEQADgEAAgGQAAgFgDgEQgFgFgGAAQgFAAgEAFg");
            this.shape.setTransform(53.2323,42.3849,2.4994,2.4994);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgTAVQgJgJAAgMQAAgLAJgJQAIgJALABQAMgBAJAJQAIAJAAALQAAAMgIAJQgJAIgMAAQgLAAgIgIgAgJgJQgEAEAAAFQAAAGAEAEQAEAEAFAAQAGAAAEgEQAEgEAAgGQAAgFgEgEQgEgFgGAAQgFAAgEAFg");
            this.shape_1.setTransform(7.3685,42.3849,2.4994,2.4994);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgUAVQgJgJAAgMQAAgMAJgIQAJgJALAAQANAAAIAJQAJAIAAAMQAAAMgJAJQgIAJgNAAQgLAAgJgJgAgJgJQgEAEAAAFQAAAGAEAEQAEAEAFAAQAGAAAFgEQAEgEgBgGQABgFgEgEQgFgFgGABQgFgBgEAFg");
            this.shape_2.setTransform(29.988,7.3935,2.4994,2.4994);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AAUA3QABgGAAgIQAAgcgPgWQgPgXgagLIAGgOQAdANASAaQASAaAAAhQAAAJgBAIg");
            this.shape_3.setTransform(47.5462,22.5773,2.4994,2.4994);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgpAPQgXgMgPgUIANgJQAMASAUAKQATAKAWAAQAkAAAbgZIAKALQgfAdgqAAQgZAAgXgMg");
            this.shape_4.setTransform(28.3634,52.6949,2.4994,2.4994);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgqA0QAAglAXgcQAXgeAkgIIADAPQgfAHgTAZQgUAYAAAgg");
            this.shape_5.setTransform(14.1793,19.8279,2.4994,2.4994);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("AgWgQIAPgDIAFAVIAVgFIAEANIgjAKg");
            this.shape_6.setTransform(29.0507,41.9475,2.4994,2.4994);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#FFFFFF").s().p("AgOAsIASgjIghAAIAgg7IANAHIgUAmIAiAAIgeA4g");
            this.shape_7.setTransform(30.0505,31.3876,2.4994,2.4994);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_effizienz_icon_w, new cjs.Rectangle(0,0,60.6,59.4), null);
        
        
        (lib.btn_icon_regenerative_effizienz_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#333333").s().p("Ah8EMQgygagjgsQgJACgKABQgfAAgVgWQgWgWAAgeQAAggAWgUQAVgWAfAAQAdAAAXAWQAWAUAAAgQAAAdgVAWQAdAjAoAWQAxAYA3AAQBaAABChAIAaAcQhNBKhpAAQhAAAg6gdgAj9BmQgLAKABAPQgBAPALAKQAKAKAPAAQAOAAAMgKQAKgKAAgPQAAgPgKgKQgMgMgOABQgPgBgKAMgACyCzQgWgWgBgeQABggAWgUQAUgUAagCIABgWQAAhGgmg5Qgng6hCgaIAPgiQBMAeAtBDQAtBEAABQQAAAPgCAOQALAFAKAKQAVAUAAAgQAAAegVAWQgXAWgdAAQgfAAgVgWgADNBmQgMAKAAAPQAAAPAMAKQAKAKAPAAQAPAAALgKQAJgKABgPQgBgPgJgKQgLgMgPABQgPgBgKAMgAhGBQIAmgIIAHAdIAhg8IhVAAIBSiXIAhASIgzBeIBWAAIhBB2IAbgIIAKAkIhaAZgAkNAfQAAhcA8hIQA1hEBQgWQAAgfAWgUQAWgWAeAAQAeAAAWAWQAUAUAAAgQAAAegUAXQgWAUgeABQgegBgWgUQgIgJgFgJQhDAUguA5QgyA/ABBOgAgbj3QgKAKAAAPQAAAPAKAKQAKAKAPAAQAOAAALgKQAKgKAAgPQAAgPgKgKQgLgLgOAAQgPAAgKALg");
            this.shape.setTransform(30.3,29.7);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_effizienz_icon, new cjs.Rectangle(0,0,60.6,59.4), null);
        
        
        (lib.btn_icon_regenerative_analyse_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AhSBuIAAgNIB7AAIAAg7IgYAYIgsAOIgwAAIAAgOIA1AAIAGgUIg7AAIAAgOIBCAAIATgVIhVAAIAAgNIBjAAIA3g3IAfAfIg3A2IAABWgAgBAnIgFAQIAQgFIBRhQIgMgLgAhtBuIAAjNIApAAIAAgOIBSAAIAAAOIApAAIAAAiIgOAAIAAgUIgbAAIAAANIhSAAIAAgNIgcAAIAAC/gAg2hRIA2AAIAAgOIg2AAg");
            this.shape.setTransform(27.6053,27.5856,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_analyse_icon_w, new cjs.Rectangle(0,0,55.2,55.2), null);
        
        
        (lib.btn_icon_regenerative_analyse_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#333333").s().p("AjOEUIAAgiIE2AAIAAiSIg9A7IhwAiIh4AAIAAgiICEAAIAPgyIiTAAIAAgjICkAAIAyg0IjWAAIAAgiID6AAICJiKIBOBOIiLCJIAADXgAgDBhIgNApIApgNIDKjJIgdgdgAkTEUIAAoEIBoAAIAAgjIDNAAIAAAjIBnAAIAABWIghAAIAAgzIhGAAIAAAhIjNAAIAAghIhGAAIAAHhgAiJjNICJAAIAAgjIiJAAg");
            this.shape.setTransform(27.6,27.6);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_analyse_icon, new cjs.Rectangle(0,0,55.2,55.2), null);
        
        
        (lib.btn_icon_lueftung_lueftung_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ag1A2QgXgWAAggQAAgfAXgWQAWgXAfAAQAgAAAWAXQAXAWAAAfQAAAggXAWQgWAXggAAQgfAAgWgXgAgqgqQgSASAAAYQAAAZASASQASASAYAAQAaAAASgSQASgSAAgZQAAgYgSgSQgSgSgaAAQgYAAgSASg");
            this.shape.setTransform(26.8335,47.3074,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("ABFBrIAAgPIAXAAIAAhqIhchJIhcBJIAABqIAYAAIAAAPIgmAAIAAiBIBqhVIBsBVIAACBg");
            this.shape_1.setTransform(26.896,26.8715,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgHgLIASATIgVAEgAgJAKIAQgEIgNgNg");
            this.shape_2.setTransform(33.5119,53.4212,2.4994,2.4994);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgGgJIAQAQIgTADg");
            this.shape_3.setTransform(33.3869,53.5462,2.4994,2.4994);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgLAVIABAAQAKgHADgNQAEgLgHgMIAAgBIAHgDIAAAAQAIAPgEAOQgEAQgNAIIgBABgAACgWQAHAMgEAMQgDANgLAGIACAEQAMgIAEgPQAEgNgHgNg");
            this.shape_4.setTransform(33.9643,48.9848,2.4994,2.4994);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgKAVQAKgHAEgNQAEgMgHgMIAFgCQAIANgFAPQgEAPgMAJg");
            this.shape_5.setTransform(34.0267,48.9848,2.4994,2.4994);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("AgCgMIANASIgVAHgAgHAKIAPgFIgJgNg");
            this.shape_6.setTransform(30.575,38.5497,2.4994,2.4994);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#FFFFFF").s().p("AgCgLIAMAQIgTAHg");
            this.shape_7.setTransform(30.575,38.6122,2.4994,2.4994);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#FFFFFF").s().p("AgbAIIABgBQAIgNAQgEQAOgEAPAIIABAAIgEAGIgBAAQgMgGgLAEQgNACgGALIgBABgAgCgIQgOAEgIAMIAEACQAHgLAMgDQANgDALAGIACgEQgJgEgJAAIgJABg");
            this.shape_8.setTransform(25.4512,40.4092,2.4994,2.4994);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#FFFFFF").s().p("AgZAIQAHgMAQgFQAPgEANAIIgDAFQgLgGgMADQgNADgHALg");
            this.shape_9.setTransform(25.4512,40.3201,2.4994,2.4994);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#FFFFFF").s().p("AgCgKIAQAQIgaAFgAgJAIIASgDIgKgLg");
            this.shape_10.setTransform(18.6403,48.2974,2.4994,2.4994);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#FFFFFF").s().p("AgCgIIANANIgVAEg");
            this.shape_11.setTransform(18.6403,48.4224,2.4994,2.4994);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#FFFFFF").s().p("AATAUQgQAAgLgLQgLgLAAgQIAAgBIAIAAIAAABQAAANAJAIQAIAJANAAIABAAIAAAIgAgGAHQAJALAPAAIAAgEQgNAAgIgKQgKgIAAgNIgEAAQAAAPALAJg");
            this.shape_12.setTransform(22.327,52.0465,2.4994,2.4994);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f("#FFFFFF").s().p("AgHAIQgLgKAAgQIAGAAQAAANAJAJQAJAJANAAIAAAGQgPAAgLgLg");
            this.shape_13.setTransform(22.327,52.0465,2.4994,2.4994);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_lueftung_icon_w, new cjs.Rectangle(0,0,53.8,66.5), null);
        
        
        (lib.btn_icon_lueftung_lueftung_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ag1A2QgXgWAAggQAAgfAXgWQAWgXAfAAQAgAAAWAXQAXAWAAAfQAAAggXAWQgWAXggAAQgfAAgWgXgAgqgqQgSASAAAYQAAAZASASQASASAYAAQAZAAASgSQASgSAAgZQAAgYgSgSQgSgSgZAAQgYAAgSASg");
            this.shape.setTransform(26.9335,47.3074,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("ABFBrIAAgPIAXAAIAAhqIhchJIhbBJIAABqIAXAAIAAAPIgnAAIAAiBIBrhVIBsBVIAACBg");
            this.shape_1.setTransform(26.9335,26.8715,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgHgLIATATIgXAEgAgIAKIAQgEIgOgNg");
            this.shape_2.setTransform(33.5494,53.4212,2.4994,2.4994);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgGgJIAQAQIgTADg");
            this.shape_3.setTransform(33.4869,53.5462,2.4994,2.4994);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("AgLAVIABAAQAKgHADgNQAEgLgHgMIAAgBIAGgDIABAAQAIAPgEAOQgEARgOAHIAAABgAACgWQAHAMgEAMQgDANgLAGIACAEQAMgIAEgPQAEgNgHgNg");
            this.shape_4.setTransform(34.0565,48.9848,2.4994,2.4994);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#020203").s().p("AgKAVQALgHADgNQAEgMgHgMIAFgCQAIANgEAPQgEAPgNAJg");
            this.shape_5.setTransform(34.0643,48.9848,2.4994,2.4994);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#020203").s().p("AgCgMIANASIgVAHgAgHAKIAPgFIgJgNg");
            this.shape_6.setTransform(30.675,38.5497,2.4994,2.4994);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#020203").s().p("AgCgLIAMAQIgTAHg");
            this.shape_7.setTransform(30.675,38.6122,2.4994,2.4994);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#020203").s().p("AgbAIIABgBQAIgNAQgEQAOgEAPAIIABAAIgEAGIgBAAQgMgGgLAEQgNACgGALIgBABgAgCgIQgOADgIANIADACQAHgLANgDQAMgDAMAGIACgEQgJgEgJAAIgJABg");
            this.shape_8.setTransform(25.5512,40.4092,2.4994,2.4994);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#020203").s().p("AgZAIQAHgMAQgFQAOgDAOAHIgDAFQgLgGgMADQgNADgHALg");
            this.shape_9.setTransform(25.5512,40.3476,2.4994,2.4994);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#020203").s().p("AgCgKIAQAQIgaAFgAgJAIIASgDIgLgLg");
            this.shape_10.setTransform(18.7403,48.2974,2.4994,2.4994);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#020203").s().p("AgCgIIAOANIgXAEg");
            this.shape_11.setTransform(18.6778,48.4224,2.4994,2.4994);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#020203").s().p("AATAUQgQAAgLgLQgLgLAAgQIAAgBIAIAAIAAABQAAANAJAIQAIAJANAAIABAAIAAAIgAgGAHQAJALAPAAIAAgEQgNAAgJgKQgJgIAAgNIgEAAQAAAPALAJg");
            this.shape_12.setTransform(22.427,52.0465,2.4994,2.4994);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f("#020203").s().p("AgHAIQgLgKAAgQIAGAAQAAANAJAJQAJAJANAAIAAAGQgQAAgKgLg");
            this.shape_13.setTransform(22.427,52.0465,2.4994,2.4994);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_lueftung_icon, new cjs.Rectangle(0,0,53.9,66.5), null);
        
        
        (lib.btn_icon_lueftung_lueftung_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("Ag1A2QgXgWAAggQAAgfAXgWQAWgXAfAAQAgAAAXAXQAWAWAAAfQAAAggWAWQgXAXggAAQgfAAgWgXg");
            this.shape.setTransform(19.25,19.1625,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_lueftung_hl, new cjs.Rectangle(0,0,38.5,38.4), null);
        
        
        (lib.btn_icon_lueftung_kuehlung_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ag1A2QgXgWAAggQAAgfAXgWQAWgXAfAAQAgAAAWAXQAXAWAAAfQAAAggXAWQgWAXggAAQgfAAgWgXgAgqgqQgSASAAAYQAAAaASASQASASAYAAQAaAAASgSQASgSAAgaQAAgYgSgSQgSgSgaAAQgYAAgSASg");
            this.shape.setTransform(26.832,47.3055,2.4997,2.4997);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("ABFBrIAAgOIAXAAIAAhrIhchJIhcBJIAABrIAYAAIAAAOIgmAAIAAiBIBqhVIBsBVIAACBg");
            this.shape_1.setTransform(26.8945,26.8702,2.4997,2.4997);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AglAEIAAgHIBLAAIAAAHg");
            this.shape_2.setTransform(26.8899,47.3621,2.4995,2.4995);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgWgeIAHgEIAlBBIgHAEg");
            this.shape_3.setTransform(26.8899,47.3621,2.4995,2.4995);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgWAfIAlhBIAHAEIglBBg");
            this.shape_4.setTransform(26.8899,47.3621,2.4995,2.4995);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_kuehlung_icon_w, new cjs.Rectangle(0,0,53.8,66.5), null);
        
        
        (lib.btn_icon_lueftung_kuehlung_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ag1A2QgXgWAAggQAAgfAXgWQAWgXAfAAQAgAAAWAXQAXAWAAAfQAAAggXAWQgWAXggAAQgfAAgWgXgAgqgqQgSASAAAYQAAAaASASQASASAYAAQAZAAATgSQASgSAAgaQAAgYgSgSQgTgSgZAAQgYAAgSASg");
            this.shape.setTransform(26.8335,47.3074,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("ABFBrIAAgOIAXAAIAAhrIhchJIhcBJIAABrIAYAAIAAAOIgmAAIAAiBIBqhVIBsBVIAACBg");
            this.shape_1.setTransform(26.896,26.8715,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AglAEIAAgHIBLAAIAAAHg");
            this.shape_2.setTransform(26.893,47.366,2.4996,2.4996);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgWgeIAHgEIAlBBIgHAEg");
            this.shape_3.setTransform(26.893,47.366,2.4996,2.4996);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("AgWAfIAlhBIAHAEIglBBg");
            this.shape_4.setTransform(26.893,47.366,2.4996,2.4996);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_kuehlung_icon, new cjs.Rectangle(0,0,53.8,66.5), null);
        
        
        (lib.btn_icon_lueftung_kuehlung_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("Ag1A2QgXgWAAggQAAgfAXgXQAWgWAfAAQAgAAAWAWQAXAXAAAfQAAAggXAWQgWAXggAAQgfAAgWgXg");
            this.shape.setTransform(19.1875,19.225,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_kuehlung_hl, new cjs.Rectangle(0,0,38.4,38.5), null);
        
        
        (lib.btn_icon_lueftung_komfort_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AAPB6IhYgYIgNhzIBkhpIAMALQAFAFADAHQADAGAAAGQAAAFgDAGIgVA2IArAAQANAAAJAJQAJAJAAAMQAAAFgCADIgXBPQgDANgLAHQgKAIgNAAgAhFgMIAKBiIBOAVQAJACAIgEQAJgFACgJIAYhPIAAgEQAAgHgEgDQgFgFgGAAIhAAAIAchKIABgGIgBgGQAAgCgFgFIgBAAg");
            this.shape.setTransform(39.8545,30.8413,2.5028,2.5028);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgaBFIAAiJIA1AAIAACJgAgLA2IAXAAIAAhrIgXAAg");
            this.shape_1.setTransform(6.7555,40.4769,2.5028,2.5028);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_komfort_icon_w, new cjs.Rectangle(0,0,61.6,61.7), null);
        
        
        (lib.btn_icon_lueftung_komfort_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AAPB6IhYgYIgNhzIBkhpIAMALQAGAHACAFQADAGAAAFQAAAGgDAHIgVA1IArAAQANAAAJAJQAJAKAAALIgCAIIgXBQQgEALgKAIQgKAIgNAAgAhFgLIAKBhIBOAVQAJACAIgEQAJgFACgJIAYhPIAAgEQAAgGgEgEQgFgFgGAAIhAAAIAchLIABgGIgBgFQgBgDgEgEIgBAAg");
            this.shape.setTransform(39.8516,30.7664,2.5026,2.5034);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AgaBFIAAiJIA1AAIAACJgAgLA2IAXAAIAAhrIgXAAg");
            this.shape_1.setTransform(6.7542,40.4045,2.5026,2.5034);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_komfort_icon, new cjs.Rectangle(0,0,61.6,61.6), null);
        
        
        (lib.btn_icon_lueftung_komfort_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("AgfBQIAAifIA/AAIAACfg");
            this.shape.setTransform(7.9406,20.1771,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_komfort_hl, new cjs.Rectangle(0,0,15.9,40.4), null);
        
        
        (lib.btn_icon_lueftung_installation_icon_W = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ag2A3QgXgXAAggQAAgfAXgXQAXgXAfAAQAhAAAWAXQAXAXAAAfQAAAggXAXQgXAXggAAQgfAAgXgXgAgqgqQgRASAAAYQAAAZARASQASARAYAAQAZAAASgRQASgSAAgZQAAgNgHgNIg+A5IgigmIAMAAIAXAaIA5g1QgTgagfAAQgYAAgSASg");
            this.shape.setTransform(27.8315,48.0802,2.4997,2.4997);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AhJBlIAAjJICTAAIAACBIgRAAIAAgzIg0AAIAAAzIgIAAIAAgzIg0AAIAABqIAaAAIAAARgAAFgfIA0AAIAAg0Ig0AAgAg3gfIA0AAIAAg0Ig0AAg");
            this.shape_1.setTransform(18.3951,25.2702,2.4997,2.4997);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_installation_icon_W, new cjs.Rectangle(0,0,47.3,67.5), null);
        
        
        (lib.btn_icon_lueftung_installation_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ag2A3QgXgXAAggQAAgfAXgXQAXgXAfAAQAgAAAXAXQAXAXAAAfQAAAggXAXQgXAXggAAQgfAAgXgXgAgqgqQgRASgBAYQABAZARASQASARAYAAQAZAAASgRQARgSAAgZQAAgPgGgLIg+A5IgigmIAMAAIAWAaIA5g1QgIgMgNgHQgNgHgPAAQgYAAgSASg");
            this.shape.setTransform(27.8332,48.0822,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AhJBlIAAjJICTAAIAACBIgSAAIAAgzIg0AAIAAAzIgHAAIAAgzIg1AAIAABqIAaAAIAAARgAAEgfIA0AAIAAg0Ig0AAgAg4gfIA1AAIAAg0Ig1AAg");
            this.shape_1.setTransform(18.3964,25.2715,2.4998,2.4998);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_installation_icon, new cjs.Rectangle(0,0,47.3,67.5), null);
        
        
        (lib.btn_icon_lueftung_installation_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("AhEBbIAAi1ICIAAIAAC1g");
            this.shape.setTransform(17.1,22.6875,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_installation_hl, new cjs.Rectangle(0,0,34.3,45.4), null);
        
        
        (lib.btn_icon_lueftung_heizung_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ag1A2QgXgWAAggQAAgfAXgWQAWgXAfAAQAgAAAWAXQAXAWAAAfQAAAggXAWQgWAXggAAQgfAAgWgXgAgqgqQgSASAAAYQAAAZASASQASASAYAAQAaAAASgSQASgSAAgZQAAgYgSgSQgSgSgaAAQgYAAgSASg");
            this.shape.setTransform(26.8371,47.3977,2.5025,2.5033);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AABAjQABgEgEgFQgGgGAAgIQAAgIAGgGQAEgEgBgFQABgEgEgFQgGgGAAgIIAHAAQAAAEADAFQAIAHgBAHQABAIgIAGQgDAEAAAFQAAAEADAFQAIAHgBAHg");
            this.shape_1.setTransform(34.2725,46.8854,2.5021,2.5029);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AACAjQAAgEgEgFQgGgHgBgHQABgIAGgGQAEgEAAgFQAAgEgEgFQgGgHgBgHIAIAAQAAAFAEAEQAHAGgBAIQABAIgHAGQgEAEAAAFQAAAFAEAEQAHAGgBAIg");
            this.shape_2.setTransform(27.1415,46.8854,2.5021,2.5029);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AACAjQAAgEgEgFQgGgHAAgHQAAgIAGgGQAEgEAAgFQAAgEgEgFQgGgHAAgHIAIAAQgBAEAEAFQAGAGABAIQgBAIgGAGQgEAEABAFQgBAEAEAFQAGAGABAIg");
            this.shape_3.setTransform(20.0105,46.8854,2.5021,2.5029);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("ABFBrIAAgPIAXAAIAAhqIhchJIhcBJIAABqIAYAAIAAAPIgnAAIAAiBIBrhVIBrBVIAACBg");
            this.shape_4.setTransform(26.8996,26.933,2.5025,2.5033);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_heizung_icon_w, new cjs.Rectangle(0,0,53.8,66.6), null);
        
        
        (lib.btn_icon_lueftung_heizung_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ag1A2QgXgWAAggQAAgfAXgWQAWgXAfAAQAgAAAWAXQAXAWAAAfQAAAggXAWQgWAXggAAQgfAAgWgXgAgqgqQgSASAAAYQAAAaASASQASASAYAAQAaAAASgSQASgSAAgaQAAgYgSgSQgSgSgaAAQgYAAgSASg");
            this.shape.setTransform(26.8416,47.4016,2.5028,2.5035);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AABAjQABgEgEgFQgGgHAAgHQAAgIAGgGQAEgFgBgEQABgDgEgGQgGgHAAgHIAHAAQAAADADAGQAIAHgBAHQABAIgIAGQgDAFAAAEQAAAEADAFQAIAHgBAHg");
            this.shape_1.setTransform(34.2821,46.9577,2.5025,2.5033);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AACAjQAAgEgEgFQgHgHAAgHQAAgIAHgGQAEgEAAgFQAAgEgEgFQgHgHAAgHIAIAAQAAAEAEAFQAHAGgBAIQABAIgHAGQgEAEAAAFQAAAEAEAFQAHAHgBAHg");
            this.shape_2.setTransform(27.1499,46.9577,2.5025,2.5033);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AACAjQAAgEgEgFQgGgHAAgHQAAgHAGgHQAEgFAAgEQAAgDgEgGQgGgHAAgHIAIAAQgBADAEAGQAGAGABAIQgBAIgGAGQgEAFABAEQgBAEAEAFQAGAHABAHg");
            this.shape_3.setTransform(20.0177,46.9577,2.5025,2.5033);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("ABFBrIAAgOIAXAAIAAhrIhchJIhcBJIAABrIAYAAIAAAOIgnAAIAAiBIBrhVIBrBVIAACBg");
            this.shape_4.setTransform(26.9041,26.9357,2.5028,2.5035);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_heizung_icon, new cjs.Rectangle(0,0,53.8,66.6), null);
        
        
        (lib.btn_icon_lueftung_heizung_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("Ag1A3QgXgXAAggQAAgfAXgXQAWgWAfAAQAgAAAWAWQAXAXAAAfQAAAggXAXQgWAWggAAQgfAAgWgWg");
            this.shape.setTransform(19.1957,19.2705,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_heizung_hl, new cjs.Rectangle(0,0,38.4,38.6), null);
        
        
        (lib.btn_icon_lueftung_energie_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgKAPQAAgNAFgGQAJgMgCgKIAIgBQACANgMAOQgEAGAEATIgHACQgCgDgBgJg");
            this.shape.setTransform(20.4003,32.6444,2.4997,2.4997);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgKAsQgGgDgFgGQgGgGgCgIQgCgNANgJQAMgKADgMIABgMIgBgJIAHAGQAHAFAIAJQANASgCAQQgCAVgPAJQgIAFgHAAIgIgBgAgNADQgKAIACAJQABAFAEAFQAEAFAFABQAIADAJgFQAKgHACgRQADgRgUgUQgBASgRAMg");
            this.shape_1.setTransform(21.1197,24.2703,2.4997,2.4997);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgRAFQgIgGABgLIAPAAQAAAEADADQADADADAAQAEAAADgDQAEgDAAgEIAOAAQABALgIAGQgIAIgKAAQgJAAgIgIg");
            this.shape_2.setTransform(19.9948,58.0791,2.4997,2.4997);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgeAIQgIAAAAgIQAAgCACgCQADgDADAAIA9AAQAIAAAAAHQAAAIgIAAg");
            this.shape_3.setTransform(19.9948,50.8299,2.4997,2.4997);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgeAHQgIAAAAgHQAAgCACgCQADgCADAAIA9AAQAIgBAAAHQAAAHgIAAg");
            this.shape_4.setTransform(19.9948,45.268,2.4997,2.4997);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AAbBTQAAgWARgRQAOgMAEgSQAFgQgFgSQgGgRgNgNQgOgMgTgDQgcgFgXAUQgXATAAAeQAAAZAUAUQARARAAAWIgPAAQAAgPgMgNQgZgYAAggQAAgkAcgZQAcgYAkAGQAWAEASAPQARAPAHAWQAGAWgGAUQgFAWgRAQQgMAMAAAPg");
            this.shape_5.setTransform(20.0423,20.6425,2.4997,2.4997);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_energie_icon_w, new cjs.Rectangle(0,0,40.1,61.3), null);
        
        
        (lib.btn_icon_lueftung_energie_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AgFgEQAJgMgBgKIAHgBQACANgMAOQgFAIAFARIgHACQgGgVAIgKg");
            this.shape.setTransform(20.3091,32.6459,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AgJAsQgHgDgGgGQgGgGgBgIQgCgOAOgJQAMgJADgMIABgMIgCgJIAHAGIAOAOQAOASgCAQQgDAVgOAJQgHAFgIAAgAgNADQgKAIACAJQAAAFAFAFQAEAFAFABQAIADAIgFQALgHACgRQACgSgTgTQgCATgQALg");
            this.shape_1.setTransform(21.0837,24.2716,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgRAGQgIgHAAgLIAQAAQAAAEACADQAEAEADAAQAEAAADgEQADgDAAgEIAQAAQgBALgHAHQgIAHgKAAQgKAAgHgHg");
            this.shape_2.setTransform(20.0212,58.0189,2.4998,2.4998);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgeAIQgEAAgBgDQgDgCAAgDQAAgCADgCQABgDAEAAIA+AAQACAAADADQACACAAACQAAAIgHAAg");
            this.shape_3.setTransform(20.0212,50.832,2.4998,2.4998);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("AgeAHQgEAAgBgBQgDgDAAgDQAAgHAIABIA+AAQAHgBAAAHQAAADgCADQgDABgCAAg");
            this.shape_4.setTransform(20.0212,45.2699,2.4998,2.4998);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#020203").s().p("AgqBTQAAgPgNgNQgYgYAAggQAAglAcgYQAbgYAlAGQAWAEASAPQARAPAGAWQAHAWgGAUQgGAVgQAQQgNANAAAOIgPAAQAAgVARgRQAOgNAEgRQAFgQgFgSQgFgRgPgNQgOgMgSgDQgcgEgXATQgXATAAAeQAAAaAUATQARAQAAAXg");
            this.shape_5.setTransform(20.0238,20.6437,2.4998,2.4998);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_energie_icon, new cjs.Rectangle(0,0,40,61.3), null);
        
        
        (lib.btn_icon_lueftung_energie_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("AgTAfQgIgIAAgLIAAgKIgLAAQgEAAgDgCQgEgDAAgEIAAgVQAAgEAEgDQADgDAEgBIBNAAQAEABAEADQADADAAAEIAAAVQAAAEgDADQgEACgEAAIgLAAIAAAKQAAALgIAIQgJAJgLAAQgLAAgIgJg");
            this.shape.setTransform(12.4375,9.875,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_energie_hl, new cjs.Rectangle(0,0,24.9,19.8), null);
        
        
        (lib.btn_icon_innovativ_thermik_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgEAVIAAgRIgQAAIAAgHIAQAAIAAgRIAIAAIAAARIARAAIAAAHIgRAAIAAARg");
            this.shape.setTransform(32.6726,15.5608,2.5028,2.5034);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgNAEIAAgHIAbAAIAAAHg");
            this.shape_1.setTransform(32.1721,44.5999,2.5028,2.5034);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgHAIQgDgDAAgFQAAgDADgDQADgEAEAAQAEAAAEAEQADADAAADQAAAFgDADQgEADgEAAQgEAAgDgDg");
            this.shape_2.setTransform(9.9601,47.9795,2.5028,2.5034);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgbBoQgMgLAAgRQAAgVASgMIAAiIQAAgJAHgGQAGgHAIAAQAJAAAGAHQAHAGAAAJIAACIQASAMAAAVQAAARgMALQgMAMgQAAQgPAAgMgMgAgGhdIAACQIgEACQgOAIAAAPQAAALAHAHQAHAHAKAAQAKAAAIgHQAHgHAAgLQAAgPgOgIIgEgCIAAiQQAAgHgHAAQgGAAAAAHg");
            this.shape_3.setTransform(9.9601,28.9538,2.5028,2.5034);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_thermik_icon_w, new cjs.Rectangle(0,0,37.8,58), null);
        
        
        (lib.btn_icon_innovativ_thermik_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AgEAVIAAgRIgPAAIAAgHIAPAAIAAgRIAIAAIAAARIAQAAIAAAHIgQAAIAAARg");
            this.shape.setTransform(32.5968,15.5607,2.4999,2.4999);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AgNAEIAAgHIAbAAIAAAHg");
            this.shape_1.setTransform(32.0968,44.5593,2.4999,2.4999);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgGAIQgEgEAAgEQAAgDAEgEQADgDADAAQAFAAADADQADAEAAADQAAAEgDAEQgDADgFAAQgDAAgDgDg");
            this.shape_2.setTransform(9.9104,47.9966,2.4999,2.4999);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgbBoQgMgLAAgRQAAgVASgMIAAiIQAAgJAHgGQAGgHAIAAQAJAAAHAHQAGAGAAAJIAACIQASAMAAAVQAAARgMALQgLAMgRAAQgPAAgMgMgAgGhdIAACQIgEACQgOAIAAAPQAAALAHAHQAIAHAJAAQALAAAHgHQAHgHAAgLQAAgQgOgHIgEgCIAAiQQAAgHgHAAQgGAAAAAHg");
            this.shape_3.setTransform(9.9104,28.9351,2.4999,2.4999);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_thermik_icon, new cjs.Rectangle(0,0,37.7,57.9), null);
        
        
        (lib.btn_icon_innovativ_thermik_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AgYAZQgKgKAAgPQAAgNAKgLQAKgKAOAAQAOAAALAKQAKALAAANQAAAPgKAKQgLAKgOAAQgOAAgKgKgAgEAAQAAAFAEAAQAFAAAAgFQAAgEgFAAQgEAAAAAEg");
            this.shape.setTransform(8.8125,45.6875,2.5,2.5);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#CB2C61").s().p("AgNBdQgGgGAAgIIAAidQAAgIAGgGQAGgGAHAAQAIAAAGAGQAGAGAAAIIAACdQAAAIgGAGQgGAGgIAAQgHAAgGgGg");
            this.shape_1.setTransform(8.875,24.75,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_thermik_hl, new cjs.Rectangle(0,0,17.7,54.5), null);
        
        
        (lib.btn_icon_Innovativ_stabi_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgbAOIAAgMIAXAXIAAhDIAJAAIAABDIAXgXIAAAMIgcAdg");
            this.shape.setTransform(51.7619,10.684,2.5026,2.5034);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AglAaIAAgQIAfAfIAAhoIANAAIAABoIAfgfIAAAQIgmAmg");
            this.shape_1.setTransform(30.99,15.9412,2.5026,2.5034);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgcAOIAAgMIAYAXIAAhDIAJAAIAABDIAYgXIAAAMIgdAdg");
            this.shape_2.setTransform(10.2806,10.684,2.5026,2.5034);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("Ah5gCIAFgOQA5ASA7AAQA9AAA4gSIAEAOQg7ATg+AAQg8AAg9gTg");
            this.shape_3.setTransform(30.4209,35.1469,2.5023,2.5031);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("Ah5gCIAFgOQA5ASA7AAQA9AAA4gSIAEAOQg6ATg/AAQg+AAg7gTg");
            this.shape_4.setTransform(30.4209,42.8439,2.5023,2.5031);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("Ah5gCIAFgOQA5ASA7AAQA9AAA4gSIAEAOQg5AThAAAQg+AAg7gTg");
            this.shape_5.setTransform(30.4209,50.5408,2.5023,2.5031);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_Innovativ_stabi_icon_w, new cjs.Rectangle(0,0,60.8,54.8), null);
        
        
        (lib.btn_icon_innovativ_stabi_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AgbAOIAAgMIAXAXIAAhDIAJAAIAABDIAXgXIAAAMIgcAcg");
            this.shape.setTransform(51.7688,10.6475,2.5029,2.5035);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AglAaIAAgRIAfAfIAAhnIANAAIAABnIAfgfIAAARIgmAmg");
            this.shape_1.setTransform(30.9948,15.9673,2.5029,2.5035);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgcAOIAAgMIAYAXIAAhDIAJAAIAABDIAYgXIAAAMIgdAcg");
            this.shape_2.setTransform(10.2835,10.6475,2.5029,2.5035);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("Ah5gCIAFgOQA5ASA7AAQA9AAA4gSIAEAOQg5AThAAAQg+AAg7gTg");
            this.shape_3.setTransform(30.4284,35.0512,2.5027,2.5033);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("Ah5gCIAFgOQA5ASA7AAQA9AAA4gSIAEAOQg7ATg+AAQg8AAg9gTg");
            this.shape_4.setTransform(30.4284,42.8113,2.5027,2.5033);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#020203").s().p("Ah5gCIAFgOQA5ASA7AAQA9AAA4gSIAEAOQg6ATg/AAQg+AAg7gTg");
            this.shape_5.setTransform(30.4284,50.5088,2.5027,2.5033);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_stabi_icon, new cjs.Rectangle(0,0,60.9,54.7), null);
        
        
        (lib.btn_icon_innovativ_stabi_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("Ah4AlIAAgBIgBAAIABhKQA6AQA8gDQA7gDA4gWIAJBKQg7AYg+ADIgSAAQg1AAgygOg");
            this.shape.setTransform(30.4797,12.7509,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_stabi_hl, new cjs.Rectangle(0,0,61,25.5), null);
        
        
        (lib.btn_icon_innovativ_modulation_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("Ah3BdIAAgRIDvAAIAAARgAgHA5IAAiEIhSAAIAAgRICzAAIAAARIhRAAIAACEg");
            this.shape.setTransform(29.9994,37.5243,2.5,2.5);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AhZBqIAAjTICzAAIAADTgAhKBaICVAAIAAizIiVAAg");
            this.shape_1.setTransform(29.9994,26.3994,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_modulation_icon_w, new cjs.Rectangle(0,0,60,60.8), null);
        
        
        (lib.btn_icon_innovativ_modulation_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ah3BdIAAgRIDvAAIAAARgAgHA5IAAiEIhSAAIAAgRICzAAIAAARIhSAAIAACEg");
            this.shape.setTransform(30.0902,37.5528,2.5026,2.5034);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AhZBqIAAjTICzAAIAADTgAhKBaICVAAIAAizIiVAAg");
            this.shape_1.setTransform(30.0902,26.4126,2.5026,2.5034);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_modulation_icon, new cjs.Rectangle(0,0,60.2,60.9), null);
        
        
        (lib.btn_icon_innovativ_modulation_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AhRBhIAAjBICjAAIAADBg");
            this.shape.setTransform(20.6071,24.3722,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_modulation_hl, new cjs.Rectangle(0,0,41.2,48.7), null);
        
        
        (lib.btn_icon_innovativ_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ah2B3IAAiUICUAAIAACUgAhoBoIB3AAIAAh3Ih3AAgAgnBWIAegdIAFAFIgeAegAhUBPIBLhLIAFAGIhLBKgAA8BKIAAiGIh+AAIAAARIgPAAIAAgfICbAAIAACUgAhUAbIAegdIAFAFIgeAdgABoAdIAAiFIh+AAIAAAQIgOAAIAAgeICbAAIAACTg");
            this.shape.setTransform(29.8497,29.7745,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_icon, new cjs.Rectangle(0,0,59.7,59.6), null);
        
        
        (lib.btn_icon_innovativ_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#CB2C61").s().p("AhGBHIAAiNICNAAIAACNg");
            this.shape.setTransform(17.7183,17.7109,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_hl, new cjs.Rectangle(0,0,35.5,35.4), null);
        
        
        (lib.btn_icon_innovativ_dimension_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgOAGIAAgLIAdAAIAAALg");
            this.shape.setTransform(57.8066,51.1442,2.4998,2.4998);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgFAPIAAgdIALAAIAAAdg");
            this.shape_1.setTransform(10.6228,3.8355,2.4998,2.4998);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("ABdBpIAAjFIjFAAIAAgMIDRAAIAADRg");
            this.shape_2.setTransform(35.3709,26.3337,2.4998,2.4998);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AAbAiIAbgcIiEAAIAAgLICEAAIgbgcIAJgJIAqAqIgqArg");
            this.shape_3.setTransform(28.7464,51.1442,2.4998,2.4998);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgFBOIAAiDIgcAbIgJgJIAqgqIArAqIgJAJIgcgbIAACDg");
            this.shape_4.setTransform(10.6228,33.0206,2.4998,2.4998);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AAFAyIAkgkIAGAHIgjAjgAgvApIBYhYIAGAHIhYBXgAgugUIAjgjIAGAGIgjAjg");
            this.shape_5.setTransform(39.9955,21.5841,2.4998,2.4998);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_dimension_icon_w, new cjs.Rectangle(0,0,61.6,61.8), null);
        
        
        (lib.btn_icon_innovativ_dimension_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("AgOAGIAAgLIAdAAIAAALg");
            this.shape.setTransform(57.8293,51.142,2.4997,2.4997);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AgFAPIAAgdIALAAIAAAdg");
            this.shape_1.setTransform(10.647,3.8347,2.4997,2.4997);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("ABdBpIAAjFIjFAAIAAgMIDRAAIAADRg");
            this.shape_2.setTransform(35.3943,26.3322,2.4997,2.4997);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AAbAiIAbgcIiDAAIAAgLICDAAIgbgcIAJgJIAqAqIgqArg");
            this.shape_3.setTransform(28.7075,51.142,2.4997,2.4997);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("AgFBOIAAiEIgbAcIgKgJIAqgqIAqAqIgJAJIgbgcIAACEg");
            this.shape_4.setTransform(10.647,33.019,2.4997,2.4997);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#020203").s().p("AAGAyIAjgkIAHAHIgkAjgAgvApIBYhYIAHAHIhYBXgAgugUIAjgjIAHAGIgkAjg");
            this.shape_5.setTransform(39.9563,21.5827,2.4997,2.4997);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_dimension_icon, new cjs.Rectangle(0,0,61.7,61.8), null);
        
        
        (lib.btn_icon_innovativ_dimension_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#56A28D").s().p("AhXBXIAAitICvAAIAACtg");
            this.shape.setTransform(21.85,21.875,2.5,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_dimension_hl, new cjs.Rectangle(0,0,43.8,43.8), null);
        
        
        (lib.btn_icon_climate_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("ABCFKIBHicIAmBBQBig6AehuQAehtg6hjIAsgZQBDB1giCBQgjCDh1BDIAlBBgAjBDmQhfhfAAiHIhLAAIBkiNIBkCNIhLAAQAAByBSBQQBQBRBxAAIAAAyQiFAAhhhfgAhTCDIAAkSIDhAAIAAESgAAqBqIBLAAIAAh8IhLAAgAg6BqIBKAAIAAh8IhKAAgAAqgrIBLAAIAAhLIhLAAgAg6grIBKAAIAAhLIhKAAgAjwiiQBCh0CEgjQCBgjB0BDIgDAGIAohHIBICeIisAQIAmhBQhjg5htAeQhuAcg6Bjg");
            this.shape.setTransform(36.3501,34.675);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_climate_icon_w, new cjs.Rectangle(0,0,72.7,69.4), null);
        
        
        (lib.btn_icon_climate_icon = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#1D1D1C").s().p("AgEAyIAAhjIAJAAIAABjg");
            this.shape.setTransform(39.2768,34.0394,2.5025,2.5033);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#1D1D1C").s().p("AgsA3IAAhtIBZAAIAABtgAgiAtIBFAAIAAhZIhFAAg");
            this.shape_1.setTransform(39.2768,34.0394,2.5025,2.5033);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#1D1D1C").s().p("AgnAFIAAgJIBPAAIAAAJg");
            this.shape_2.setTransform(39.2768,31.536,2.5025,2.5033);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#1D1D1C").s().p("AghAcIAcg+IAnBFg");
            this.shape_3.setTransform(51.4766,60.6998,2.5025,2.5033);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#1D1D1C").s().p("AglBIQAngXAMgsQAMgrgXgoIARgKQAbAvgOAzQgOA0guAbg");
            this.shape_4.setTransform(63.2456,40.6106,2.5025,2.5033);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#1D1D1C").s().p("AAGgiIAcA+IhDAHg");
            this.shape_5.setTransform(58.9842,8.6932,2.5025,2.5033);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#1D1D1C").s().p("AhYAcQAbguA0gOQAzgOAvAbIgKASQgogXgrAMQgsALgXAng");
            this.shape_6.setTransform(34.4594,11.4743,2.5025,2.5033);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#1D1D1C").s().p("AgnAcIAng3IAoA3g");
            this.shape_7.setTransform(9.9972,27.5933,2.5025,2.5033);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#1D1D1C").s().p("AgaAbQgmglAAg2IAUAAQAAAuAgAfQAgAgAtAAIAAAUQg1AAgmgmg");
            this.shape_8.setTransform(23.7611,50.9368,2.5025,2.5033);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_climate_icon, new cjs.Rectangle(0,0,72.7,69.4), null);
        
        
        (lib.btn_icon_climate_hl = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#57A28D").s().p("AgvA0IAAhmIBgAAIAABmg");
            this.shape.setTransform(12.1458,12.903,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_climate_hl, new cjs.Rectangle(0,0,24.3,25.8), null);
        
        
        (lib.btn_home = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_2
            this.shape = new cjs.Shape();
	        this.shape.graphics.f("rgba(255,255,255,0.01)").s().p("AlYE1IAAppIKxAAIAAJpg");
	        this.shape.setTransform(5,10, 2, 2);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            // Ebene_1
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AA6A+IAAg+Ig6gxIg5AxIAAA+IgJAAIAAhCIBCg5IBDA5IAABCg");
            this.shape_1.setTransform(15.9382,14.5755,2.37,2.37);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AAcAhIAAg4IgcAAIAAA4IhCAAIAAgJIA5AAIAAg4IAvAAIAAA4IAdAAIAAAJg");
            this.shape_2.setTransform(15.9382,25.6552,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1}]}).wait(1));
        
            this._renderFirstFrame();
        
}).prototype = getMCSymbolPrototype(lib.btn_home, new cjs.Rectangle(-25.8,-9.7,69,61.8), null);
        
        
        (lib.btn_frame = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AjHDIIAAmPIGPAAIAAGPgAizC0IFnAAIAAlnIlnAAg");
            this.shape.setTransform(49.9887,50,2.5026,2.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_frame, new cjs.Rectangle(0,0,100,100), null);
        
        
        (lib.btn_bg_white = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AnzH0IAAvnIPnAAIAAPng");
            this.shape.setTransform(50,50.025);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_bg_white, new cjs.Rectangle(0,0,100,100.1), null);
        
        
        (lib.btn_back = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_2
            this.shape = new cjs.Shape();
	        this.shape.graphics.f("rgba(255,255,255,0.01)").s().p("Al3FRIAAqhILvAAIAAKhg");
	        this.shape.setTransform(15,10, 1.4, 1.2);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            // Ebene_1
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AAYAtQANAAAJgJQAJgJAAgNQAAgNgJgIQgJgJgMAAIhGAAIAcAaIgPAAIgiggIAigiIAPAAIgcAbIBHAAIAAABQARABAMAMQAMANAAAQQAAASgNANQgNAMgRAAg");
            this.shape_1.setTransform(15.9455,13.6251,2.3799,2.3799);
        
            this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));
        
            this._renderFirstFrame();
        
}).prototype = getMCSymbolPrototype(lib.btn_back, new cjs.Rectangle(-21.4,-18.3,75.1,67.3), null);
        
        
        (lib.btn_active = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("rgba(64,64,64,0.008)").s().p("AnzH0IAAvnIPnAAIAAPng");
            this.shape.setTransform(50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_active, new cjs.Rectangle(0,0,100,100), null);
        
        
        (lib.headline = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // l1
            this.l1 = new lib.headline_l1();
            this.l1.name = "l1";
            this.l1.setTransform(222.5,34,1,1.0096,0,0,0,222.5,33.7);
        
            this.timeline.addTween(cjs.Tween.get(this.l1).wait(1));
        
            // l3
            this.l3 = new lib.headline_l3();
            this.l3.name = "l3";
            this.l3.setTransform(222.5,79.5,1,1,0,0,0,222.5,27.5);
        
            this.timeline.addTween(cjs.Tween.get(this.l3).wait(1));
        
            // l2
            this.l2 = new lib.headline_l2();
            this.l2.name = "l2";
            this.l2.setTransform(222.5,89.5,1,1,0,0,0,222.5,27.5);
        
            this.timeline.addTween(cjs.Tween.get(this.l2).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.headline, new cjs.Rectangle(0,0,445,117), null);
        
        
        (lib.btn_language = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_3
            this.shape = new cjs.Shape();
	this.shape.graphics.f("rgba(255,255,255,0.008)").s().p("AkNEGIAAoMIIbAAIAAIMg");
	this.shape.setTransform(16.35,14.1);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            // en
            this.en = new lib.btn_language_en();
            this.en.name = "en";
            this.en.setTransform(15.8,15.3,1,1,0,0,0,15.8,15.3);
        
            this.timeline.addTween(cjs.Tween.get(this.en).wait(1));
        
            // de
            this.de = new lib.btn_language_de();
            this.de.name = "de";
            this.de.setTransform(15.8,15.3,1,1,0,0,0,15.8,15.3);
        
            this.timeline.addTween(cjs.Tween.get(this.de).wait(1));
        
            this._renderFirstFrame();
        
}).prototype = getMCSymbolPrototype(lib.btn_language, new cjs.Rectangle(-10.6,-12.1,54,52.5), null);
        
        
        (lib.btn_label = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.l2 = new lib.btn_label_l2();
            this.l2.name = "l2";
            this.l2.setTransform(52,23.2,1,1,0,0,0,52,8.6);
        
            this.l1 = new lib.btn_label_l1();
            this.l1.name = "l1";
            this.l1.setTransform(52,9.8,1,1,0,0,0,52,9.8);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.l1},{t:this.l2}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_label, new cjs.Rectangle(0,0,100,33), null);
        
        
        (lib.btn_icon_windowid_urban_mining = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_windowid_urban_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50.65,50,1,1,0,0,0,22.5,30);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_windowid_urban_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50.65,50,1,1,0,0,0,22.5,30);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_windowid_urban_hl();
            this.hl.name = "hl";
            this.hl.setTransform(55.25,42.5,1,1,0,0,0,10.5,10.5);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_urban_mining, new cjs.Rectangle(28.2,20,45,60), null);
        
        
        (lib.btn_icon_windowid_schnelle_hilfe = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_windowid_schnell_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(53.25,50.8,1,1,0,0,0,22.5,28.8);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_windowid_schnell_icon();
            this.icon.name = "icon";
            this.icon.setTransform(53.1,50.8,1,1,0,0,0,22.5,28.8);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_windowid_schnell_hl();
            this.hl.name = "hl";
            this.hl.setTransform(35,57.35,1,1,0,0,0,12,22.1);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_schnelle_hilfe, new cjs.Rectangle(23,22,52.8,57.5), null);
        
        
        (lib.btn_icon_windowid_effizient_icon1 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.instance = new lib.btn_icon_windowid_effizient_icon();
            this.instance.setTransform(43.75,43.6,1,1,0,0,0,15.1,16.2);
        
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#020203").s().p("Ag3AeIAAg7IA3AAIAAAOIgoAAIAAAfIBfAAIAAAOg");
            this.shape.setTransform(39.766,52.3235,2.5026,2.5034);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#020203").s().p("AgFAAQAAgFAFAAQAGAAAAAFQAAAGgGAAQgFAAAAgGg");
            this.shape_1.setTransform(53.5931,52.3235,2.5026,2.5034);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#020203").s().p("AgUA9QgJgIAAgMIAAhvIA7AAIAABvQAAAMgJAIQgJAKgMAAQgLAAgJgKgAgPApQAAAGAFAFQAFAEAFAAQAGAAAFgEQAFgFAAgGIAAhgIgfAAg");
            this.shape_2.setTransform(53.6556,42.1847,2.5026,2.5034);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#020203").s().p("AgGBNIAAiZIANAAIAACZg");
            this.shape_3.setTransform(20.4413,21.2866,2.5031,2.5037);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#020203").s().p("AhKAHIAAgNICVAAIAAANg");
            this.shape_4.setTransform(20.5039,17.844,2.5031,2.5037);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#020203").s().p("AhRBpIAAjRICjAAIAABhIgPAAIAAhTIiGAAIAAC1IBDAAIAAAOg");
            this.shape_5.setTransform(20.5039,26.3567,2.5031,2.5037);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape},{t:this.instance}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_effizient_icon1, new cjs.Rectangle(0,0,61.2,59.9), null);
        
        
        (lib.btn_icon_windowid = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_windowid_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(51.55,49.95,1,1,0,0,0,26.9,33.6);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_windowid_icon();
            this.icon.name = "icon";
            this.icon.setTransform(51.55,50,1,1,0,0,0,26.8,33.5);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_windowid_hl();
            this.hl.name = "hl";
            this.hl.setTransform(77.45,55.7,1,1,0,0,0,6.8,22.7);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid, new cjs.Rectangle(24.7,16.4,59.5,67.19999999999999), null);
        
        
        (lib.btn_icon_smart_privacy_tageslichtsensor = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_smart_tageslicht_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50.1,49.95,1,1,0,0,0,28.7,28.7);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_smart_tageslicht_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50,49.95,1,1,0,0,0,28.6,28.7);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_smart_tageslicht_hl();
            this.hl.name = "hl";
            this.hl.setTransform(56.7,32.1,1,1,0,0,0,15.2,15.2);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_privacy_tageslichtsensor, new cjs.Rectangle(21.4,16.9,57.4,61.800000000000004), null);
        
        
        (lib.btn_icon_smart_privacy_security = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_smart_security_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(49.95,50.1,1,1,0,0,0,23.2,31.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_smart_security_icon();
            this.icon.name = "icon";
            this.icon.setTransform(49.95,50.1,1,1,0,0,0,23.3,31.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_smart_security_hl();
            this.hl.name = "hl";
            this.hl.setTransform(63.45,47.45,1,1,0,0,0,17.8,23.7);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_privacy_security, new cjs.Rectangle(26.7,19,54.5,62.2), null);
        
        
        (lib.btn_icon_smart_privacy_praesenz = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_smart_praesenz_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50,50.1,1,1,0,0,0,28.4,31.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_smart_praesenz_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50,50.1,1,1,0,0,0,28.4,31.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_smart_praesenz_hl();
            this.hl.name = "hl";
            this.hl.setTransform(41.35,68.85,1,1,0,0,0,16.1,17.6);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_privacy_praesenz, new cjs.Rectangle(21.6,19,56.800000000000004,67.5), null);
        
        
        (lib.btn_icon_smart_privacy = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_smart_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50,49.5,1,1,0,0,0,32.1,32.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_smart_icon();
            this.icon.name = "icon";
            this.icon.setTransform(49.95,49.7,1,1,0,0,0,32.2,32.3);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_smart_hl();
            this.hl.name = "hl";
            this.hl.setTransform(54.5,71.3,1,1,0,0,0,15.2,15.2);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_privacy, new cjs.Rectangle(17.8,17.4,64.5,69.30000000000001), null);
        
        
        (lib.btn_icon_regenerative_energie_nutzungsoptionen = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_regenerative_nutzung_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50,50.05,1,1,0,0,0,33.5,32.5);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_regenerative_nutzung_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50,50,1,1,0,0,0,33.5,32.5);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_regenerative_nutzung_hl();
            this.hl.name = "hl";
            this.hl.setTransform(42.8,49.8,1,1,0,0,0,4.5,13.1);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_energie_nutzungsoptionen, new cjs.Rectangle(16.5,17.5,67.1,65.2), null);
        
        
        (lib.btn_icon_regenerative_energie_energiegewinnung = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_regenerative_effizienz_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(49.55,49.05,1,1,0,0,0,30.3,29.7);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_regenerative_effizienz_icon();
            this.icon.name = "icon";
            this.icon.setTransform(49.55,49.05,1,1,0,0,0,30.3,29.7);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // Flächen___Text
            this.hl = new lib.btn_icon_regenerative_energie_energiegewinnung_hl();
            this.hl.name = "hl";
            this.hl.setTransform(54.95,40.45,1,1,0,0,0,28.9,23.8);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_energie_energiegewinnung, new cjs.Rectangle(19.3,16.7,64.60000000000001,62.099999999999994), null);
        
        
        (lib.btn_icon_regenerative_energie_analyse = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_regenerative_analyse_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(54.5,49.15,1,1,0,0,0,27.6,27.6);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_regenerative_analyse_icon();
            this.icon.name = "icon";
            this.icon.setTransform(54.5,49.15,1,1,0,0,0,27.6,27.6);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // Highlightflächen
            this.hl = new lib.btn_icon_regenerative_energie_analyse_hl();
            this.hl.name = "hl";
            this.hl.setTransform(43.1,32,1,1,0,0,0,9.8,4.9);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_energie_analyse, new cjs.Rectangle(26.9,21.6,55.199999999999996,55.199999999999996), null);
        
        
        (lib.btn_icon_regenerative_energie = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_regenerative_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50.5,50,1,1,0,0,0,33.5,32.5);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_regenerative_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50.65,49.95,1,1,0,0,0,33.5,32.5);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_regenerative_hl();
            this.hl.name = "hl";
            this.hl.setTransform(54.8,48,1,1,0,0,0,10.8,20.1);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_energie, new cjs.Rectangle(17,17.5,67.2,65), null);
        
        
        (lib.btn_icon_lueftung_lueftung = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_lueftung_lueftung_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50,49.95,1,1,0,0,0,26.9,33.2);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_lueftung_lueftung_icon();
            this.icon.name = "icon";
            this.icon.setTransform(49.9,49.95,1,1,0,0,0,26.9,33.2);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_lueftung_lueftung_hl();
            this.hl.name = "hl";
            this.hl.setTransform(65.7,64.1,1,1,0,0,0,19.2,19.2);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueftung_lueftung, new cjs.Rectangle(23,16.8,62,66.5), null);
        
        
        (lib.btn_icon_lueften_heizen_kuehlen_kuehlen = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_lueftung_kuehlung_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50,49.95,1,1,0,0,0,26.9,33.2);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_lueftung_kuehlung_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50,49.95,1,1,0,0,0,26.9,33.2);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_lueftung_kuehlung_hl();
            this.hl.name = "hl";
            this.hl.setTransform(64.7,63.1,1,1,0,0,0,19.2,19.2);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueften_heizen_kuehlen_kuehlen, new cjs.Rectangle(23.1,16.8,60.800000000000004,66.5), null);
        
        
        (lib.btn_icon_lueften_heizen_kuehlen_komfort = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_lueftung_komfort_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(51.7,48.8,1,1,0,0,0,30.8,30.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_lueftung_komfort_icon();
            this.icon.name = "icon";
            this.icon.setTransform(51.7,48.85,1,1,0,0,0,30.8,30.8);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_lueftung_komfort_hl();
            this.hl.name = "hl";
            this.hl.setTransform(22.65,51.85,1,1,0,0,0,8,20.2);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueften_heizen_kuehlen_komfort, new cjs.Rectangle(14.7,17.9,67.8,61.699999999999996), null);
        
        
        (lib.btn_icon_lueften_heizen_kuehlen_installation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_lueftung_installation_icon_W();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(49.95,52.4,1,1,0,0,0,23.6,33.8);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_lueftung_installation_icon();
            this.icon.name = "icon";
            this.icon.setTransform(49.95,52.4,1,1,0,0,0,23.6,33.8);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_lueftung_installation_hl();
            this.hl.name = "hl";
            this.hl.setTransform(54.25,51.7,1,1,0,0,0,17.1,22.7);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueften_heizen_kuehlen_installation, new cjs.Rectangle(26.4,18.6,47.199999999999996,67.5), null);
        
        
        (lib.btn_icon_lueften_heizen_kuehlen_heizen = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_lueftung_heizung_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(26.9,33.3,1,1,0,0,0,26.9,33.3);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_lueftung_heizung_icon();
            this.icon.name = "icon";
            this.icon.setTransform(26.9,33.3,1,1,0,0,0,26.9,33.3);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_lueftung_heizung_hl();
            this.hl.name = "hl";
            this.hl.setTransform(44.75,47.4,1,1,0,0,0,19.2,19.2);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueften_heizen_kuehlen_heizen, new cjs.Rectangle(0,0,64,66.8), null);
        
        
        (lib.btn_icon_lueften_heizen_kuehlen_energieeffizienz = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_lueftung_energie_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(47.35,49.2,1,1,0,0,0,20.1,30.6);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_lueftung_energie_icon();
            this.icon.name = "icon";
            this.icon.setTransform(47.35,49.2,1,1,0,0,0,20,30.6);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_lueftung_energie_hl();
            this.hl.name = "hl";
            this.hl.setTransform(55.25,75.55,1,1,0,0,0,12.5,9.8);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueften_heizen_kuehlen_energieeffizienz, new cjs.Rectangle(27.3,18.6,40.400000000000006,66.9), null);
        
        
        (lib.btn_icon_lueften_heizen_kuehlen = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_climate_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(48.3,49,1,1,0,0,0,36.4,34.6);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_climate_icon();
            this.icon.name = "icon";
            this.icon.setTransform(48.3,49,1,1,0,0,0,36.4,34.6);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_climate_hl();
            this.hl.name = "hl";
            this.hl.setTransform(57,52.45,1,1,0,0,0,12.2,12.9);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_lueften_heizen_kuehlen, new cjs.Rectangle(11.9,14.4,72.69999999999999,69.39999999999999), null);
        
        
        (lib.btn_icon_innovatives_thermik = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_innovativ_thermik_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(52.05,49.2,1,1,0,0,0,18.9,28.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // Icons
            this.icon = new lib.btn_icon_innovativ_thermik_icon();
            this.icon.name = "icon";
            this.icon.setTransform(52.15,49.85,1,1,0,0,0,18.8,28.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_innovativ_thermik_hl();
            this.hl.name = "hl";
            this.hl.setTransform(51,50.4,1,1,0,0,0,8.8,27.2);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovatives_thermik, new cjs.Rectangle(33.2,20.3,37.89999999999999,58.5), null);
        
        
        (lib.btn_icon_innovatives_stabilitaet = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_Innovativ_stabi_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(49.15,50.95,1,1,0,0,0,30.4,27.4);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_innovativ_stabi_icon();
            this.icon.name = "icon";
            this.icon.setTransform(49.15,51.05,1,1,0,0,0,30.4,27.4);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_innovativ_stabi_hl();
            this.hl.name = "hl";
            this.hl.setTransform(55.05,71.4,1,1,0,0,0,30.4,12.8);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovatives_stabilitaet, new cjs.Rectangle(18.8,23.6,66.8,60.49999999999999), null);
        
        
        (lib.btn_icon_innovatives_modulation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_innovativ_modulation_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50,50,1,1,0,0,0,30,30.4);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_innovativ_modulation_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50,50.05,1,1,0,0,0,30.1,30.4);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_innovativ_modulation_hl();
            this.hl.name = "hl";
            this.hl.setTransform(56,40.15,1,1,0,0,0,20.6,24.4);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovatives_modulation, new cjs.Rectangle(19.9,15.8,60.199999999999996,64.7), null);
        
        
        (lib.btn_icon_innovatives_dimensionen = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_innovativ_dimension_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(47.5,52.7,1,1,0,0,0,30.8,30.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_innovativ_dimension_icon();
            this.icon.name = "icon";
            this.icon.setTransform(47.65,52.75,1,1,0,0,0,30.8,30.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_innovativ_dimension_hl();
            this.hl.name = "hl";
            this.hl.setTransform(62.3,38.9,1,1,0,0,0,21.9,21.9);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovatives_dimensionen, new cjs.Rectangle(16.7,17,67.5,66.6), null);
        
        
        (lib.btn_icon_innovativ_icon_w = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.instance = new lib.btn_innovativ_icon_w();
            this.instance.setTransform(29.9,29.9,1,1,0,0,0,29.9,29.9);
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_icon_w, new cjs.Rectangle(0,0,59.7,59.7), null);
        
        
        (lib.btn_icon_windowid_montage = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_windowid_effizient_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(53.4,50.05,1,1,0,0,0,30.6,29.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_windowid_effizient_icon1();
            this.icon.name = "icon";
            this.icon.setTransform(53.4,50.05,1,1,0,0,0,30.6,29.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_windowid_effizient_hl();
            this.hl.name = "hl";
            this.hl.setTransform(66.4,72.45,1,1,0,0,0,17.7,7.5);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_montage, new cjs.Rectangle(22.8,20.2,61.2,59.8), null);
        
        
        (lib.btn_icon_innovatives = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // icon_w
            this.icon_w = new lib.btn_icon_innovativ_icon_w();
            this.icon_w.name = "icon_w";
            this.icon_w.setTransform(50.05,50.05,1,1,0,0,0,29.9,29.9);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));
        
            // icon
            this.icon = new lib.btn_icon_innovativ_icon();
            this.icon.name = "icon";
            this.icon.setTransform(50.05,50.1,1,1,0,0,0,29.9,29.8);
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));
        
            // hl
            this.hl = new lib.btn_icon_innovativ_hl();
            this.hl.name = "hl";
            this.hl.setTransform(44.85,66.15,1,1,0,0,0,17.7,17.7);
        
            this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn_icon_innovatives, new cjs.Rectangle(20.2,20.2,59.7,63.7), null);
        
        
        (lib.btn = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // active
            this.active = new lib.btn_active();
            this.active.name = "active";
            this.active.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.active).wait(1));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AnzH0IAAvnIPnAAIAAPng");
            mask.setTransform(50,50.025);
        
            // icon_smart_privacy_praesenz
            this.icon_smart_privacy_praesenz = new lib.btn_icon_smart_privacy_praesenz();
            this.icon_smart_privacy_praesenz.name = "icon_smart_privacy_praesenz";
            this.icon_smart_privacy_praesenz.setTransform(50,50,1,1,0,0,0,50,50);
        
            var maskedShapeInstanceList = [this.icon_smart_privacy_praesenz];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.icon_smart_privacy_praesenz).wait(1));
        
            // icon_smart_privacy_security
            this.icon_smart_privacy_security = new lib.btn_icon_smart_privacy_security();
            this.icon_smart_privacy_security.name = "icon_smart_privacy_security";
            this.icon_smart_privacy_security.setTransform(50,50.05,1,1,0,0,0,50,50);
        
            var maskedShapeInstanceList = [this.icon_smart_privacy_security];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.icon_smart_privacy_security).wait(1));
        
            // icon_smart_privacy_tageslichtsensor
            this.icon_smart_privacy_tageslichtsensor = new lib.btn_icon_smart_privacy_tageslichtsensor();
            this.icon_smart_privacy_tageslichtsensor.name = "icon_smart_privacy_tageslichtsensor";
            this.icon_smart_privacy_tageslichtsensor.setTransform(50,50,1,1,0,0,0,50,50);
        
            var maskedShapeInstanceList = [this.icon_smart_privacy_tageslichtsensor];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.icon_smart_privacy_tageslichtsensor).wait(1));
        
            // icon_smart_privacy
            this.icon_smart_privacy = new lib.btn_icon_smart_privacy();
            this.icon_smart_privacy.name = "icon_smart_privacy";
            this.icon_smart_privacy.setTransform(50,50,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_smart_privacy).wait(1));
        
            // icon_lueften_heizen_kuehlen_lueften
            this.icon_lueften_heizen_kuehlen_lueften = new lib.btn_icon_lueftung_lueftung();
            this.icon_lueften_heizen_kuehlen_lueften.name = "icon_lueften_heizen_kuehlen_lueften";
            this.icon_lueften_heizen_kuehlen_lueften.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_lueften_heizen_kuehlen_lueften).wait(1));
        
            // icon_lueften_heizen_kuehlen_energieeffizienz
            this.icon_lueften_heizen_kuehlen_energieeffizienz = new lib.btn_icon_lueften_heizen_kuehlen_energieeffizienz();
            this.icon_lueften_heizen_kuehlen_energieeffizienz.name = "icon_lueften_heizen_kuehlen_energieeffizienz";
            this.icon_lueften_heizen_kuehlen_energieeffizienz.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_lueften_heizen_kuehlen_energieeffizienz).wait(1));
        
            // icon_lueften_heizen_kuehlen_kuehlen
            this.icon_lueften_heizen_kuehlen_kuehlen = new lib.btn_icon_lueften_heizen_kuehlen_kuehlen();
            this.icon_lueften_heizen_kuehlen_kuehlen.name = "icon_lueften_heizen_kuehlen_kuehlen";
            this.icon_lueften_heizen_kuehlen_kuehlen.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_lueften_heizen_kuehlen_kuehlen).wait(1));
        
            // icon_lueften_heizen_kuehlen_installation
            this.icon_lueften_heizen_kuehlen_installation = new lib.btn_icon_lueften_heizen_kuehlen_installation();
            this.icon_lueften_heizen_kuehlen_installation.name = "icon_lueften_heizen_kuehlen_installation";
            this.icon_lueften_heizen_kuehlen_installation.setTransform(50,50.05,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_lueften_heizen_kuehlen_installation).wait(1));
        
            // icon_lueften_heizen_kuehlen_heizen
            this.icon_lueften_heizen_kuehlen_heizen = new lib.btn_icon_lueften_heizen_kuehlen_heizen();
            this.icon_lueften_heizen_kuehlen_heizen.name = "icon_lueften_heizen_kuehlen_heizen";
            this.icon_lueften_heizen_kuehlen_heizen.setTransform(54.05,50,1,1,0,0,0,31.9,33.4);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_lueften_heizen_kuehlen_heizen).wait(1));
        
            // icon_lueften_heizen_kuehlen_komfort
            this.icon_lueften_heizen_kuehlen_komfort = new lib.btn_icon_lueften_heizen_kuehlen_komfort();
            this.icon_lueften_heizen_kuehlen_komfort.name = "icon_lueften_heizen_kuehlen_komfort";
            this.icon_lueften_heizen_kuehlen_komfort.setTransform(50,49.95,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_lueften_heizen_kuehlen_komfort).wait(1));
        
            // icon_lueften_heizen_kuehlen
            this.icon_lueften_heizen_kuehlen = new lib.btn_icon_lueften_heizen_kuehlen();
            this.icon_lueften_heizen_kuehlen.name = "icon_lueften_heizen_kuehlen";
            this.icon_lueften_heizen_kuehlen.setTransform(50,50.1,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_lueften_heizen_kuehlen).wait(1));
        
            // icon_regenerative_energie_analyse
            this.icon_regenerative_energie_analyse = new lib.btn_icon_regenerative_energie_analyse();
            this.icon_regenerative_energie_analyse.name = "icon_regenerative_energie_analyse";
            this.icon_regenerative_energie_analyse.setTransform(50,49.95,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_regenerative_energie_analyse).wait(1));
        
            // icon_regenerative_energie_energiegewinnung
            this.icon_regenerative_energie_energiegewinnung = new lib.btn_icon_regenerative_energie_energiegewinnung();
            this.icon_regenerative_energie_energiegewinnung.name = "icon_regenerative_energie_energiegewinnung";
            this.icon_regenerative_energie_energiegewinnung.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_regenerative_energie_energiegewinnung).wait(1));
        
            // icon_regenerative_energie_nutzungsoptionen
            this.icon_regenerative_energie_nutzungsoptionen = new lib.btn_icon_regenerative_energie_nutzungsoptionen();
            this.icon_regenerative_energie_nutzungsoptionen.name = "icon_regenerative_energie_nutzungsoptionen";
            this.icon_regenerative_energie_nutzungsoptionen.setTransform(50,50.1,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_regenerative_energie_nutzungsoptionen).wait(1));
        
            // icon_regenerative_energie
            this.icon_regenerative_energie = new lib.btn_icon_regenerative_energie();
            this.icon_regenerative_energie.name = "icon_regenerative_energie";
            this.icon_regenerative_energie.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_regenerative_energie).wait(1));
        
            // icon_windowid_urban_mining
            this.icon_windowid_urban_mining = new lib.btn_icon_windowid_urban_mining();
            this.icon_windowid_urban_mining.name = "icon_windowid_urban_mining";
            this.icon_windowid_urban_mining.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_windowid_urban_mining).wait(1));
        
            // icon_windowid_schnelle_hilfe
            this.icon_windowid_schnelle_hilfe = new lib.btn_icon_windowid_schnelle_hilfe();
            this.icon_windowid_schnelle_hilfe.name = "icon_windowid_schnelle_hilfe";
            this.icon_windowid_schnelle_hilfe.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_windowid_schnelle_hilfe).wait(1));
        
            // icon_windowid_montage
            this.icon_windowid_montage = new lib.btn_icon_windowid_montage();
            this.icon_windowid_montage.name = "icon_windowid_montage";
            this.icon_windowid_montage.setTransform(50,50.1,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_windowid_montage).wait(1));
        
            // icon_windowid
            this.icon_windowid = new lib.btn_icon_windowid();
            this.icon_windowid.name = "icon_windowid";
            this.icon_windowid.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_windowid).wait(1));
        
            // icon_innovatives_modulation
            this.icon_innovatives_modulation = new lib.btn_icon_innovatives_modulation();
            this.icon_innovatives_modulation.name = "icon_innovatives_modulation";
            this.icon_innovatives_modulation.setTransform(50,50.1,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_innovatives_modulation).wait(1));
        
            // icon_innovatives_dimensionen
            this.icon_innovatives_dimensionen = new lib.btn_icon_innovatives_dimensionen();
            this.icon_innovatives_dimensionen.name = "icon_innovatives_dimensionen";
            this.icon_innovatives_dimensionen.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_innovatives_dimensionen).wait(1));
        
            // icon_innovatives_thermik
            this.icon_innovatives_thermik = new lib.btn_icon_innovatives_thermik();
            this.icon_innovatives_thermik.name = "icon_innovatives_thermik";
            this.icon_innovatives_thermik.setTransform(50.05,50.2,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_innovatives_thermik).wait(1));
        
            // icon_innovatives_stabilitaet
            this.icon_innovatives_stabilitaet = new lib.btn_icon_innovatives_stabilitaet();
            this.icon_innovatives_stabilitaet.name = "icon_innovatives_stabilitaet";
            this.icon_innovatives_stabilitaet.setTransform(50,50.1,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_innovatives_stabilitaet).wait(1));
        
            // icon_innovatives
            this.icon_innovatives = new lib.btn_icon_innovatives();
            this.icon_innovatives.name = "icon_innovatives";
            this.icon_innovatives.setTransform(50,50.1,1,1,0,0,0,50,50.1);
        
            this.timeline.addTween(cjs.Tween.get(this.icon_innovatives).wait(1));
        
            // frame
            this.frame = new lib.btn_frame();
            this.frame.name = "frame";
            this.frame.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.frame).wait(1));
        
            // label
            this.label = new lib.btn_label();
            this.label.name = "label";
            this.label.setTransform(52.4,120.15,1,1,0,0,0,52,15.8);
        
            this.timeline.addTween(cjs.Tween.get(this.label).wait(1));
        
            // bg_white
            this.bg_white = new lib.btn_bg_white();
            this.bg_white.name = "bg_white";
            this.bg_white.setTransform(50,50,1,1,0,0,0,50,50);
        
            this.timeline.addTween(cjs.Tween.get(this.bg_white).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.btn, new cjs.Rectangle(0,0,100.4,137.3), null);
        
        
        (lib.navigation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // slogan
            this.slogan = new lib.slogan();
            this.slogan.name = "slogan";
            this.slogan.setTransform(102.5,35,1,1,0,0,0,102.5,35);
        
            this.timeline.addTween(cjs.Tween.get(this.slogan).wait(1));
        
            // headline
            this.headline = new lib.headline();
            this.headline.name = "headline";
            this.headline.setTransform(222.5,468.5,1,1,0,0,0,222.5,58.5);
        
            this.timeline.addTween(cjs.Tween.get(this.headline).wait(1));
        
            // buttons
            this.btn = new lib.btn();
            this.btn.name = "btn";
            this.btn.setTransform(55.8,637.6,1,1,0,0,0,53.7,66.5);
        
            this.timeline.addTween(cjs.Tween.get(this.btn).wait(1));
        
            // back
            this.btn_back = new lib.btn_back();
            this.btn_back.name = "btn_back";
            this.btn_back.setTransform(380,1066.7,1,1,0,0,0,16,13.6);
        
            this.timeline.addTween(cjs.Tween.get(this.btn_back).wait(1));
        
	// language
            this.btn_language = new lib.btn_language();
            this.btn_language.name = "btn_language";
            this.btn_language.setTransform(78.95,1067.85,1,1,0,0,0,15.8,15.3);
        
            this.timeline.addTween(cjs.Tween.get(this.btn_language).wait(1));
        
            // home
            this.btn_home = new lib.btn_home();
            this.btn_home.name = "btn_home";
            this.btn_home.setTransform(21.1,1066.95,1,1,0,0,0,16,16.8);
        
            this.timeline.addTween(cjs.Tween.get(this.btn_home).wait(1));
        
            this._renderFirstFrame();
        
}).prototype = getMCSymbolPrototype(lib.navigation, new cjs.Rectangle(-20.7,0,465.7,1102.3), null);

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}