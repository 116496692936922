/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'
import { uiSound } from '../../../../actions/audio/audioPlayer'
import { setHeadlineContent } from '../../../../actions/uiHelpers'

const createjs = cjs

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */


    (lib.testBtn = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#000000").s().p("AnBGQIAAsfIODAAIAAMfg");
        this.shape.setTransform(5, 10.05);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.testBtn, new cjs.Rectangle(-40, -29.9, 90, 80), null);


    (lib.standard_f_btn = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // Ebene_2
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgZAGIAAgLIAzAAIAAALg");
        this.shape.setTransform(10.55, 13.025);

        this.txt_01 = new cjs.Text("Fenster", "13px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_01.name = "txt_01";
        this.txt_01.lineHeight = 17;
        this.txt_01.lineWidth = 76;
        this.txt_01.parent = this;
        this.txt_01.setTransform(7.5, 21.5);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.txt_01 }, { t: this.shape }] }).wait(2));

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("rgba(84,84,84,0.008)").s().p("AmRDTIAAmlIMjAAIAAGlg");
        this.shape_1.setTransform(44.975, 25.85);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AnBECIAAoDIODAAIAAIDgAmRDTIMjAAIAAmlIsjAAg");
        this.shape_2.setTransform(44.975, 25.825);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#34A58C").s().p("AnBEEIAAoHIODAAIAAIHg");
        this.shape_3.setTransform(45, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }] }).to({ state: [{ t: this.shape_3 }] }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0, -2.5, 90, 54.5);


    (lib.rehau_f_btn = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // Ebene_2
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgeAsQgMgLAAgWIAAhBIAZAAIAABBQAAAXARAAQASAAAAgXIAAhBIAZAAIAABBQAAAWgMALQgLALgUAAQgTAAgLgLg");
        this.shape.setTransform(52.025, 14.625);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AAZA2IgHgWIgjAAIgHAWIgaAAIAlhrIAbAAIAlBrgAAMALIgMgnIgLAnIAXAAg");
        this.shape_1.setTransform(41.925, 14.55);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AAVA2IAAguIgoAAIAAAuIgZAAIAAhrIAZAAIAAArIAoAAIAAgrIAYAAIAABrg");
        this.shape_2.setTransform(31.425, 14.55);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgjA2IAAhrIBHAAIAAAUIgvAAIAAAYIAoAAIAAATIgoAAIAAAYIAwAAIAAAUg");
        this.shape_3.setTransform(21.8, 14.55);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AAPA2IgWgrIgJAAIAAArIgZAAIAAhrIApAAQAUAAAKAIQAKAJAAAQQAAAKgGAJQgGAHgLADIAZAtgAgQgIIAPAAQAIAAAEgCQAEgEAAgGQAAgNgQAAIgPAAg");
        this.shape_4.setTransform(12.675, 14.55);

        this.txt_01 = new cjs.Text("Fenster", "13px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_01.name = "txt_01";
        this.txt_01.lineHeight = 17;
        this.txt_01.lineWidth = 76;
        this.txt_01.parent = this;
        this.txt_01.setTransform(7.5, 23.5);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.txt_01 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(2));

        // Ebene_1
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("rgba(84,84,84,0.008)").s().p("AmRDTIAAmlIMjAAIAAGlg");
        this.shape_5.setTransform(44.975, 25.85);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AnBECIAAoDIODAAIAAIDgAmRDTIMjAAIAAmlIsjAAg");
        this.shape_6.setTransform(44.975, 25.825);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#34A58C").s().p("AnBEEIAAoHIODAAIAAIHg");
        this.shape_7.setTransform(45, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_6 }, { t: this.shape_5 }] }).to({ state: [{ t: this.shape_7 }] }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0, 0, 90, 52);


    (lib.pfeile = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AQuA7IAsg7Igrg6IAegVIBGBPIhGBQgAO4A7IAsg7Igsg6IAfgVIBGBPIhGBQgANCA7IAsg7Igsg6IAfgVIBFBPIhFBQgALMA7IAsg7Igsg6IAfgVIBFBPIhFBQgAJWA7IAsg7Igsg6IAegVIBGBPIhGBQgAHgA7IAtg7Igtg6IAegVIBGBPIhGBQgAFqA7IAsg7Igsg6IAegVIBGBPIhGBQgAD0A7IAsg7Igsg6IAegVIBGBPIhGBQgAB+A7IAsg7Igsg6IAegVIBHBPIhHBQgAAHA7IAtg7Igsg6IAegVIBGBPIhGBQgAhuA7IAtg7Igsg6IAegVIBGBPIhGBQgAjkA7IAtg7Igsg6IAegVIBGBPIhGBQgAlaA7IAtg7Igsg6IAdgVIBHBPIhHBQgAnQA7IAtg7Igsg6IAdgVIBHBPIhHBQgApFA7IAsg7Igsg6IAdgVIBHBPIhHBQgAq8A7IAtg7Igsg6IAdgVIBHBPIhHBQgAsyA7IAtg7Igsg6IAdgVIBHBPIhHBQgAunA7IAtg7Igsg6IAdgVIBHBPIhHBQgAwdA7IAtg7Igsg6IAegVIBGBPIhGBQgAySA7IAsg7Igrg6IAegVIBGBPIhGBQg");
        this.shape.setTransform(117.1, 8.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.pfeile, new cjs.Rectangle(0, 0, 234.2, 16.1), null);


    (lib.hand_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgZA3IgKgKIBcAAQAAgEgDgDQgDgCgEAAIgWAAQgFgBgDgDQgEgDAAgFQAAgGAEgDIA0gzIABgDIgBgEQgEgDgDADIg0AzIgHgHIARgQQAEgDgEgEQgDgDgEADIgFAFIgBACIgKAKIgHgGIALgNQAEgDgEgDQgCgEgEAEIgFAEIgBACIgGAGIgHgIIAGgEIABgDQABAAAAAAQAAAAAAgBQABAAAAgBQAAAAAAgBQAAAAAAgBQAAAAgBgBQAAAAAAAAQAAgBgBAAQgDgDgEADIgwAvIgHgHIAxgvQAEgFAGAAQAGAAAEAFIAEAFQAHgEAHAIIAEAFQAIgDAHAGIADAEIAZgZQAEgEAGAAQAGAAAEAEQAFAFAAAGQAAAGgFAEIgzAzQgBAAAAABQAAAAAAABQAAAAAAABQAAAAABABIABAAIAWAAQAJgBAFAHQAGAFAAAIIAAAKg");
        this.shape.setTransform(18.9007, 13.0242, 2.37, 2.37);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.hand_mc, new cjs.Rectangle(0, 0, 37.8, 26.1), null);


    (lib.Group_4 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgPABQgBAAAAAAQAAAAgBgBQAAAAAAAAQAAAAAAAAQAAgBAAAAQAAAAAAgBQABAAAAAAQAAAAABAAIAgACQAAAAAAAAQABAAAAAAQAAAAAAAAQAAAAAAAAQAAABAAABQAAAAAAAAQAAABgBAAQAAAAAAAAg");
        this.shape.setTransform(1.975, 8.6);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgPABQgBAAAAAAQAAAAgBAAQAAAAAAgBQAAAAAAAAQAAAAAAgBQAAAAAAgBQABAAAAAAQAAAAABAAIAgACQAAAAAAAAQABAAAAAAQAAAAAAAAQAAAAAAABQAAAAAAABQAAAAAAABQAAAAgBAAQAAAAAAAAg");
        this.shape_1.setTransform(1.975, 6.625);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgPABQgBAAAAAAQAAAAgBAAQAAAAAAgBQAAAAAAAAQAAAAAAgBQAAAAAAgBQABAAAAAAQAAAAABAAIAgACQAAAAAAAAQABAAAAAAQAAAAAAAAQAAABAAAAQAAABAAAAQAAAAAAABQAAAAgBAAQAAAAAAAAg");
        this.shape_2.setTransform(1.975, 4.65);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgPABQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAAAQAAAAABAAIAgACQAAAAAAAAQABAAAAAAQAAAAAAAAQAAABAAAAQAAABAAAAQAAAAAAABQAAAAgBAAQAAAAAAAAg");
        this.shape_3.setTransform(1.975, 2.6875);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgTA3IAAhuIAnACIAABtgAgMAuIAZABIAAhcIgZgBg");
        this.shape_4.setTransform(2, 5.625);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group_4, new cjs.Rectangle(0, 0, 4, 11.3), null);


    (lib.Group_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBAAQAAAAAAgBQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAAAQABAAAAAAIApABQAAAAABABQAAAAABAAQAAAAAAAAQAAAAAAABQAAABAAAAQAAABAAAAQgBAAAAAAQgBABAAAAg");
        this.shape.setTransform(2.525, 9.7);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBgBQAAAAAAAAQAAAAAAAAIABgCIABAAIApACQAAAAABAAQAAAAABAAQAAAAAAAAQAAAAAAABQAAABAAAAQAAABAAAAQgBAAAAAAQgBABAAAAg");
        this.shape_1.setTransform(2.525, 7.4917);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBAAQAAgBAAAAQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAgBQABAAAAAAIApADQAAAAABAAQAAAAABAAQAAAAAAAAQAAABAAAAQAAABAAAAQAAABAAAAQgBAAAAABQgBAAAAAAg");
        this.shape_2.setTransform(2.525, 5.275);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBAAQAAgBAAAAQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAgBQABAAAAAAIApADQAAAAABAAQAAAAABAAQAAAAAAAAQAAABAAAAQAAABAAAAQAAABAAAAQgBAAAAABQgBAAAAAAg");
        this.shape_3.setTransform(2.525, 3.075);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgYA+IAAh9IAxAEIAAB6gAgPA0IAfABIAAhnIgfgCg");
        this.shape_4.setTransform(2.525, 6.35);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group_2, new cjs.Rectangle(0, 0, 5.1, 12.7), null);


    (lib.Group_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAAAAAgBQAAAAAAAAQABgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQABAAAAAAQAAABAAAAIAAApQAAAAAAABQAAAAgBAAQAAABAAAAQgBAAAAAAQAAAAAAgBQAAAAgBAAQAAAAAAgBQAAAAAAAAg");
        this.shape.setTransform(7.4, 3.35);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAAAAAgBQAAAAAAAAQABgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQABAAAAAAQAAABAAAAIAAApQAAAAAAABQAAAAgBAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAAAQAAgBAAAAg");
        this.shape_1.setTransform(5.7, 3.15);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAgBAAAAQAAgBAAAAQABAAAAAAQAAAAAAAAQAAAAABAAQAAABABAAQAAAAAAABQAAAAAAAAIAAApQAAABAAAAQAAABAAAAQgBAAAAAAQgBAAAAAAQAAAAAAAAQAAgBgBAAQAAAAAAgBQAAAAAAAAg");
        this.shape_2.setTransform(4, 2.975);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAgBAAAAQAAAAABgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAAAABQABAAAAAAQAAABAAABIAAAoQAAABAAAAQAAAAgBABQAAAAAAAAQAAAAgBAAQAAAAAAAAQAAAAAAgBQgBAAAAAAQAAgBAAAAg");
        this.shape_3.setTransform(2.275, 2.7958);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgvAVIAAgzIBfALIAAAygAgnAMIBPAJIAAggIhPgJg");
        this.shape_4.setTransform(4.8, 3.05);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group_1, new cjs.Rectangle(0, 0, 9.6, 6.1), null);


    (lib.Group = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgBAVIAAgqQAAAAAAgBQAAAAAAgBQABAAAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAAAAAABQAAAAAAABIAAAqQAAAAAAABQAAAAAAABQgBAAAAAAQAAAAgBAAQAAAAAAAAQAAgBgBAAQAAAAAAgBQAAAAAAgBg");
        this.shape.setTransform(8.15, 3.525);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgBAWIAAgrQAAAAAAgBQAAAAAAgBQABAAAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAAAAAABQAAAAAAABIAAAqQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAgBAAQAAAAAAAAQAAAAgBAAQAAgBAAAAQAAgBAAAAg");
        this.shape_1.setTransform(6.275, 3.325);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgBAWIAAgrQAAgBAAAAQAAAAABgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAAAAAQABABAAAAQAAABAAAAIAAArQAAABAAAAQAAABgBAAQAAAAAAAAQAAAAgBAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAg");
        this.shape_2.setTransform(4.4, 3.125);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgBAWIAAgrQAAgBAAAAQAAAAABgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAABAAQAAABAAAAQAAABAAAAIAAArQAAABAAAAQAAAAAAABQgBAAAAAAQAAAAgBAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAg");
        this.shape_3.setTransform(2.5, 2.925);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("Ag0AVIAAg0IBpAMIAAAzgAgsAMIBYAKIAAghIhYgJg");
        this.shape_4.setTransform(5.3, 3.2);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group, new cjs.Rectangle(0, 0, 10.6, 6.4), null);


    (lib.Path_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("Ak5p1IJzA9IAARxIpzA9g");
        this.shape.setTransform(31.35, 63);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_2, new cjs.Rectangle(0, 0, 62.7, 126), null);


    (lib.Path = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgsL7IAA31IBZgUIAAYdg");
        this.shape.setTransform(4.475, 78.25);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path, new cjs.Rectangle(0, 0, 9, 156.5), null);


    (lib.Group_3 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAAAIAggCQABAAAAAAQABAAAAAAQAAABAAAAQAAAAAAABQAAAAAAAAQAAABAAAAQAAAAgBAAQAAAAgBAAIggACQAAAAAAAAQgBAAAAAAQAAgBAAAAQAAAAAAgBg");
        this.shape.setTransform(2.025, 2.65);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAAAIAggCQABAAAAAAQABAAAAABQAAAAAAAAQAAABAAAAQAAAAAAAAQAAABAAAAQAAAAgBAAQAAABgBAAIggABQAAAAAAAAQgBAAAAgBQAAAAAAAAQAAgBAAAAg");
        this.shape_1.setTransform(2.025, 4.6);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgRABQAAAAAAgBQAAAAAAAAQAAAAABAAQAAAAAAAAIAggCQABAAAAAAQABAAAAAAQAAABAAAAQAAABAAAAQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAgBAAIggACQAAAAAAAAQgBAAAAAAQAAAAAAgBQAAAAAAgBg");
        this.shape_2.setTransform(2.025, 6.575);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAAAIAggCQABAAAAAAQABAAAAAAQAAAAAAABQAAAAAAABQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAgBAAIggACQAAAAAAAAQgBAAAAAAQAAgBAAAAQAAAAAAgBg");
        this.shape_3.setTransform(2.025, 8.5375);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgTg2IAngBIAABtIgnACgAgMgtIAABcIAZgCIAAhbg");
        this.shape_4.setTransform(2, 5.625);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group_3, new cjs.Rectangle(0, 0, 4, 11.3), null);


    (lib.Group_2_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAAAIAggCQABAAAAAAQABAAAAAAQAAABAAAAQAAAAAAABQAAAAAAAAQAAABAAAAQAAAAgBAAQAAAAgBAAIggACQAAAAAAAAQgBAAAAAAQAAgBAAAAQAAAAAAgBg");
        this.shape_5.setTransform(2.025, 2.65);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgRABQAAAAAAgBQAAAAAAAAQAAAAABAAQAAAAAAgBIAggBQABAAAAAAQABAAAAABQAAAAAAAAQAAABAAAAQAAAAAAAAQAAABAAAAQAAAAgBAAQAAABgBAAIggABQAAAAAAAAQgBAAAAgBQAAAAAAAAQAAgBAAAAg");
        this.shape_6.setTransform(2.025, 4.6);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAAAIAggCQABAAAAAAQABAAAAAAQAAABAAAAQAAAAAAABQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAgBAAIggACQAAAAAAAAQgBAAAAAAQAAgBAAAAQAAgBAAAAg");
        this.shape_7.setTransform(2.025, 6.575);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgRABQAAAAAAgBQAAAAAAAAQAAAAABAAQAAAAAAAAIAggCQABAAAAAAQABAAAAAAQAAABAAAAQAAABAAAAQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAgBAAIggACQAAAAAAAAQgBAAAAAAQAAgBAAAAQAAAAAAgBg");
        this.shape_8.setTransform(2.025, 8.525);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgTg2IAngBIAABtIgnACgAgMgtIAABcIAZgCIAAhbg");
        this.shape_9.setTransform(2, 5.625);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group_2_1, new cjs.Rectangle(0, 0, 4, 11.3), null);


    (lib.Group_1_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABAAIAggCQAAAAAAAAQABAAAAAAQAAABAAAAQAAAAAAABQAAAAAAAAQAAABAAAAQAAAAgBAAQAAAAAAAAIggACQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAAAAAgBg");
        this.shape_5.setTransform(1.975, 2.65);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABAAIAggCQAAAAAAAAQABAAAAAAQAAABAAAAQAAABAAAAQAAAAAAAAQAAABAAAAQAAAAgBAAQAAAAAAAAIggACQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAgBAAAAg");
        this.shape_6.setTransform(1.975, 4.625);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgRABQAAAAAAgBQAAAAAAAAQABAAAAAAQAAAAABAAIAggCQAAAAAAAAQABAAAAAAQAAABAAAAQAAABAAAAQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAAAAAIggACQgBAAAAAAQAAAAgBAAQAAAAAAgBQAAAAAAgBg");
        this.shape_7.setTransform(1.975, 6.575);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgRABQAAAAAAgBQAAAAAAAAQABAAAAAAQAAAAABgBIAggBQAAAAAAAAQABAAAAABQAAAAAAAAQAAABAAAAQAAAAAAAAQAAAAAAAAQAAABgBAAQAAAAAAAAIggACQgBAAAAAAQAAAAgBgBQAAAAAAAAQAAgBAAAAg");
        this.shape_8.setTransform(1.975, 8.55);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgTg2IAngBIAABtIgnACgAgMgtIAABcIAZgCIAAhbg");
        this.shape_9.setTransform(2, 5.625);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group_1_1, new cjs.Rectangle(0, 0, 4, 11.3), null);


    (lib.Group_5 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABAAIAggCQAAAAAAAAQABAAAAAAQAAABAAAAQAAABAAAAQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAAAAAIggACIgBAAQAAAAgBAAQAAAAAAgBQAAAAAAAAQAAgBAAAAg");
        this.shape_5.setTransform(1.975, 2.6833);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABgBIAggBQAAAAAAAAQABAAAAAAQAAABAAAAQAAABAAAAQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAAAAAIggACQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAAAAAgBg");
        this.shape_6.setTransform(1.975, 4.65);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgRABQAAAAAAgBQAAAAAAAAQABAAAAAAQAAAAABAAIAggCQAAAAAAAAQABAAAAAAQAAABAAAAQAAAAAAABQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAAAABIggABQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAgBAAAAg");
        this.shape_7.setTransform(1.975, 6.6);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgRABQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABAAIAggCQAAAAAAAAQABAAAAAAQAAABAAAAQAAAAAAABQAAAAAAAAQAAAAAAABQAAAAgBAAQAAAAAAAAIggACQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAgBAAAAg");
        this.shape_8.setTransform(1.975, 8.575);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgTg1IAngCIAABtIgnACgAgMgtIAABcIAZgBIAAhcg");
        this.shape_9.setTransform(2, 5.625);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Group_5, new cjs.Rectangle(0, 0, 4, 11.3), null);


    (lib.Path_9 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("Ai3APIFvhPIAACBg");
        this.shape.setTransform(18.425, 6.475);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_9, new cjs.Rectangle(0, 0, 36.9, 13), null);


    (lib.Path_6 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#DD0261").s().p("AgiAGIAAgXIBFAJIg+AaQgHgEAAgIg");
        this.shape.setTransform(3.475, 1.775);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_6, new cjs.Rectangle(0, 0, 7, 3.6), null);


    (lib.Path_4 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#DD0261").s().p("AgiAGIAAgXIBFAJIg+AaQgHgEAAgIg");
        this.shape.setTransform(3.475, 1.775);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_4, new cjs.Rectangle(0, 0, 7, 3.6), null);


    (lib.Path_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgsK0IAA1oIBZgUIAAWQg");
        this.shape_1.setTransform(4.475, 71.25);

        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_1, new cjs.Rectangle(0, 0, 9, 142.5), null);


    (lib.Path_1_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#5FB4DE").s().p("Ag7BLQAFgGAAgHIAAh8QAAgPAMgKIBmAPQgKAHAAAOIgBB9QAAAHgFAGg");
        this.shape.setTransform(6.025, 8.75);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_1_1, new cjs.Rectangle(0, 0, 12.1, 17.5), null);


    (lib.Path_0 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("Ak5ovIJzA9IAAPlIpzA9g");
        this.shape.setTransform(31.35, 56);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_0, new cjs.Rectangle(0, 0, 62.7, 112), null);


    (lib.Path_3 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#5FB4DE").s().p("Ag7BLQAFgHAAgGIAAh7QAAgPAMgLIBmAPQgKAIAAANIgBB9QAAAIgFAFg");
        this.shape_2.setTransform(6.025, 8.75);

        this.timeline.addTween(cjs.Tween.get(this.shape_2).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_3, new cjs.Rectangle(0, 0, 12.1, 17.5), null);


    (lib.pfeilani = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_2 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AwmFMIAAqYMAhMAAAIAAKYg");
        mask.setTransform(116.25, 13.3);

        // Ebene_1
        this.instance = new lib.pfeile();
        this.instance.setTransform(117.1, 8, 1, 1, 0, 0, 0, 117.1, 8);

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).to({ x: 129.1 }, 10).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(10, 0, 212.5, 16.1);


    (lib.hand_2mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.hand_mc = new lib.hand_mc();
        this.hand_mc.name = "hand_mc";
        this.hand_mc.setTransform(18.9, 21.4, 1.2, 1.2, 0, 0, 0, 18.9, 21.4);

        this.timeline.addTween(cjs.Tween.get(this.hand_mc).wait(79).to({ scaleX: 1, scaleY: 1 }, 20, cjs.Ease.quadInOut).to({ scaleX: 1.2, scaleY: 1.2 }, 20, cjs.Ease.quadInOut).to({ scaleX: 1, scaleY: 1 }, 20, cjs.Ease.quadInOut).to({ scaleX: 1.2, scaleY: 1.2 }, 21, cjs.Ease.quadInOut).wait(118));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-3.8, -4.3, 45.4, 31.400000000000002);


    (lib.animation_rotopenrechts = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_6 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AB6ixQAAgUgNgPQgOgPgUgCIkbgeQgJABAAgLQAAgIAFgDIAEgDICqAKIgGACQgFAEAFADICvAUQAlAEAZAdQAZAbAAAmIAAGjIhgAMg");
        mask.setTransform(27, 63.25);

        // Ebene_5
        this.instance = new lib.pfeilani();
        this.instance.setTransform(39.6, 50, 0.7289, 0.7289, 89.2768, 0, 0, 117, 13.2);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        // Ebene_1 (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("AmmCLIKoklIClARIqpEkg");
        mask_1.setTransform(42.3, 23.325);

        // Ebene_2
        this.instance_1 = new lib.pfeilani();
        this.instance_1.setTransform(26.65, 25.1, 1, 0.979, 0, 116.9325, 158.0265, 117.1, 13.5);
        this.instance_1.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.animation_rotopenrechts, new cjs.Rectangle(0, 7.8, 84.6, 83.9), null);


    (lib.animation_rotopen = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_6 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("An+h7QAAg+AugoQAtgqA9AHIK1BNIAPAAIAMgCICVAWQgfASgigCIrmhHQgmgEgdAaQgdAaAAAmIAAF5Ih2ARg");
        mask.setTransform(-30.975, 53.2745);

        // Ebene_5
        this.instance = new lib.pfeilani();
        this.instance.setTransform(-72.1, 19.35, 0.81, 0.81, 0, -90, 90, 117.1, 13.2);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        // Ebene_4 (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("An+h7QAAg+AtgpQAugpA9AHIK1BNIAPAAQAFAAAHgCICVAWQgHAFgMAEQgYAIgWgBIrmhHQgmgEgdAaQgdAaAAAmIAAF5Ih2ARg");
        mask_1.setTransform(-30.975, 53.574);

        // Ebene_3
        this.instance_1 = new lib.pfeilani();
        this.instance_1.setTransform(-46.65, 28.4, 0.7, 0.7, -176.5482, 0, 0, 117, 13.4);
        this.instance_1.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

        // Ebene_1 (mask)
        var mask_2 = new cjs.Shape();
        mask_2._off = true;
        mask_2.graphics.p("AmmCLIKoklIClARIqpEkg");
        mask_2.setTransform(42.3, 23.325);

        // Ebene_2
        this.instance_2 = new lib.pfeilani();
        this.instance_2.setTransform(16.15, 28.8, 1, 0.979, 0, 116.9325, 158.0265, 117.1, 13.5);
        this.instance_2.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_2];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.animation_rotopen, new cjs.Rectangle(-82.1, 7.8, 166.7, 71.7), null);


    (lib.anigrün_unten = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AlqH2IAAkAIAGgFIAABDIIRhOQAegDAUgaQAVgaAAghIAAp3IB3gPIAAJ1QAABYg4BGQg3BGhPAKIoRBNIAABBg");
        mask.setTransform(25.275, 44.575);

        // Ebene_4
        this.instance = new lib.pfeilani();
        this.instance.setTransform(50.4, 43.4, 0.9, 0.9, 0, 89.8349, -90.1651, 117, 13.2);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        // Ebene_3 (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("Ak5GOIg4g6IIlhNQAdgEAVgaQAVgaAAghIAAp2IB3gQIAAJ2QAABYg4BFQg3BGhPALIobBNg");
        mask_1.setTransform(24.575, 40.475);

        // Ebene_2
        this.instance_1 = new lib.pfeilani();
        this.instance_1.setTransform(61.4, 75.15, 0.8999, 0.8999, -9.0627, 0, 0, 116.9, 13.2);
        this.instance_1.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.anigrün_unten, new cjs.Rectangle(-12.4, -5.9, 74, 101), null);


    (lib.anigrün_oben = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AkyFJIDNifIAABBIDbgcQAdgEAUgaQAVgaABghIAAoXIB2gQIAAIYQAABXg4BGQg3BGhOAKIjbAcIAABCg");
        mask.setTransform(31.35, 43.425);

        // Ebene_4
        this.instance = new lib.pfeilani();
        this.instance.setTransform(50.4, 43.4, 0.9, 0.9, 90.1651, 0, 0, 117, 13.2);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        // Ebene_3 (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("AkyFJIDNifIAABBIDbgcQAdgEAUgaQAVgaABghIAAoXIB2gQIAAIYQAABXg4BGQg4BGhNAKIjbAcIAABCg");
        mask_1.setTransform(30.7, 43.425);

        // Ebene_2
        this.instance_1 = new lib.pfeilani();
        this.instance_1.setTransform(77.3, 62.25, 0.9, 0.9, 173.1524, 0, 0, 117, 13.2);
        this.instance_1.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.anigrün_oben, new cjs.Rectangle(0, 0, 62.1, 86.9), null);


    (lib.ani_standart_grün_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_6 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AgxEaIj6AIIFMiEQAKgFAJgKID4AeQgOAMgFABIlSCGg");
        mask.setTransform(29.9781, 31.9936);

        // Ebene_5
        this.instance = new lib.pfeilani();
        this.instance.setTransform(69.05, 46.65, 0.88, 1.1758, 0, -61.0347, -19.4819, 116.9, 13.3);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        // Ebene_4 (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("AAgDYQAMgOAAgSIAAkmQAAgjAdgXID1AhQgZATAAAhIgBEpQAAAQgMAQg");
        mask_1.setTransform(31.8031, 24.5859);

        // Ebene_3
        this.instance_1 = new lib.pfeilani();
        this.instance_1.setTransform(53.5, 36.85, 1.0559, 1.0567, 0, -92.4555, -89.9925, 116.6, 13.5);
        this.instance_1.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

        // Ebene_2 (mask)
        var mask_2 = new cjs.Shape();
        mask_2._off = true;
        mask_2.graphics.p("AhgAXQAQgOASgDIEyg9ID1AqIk4A2QgPAEgNALg");
        mask_2.setTransform(48.8811, 5.5715);

        // Ebene_1
        this.instance_2 = new lib.pfeilani();
        this.instance_2.setTransform(24.4, 21.05, 0.88, 1.1757, 0, -54.3181, -12.7631, 116.8, 13.3);
        this.instance_2.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_2];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.ani_standart_grün_1, new cjs.Rectangle(0, 0, 97.8, 64), null);


    (lib.ani_rot_unten = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_4 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AlFiTQAAhYA3hGQA4hGBPgKIEAgjIAAhBIDNBpIjNCgIAAhDIkAAjQgeAEgVAaQgVAaAAAiIAAJ5Ih2APg");
        mask.setTransform(32.625, 48.575);

        // Ebene_3
        this.instance = new lib.pfeilani();
        this.instance.setTransform(-5.65, 26.35, 0.9, 0.9, -9.9842, 0, 0, 117, 13.3);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        // Ebene_1 (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("AlFiTQAAhYA3hGQA4hGBPgKIEAgjIAAhBIDNBpIjNCgIAAhDIkAAjQgeAEgVAaQgVAaAAAiIAAJ5Ih2APg");
        mask_1.setTransform(32.625, 48.575);

        // Ebene_2
        this.instance_1 = new lib.pfeilani();
        this.instance_1.setTransform(10.05, 60.9, 0.9, 0.9, -88.4814, 0, 0, 116.9, 13.3);
        this.instance_1.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.ani_rot_unten, new cjs.Rectangle(0, 0, 65.3, 97.2), null);


    (lib.ani_rot_standard_rechts = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("Aj9CqIiXgQQgagDgNgMQgOgIAAgSIAAg5ICkAUIiGA6IAIgDIIQjhIhWgJIGzhEIgoBuIgrgEIn7DZQgwAUgvAAQgNAAgNgCg");
        mask.setTransform(45.825, 17.2016);

        // Ebene_2
        this.instance = new lib.pfeilani();
        this.instance.setTransform(21.95, 20.85, 1.1, 1.4698, 0, 116.7273, 158.2802, 116.8, 13.3);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.ani_rot_standard_rechts, new cjs.Rectangle(0, 0, 91.7, 34.4), null);


    (lib.ani_rot_standard_links = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("Aj9CqIiXgQQgagDgNgMQgOgIAAgSIAAg5ICkAUIiGA6IAIgDIIQjhIhWgJIGzhEIgoBuIgrgEIn7DZQgwAUgvAAQgNAAgNgCg");
        mask.setTransform(45.825, 17.2016);

        // Ebene_2
        this.instance = new lib.pfeilani();
        this.instance.setTransform(33.35, 27.45, 1.1, 1.4698, 0, -61.034, -19.4821, 116.9, 13.3);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.ani_rot_standard_links, new cjs.Rectangle(0, 0, 91.7, 34.4), null);


    (lib.ani_rot_standard_hinten = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AiBAFIEDgzIAABaIgPADg");
        mask.setTransform(36.525, 20.275);

        // Ebene_2
        this.instance = new lib.pfeilani();
        this.instance.setTransform(33.35, 27.45, 1.1, 1.4698, 0, -61.0345, -19.4821, 116.9, 13.3);
        this.instance.alpha = 0.3984;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.ani_rot_standard_hinten, new cjs.Rectangle(23.5, 15.6, 26.1, 9.4), null);


    (lib.ani_grün_standard_hintenrechts = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_4 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AiBCVQANgSAAgQIAAkjIDsAtQAKghAAANIgBEqQAAAQgMAOg");
        mask.setTransform(33.625, 23.75);

        // Ebene_3
        this.instance = new lib.pfeilani();
        this.instance.setTransform(28.95, -27.2, 1, 1, 90.0157, 0, 0, 117, 13.2);
        this.instance.alpha = 0.5;

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        // Ebene_1 (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("AjiC3IA2AHICGg1QALgEAIgJID3AcQgIAKgKAFIiUA6IBCAHIliAfg");
        mask_1.setTransform(22.7606, 26.2648);

        // Ebene_2
        this.instance_1 = new lib.pfeilani();
        this.instance_1.setTransform(30.9, 33.55, 1, 1.4013, 0, 112.1795, 156.6554, 117, 13.2);
        this.instance_1.alpha = 0.5;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.ani_grün_standard_hintenrechts, new cjs.Rectangle(0, 6.1, 46.7, 46.5), null);


    (lib.rehaufenster_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_5
        this.txt_01 = new cjs.Text(" ", "14px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_01.name = "txt_01";
        this.txt_01.lineHeight = 18;
        this.txt_01.lineWidth = 375;
        this.txt_01.parent = this;
        this.txt_01.setTransform(-12.5, -106.55);

        this.instance = new lib.anigrün_unten();
        this.instance.setTransform(273.4, 225.15, 0.7452, 0.81, 0, 0, 0, 77.2, 43.6);

        this.instance_1 = new lib.anigrün_unten();
        this.instance_1.setTransform(108.25, 229.05, 1, 1, 0, 0, 0, 77.2, 43.5);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_1 }, { t: this.instance }, { t: this.txt_01 }] }).wait(1));

        // grün2
        this.instance_2 = new lib.anigrün_oben();
        this.instance_2.setTransform(221.45, 142.65, 0.9, 0.9, 0, 0, 0, 30.7, 43.5);

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

        // anigrün1
        this.instance_3 = new lib.anigrün_oben();
        this.instance_3.setTransform(32, 123.2, 1, 1, 0, 0, 0, 30.7, 43.4);

        this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

        // anirot
        this.instance_4 = new lib.ani_rot_unten();
        this.instance_4.setTransform(251.75, 142.15, 0.8194, 0.9, 0, 0, 0, 0, 48.7);

        this.instance_5 = new lib.ani_rot_unten();
        this.instance_5.setTransform(113.2, 131.6, 1, 1, 0, 0, 0, 32.6, 48.6);

        this.instance_6 = new lib.animation_rotopenrechts();
        this.instance_6.setTransform(248, 30.5, 1, 1, 0, 0, 0, 48.4, 19.4);

        this.instance_7 = new lib.animation_rotopen();
        this.instance_7.setTransform(181.1, 20.9, 1, 1, 0, 0, 0, 48.4, 19.4);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_7 }, { t: this.instance_6 }, { t: this.instance_5 }, { t: this.instance_4 }] }).wait(1));

        // fenster
        this.instance_8 = new lib.Path_2();
        this.instance_8.setTransform(171.35, 195.25, 2.37, 2.37, 0, 0, 0, 31.4, 63);
        this.instance_8.alpha = 0.5;

        this.instance_9 = new lib.Group();
        this.instance_9.setTransform(145.25, 37.9, 2.37, 2.37, 0, 0, 0, 5.3, 3.2);
        this.instance_9.alpha = 0.5;

        this.instance_10 = new lib.Group_1();
        this.instance_10.setTransform(214.25, 45.25, 2.37, 2.37, 0, 0, 0, 4.8, 3.1);
        this.instance_10.alpha = 0.5;

        this.instance_11 = new lib.Group_2();
        this.instance_11.setTransform(86.45, 113.2, 2.37, 2.37, 0, 0, 0, 2.5, 6.4);
        this.instance_11.alpha = 0.5;

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgLAZIAAgxIAXAAIAAAxg");
        this.shape.setTransform(86.8519, 178.2657, 2.3698, 2.3698);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgOASQgHgIAAgKQAAgJAHgIQAGgHAIAAQAJAAAGAHQAHAIAAAJQAAAKgHAIQgGAHgJAAQgIAAgGgHg");
        this.shape_1.setTransform(84.3044, 178.2657, 2.3698, 2.3698);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgLBnQgGgGAAgIIAAixQAAgIAGgGQAEgGAHAAQAIAAAFAGQAFAGAAAIIAACwQAAAJgFAFQgFAGgIAAIAAABQgHAAgEgGg");
        this.shape_2.setTransform(89.6957, 198.117, 2.3698, 2.3698);

        this.instance_12 = new lib.Group_4();
        this.instance_12.setTransform(257, 122.1, 2.37, 2.37, 0, 0, 0, 2, 5.6);
        this.instance_12.alpha = 0.5;

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#DD0261").s().p("AlFiTQAAhYA3hGQA4hGBPgKIEAgjIAAhBIDNBpIjNCgIAAhDIkAAjQgeAEgVAaQgVAaAAAiIAAJ5Ih2APg");
        this.shape_3.setTransform(113.225, 131.875);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#DD0261").s().p("AhvhEQAAgdATgXQATgXAbgEIBYgLIAAgWIBGAjIhGA1IAAgWIhYALQgKACgHAIQgIAJAAALIAAD5IgoAFg");
        this.shape_4.setTransform(278.7114, 142.733, 2.37, 2.37);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#DD0261").s().p("AmlCPIKnklICkASIqoEjgACvieIABAAIAVACg");
        this.shape_5.setTransform(178.9625, 22.9125);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#DD0261").s().p("AmHB5IJ3kDICXARIp3EEg");
        this.shape_6.setTransform(245.35, 32.425);

        this.instance_13 = new lib.Group_5();
        this.instance_13.setTransform(46.8, 262.5, 2.37, 2.37, 0, 0, 0, 2, 5.6);
        this.instance_13.alpha = 0.5;

        this.instance_14 = new lib.Group_1_1();
        this.instance_14.setTransform(46.8, 148.75, 2.37, 2.37, 0, 0, 0, 2, 5.6);
        this.instance_14.alpha = 0.5;

        this.instance_15 = new lib.Group_2_1();
        this.instance_15.setTransform(243.65, 249.25, 2.37, 2.37, 0, 0, 0, 2, 5.6);
        this.instance_15.alpha = 0.5;

        this.instance_16 = new lib.Group_3();
        this.instance_16.setTransform(243.65, 161.9, 2.37, 2.37, 0, 0, 0, 2, 5.6);
        this.instance_16.alpha = 0.5;

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgPACQgBgBAAAAQAAAAgBgBQAAAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAAAQABgBAAAAQAAAAABAAIAgABQAAAAAAABQABAAAAAAQAAAAAAAAQAAAAAAABQAAAAAAABQAAAAAAAAQAAABgBAAQAAAAAAAAg");
        this.shape_7.setTransform(256.9419, 129.2707, 2.3698, 2.3698);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgPABQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQABAAAAAAQAAAAABAAIAgACQAAAAAAAAQABAAAAAAQAAAAAAAAQAAAAAAABQAAAAAAABQAAAAAAABQAAAAgBAAQAAAAAAAAg");
        this.shape_8.setTransform(256.9419, 124.5905, 2.3698, 2.3698);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgPACQgBAAAAgBQAAAAgBAAQAAAAAAgBQAAAAAAAAQAAgBAAAAQAAAAAAAAQABgBAAAAQAAAAABAAIAgACQAAAAAAAAQABAAAAAAQAAAAAAAAQAAAAAAABQAAAAAAABQAAAAAAAAQAAABgBAAQAAAAAAAAg");
        this.shape_9.setTransform(256.9419, 119.9102, 2.3698, 2.3698);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AgPABQgBAAAAAAQAAAAgBAAQAAgBAAAAQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAAAQAAAAABAAIAgACQAAAAAAAAQABAAAAAAQAAAAAAAAQAAABAAAAQAAABAAAAQAAAAAAABQAAAAgBAAQAAAAAAAAg");
        this.shape_10.setTransform(256.9419, 115.2595, 2.3698, 2.3698);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AgTA3IAAhuIAnACIAABtgAgMAuIAZABIAAhcIgZgBg");
        this.shape_11.setTransform(257.0011, 122.2207, 2.3698, 2.3698);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f().s("#FFFFFF").p("AAAo7IAAR3");
        this.shape_12.setTransform(239.962, 194.7543, 2.37, 2.37);

        this.instance_17 = new lib.Path();
        this.instance_17.setTransform(52.7, 194.8, 2.37, 2.37, 0, 0, 0, 4.5, 78.2);
        this.instance_17.alpha = 0.5;

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#FFFFFF").s().p("AgsL6IAA3zIBZgVIAAYcgAgirxIAAXjIBFAQIAA4Dg");
        this.shape_13.setTransform(52.6731, 194.9321, 2.37, 2.37);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AgBAWIAAgrQAAAAAAgBQAAAAABgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAAAAAABQAAAAAAABIAAAqQAAAAAAABQAAAAAAABQgBAAAAAAQAAAAgBAAQAAAAAAAAQAAgBAAAAQgBAAAAgBQAAAAAAAAg");
        this.shape_14.setTransform(152.0205, 38.5679, 2.3698, 2.3698);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#FFFFFF").s().p("AgBAWIAAgrQAAAAAAgBQAAAAAAgBQABAAAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAAAAAABQAAAAAAABIAAAqQAAABAAAAQAAAAAAABQgBAAAAAAQAAAAgBAAQAAAAAAAAQAAAAgBAAQAAgBAAAAQAAgBAAAAg");
        this.shape_15.setTransform(147.5772, 38.094, 2.3698, 2.3698);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#FFFFFF").s().p("AgBAWIAAgrQAAAAAAgBQAAAAABgBQAAAAAAAAQAAAAAAAAQAAAAABAAQAAABAAAAQABAAAAABQAAAAAAAAIAAArQAAABAAAAQAAAAgBABQAAAAAAAAQgBAAAAAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAg");
        this.shape_16.setTransform(143.1339, 37.62, 2.3698, 2.3698);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#FFFFFF").s().p("AgBAWIAAgrQAAgBAAAAQAAgBABAAQAAAAAAAAQAAAAAAAAQAAAAABAAQAAAAAAAAQABABAAAAQAAABAAAAIAAArQAAABAAAAQAAAAgBABQAAAAAAAAQgBAAAAAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAg");
        this.shape_17.setTransform(138.6313, 37.1461, 2.3698, 2.3698);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#FFFFFF").s().p("Ag0AVIAAg0IBpALIAAA0gAgrANIBYAJIAAghIhYgKg");
        this.shape_18.setTransform(145.2667, 37.7978, 2.3698, 2.3698);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBAAQAAAAAAgBQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAAAQABAAAAAAIApABQAAAAABABQAAAAABAAQAAAAAAAAQAAAAAAABQAAABAAAAQAAABAAAAQgBAAAAAAQgBABAAAAg");
        this.shape_19.setTransform(86.5557, 121.0951, 2.3698, 2.3698);

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBgBQAAAAAAAAQAAAAAAAAQAAgBAAgBQAAAAAAAAQABgBAAAAQABAAAAABIApACQAAAAABAAQAAAAABAAQAAAAAAAAQAAAAAAABQAAABAAAAQAAABAAAAQgBAAAAAAQgBABAAAAg");
        this.shape_20.setTransform(86.5557, 115.8519, 2.3698, 2.3698);

        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBAAQAAgBAAAAQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAgBQABAAAAAAIApADQAAAAABAAQAAAAABAAQAAAAAAAAQAAABAAAAQAAABAAAAQAAABAAAAQgBAAAAABQgBAAAAAAg");
        this.shape_21.setTransform(86.5557, 110.6088, 2.3698, 2.3698);

        this.shape_22 = new cjs.Shape();
        this.shape_22.graphics.f("#FFFFFF").s().p("AgUABQAAAAgBAAQAAAAgBAAQAAgBAAAAQAAAAAAAAQAAgBAAAAQAAgBAAAAQABAAAAgBQABAAAAAAIApADQAAAAABAAQAAAAABAAQAAAAAAAAQAAABAAAAQAAABAAAAQAAABAAAAQgBAAAAABQgBAAAAAAg");
        this.shape_22.setTransform(86.5557, 105.3954, 2.3698, 2.3698);

        this.shape_23 = new cjs.Shape();
        this.shape_23.graphics.f("#FFFFFF").s().p("AgYA+IAAh9IAxAEIAAB6gAgPA0IAfABIAAhnIgfgCg");
        this.shape_23.setTransform(86.5557, 113.1563, 2.3698, 2.3698);

        this.shape_24 = new cjs.Shape();
        this.shape_24.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAAAAAgBQAAAAAAgBQABAAAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQABAAAAAAQAAABAAAAIAAApQAAAAAAABQAAAAgBAAQAAABAAAAQgBAAAAAAQAAAAAAgBQAAAAgBAAQAAAAAAgBQAAAAAAAAg");
        this.shape_24.setTransform(220.3883, 45.855, 2.3698, 2.3698);

        this.shape_25 = new cjs.Shape();
        this.shape_25.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAgBAAAAQAAAAAAgBQABAAAAAAQAAAAAAAAQAAAAABAAQAAAAABABQAAAAAAAAQAAABAAABIAAAoQAAAAAAABQAAAAAAAAQgBABAAAAQgBAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAAAQAAgBAAAAg");
        this.shape_25.setTransform(216.3597, 45.381, 2.3698, 2.3698);

        this.shape_26 = new cjs.Shape();
        this.shape_26.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAgBAAAAQAAgBAAAAQABAAAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAAAAAABQAAAAAAAAIAAApQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAgBAAQAAAAAAAAQAAgBgBAAQAAAAAAgBQAAAAAAAAg");
        this.shape_26.setTransform(212.3311, 44.9663, 2.3698, 2.3698);

        this.shape_27 = new cjs.Shape();
        this.shape_27.graphics.f("#FFFFFF").s().p("AgBAVIAAgpQAAgBAAAAQAAAAABgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAAAABQABAAAAAAQAAABAAABIAAAoQAAABAAAAQAAAAgBABQAAAAAAAAQAAAAgBAAQAAAAAAAAQAAAAAAgBQgBAAAAAAQAAgBAAAAg");
        this.shape_27.setTransform(208.2432, 44.5417, 2.3698, 2.3698);

        this.shape_28 = new cjs.Shape();
        this.shape_28.graphics.f("#FFFFFF").s().p("AgvAUIAAgxIBfAKIAAAygAgnAMIBPAIIAAgeIhPgJg");
        this.shape_28.setTransform(214.2269, 45.144, 2.3698, 2.3698);

        this.shape_29 = new cjs.Shape();
        this.shape_29.graphics.f("#FFFFFF").s().p("AjeD/QAAgBAAAAQAAAAAAgBQAAAAAAgBQAAAAAAgBIG6n5QABAAAAAAQAAAAABAAQAAAAAAAAQABAAAAABQAAAAAAAAQAAABAAAAQAAAAAAABQAAAAAAABIm6H5IgBAAg");
        this.shape_29.setTransform(178.7569, 240.0043, 2.37, 2.37);

        this.shape_30 = new cjs.Shape();
        this.shape_30.graphics.f("#FFFFFF").s().p("AiMCeQAAgBAAAAQAAAAAAgBQAAAAAAgBQAAAAAAgBIEWk3QAAAAAAAAQABAAAAAAQAAAAABAAQAAAAAAAAQABABAAAAQAAABAAAAQAAABAAAAQAAABgBAAIkVE3IgBAAg");
        this.shape_30.setTransform(167.1439, 221.6242, 2.37, 2.37);

        this.shape_31 = new cjs.Shape();
        this.shape_31.graphics.f("#FFFFFF").s().p("AiUCbQAAAAAAAAQgBgBAAAAQAAgBABAAQAAgBAAAAIEmkzQAAAAAAAAQABAAAAAAQABAAAAAAQAAABABAAQAAAAAAABQAAAAAAABQAAAAAAAAQAAABAAAAIkmEyIgCABg");
        this.shape_31.setTransform(157.6047, 117.9191, 2.37, 2.37);

        this.shape_32 = new cjs.Shape();
        this.shape_32.graphics.f("#FFFFFF").s().p("AhdBgQgBAAAAgBQAAAAAAgBQAAAAAAAAQAAgBABAAIC4i8QABgBAAAAQAAAAABAAQAAAAABAAQAAAAAAABQABAAAAAAQAAABAAAAQAAAAAAABQAAABgBAAIi4C9IgCAAg");
        this.shape_32.setTransform(144.629, 99.8657, 2.37, 2.37);

        this.shape_33 = new cjs.Shape();
        this.shape_33.graphics.f("#FFFFFF").s().p("AlBqAIKDBAIAASBIqDBAgAkvJrIJig6IAAxhIpig6g");
        this.shape_33.setTransform(172.6541, 194.6358, 2.37, 2.37);

        this.shape_34 = new cjs.Shape();
        this.shape_34.graphics.f("#FFFFFF").s().p("Au67XId0DYMAAAAv/I90DYgAuubLIdfjUMAAAgvtI9fjUg");
        this.shape_34.setTransform(170.45, 194.65);

        this.shape_35 = new cjs.Shape();
        this.shape_35.graphics.f("#FFFFFF").s().p("AmvsOINfBoIAAVOItfBmgAmcL5IM+hhIAA0vIs+hgg");
        this.shape_35.setTransform(169.4547, 194.9321, 2.37, 2.37);

        this.shape_36 = new cjs.Shape();
        this.shape_36.graphics.f("#5FB4DE").s().p("AkIE9ICmh+IAAA1IDRgcQAXgDASgUQARgVAAgaIAAoUIBggMIAAITQAABGgtA3QgtA2hAAJIjRAbIAAAzg");
        this.shape_36.setTransform(222.375, 140.125);

        this.shape_37 = new cjs.Shape();
        this.shape_37.graphics.f("#5FB4DE").s().p("AjjFEIgzgsIGTgxQAYgCARgVQARgVAAgaIAAoUIBggMIAAIUQAABFgtA3QgtA2hAAIImQAxg");
        this.shape_37.setTransform(233.825, 222.775);

        this.shape_38 = new cjs.Shape();
        this.shape_38.graphics.f("#5FB4DE").s().p("AkyFJIDNifIAABBIDagcQAegEAUgaQAWgagBghIAAoXIB3gQIAAIYQAABXg4BGQg4BGhOAKIjaAcIAABCg");
        this.shape_38.setTransform(32, 123.175);

        this.shape_39 = new cjs.Shape();
        this.shape_39.graphics.f("#5FB4DE").s().p("AlMGSIgxg8II9hSQAegEAUgZQAVgaAAgiIAAp2IB3gQIAAJ2QAABXg4BHQg3BGhPAKIo2BSg");
        this.shape_39.setTransform(54.275, 226.5);

        this.shape_40 = new cjs.Shape();
        this.shape_40.graphics.f("#DD0261").s().p("An+h7QAAg+AugoQAtgqA9AHIK1BNIAPAAIAMgCICVAWQgfASgigCIrmhHQgmgEgdAaQgdAaAAAmIAAF5Ih2ARg");
        this.shape_40.setTransform(101.975, 55.2245);

        this.shape_41 = new cjs.Shape();
        this.shape_41.graphics.f("#DD0261").s().p("AB6ixQAAgUgNgPQgOgOgUgDIkbgdQgJAAAAgLQAAgHAFgEIAEgEICqALIgGACQgFAEAFACICwAUQAkAFAZAdQAZAbAAAmIAAGjIhgALg");
        this.shape_41.setTransform(227.05, 73);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_41 }, { t: this.shape_40 }, { t: this.shape_39 }, { t: this.shape_38 }, { t: this.shape_37 }, { t: this.shape_36 }, { t: this.shape_35 }, { t: this.shape_34 }, { t: this.shape_33 }, { t: this.shape_32 }, { t: this.shape_31 }, { t: this.shape_30 }, { t: this.shape_29 }, { t: this.shape_28 }, { t: this.shape_27 }, { t: this.shape_26 }, { t: this.shape_25 }, { t: this.shape_24 }, { t: this.shape_23 }, { t: this.shape_22 }, { t: this.shape_21 }, { t: this.shape_20 }, { t: this.shape_19 }, { t: this.shape_18 }, { t: this.shape_17 }, { t: this.shape_16 }, { t: this.shape_15 }, { t: this.shape_14 }, { t: this.shape_13 }, { t: this.instance_17 }, { t: this.shape_12 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.instance_16 }, { t: this.instance_15 }, { t: this.instance_14 }, { t: this.instance_13 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.instance_12 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }, { t: this.instance_11 }, { t: this.instance_10 }, { t: this.instance_9 }, { t: this.instance_8 }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.rehaufenster_mc, new cjs.Rectangle(-30.8, -108.5, 394.8, 488.9), null);


    (lib.hand_ani_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }
        this.frame_19 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(19).call(this.frame_19).wait(1));

        // Ebene_1
        this.instance = new lib.hand_2mc();
        this.instance.setTransform(18.9, 13, 1, 1, 0, 0, 0, 18.9, 13);

        this.hand_mc = new lib.hand_mc();
        this.hand_mc.name = "hand_mc";
        this.hand_mc.setTransform(18.9, 21.4, 1, 1, 0, 0, 0, 18.9, 21.4);
        this.hand_mc.alpha = 0;

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance }] }).to({ state: [{ t: this.hand_mc }] }, 19).wait(1));
        this.timeline.addTween(cjs.Tween.get(this.instance).to({ _off: true, regY: 21.4, y: 21.4, alpha: 0 }, 19).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-3.8, -4.3, 45.4, 39.3);


    (lib.fenster = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_4
        this.txt_01 = new cjs.Text("", "14px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_01.name = "txt_01";
        this.txt_01.lineHeight = 18;
        this.txt_01.lineWidth = 375;
        this.txt_01.parent = this;
        this.txt_01.setTransform(-4, -99.05);

        this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));

        // Ebene_3
        this.instance = new lib.ani_grün_standard_hintenrechts();
        this.instance.setTransform(109.95, 60.1, 1, 1, 0, 0, 0, 24.6, 26.2);

        this.instance_1 = new lib.ani_standart_grün_1();
        this.instance_1.setTransform(49.05, 53.3, 1, 1, 0, 0, 0, 48.9, 32);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_1 }, { t: this.instance }] }).wait(1));

        // Ebene_2
        this.instance_2 = new lib.ani_rot_standard_hinten();
        this.instance_2.setTransform(84.65, 28.4, 1, 1, 0, 0, 0, 33.5, 27.4);

        this.instance_3 = new lib.ani_rot_standard_rechts();
        this.instance_3.setTransform(243.25, 58, 1, 1, -3.0027, 0, 0, 33.5, 27.4);

        this.instance_4 = new lib.ani_rot_standard_links();
        this.instance_4.setTransform(186.6, 40, 1, 1, 0, 0, 0, 45.8, 17.2);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_4 }, { t: this.instance_3 }, { t: this.instance_2 }] }).wait(1));

        // Ebene_1
        this.instance_5 = new lib.Path_1();
        this.instance_5.setTransform(61.35, 218.75, 2.37, 2.37, 0, 0, 0, 4.5, 71.2);
        this.instance_5.alpha = 0.5;

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgsK1IAA1oIBZgVIAAWRgAgiqrIAAVYIBFAPIAA13g");
        this.shape.setTransform(61.3236, 218.869, 2.37, 2.37);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#5FB4DE").s().p("AkkAWQAQgNASgDIEyg9ID1AqIk3A2QgPAEgNALg");
        this.shape_1.setTransform(68.475, 27.375);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#DD0261").s().p("AiBAFIEDgzIAABaIgPADg");
        this.shape_2.setTransform(86.375, 21.275);

        this.instance_6 = new lib.Path_4();
        this.instance_6.setTransform(216.85, 57.95, 2.37, 2.37, 0, 0, 0, 3.5, 1.8);
        this.instance_6.alpha = 0.6484;

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#DD0261").s().p("AhvBBIhAgHQgPgBgFgIIgCgHIADAFQAFAFAHgDIELhyIAkAYIBNgLIkDBuQgSAIgVAAIgLgBg");
        this.shape_3.setTransform(255.5447, 49.4934, 2.37, 2.37);

        this.instance_7 = new lib.Path_6();
        this.instance_7.setTransform(148.8, 49.5, 2.37, 2.37, 0, 0, 0, 3.5, 1.8);
        this.instance_7.alpha = 0.6484;

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#DD0261").s().p("Aj9CqIiXgQQgigEgNgTIgGgSIAIAPQAMALASgIIIPjhIhVgJIGzhEIgpBuIgqgEIn8DZQgvAUgvAAQgNAAgNgCg");
        this.shape_4.setTransform(186.35, 39.6016);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("Ai3AhIFvhPIAAAOIlvBPg");
        this.shape_5.setTransform(55.7541, 11.1982, 2.37, 2.37);

        this.instance_8 = new lib.Path_9();
        this.instance_8.setTransform(55.65, 15.4, 2.37, 2.37, 0, 0, 0, 18.4, 6.5);
        this.instance_8.alpha = 0.5;

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("Am/AeIAAipIN/CCIAACVg");
        this.shape_6.setTransform(205.6563, 33.1207, 2.37, 2.37);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgLAZIAAgxIAXAAIAAAxg");
        this.shape_7.setTransform(95.5016, 202.2003, 2.3698, 2.3698);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgOASQgHgIAAgKQAAgJAHgIQAGgHAIAAQAJAAAGAHQAHAIAAAJQAAAKgHAIQgGAHgJAAQgIAAgGgHg");
        this.shape_8.setTransform(92.9541, 202.2003, 2.3698, 2.3698);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgMBnQgFgGAAgIIAAixQAAgIAFgGQAGgGAGAAQAIAAAFAGQAFAGAAAIIAACwQAAAJgFAFQgFAGgIAAIAAABQgHAAgFgGg");
        this.shape_9.setTransform(98.3453, 222.0517, 2.3698, 2.3698);

        this.instance_9 = new lib.Path_1_1();
        this.instance_9.setTransform(49.25, 50.45, 2.3699, 2.3699, 0, 0, 0, 6, 8.8);
        this.instance_9.alpha = 0.5;

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#5FB4DE").s().p("AgxAyIj6AJIFMiEQAKgFAJgJID4AdQgOAMgFABIlSCFg");
        this.shape_10.setTransform(29.975, 76.975);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f().s("#FFFFFF").p("AAAn1IAAPr");
        this.shape_11.setTransform(248.6125, 218.6913, 2.37, 2.37);

        this.instance_10 = new lib.Path_0();
        this.instance_10.setTransform(181.65, 218.5, 2.37, 2.37, 0, 0, 0, 31.4, 56);
        this.instance_10.alpha = 0.5;

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#FFFFFF").s().p("AjeD/QAAgBAAAAQAAAAAAgBQAAAAAAgBQAAAAAAgBIG6n5QABAAAAAAQAAAAABAAQAAAAAAAAQAAAAABAAQAAABAAAAQAAABAAAAQAAABAAAAQAAAAAAABIm6H5IgBAAg");
        this.shape_12.setTransform(187.4074, 263.9582, 2.37, 2.37);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#FFFFFF").s().p("AiMCeQAAAAAAgBQAAAAAAgBQAAAAAAgBQAAAAAAgBIEWk3QAAAAAAAAQABAAAAAAQAAAAABAAQAAAAAAAAQABABAAAAQAAABAAAAQAAABAAAAQAAABgBAAIkVE3IgBAAg");
        this.shape_13.setTransform(175.7944, 245.5611, 2.37, 2.37);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AiUCbQAAAAAAgBQAAAAAAgBQAAAAAAAAQAAgBAAAAIElkyQABgBAAAAQABAAAAAAQABAAAAAAQAAABAAAAQABAAAAABQABAAAAAAQAAABgBAAQAAABgBAAIklEzIgBAAg");
        this.shape_14.setTransform(166.2552, 158.3881, 2.37, 2.37);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#FFFFFF").s().p("AhdBgQAAAAgBgBQAAAAAAAAQAAgBAAAAQABgBAAAAIC4i9QABAAAAAAQAAAAABAAQAAAAAAAAQABAAAAABQAAAAABAAQAAABAAAAQAAABAAAAQgBABAAAAIi4C9IgCAAg");
        this.shape_15.setTransform(153.2794, 140.3748, 2.37, 2.37);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#FFFFFF").s().p("AlAo5IKBBAIAAP0IqBA/gAkuIlIJhg6IAAvVIphg6g");
        this.shape_16.setTransform(181.3046, 218.5135, 2.37, 2.37);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#FFFFFF").s().p("AmSqcIMlBbIAASDIslBbgAmNKYIMchaIAAx7Ischag");
        this.shape_17.setTransform(179.1124, 218.5728, 2.37, 2.37);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#FFFFFF").s().p("AmvrIINfBnIAATDItfBngAmcKyIM+hgIAAyjIs+hgg");
        this.shape_18.setTransform(178.1051, 218.869, 2.37, 2.37);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#FFFFFF").s().p("AGmBnIAAhUItLhwIAACUICbATIAAAKIilgUIAAipINfBzIAABgg");
        this.shape_19.setTransform(114.4115, 44.0819, 2.37, 2.37);

        this.instance_11 = new lib.Path_3();
        this.instance_11.setTransform(119.75, 54.7, 2.3699, 2.3699, 0, 0, 0, 6.1, 8.8);
        this.instance_11.alpha = 0.5;

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#5FB4DE").s().p("AjjgJIA3AHICGg2QALgDAIgJID3AcQgIAKgKAEIiUA6IBCAHIljAfg");
        this.shape_20.setTransform(107.45, 79.45);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_20 }, { t: this.instance_11 }, { t: this.shape_19 }, { t: this.shape_18 }, { t: this.shape_17 }, { t: this.shape_16 }, { t: this.shape_15 }, { t: this.shape_14 }, { t: this.shape_13 }, { t: this.shape_12 }, { t: this.instance_10 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.instance_9 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.instance_8 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.instance_7 }, { t: this.shape_3 }, { t: this.instance_6 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }, { t: this.instance_5 }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.fenster, new cjs.Rectangle(-98.8, -110.3, 481.6, 498.1), null);


    (lib.standardFensterAni_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(62));

        // Ebene_1
        this.standard_fenster_mc = new lib.fenster();
        this.standard_fenster_mc.name = "standard_fenster_mc";
        this.standard_fenster_mc.setTransform(162, 294.85, 1, 1, 0, 0, 0, 156, 193.8);

        this.timeline.addTween(cjs.Tween.get(this.standard_fenster_mc).to({ alpha: 0 }, 59).to({ _off: true }, 1).wait(2));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-92.8, -9.2, 481.6, 498);


    (lib.rehauFensterAni_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(61));

        // Ebene_1
        this.rehau_fenster_mc = new lib.rehaufenster_mc();
        this.rehau_fenster_mc.name = "rehau_fenster_mc";
        this.rehau_fenster_mc.setTransform(152.6, 190.2, 1, 1, 0, 0, 0, 152.6, 190.2);

        this.timeline.addTween(cjs.Tween.get(this.rehau_fenster_mc).to({ alpha: 0 }, 59).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-30.8, -108.5, 394.8, 488.9);


    (lib.sub_ani_01_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
            if (this.isLoaded) {
                return
            }
            var rehau = false;
            var firstTouch = true;
            var hand_mc = this.hand_ani_mc;
            var rehauFenster = this.rehauFensterAni_mc;
            var standardFenster = this.standardFensterAni_mc;


            this.goBack = function () {

                rehau = false;
                firstTouch = true;
                this.rehau_btn.gotoAndStop(0);
                this.standard_btn.gotoAndStop(1);
                rehauFenster.gotoAndStop(rehauFenster.totalFrames - 1);
                standardFenster.gotoAndStop(0);
                hand_mc.gotoAndStop(0);
            }

            function fadeMcs() {

                if (rehau == true) {
                    if (standardFenster.currentFrame < standardFenster.totalFrames - 1) {
                        standardFenster.gotoAndStop(standardFenster.currentFrame + 1);
                    }
                    if (rehauFenster.currentFrame > 0) {
                        rehauFenster.gotoAndStop(rehauFenster.currentFrame - 1);
                    }
                    if (standardFenster.currentFrame == standardFenster.totalFrames - 1 && rehauFenster.currentFrame == 0) {
                        createjs.Ticker.removeEventListener("tick", fadeMcs);
                    }
                } else {
                    if (standardFenster.currentFrame > 0) {
                        standardFenster.gotoAndStop(standardFenster.currentFrame - 1);
                    }
                    if (rehauFenster.currentFrame < rehauFenster.totalFrames - 1) {
                        rehauFenster.gotoAndStop(rehauFenster.currentFrame + 1);
                    }
                    if (standardFenster.currentFrame == 0 && rehauFenster.currentFrame == rehauFenster.totalFrames - 1) {
                        createjs.Ticker.removeEventListener("tick", fadeMcs);
                    }

                }

            }

            function ClickHandler_test() {
                this.parent.goHome();
            }
            this.testBtn.addEventListener("mousedown", ClickHandler_test.bind(this));
            this.standard_btn.addEventListener("mousedown", ClickHandler_standard.bind(this));
            this.rehau_btn.addEventListener("mousedown", ClickHandler_rehau.bind(this));

            function ClickHandler_standard() {
                if (rehau == true) {
                    rehau = false;
                    this.rehau_btn.gotoAndStop(0);
                    this.standard_btn.gotoAndStop(1);
                    startTicker();
                    if (this.parent.visible) {
                        setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.animation.texte.standard_btn_txt, 'flash installation')
                    }

                }
                uiSound(this.parent.parent, 'clack')
            }

            function ClickHandler_rehau() {
                if (rehau == false) {
                    rehau = true;
                    this.rehau_btn.gotoAndStop(1);
                    this.standard_btn.gotoAndStop(0);
                    startTicker();
                    if (this.parent.visible) {
                        setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.animation.texte.rehau_btn_txt, 'flash installation rehau')
                    }
                }
                uiSound(this.parent.parent, 'clack')
            }

            function startTicker() {
                handWeg();
                if (createjs.Ticker.hasEventListener("tick")) {
                    createjs.Ticker.removeEventListener("tick", fadeMcs);
                }
                createjs.Ticker.addEventListener("tick", fadeMcs);
            }
            function handWeg() {
                if (firstTouch == true) {
                    hand_mc.play();
                    firstTouch = false;
                    setTimeout(5000);

                }
            }
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // icon_02
        this.testBtn = new lib.testBtn();
        this.testBtn.name = "testBtn";
        this.testBtn.setTransform(411.55, 409.6, 1, 1, 0, 0, 0, 13.5, 13.5);

        this.timeline.addTween(cjs.Tween.get(this.testBtn).wait(2));

        // buttons
        this.rehau_btn = new lib.rehau_f_btn();
        this.rehau_btn.name = "rehau_btn";
        this.rehau_btn.setTransform(266.05, 376.55, 1, 1, 0, 0, 0, 45, 24.6);

        this.hand_ani_mc = new lib.hand_ani_mc();
        this.hand_ani_mc.name = "hand_ani_mc";
        this.hand_ani_mc.setTransform(349.7, 379.6, 1, 1, -100.0035, 0, 0, 18.9, 21.4);

        this.standard_btn = new lib.standard_f_btn();
        this.standard_btn.name = "standard_btn";
        this.standard_btn.setTransform(121.85, 377.75, 1, 1, 0, 0, 0, 45, 25.8);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.standard_btn }, { t: this.hand_ani_mc }, { t: this.rehau_btn }] }).wait(2));

        // standart_fenster
        this.standardFensterAni_mc = new lib.standardFensterAni_mc();
        this.standardFensterAni_mc.name = "standardFensterAni_mc";
        this.standardFensterAni_mc.setTransform(170.75, 77.65, 1, 1, 0, 0, 0, 151.2, 239.8);

        this.timeline.addTween(cjs.Tween.get(this.standardFensterAni_mc).wait(2));

        // rehau_fenster
        this.rehauFensterAni_mc = new lib.rehauFensterAni_mc();
        this.rehauFensterAni_mc.name = "rehauFensterAni_mc";
        this.rehauFensterAni_mc.setTransform(199.25, 99.55, 1, 1, 0, 0, 0, 164.9, 153.2);

        this.timeline.addTween(cjs.Tween.get(this.rehauFensterAni_mc).wait(2));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-73.3, -171.4, 521.4, 617.6);


    (lib.main_animation = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        this.isSingleFrame = false;
        // timeline functions:
        this.frame_0 = function () {
            if (this.isSingleFrame) {
                return;
            }
            if (this.totalFrames == 1) {
                this.isSingleFrame = true;
            }
            this.goHome = function () {
                this.sub_ani_01_mc.goBack();
            }
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

        // subAnimation_mc
        this.sub_ani_01_mc = new lib.sub_ani_01_mc();
        this.sub_ani_01_mc.name = "sub_ani_01_mc";
        this.sub_ani_01_mc.setTransform(177.65, 324.8, 1, 1, 0, 0, 0, 114.4, 160.6);

        this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(-10, -7.2, 521.3, 617.6), null);

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}