import { FC, useEffect, useRef } from 'react'
import './Canvas.scss'
import * as createjs from 'createjs-module'
import lib from '../assets/animate/init'
import { config } from './../config'
import { mouseDrag } from '../actions/mouseHelper'
import { radarTouch } from '../actions/touchHelper'
import { connectWS } from '../actions/websocket'
import { switchProjector } from '../actions/viewsonicApi'

interface CanvasProps {
    type: string
    mouse?: {
        left: number,
        top: number
    },
    ws?: WebSocket
}

const Canvas: FC<CanvasProps> = ({ type, mouse, ws }) => {

    const canvasRef = useRef(null)

    var exportRoot: any = useRef({});

    useEffect(() => { 
        const canvas: any = canvasRef.current

        exportRoot.current = new lib.rhfwv1(type)

        exportRoot.current.type = type

        var stage = new (lib as any).Stage(canvas)

        stage.addChild(exportRoot.current)
        createjs.Ticker.framerate = lib.properties.fps
        createjs.Ticker.addEventListener("tick", stage)

        var ctx = canvas.getContext('2d');
        if (window.devicePixelRatio > 1 && type !== 'debug') {

            var canvasWidth = canvas.width;
            var canvasHeight = canvas.height;

            canvas.width = canvasWidth * window.devicePixelRatio;
            canvas.height = canvasHeight * window.devicePixelRatio;
            canvas.style.width = canvasWidth + "px";
            canvas.style.height = canvasHeight + "px";

            ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

            stage.scaleX = window.devicePixelRatio;
            stage.scaleY = window.devicePixelRatio;
        }

        // RadarTouch Input
        const rT = config.isLocal && exportRoot.current.type === 'screen' ? radarTouch(canvas, ctx) : undefined

        const ws = config.isLocal ? connectWS(exportRoot.current) : undefined
        if (ws) {
            exportRoot.current.webSocket = ws
            // console.log(ws)
        }
        // console.log('exportRoot#####', exportRoot.current)

        return () => {

        }
    }, [type, ws])

    useEffect(() => {
        mouseDrag(exportRoot.current, mouse)
        // console.log(exportRoot.current.status.projector)
        if (exportRoot.current.status.projector !== 'screen' || !exportRoot.current.status.projector) {
            switchProjector(exportRoot.current, 'screen')
        }
        return () => { }
    }, [mouse])

    // const resolution = (type === 'screen') ? config.screen


    return <canvas
        ref={canvasRef}
        width={(type === 'screen' || type === 'outside' || type === 'debug') ? config.screen.width : config.mobile.width}
        height={(type === 'screen' || type === 'outside' || type === 'debug') ? config.screen.height : config.mobile.height}
        className={'canvas ' + type}
        id='canvas'
    />
}

export default Canvas