import * as dummy_animation_1 from './content/dummy/animation_1'

import * as energieeffizienz from './content/lueften_heizen_kuehlen/energieeffizienz'
import * as heizen from './content/lueften_heizen_kuehlen/heizen'
import * as installation from './content/lueften_heizen_kuehlen/installation'
import * as komfort from './content/lueften_heizen_kuehlen/komfort'
import * as kuehlen from './content/lueften_heizen_kuehlen/kuehlen'
import * as lueften from './content/lueften_heizen_kuehlen/lueften'
import * as analyse from './content/regenerative_energie/analyse'
import * as analyse_1 from './content/regenerative_energie/analyse_1'
import * as analyse_2 from './content/regenerative_energie/analyse_2'
import * as analyse_3 from './content/regenerative_energie/analyse_3'
import * as energiegewinnung from './content/regenerative_energie/energiegewinnung'
import * as nutzungsoptionen from './content/regenerative_energie/nutzungsoptionen'
import * as praesenzCenter from './content/smart_privacy/praesenz-center'
import * as praesenzRight from './content/smart_privacy/praesenz-right'
import * as securityCenter from './content/smart_privacy/security-center'
import * as securityRight from './content/smart_privacy/security-right'
import * as tageslichtsensor from './content/smart_privacy/tageslichtsensor'
import * as tageslichtsensorRight from './content/smart_privacy/tageslichtsensor-right'
import * as daemmerungssensor from './content/smart_privacy/daemmerungssensor'
import * as daemmerungssensorRight from './content/smart_privacy/daemmerungssensor-right'
import * as dimensionen from './content/innovatives/dimensionen'
import * as modulation from './content/innovatives/modulation'
import * as stabilitaet from './content/innovatives/stabilitaet'
import * as thermik from './content/innovatives/thermik'
import * as montage from './content/windowid/montage'
import * as schnelle_hilfe from './content/windowid/schnelle_hilfe'
import * as urban_mining from './content/windowid/urban_mining'
import * as window_id from './content/windowid/window_id'
import * as screensaver from './outside/screensaver'

export const animationLib = {
    'dummy/animation_1': dummy_animation_1,
    'lueften_heizen_kuehlen/energieeffizienz': energieeffizienz,
    'lueften_heizen_kuehlen/heizen': heizen,
    'lueften_heizen_kuehlen/installation': installation,
    'lueften_heizen_kuehlen/komfort': komfort,
    'lueften_heizen_kuehlen/kuehlen': kuehlen,
    'lueften_heizen_kuehlen/lueften': lueften,
    'regenerative_energie/analyse': analyse,
    'regenerative_energie/analyse_1': analyse_1,
    'regenerative_energie/analyse_2': analyse_2,
    'regenerative_energie/analyse_3': analyse_3,
    'regenerative_energie/energiegewinnung': energiegewinnung,
    'regenerative_energie/nutzungsoptionen': nutzungsoptionen,
    'smart_privacy/praesenz-center': praesenzCenter,
    'smart_privacy/praesenz-right': praesenzRight,
    'smart_privacy/security-center': securityCenter,
    'smart_privacy/security-right': securityRight,
    'smart_privacy/tageslichtsensor': tageslichtsensor,
    'smart_privacy/tageslichtsensor-right': tageslichtsensorRight,
    'smart_privacy/daemmerungssensor': daemmerungssensor,
    'smart_privacy/daemmerungssensor-right': daemmerungssensorRight,
    'innovatives/dimensionen': dimensionen,
    'innovatives/modulation': modulation,
    'innovatives/stabilitaet': stabilitaet,
    'innovatives/thermik': thermik,
    'windowid/montage': montage,
    'windowid/schnelle_hilfe': schnelle_hilfe,
    'windowid/urban_mining': urban_mining,
    'windowid/window_id': window_id,
    'screensaver': screensaver
}