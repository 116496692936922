/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */


    (lib.txt_mc_11 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Gesamtertrag pro Tag 0,2 kWh", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_11, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_10 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Ertrag bis zu 0,80 kWh/m2 pro Tag", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_10, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_09 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Gesamtleistung 50 Watt", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_09, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_08 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Aktive Fläche der Fenterbank 0,25 m2", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 357;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_08, new cjs.Rectangle(0,0,360.5,20.8), null);
        
        
        (lib.txt_mc_07 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Max. Leistung 200 Watt pro m2", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_07, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_06 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Speicherkapazität der Batterie 1 kWh", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_06, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_05 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Gesamtertrag pro Tag 1,44 kWh", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_05, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_04 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Ertrag bis zu 0,20 kWh/m2 pro Tag", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_04, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Gesamtleistung 360 Watt", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_03, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Glasfläche 7,2 m2", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 357;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_02, new cjs.Rectangle(0,0,360.5,20.8), null);
        
        
        (lib.txt_mc_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Max. Leistung 50 Watt pro m2", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_01, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.testBtn = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#000000").s().p("AjYDjIAAnFIGwAAIAAHFg");
            this.shape.setTransform(19.05,22.025);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.testBtn, new cjs.Rectangle(-2.6,-0.6,43.300000000000004,45.300000000000004), null);
        
        
        (lib.mc_h2_2 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Fensterbank", "bold 20px 'Brix Sans Black'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 26;
            this.txt_01.lineWidth = 198;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.mc_h2_2, new cjs.Rectangle(0,0,202,27.9), null);
        
        
        (lib.mc_h1_1 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Glasscheibe", "bold 20px 'Brix Sans Black'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 26;
            this.txt_01.lineWidth = 194;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.mc_h1_1, new cjs.Rectangle(0,0,198,27.9), null);
        
        
        (lib.iconRahmen_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhYBZICxAAIAAixIixAAg");
            this.shape.setTransform(25.7705,25.7705,2.3697,2.3697);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.iconRahmen_mc, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_color_shape_green = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#34A58C").s().p("AkBECIAAoDIIDAAIAAIDg");
            this.shape.setTransform(25.775,25.775);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.icon_color_shape_green, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_color_shape = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#DD0061").s().p("AkBECIAAoDIIDAAIAAIDg");
            this.shape.setTransform(25.775,25.775);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.icon_color_shape, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_mc_07 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgpAEIAAgHIBSAAIAAAHg");
            this.shape.setTransform(21.8341,29.0681,1.3648,1.3648);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgzACIAAgDIBnAAIAAADg");
            this.shape_1.setTransform(21.8341,23.7794,1.3648,1.3648);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgsACIAAgDIBZAAIAAADg");
            this.shape_2.setTransform(21.8682,21.2203,1.3648,1.3648);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgFgcIAEgBIAHA7IgDAAg");
            this.shape_3.setTransform(25.8604,22.7557,1.3648,1.3648);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgBAeIAAg7IADAAIAAA7g");
            this.shape_4.setTransform(21.8682,22.7557,1.3648,1.3648);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgGAeIAIg7IAFABIgIA6g");
            this.shape_5.setTransform(17.8078,22.7557,1.3648,1.3648);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("Ag/AiIAUhDIBXAAIAUBDgAg0AaIBoAAIgOgyIhLAAg");
            this.shape_6.setTransform(21.8682,22.7557,1.3648,1.3648);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#FFFFFF").s().p("AgKAFIAPgPIAGAGIgPAPg");
            this.shape_7.setTransform(10.2671,25.2466,1.3648,1.3648);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#FFFFFF").s().p("AgLAAIAUgGIADAIIgVAFg");
            this.shape_8.setTransform(8.2881,21.7321,1.3648,1.3648);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#FFFFFF").s().p("AgLACIACgIIAVAFIgDAIg");
            this.shape_9.setTransform(8.2881,17.7058,1.3648,1.3648);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#FFFFFF").s().p("AgKgEIAGgGIAPAPIgGAGg");
            this.shape_10.setTransform(10.2671,14.1914,1.3648,1.3648);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#FFFFFF").s().p("AgGgIIAHgDIAGAVIgHACg");
            this.shape_11.setTransform(13.7816,12.1783,1.3648,1.3648);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#FFFFFF").s().p("AgGAKIAGgVIAHADIgGAUg");
            this.shape_12.setTransform(17.8078,12.1783,1.3648,1.3648);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f("#FFFFFF").s().p("AgKAFIAPgPIAGAGIgPAPg");
            this.shape_13.setTransform(21.3223,14.1914,1.3648,1.3648);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f("#FFFFFF").s().p("AggAGQAAgPALgMQALgLAQAAQAQAAALALIgGAGQgJgJgMAAQgMAAgJAJQgJAJAAAMQAAANAJAJIgGAFQgLgKAAgRg");
            this.shape_14.setTransform(15.044,18.9342,1.3648,1.3648);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_06_green = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgKAJIAAgRIAVAAIAAARgAgDADIAIAAIAAgFIgIAAg");
            this.shape.setTransform(19.5,10.3,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgdArIAAhUIA7AAIAABUgAgXAkIAvAAIAAhHIgvAAg");
            this.shape_1.setTransform(19.5,22.5,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgdArIAAhVIA7AAIAABVgAgXAkIAvAAIAAhIIgvAAg");
            this.shape_2.setTransform(19.5,19.6,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgGgEIAEgBIACAGIAGgBIABADIgKADg");
            this.shape_3.setTransform(19.3,22.85,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgDAOIAEgLIgKAAIAKgSIAEADIgGALIAKAAIgJARg");
            this.shape_4.setTransform(19.55,20.2,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape_green();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_05_green = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AAEALIgEgIIgDAIIgGAAIAHgLIgHgKIAGAAIADAHIAEgHIAGAAIgHAKIAHALg");
            this.shape.setTransform(24,13.1,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AAFALIgBgEIgHAAIgCAEIgFAAIAIgVIAFAAIAIAVgAgCACIAFAAIgDgHg");
            this.shape_1.setTransform(19.85,13.1,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AAJALIgCgPIgEAPIgFAAIgEgPIgBAPIgFAAIACgVIAHAAIADAPIAEgPIAHAAIACAVg");
            this.shape_2.setTransform(14.8,13.1,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgCAVIAAgpIAFAAIAAApg");
            this.shape_3.setTransform(10.4,24.85,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgrAWIAAgGIAeAAIAwgcIgDgCQgBgBgBAAQAAAAgBABQAAAAgBAAQAAAAgBABIgVALIgYAAIAAgHIAXAAIATgKQACgCAEAAQAHAAAEAHIADAFIg3Afg");
            this.shape_4.setTransform(21.05,23.25,2,2);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgXALIAAgGIAEAAIAQgPIAbAAIAAAHQAAADgEAFQgEADgFAAIAAgHQAGAAAAgEIgRAAIgQAOg");
            this.shape_5.setTransform(17,21.05,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape_green();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_05 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AAEALIgEgIIgDAIIgGAAIAHgLIgHgKIAGAAIADAHIAEgHIAGAAIgHAKIAHALg");
            this.shape.setTransform(24,13.1,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AAFALIgBgEIgHAAIgCAEIgFAAIAIgVIAFAAIAIAVgAgCACIAFAAIgDgHg");
            this.shape_1.setTransform(19.85,13.1,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AAJALIgCgPIgEAPIgFAAIgEgPIgBAPIgFAAIACgVIAHAAIADAPIAEgPIAHAAIACAVg");
            this.shape_2.setTransform(14.8,13.1,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgCAVIAAgpIAFAAIAAApg");
            this.shape_3.setTransform(10.4,24.85,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgrAWIAAgGIAeAAIAwgcIgDgCQgBgBgBAAQAAAAgBABQAAAAgBAAQAAAAgBABIgVALIgYAAIAAgHIAXAAIATgKQACgCAEAAQAHAAAEAHIADAFIg3Afg");
            this.shape_4.setTransform(21.05,23.25,2,2);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgXALIAAgGIAEAAIAQgPIAbAAIAAAHQAAADgEAFQgEADgFAAIAAgHQAGAAAAgEIgRAAIgQAOg");
            this.shape_5.setTransform(17,21.05,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_04_green = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgIAJQgDgEgBgFQABgEADgEQAEgDAEgBQAFABAEADQADAEABAEIgHAAQAAgGgGABQgFgBAAAGQAAAGAFAAIAAAHQgEgBgEgDg");
            this.shape.setTransform(19.25,13.15,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgIAJQgEgEABgFQgBgEAEgEQAEgEAEAAQAFAAAEAEQAEAEAAAEQAAAFgEAEQgEAEgFAAQgEAAgEgEgAgDgDQgBAAAAABQgBAAAAABQAAAAAAABQgBAAAAAAQAAAGAGAAQAGAAAAgGQAAgFgGAAQAAAAAAAAQgBAAAAAAQgBABAAAAQgBAAAAABg");
            this.shape_1.setTransform(22.45,16.3,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgCAUIAAgnIAFAAIAAAng");
            this.shape_2.setTransform(11.1,26.05,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgrAWIAAgGIAeAAIAwgbQgDgGgFADIgVAMIgYAAIAAgHIAXAAIATgKQACgCAEAAQAHAAAEAGIADAGIg3Afg");
            this.shape_3.setTransform(21.75,24.45,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgXALIAAgGIAEAAIAQgPIAbAAIAAAGQAAAFgEADQgEAEgFAAIAAgHQAGABAAgGIgRAAIgQAPg");
            this.shape_4.setTransform(17.7,22.25,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape_green();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_04 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgIAJQgDgEgBgFQABgEADgEQAEgDAEgBQAFABAEADQADAEABAEIgHAAQAAgGgGABQgFgBAAAGQAAAGAFAAIAAAHQgEgBgEgDg");
            this.shape.setTransform(19.25,13.15,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgIAJQgEgEABgFQgBgEAEgEQAEgEAEAAQAFAAAEAEQAEAEAAAEQAAAFgEAEQgEAEgFAAQgEAAgEgEgAgDgDQgBAAAAABQgBAAAAABQAAAAAAABQgBAAAAAAQAAAGAGAAQAGAAAAgGQAAgFgGAAQAAAAAAAAQgBAAAAAAQgBABAAAAQgBAAAAABg");
            this.shape_1.setTransform(22.45,16.3,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgCAUIAAgnIAFAAIAAAng");
            this.shape_2.setTransform(11.1,26.05,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgrAWIAAgGIAeAAIAwgbQgDgGgFADIgVAMIgYAAIAAgHIAXAAIATgKQACgCAEAAQAHAAAEAGIADAGIg3Afg");
            this.shape_3.setTransform(21.75,24.45,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgXALIAAgGIAEAAIAQgPIAbAAIAAAGQAAAFgEADQgEAEgFAAIAAgHQAGABAAgGIgRAAIgQAPg");
            this.shape_4.setTransform(17.7,22.25,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_03_green = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgLgIIAHgCIADALIALgCIACAGIgSAGg");
            this.shape.setTransform(19.25,23.75,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgHAXIAJgSIgRAAIAQgfIAIAEIgKAUIARAAIgQAdg");
            this.shape_1.setTransform(19.6,19.3,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgeAuIAEgGQAOAJAOAAQAZAAAPgVQAMgQgEgTQgDgUgRgMQgNgJgPAAQgYAAgPAVQgQAWALAZIALgFIgEAbIgWgPIAIgEQgMgcASgaQARgYAcAAQARAAAPAKQATAOAEAXQAEAWgNATQgRAXgdAAQgRAAgPgKg");
            this.shape_2.setTransform(19.7914,19.9,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape_green();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgLgIIAHgCIADALIALgCIACAGIgSAGg");
            this.shape.setTransform(19.25,23.75,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgHAXIAJgSIgRAAIAQgfIAIAEIgKAUIARAAIgQAdg");
            this.shape_1.setTransform(19.6,19.3,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgeAuIAEgGQAOAJAOAAQAZAAAPgVQAMgQgEgTQgDgUgRgMQgNgJgPAAQgYAAgPAVQgQAWALAZIALgFIgEAbIgWgPIAIgEQgMgcASgaQARgYAcAAQARAAAPAKQATAOAEAXQAEAWgNATQgRAXgdAAQgRAAgPgKg");
            this.shape_2.setTransform(19.7914,19.9,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_02_green = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgsAtIAAhEIBEAAIAABEgAglAmIA2AAIAAg3Ig2AAgAgHAeIANgOIACACIgNAOgAgcAaIAighIACACIghAigAAmAYIAAg9Ig6AAIAAAHIgGAAIAAgOIBHAAIAABEgAgcACIAOgNIADADIgOANg");
            this.shape.setTransform(19.4,20.4,2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape_green();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgsAtIAAhEIBEAAIAABEgAglAmIA2AAIAAg3Ig2AAgAgHAeIANgOIACACIgNAOgAgcAaIAighIACACIghAigAAmAYIAAg9Ig6AAIAAAHIgGAAIAAgOIBHAAIAABEgAgcACIAOgNIADADIgOANg");
            this.shape.setTransform(19.4,20.4,2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_01_green = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgKAIIATgSIACACIgTATg");
            this.shape.setTransform(15.1,14.8,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgTASIAlgmIADADIgnAmg");
            this.shape_1.setTransform(17.05,16.75,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgEAEIAAgDIACAAQADAAAAgEIAEAAQAAAHgHAAg");
            this.shape_2.setTransform(20.95,20.8,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgEAEQAAgDADgBQABgDAEAAIABAAIAAADIgBAAQgFAAAAAEg");
            this.shape_3.setTransform(22.25,21.8,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AAAAFIAAgCQAAgEgDAAIAAgDQAHAAAAAHIAAACg");
            this.shape_4.setTransform(21.15,21.95,2,2);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgBACQgCgCAAgCIAAgCIADAAIAAACQAAAEAEAAIAAADQgDAAgCgDg");
            this.shape_5.setTransform(22.1,20.65,2,2);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("AgTAUQgIgJAAgLQAAgKAIgJQAJgHAKgBQAMABAIAHQAIAJAAAKQAAALgIAJQgIAHgMABQgKgBgJgHgAgOgOQgGAGAAAIQAAAJAGAGQAGAGAIAAQAJAAAGgGQAGgGAAgJQAAgIgGgGQgGgGgJAAQgIAAgGAGg");
            this.shape_6.setTransform(22.9,22.6,2,2);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#FFFFFF").s().p("AgQARIAAgGIAbAAIAAgbIAGAAIAAAhg");
            this.shape_7.setTransform(21.75,21.5,2,2);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#FFFFFF").s().p("AgMgHIAFgFIAUAUIgEAFg");
            this.shape_8.setTransform(28.45,28.15,2,2);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#FFFFFF").s().p("AghAiIAAhDIBDAAIAAAdIgHAAIAAgXIg2AAIAAA2IAXAAIAAAHg");
            this.shape_9.setTransform(17.05,16.8,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape_green();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.iconAni_mc_11 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_05();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_11();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_10 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_04();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_10();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_09 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_03();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_09();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_08 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_02();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_08();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,484.5,31.1);
        
        
        (lib.iconAni_mc_07 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_07();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_07();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_06 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_06_green();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_06();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_05 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_05_green();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,y:97.35,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_05();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,32.1);
        
        
        (lib.iconAni_mc_04 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_04_green();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_04();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_03_green();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_03();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_02_green();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_02();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,484.5,31.1);
        
        
        (lib.iconAni_mc_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_01_green();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_01();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {play_icon_1:238,play_icon_2:249,play_icon_3:262,play_icon_4:275,play_icon_5:287,play_icon_6:299,play_icon_7:312,play_icon_8:326,play_icon_9:340,play_icon_10:356,play_icon_11:374};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var subAniMc = this;
                this.wait = function(){
                    if(subAniMc.parent.setTime == true){
                        subAniMc.play()
                    }
                }
                
                
                this.goBack = function() {
                    this.parent.setTime = false;
                    for(var i = 1;i<12;i++){
                        this["mc_"+i].icon.gotoAndStop(0);
                    }
                    subAniMc.gotoAndPlay(1);
                }
                this.testBtn.addEventListener("mousedown", clickHandler_test.bind(this));
                function clickHandler_test(){
                    this.parent.goHome();
                }
            }
            this.frame_235 = function() {
                this.stop();
                this.parent.setTime = true;
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_238 = function() {
                this.stop();
                this.mc_1.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_249 = function() {
                this.stop();
                this.mc_2.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_262 = function() {
                this.stop();
                this.mc_3.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_275 = function() {
                this.stop();
                this.mc_4.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_287 = function() {
                this.stop();
                this.mc_5.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_299 = function() {
                this.stop();
                this.mc_6.icon.play();
                setTimeout(this.wait, this.parent.time2wait+2000);
            }
            this.frame_312 = function() {
                this.stop();
                this.mc_7.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_326 = function() {
                this.stop();
                this.mc_8.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_340 = function() {
                this.stop();
                this.mc_9.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_356 = function() {
                this.stop();
                this.mc_10.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_374 = function() {
                this.stop();
                this.mc_11.icon.play();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(235).call(this.frame_235).wait(3).call(this.frame_238).wait(11).call(this.frame_249).wait(13).call(this.frame_262).wait(13).call(this.frame_275).wait(12).call(this.frame_287).wait(12).call(this.frame_299).wait(13).call(this.frame_312).wait(14).call(this.frame_326).wait(14).call(this.frame_340).wait(16).call(this.frame_356).wait(18).call(this.frame_374).wait(37));
        
            // Ebene_11
            this.testBtn = new lib.testBtn();
            this.testBtn.name = "testBtn";
            this.testBtn.setTransform(387.5,487.45,1,1,0,0,0,13.5,13.5);
        
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#DD0061").s().p("AgTAYQgGgJAAgPQAAgOAGgJQAIgJANAAQARAAAHANIgJAFQgEgIgKAAQgRAAAAAWQAAAXARAAQAKAAAEgIIAJAFQgHANgRAAQgNAAgIgJg");
            this.shape.setTransform(-101.45,-23.225);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#DD0061").s().p("AAkAgIAAgnQAAgPgOAAQgKABgGAHIAAAuIgLAAIAAguQgDgIgLAAQgJABgHAHIAAAuIgLAAIAAg/IALAAIAAAIQAHgIALgBQAOAAAFAKQAJgKAOAAQAWAAAAAYIAAAog");
            this.shape_1.setTransform(-110.3,-23.3);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_2.setTransform(-119.5,-19.2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#DD0061").s().p("AAJAvIAAhSIgZALIgDgKIAdgMIAKAAIAABdg");
            this.shape_3.setTransform(-126.15,-24.775);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#DD0061").s().p("AghAAQAAgwAhAAQAiAAAAAwQAAAxgiAAQghAAAAgxgAgVAAQAAAmAVAAQAWAAAAgmQAAgmgWAAQgVAAAAAmg");
            this.shape_4.setTransform(-132.525,-24.775);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_5.setTransform(-140.2,-19.2);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#DD0061").s().p("AgEAvIAAg/IAKAAIAAA/gAgHgnQAAgHAHAAQAIAAAAAHQAAAIgIAAQgHAAAAgIg");
            this.shape_6.setTransform(-145.2,-24.775);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#DD0061").s().p("AAQAgIAAgmQAAgIgDgDQgEgFgHAAQgKABgHAHIAAAuIgLAAIAAg/IALAAIAAAIQAGgJAOAAQAWAAAAAZIAAAng");
            this.shape_7.setTransform(-150.3,-23.3);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#DD0061").s().p("AgUAcQgFgEAAgJQAAgQAcgEIALgBIAAgGQAAgLgNAAQgMAAAAAIIAAADIgKAAIAAgEQAAgIAGgEQAGgEALAAQAYAAAAAUIAAAsIgLAAIAAgIQgIAJgNAAQgJAAgFgFgAAEACQgRADAAAKQAAAJALAAQAJAAAHgIIAAgPg");
            this.shape_8.setTransform(-157.375,-23.225);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_9.setTransform(-164,-19.2);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#DD0061").s().p("AgSAqIgDAFIgGAAIAAhfIALAAIAAAnQAGgIALAAQAbAAAAAhQAAAPgHAIQgHAKgNAAQgMAAgHgHgAgQAAIAAAeQAFAJALgBQARAAAAgWQAAgXgRAAQgLAAgFAHg");
            this.shape_10.setTransform(-170.925,-24.8);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#DD0061").s().p("AgaAIIAAgoIALAAIAAAnQAAAIADADQADAEAJAAQAJAAAHgHIAAgvIALAAIAAA/IgLAAIAAgHQgGAJgOAAQgWAAAAgZg");
            this.shape_11.setTransform(-178.35,-23.175);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#DD0061").s().p("AgYAXIAGgHQAIAIAKAAQANAAAAgJQAAgHgJgCIgKgDQgQgDAAgNQAAgIAGgFQAGgGALAAQAPAAAHAIIgGAHQgHgHgJAAQgMAAAAAKQAAAHAIACIAKACQASADAAAOQAAAIgGAGQgIAFgLAAQgQAAgIgKg");
            this.shape_12.setTransform(-184.95,-23.225);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape},{t:this.testBtn}]}).wait(411));
        
            // Ebene_12
            this.mc_11 = new lib.iconAni_mc_11();
            this.mc_11.name = "mc_11";
            this.mc_11.setTransform(216,483.2,1,1,0,0,0,216,48.2);
            this.mc_11.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_11).to({_off:true},1).wait(230).to({_off:false,alpha:1},0).wait(180));
        
            // Ebene_10
            this.mc_10 = new lib.iconAni_mc_10();
            this.mc_10.name = "mc_10";
            this.mc_10.setTransform(216,443.2,1,1,0,0,0,216,48.2);
            this.mc_10.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_10).to({_off:true},1).wait(216).to({_off:false,alpha:1},0).wait(194));
        
            // Ebene_9
            this.mc_9 = new lib.iconAni_mc_09();
            this.mc_9.name = "mc_9";
            this.mc_9.setTransform(216,403.2,1,1,0,0,0,216,48.2);
            this.mc_9.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_9).to({_off:true},1).wait(200).to({_off:false,alpha:1},0).wait(210));
        
            // Ebene_8
            this.mc_8 = new lib.iconAni_mc_08();
            this.mc_8.name = "mc_8";
            this.mc_8.setTransform(216,363.2,1,1,0,0,0,216,48.2);
            this.mc_8.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_8).to({_off:true},1).wait(185).to({_off:false,alpha:1},0).wait(225));
        
            // icon_1
            this.mc_7 = new lib.iconAni_mc_07();
            this.mc_7.name = "mc_7";
            this.mc_7.setTransform(204.8,323.2,1,1,0,0,0,204.8,48.2);
            this.mc_7.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_7).to({_off:true},1).wait(172).to({_off:false,alpha:1},0).wait(238));
        
            // h2_2
            this.mc_h2_2 = new lib.mc_h2_2();
            this.mc_h2_2.name = 'mc_h2_2';
            this.mc_h2_2.setTransform(195,259.75,1,1,0,0,0,101,12.1);
            this.mc_h2_2.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_h2_2).to({_off:true},1).wait(151).to({_off:false},0).to({x:144,alpha:1},16,cjs.Ease.quadOut).wait(243));
        
            // Ebene_6
            this.mc_6 = new lib.iconAni_mc_06();
            this.mc_6.name = "mc_6";
            this.mc_6.setTransform(216,223.2,1,1,0,0,0,216,48.2);
            this.mc_6.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_6).to({_off:true},1).wait(120).to({_off:false,alpha:1},0).wait(290));
        
            // Ebene_5
            this.mc_5 = new lib.iconAni_mc_05();
            this.mc_5.name = "mc_5";
            this.mc_5.setTransform(216,183.2,1,1,0,0,0,216,48.2);
            this.mc_5.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_5).to({_off:true},1).wait(104).to({_off:false,alpha:1},0).wait(306));
        
            // Ebene_4
            this.mc_4 = new lib.iconAni_mc_04();
            this.mc_4.name = "mc_4";
            this.mc_4.setTransform(216,143.2,1,1,0,0,0,216,48.2);
            this.mc_4.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_4).to({_off:true},1).wait(89).to({_off:false,alpha:1},0).wait(321));
        
            // Ebene_3
            this.mc_3 = new lib.iconAni_mc_03();
            this.mc_3.name = "mc_3";
            this.mc_3.setTransform(216,103.2,1,1,0,0,0,216,48.2);
            this.mc_3.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_3).to({_off:true},1).wait(74).to({_off:false,alpha:1},0).wait(336));
        
            // Ebene_2
            this.mc_2 = new lib.iconAni_mc_02();
            this.mc_2.name = "mc_2";
            this.mc_2.setTransform(216,63.2,1,1,0,0,0,216,48.2);
            this.mc_2.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_2).to({_off:true},1).wait(59).to({_off:false,alpha:1},0).wait(351));
        
            // icon_1
            this.mc_1 = new lib.iconAni_mc_01();
            this.mc_1.name = "mc_1";
            this.mc_1.setTransform(204.8,23.2,1,1,0,0,0,204.8,48.2);
            this.mc_1.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_1).to({_off:true},1).wait(44).to({_off:false,alpha:1},0).wait(366));
        
            // Ebene_16
            this.mc_h2_1 = new lib.mc_h1_1();
            this.mc_h2_1.name = "mc_h2_1";
            this.mc_h2_1.setTransform(196,-43.05,1,1,0,0,0,99,10.3);
            this.mc_h2_1.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_h2_1).to({_off:true},1).wait(18).to({_off:false},0).to({x:142,alpha:1},19,cjs.Ease.quadOut).wait(373));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-189.9,-53.3,656.9,572);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.setTime = false;
                this.time2wait = 200;
                this.goHome=function(){
                    this.sub_ani_01_mc.goBack();
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(195.65,235.75,1,1,0,0,0,114.4,160.6);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(-108.7,21.8,657,572), null);
        
        
        
    /* IMPORT END 
    // stage content: ...
    */

    return lib
}