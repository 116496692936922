export const handleSpeech = (root, speech) => {
    // console.log(speech, root) // , commonwords(speech + ' ', 'kekse')

    if (root.safearea && root.safearea.sprache) {
        root.safearea.sprache.text = speech
        return
    }

    if (root.status && root.status.currentPage){ // && root.status.currentPage.speech) {
        // Sprache erkennen screen

        // log?
        //root.headlineContent.l2.txt.text = speech

        if (
            root.status.currentPage && 
            root.status.currentPage.animation && 
            root['animations_' + root.status.currentPage.animation.right]
            && root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc
            && root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.micro_mc) {
            root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.micro_mc.gotoAndPlay(1)
            if (speech !== '') {
                root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.micro_mc.micro.shape.graphics._fill.style = "#fb1c46"    
            } else {
                root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.micro_mc.micro.shape.graphics._fill.style = "#ffffff"
            }
            
            // root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.micro_mc.sprache.text = speech
        }



        // Stop nach timeout?

        // root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.action_btn.click()

        // console.log(root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.action_btn.dispatchEvent(new Event("mousedown")))
        console.log(root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc)

        if (root.status.speech === '' && root.status.currentPage.speech && root.status.currentPage.speech.synonyms) {
            var substrings = root.status.currentPage.speech.synonyms,
                length = substrings.length;
            while (length--) {
                if (speech.indexOf(substrings[length]) !== -1) {
                    if (root.status.speech === '') {
                        console.log('treffer')

                        if (root['animations_' + root.status.currentPage.animation.right]
                            && root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc[root.status.currentPage.speech.button]) {

                            console.log('#####', root['animations_' + root.status.currentPage.animation.right])
                            if (root['animations_' + root.status.currentPage.animation.right].visible) {
                                root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc[root.status.currentPage.speech.button].dispatchEvent(new Event("mousedown"))
                            }
                        }

                        root.status.speech = substrings[length]

                        // root.headlineContent.l1.txt.text = 'Wort erkannt'
                        root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.micro_mc.sprache.text = ''
                        root['animations_' + root.status.currentPage.animation.right].sub_ani_01_mc.micro_mc.micro.shape.graphics._fill.style = "#34A58C"
                    }
                    // root.rehaulogo.logo[logo].shape.graphics._fill.style = "#ffacac"
                }
            }
        }


    }




}


function commonwords(string, wordlist) {
    string = string.toLowerCase().split(/\s+/);
    wordlist = wordlist.toLowerCase().split(/\s+/);
    return wordlist.every(function (itm) {
        return string.indexOf(itm) !== -1;
    });
}