import { FC } from 'react'
import { config } from '../config';
import Canvas from '../ui/Canvas';

const Outside: FC = () => {

    return (
        <div className='outside'>
            {
                (!config.isLocal)
                    ? <div>
                        <h2>Outside</h2>
                        <h2>Version: {config.version}</h2>
                    </div> : ''
            }
            <Canvas
                type={'outside'}
            />
        </div>
    );

}

export default Outside