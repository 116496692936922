import * as cjs from 'createjs-module'

function mc_symbol_clone() {
    var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
    clone.gotoAndStop(this.currentFrame);
    clone.paused = this.paused;
    clone.framerate = this.framerate;
    return clone;
}

export function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
    var prototype = cjs.extend(symbol, cjs.MovieClip);
    prototype.clone = mc_symbol_clone;
    prototype.nominalBounds = nominalBounds;
    prototype.frameBounds = frameBounds;

    return prototype;
}

export const saveMcPos = (root, mc) => {

    // Main MC
    root.mcPos[mc] = {
        x: root[mc].x,
        y: root[mc].y
    }

    root[mc].children.forEach(obj => {
        // console.log('###1:', obj, obj.name)
        if (obj.name) {
            root.mcPos[mc + "." + obj.name] = {
                x: root[mc][obj.name].x,
                y: root[mc][obj.name].y
            }
        }

        if (obj.children) {
            obj.children.forEach(obj2 => {
                // console.log('###2:', obj2, 'obj2.name:', obj2.name, 'MovieClip: ', (obj2 instanceof cjs.MovieClip))
                if (!(obj2 instanceof cjs.MovieClip) || !obj2.name) {
                    return
                }
                root.mcPos[mc + "." + obj.name + '.' + obj2.name] = {
                    x: root[mc][obj.name][obj2.name].x,
                    y: root[mc][obj.name][obj2.name].y
                }

            });
        }

    });
}