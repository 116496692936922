import { config } from './../config'

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,OPTIONS',
}

export const fanApi = async (color: string = 'red') => {

    if (!config.isLocal) {
        return
    }

    var url = `${config.fanApi.url}:${config.fanApi.port}/${color}`

    fanHotApi('off')

    var options: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors'
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return { error: true, text: `${error}` }
    })

    const data = await response;

    if (data.result && data.result === 'error') {
        console.log('FAn API Error:', data)
    } else {
        console.log('FAN:', data)

        if (color === 'red') {
            fanHotApi('on')
        }
    }
    return data
}


export const fanHotApi = async (status: string = 'off') => {

    if (!config.isLocal) {
        return
    }

    var url = `${config.hotFanApi.url}:${config.hotFanApi.port}/${status}`

    var options: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors'
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return { error: true, text: `${error}` }
    })

    const data = await response;

    if (data.result && data.result === 'error') {
        console.log('FAn HOT API Error:', data)
    } else {
        console.log('FAN HOT:', data)
    }
    return data
}
