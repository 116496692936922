import { config } from '../config';
import { FC, useEffect, useState } from 'react'
import Canvas from '../ui/Canvas';
import './Admin.scss'
import { shellyApi } from '../actions/shellyApi';
import { viewsonicApi } from '../actions/viewsonicApi'
import LedController from './LedController';
import { fanApi } from '../actions/fanApi';
import { powerApi } from '../actions/adminHelper';


interface DebugProps {
    ws?: WebSocket
}

const projectors = [
    {
        ip: '192.168.22.21',
        name: 'Front'
    },
    {
        ip: '192.168.22.22',
        name: 'Oben, Back'
    }
]

const fanleds = [
    {
        color: 'red',
        name: 'Rot'
    },
    {
        color: 'blue',
        name: 'Blau'
    },
    {
        color: 'white',
        name: 'Weiß'
    },
    {
        color: 'off',
        name: 'Aus'
    }
]

const Debug: FC<DebugProps> = ({ ws }) => {

    // const [MousePosition, setMousePosition] = useState({
    //     left: 0,
    //     top: 0
    // })

    // function handleMouseMove(ev: any) { setMousePosition({ left: ev.pageX, top: ev.pageY }); }

    // console.log('Shelly', shellyApi())

    const [isOn, setOn] = useState(true)
    const [getStatus, setGetStatus] = useState(true)
    const [pA, setPA] = useState<any>()

    // useEffect(() => {
    //     if (config.isLocal) {
    //         setPA(powerApi())
    //         console.log(pA)
    //         if (pA && pA.serverStatus === 'on') {
    //             setOn(true)
    //         } else {
    //             setOn(false)
    //         }
    //         setGetStatus(false)
    //     }

    // }, [pA, getStatus])

    return (
        <div
            // onMouseMove={(ev) => handleMouseMove(ev)}
            className='debug'
        >
            <h2>Admin</h2>
            <h2>Version: {config.version}</h2>
            <div className='admin'>
                <div className='led'>
                    <LedController />
                </div>
                <div className='viewsonic'>
                    <h3>Beamer</h3>
                    {
                        projectors.map((projector: any, lkey) => (
                            <div key={lkey}>
                                <strong>{projector.name}</strong>
                                <button
                                    onClick={() => { viewsonicApi('on', projector.ip) }}
                                >AN</button>
                                <button onClick={() => { viewsonicApi('off', projector.ip) }}>AUS</button>
                                <button onClick={() => { viewsonicApi('blankon', projector.ip) }}>Blank an</button>
                                <button onClick={() => { viewsonicApi('blankoff', projector.ip) }}>Blank aus</button>
                                {
                                    (projector.name === 'Front') ? <div>
                                        <button onClick={() => { viewsonicApi('hdmi1', projector.ip) }}>HDMI Front</button>
                                        <button onClick={() => { viewsonicApi('hdmi2', projector.ip) }}>HDMI 2</button>
                                    </div> : ''
                                }

                            </div>
                        ))
                    }
                </div>
                <div className='fanled'>
                    <h3>Luft/Led</h3>
                    <div className='colors'>
                        {
                            fanleds.map((fanled: any, lkey) => (
                                <div key={lkey}>
                                    <button
                                        onClick={() => { fanApi(fanled.color) }}
                                    >{fanled.name}</button>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='admin-mode'>
                    <h3>Admin-Mode</h3>
                    <div className={`power ${getStatus ? 'disabled' : ''}`}>
                        <div
                            className={`button ${isOn ? 'on disabled' : ''}`}
                            onClick={() => {
                                if (!isOn) {
                                    setOn(true)
                                    setGetStatus(true)
                                    powerApi('on')
                                }
                            }}
                        >ON</div>
                        <div
                            className={`button ${!isOn ? 'off disabled' : ''}`}
                            onClick={() => {
                                if (isOn) {
                                    setOn(false)
                                    setGetStatus(true)
                                    powerApi('off')
                                }
                            }}
                        >OFF</div>
                    </div>
                </div>
            </div>

            <Canvas
                type={'debug'}
            // mouse={MousePosition}
            // ws={ws}
            />
        </div>
    );
}

export default Debug