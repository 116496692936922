
/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    
    function getMCSymbolPrototype ....
    */



    (lib.sliderInhalt_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#DD0061").s().p("AgnQrMAAAghVIBPAAMAAAAhVg");
            this.shape.setTransform(4,106.725);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.sliderInhalt_mc, new cjs.Rectangle(0,0,8,213.5), null);
        
        
        (lib.sliderBalcken = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AiLAjIAAhFIEXAAIAABFg");
            this.shape.setTransform(14,3.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.sliderBalcken, new cjs.Rectangle(0,0,28,7), null);
        
        
        (lib.hand_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgZA3IgKgKIBcAAQAAgEgDgDQgDgCgEAAIgWAAQgFgBgDgDQgEgDAAgFQAAgGAEgDIA0gzIABgDIgBgEQgEgDgDADIg0AzIgHgHIARgQQAEgDgEgEQgDgDgEADIgFAFIgBACIgKAKIgHgGIALgNQAEgDgEgDQgCgEgEAEIgFAEIgBACIgGAGIgHgIIAGgEIABgDQABAAAAAAQAAAAAAgBQABAAAAgBQAAAAAAgBQAAAAAAgBQAAAAgBgBQAAAAAAAAQAAgBgBAAQgDgDgEADIgwAvIgHgHIAxgvQAEgFAGAAQAGAAAEAFIAEAFQAHgEAHAIIAEAFQAIgDAHAGIADAEIAZgZQAEgEAGAAQAGAAAEAEQAFAFAAAGQAAAGgFAEIgzAzQgBAAAAABQAAAAAAABQAAAAAAABQAAAAABABIABAAIAWAAQAJgBAFAHQAGAFAAAIIAAAKg");
            this.shape.setTransform(18.9007,13.0242,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.hand_mc, new cjs.Rectangle(0,0,37.8,26.1), null);
        
        
        (lib.gewicht_03_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AiRAHIAAgNIEjAAIAAANg");
            this.shape.setTransform(38.3972,65.5981,2.37,2.37);
        
            this.txt_01 = new cjs.Text("0kg", "bold 14px 'Brix Sans Black'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.textAlign = "center";
            this.txt_01.lineHeight = 18;
            this.txt_01.lineWidth = 61;
            this.txt_01.parent = this;
            this.txt_01.setTransform(38.95,38.45);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AigCBIAAhSIA3iXQAEgLAJgHQAKgGALAAICOAAQAMAAAKAGQAJAHAEALIA3CXIAABSgAhNheIg1CSIAAAwIEGAAIAAgwIg2iSQgCgFgFAAIiOAAQgFAAgBAFg");
            this.shape_1.setTransform(38.1565,50.4654,2.37,2.37);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgiAjQgPgPAAgUQAAgUAPgOQAPgPATAAQAVAAAOAPQAPAOAAAUQAAAUgPAPQgOAPgVAAQgTAAgPgPgAgNgNQgGAFAAAIQAAAIAGAGQAGAGAHAAQAJAAAFgGQAGgGAAgIQAAgHgGgGQgFgGgJAAQgHAAgGAGg");
            this.shape_2.setTransform(38.0972,11.7752,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.txt_01},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.gewicht_03_mc, new cjs.Rectangle(0,0,76.3,81.2), null);
        
        
        (lib.gewicht_02_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AiRAHIAAgNIEjAAIAAANg");
            this.shape.setTransform(38.3972,65.5981,2.37,2.37);
        
            this.txt_01 = new cjs.Text("0kg", "bold 14px 'Brix Sans Black'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.textAlign = "center";
            this.txt_01.lineHeight = 18;
            this.txt_01.lineWidth = 61;
            this.txt_01.parent = this;
            this.txt_01.setTransform(38.95,39.55);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AigCBIAAhSIA3iXQAEgLAJgHQAKgHALABICOAAQAMgBAKAHQAJAHAEALIA3CXIAABSgAhNheIg1CSIAAAwIEGAAIAAgwIg2iSQgCgFgFAAIiOAAQgFAAgBAFg");
            this.shape_1.setTransform(38.1565,50.6231,2.37,2.37);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgiAjQgPgOAAgVQAAgTAPgPQAPgPATAAQAVAAAOAPQAPAPAAATQAAAVgPAOQgOAPgVAAQgTAAgPgPgAgNgNQgGAGAAAHQAAAJAGAFQAGAGAHAAQAJAAAFgGQAGgFAAgJQAAgHgGgGQgFgGgJAAQgHAAgGAGg");
            this.shape_2.setTransform(38.0972,11.8145,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.txt_01},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.gewicht_02_mc, new cjs.Rectangle(0,0,76.3,81.3), null);
        
        
        (lib.gewicht_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AiRAHIAAgNIEjAAIAAANg");
            this.shape.setTransform(38.3972,65.5981,2.37,2.37);
        
            this.txt_01 = new cjs.Text("0kg", "bold 14px 'Brix Sans Black'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.textAlign = "center";
            this.txt_01.lineHeight = 18;
            this.txt_01.lineWidth = 61;
            this.txt_01.parent = this;
            this.txt_01.setTransform(38.95,38.35);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AigCBIAAhSIA3iXQAEgLAJgHQAKgGALAAICOAAQAMAAAKAGQAJAHAEALIA3CXIAABSgAhNheIg1CSIAAAwIEGAAIAAgwIg2iSQgCgFgFAAIiOAAQgFAAgBAFg");
            this.shape_1.setTransform(38.1565,50.5401,2.37,2.37);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgiAjQgPgPAAgUQAAgUAPgOQAPgPATAAQAVAAAOAPQAPAOAAAUQAAAUgPAPQgOAPgVAAQgTAAgPgPgAgNgNQgGAFAAAIQAAAIAGAGQAGAGAHAAQAJAAAFgGQAGgGAAgIQAAgHgGgGQgFgGgJAAQgHAAgGAGg");
            this.shape_2.setTransform(38.0972,11.7907,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.txt_01},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.gewicht_01, new cjs.Rectangle(0,0,76.3,81.2), null);
        
        
        (lib.hand_2mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.hand_mc = new lib.hand_mc();
            this.hand_mc.name = "hand_mc";
            this.hand_mc.setTransform(18.9,21.4,1.2,1.2,0,0,0,18.9,21.4);
        
            this.timeline.addTween(cjs.Tween.get(this.hand_mc).wait(79).to({x:19.15,y:11.4},20,cjs.Ease.quadInOut).to({x:18.9,y:21.4},20,cjs.Ease.quadInOut).to({x:19.15,y:11.4},20,cjs.Ease.quadInOut).to({x:18.9,y:21.4},21,cjs.Ease.quadInOut).wait(118));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-3.8,-14.3,45.699999999999996,41.400000000000006);
        
        
        (lib.hand_ani_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_19 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(19).call(this.frame_19).wait(1));
        
            // Ebene_1
            this.instance = new lib.hand_2mc();
            this.instance.setTransform(18.9,13,1,1,0,0,0,18.9,13);
        
            this.hand_mc = new lib.hand_mc();
            this.hand_mc.name = "hand_mc";
            this.hand_mc.setTransform(18.9,21.4,1,1,0,0,0,18.9,21.4);
            this.hand_mc.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance}]}).to({state:[{t:this.hand_mc}]},19).wait(1));
            this.timeline.addTween(cjs.Tween.get(this.instance).to({_off:true,regY:21.4,y:21.4,alpha:0},19).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-3.8,-4.3,45.4,39.3);
        
        
        (lib.sub_animatin_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_1 = function() {
                if (this.parent.firstTouch == true) {
                    this.hand_ani_mc.play();
                    this.parent.firstTouch = false;
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1).call(this.frame_1).wait(59));
        
            // texte
            this.txt_slider = new cjs.Text("txt_slider", "15px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_slider.name = "txt_slider";
            this.txt_slider.textAlign = "center";
            this.txt_slider.lineHeight = 19;
            this.txt_slider.lineWidth = 196;
            this.txt_slider.parent = this;
            this.txt_slider.setTransform(20.2538,266.75,1,1,-89.9948);
        
            this.txt_03_02 = new cjs.Text("txt_03_02", "11px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_03_02.name = "txt_03_02";
            this.txt_03_02.lineHeight = 13;
            this.txt_03_02.lineWidth = 97;
            this.txt_03_02.parent = this;
            this.txt_03_02.setTransform(107.15,408.25);
        
            this.txt_02_02 = new cjs.Text("txt_02_02", "11px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_02_02.name = "txt_02_02";
            this.txt_02_02.lineHeight = 13;
            this.txt_02_02.lineWidth = 97;
            this.txt_02_02.parent = this;
            this.txt_02_02.setTransform(107.15,275.15);
        
            this.txt_01_02 = new cjs.Text("txt_01_02", "11px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01_02.name = "txt_01_02";
            this.txt_01_02.lineHeight = 13;
            this.txt_01_02.lineWidth = 97;
            this.txt_01_02.parent = this;
            this.txt_01_02.setTransform(107.15,145.15);
        
            this.txt_02_01 = new cjs.Text("txt_02_01", "bold 14px 'Brix Sans Black'", "#FFFFFF");
            this.txt_02_01.name = "txt_02_01";
            this.txt_02_01.lineHeight = 17;
            this.txt_02_01.lineWidth = 134;
            this.txt_02_01.parent = this;
            this.txt_02_01.setTransform(107.15,259.95);
        
            this.txt_03_01 = new cjs.Text("txt_03_01", "bold 14px 'Brix Sans Black'", "#FFFFFF");
            this.txt_03_01.name = "txt_03_01";
            this.txt_03_01.lineHeight = 17;
            this.txt_03_01.lineWidth = 134;
            this.txt_03_01.parent = this;
            this.txt_03_01.setTransform(107.15,394.15);
        
            this.txt_01_01 = new cjs.Text("txt_01_01", "bold 14px 'Brix Sans Black'", "#FFFFFF");
            this.txt_01_01.name = "txt_01_01";
            this.txt_01_01.lineHeight = 17;
            this.txt_01_01.lineWidth = 134;
            this.txt_01_01.parent = this;
            this.txt_01_01.setTransform(107.15,129.4);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.txt_01_01},{t:this.txt_03_01},{t:this.txt_02_01},{t:this.txt_01_02},{t:this.txt_02_02},{t:this.txt_03_02},{t:this.txt_slider}]}).wait(60));
        
            // gewicht_1
            this.gewicht_01_mc = new lib.gewicht_01();
            this.gewicht_01_mc.name = "gewicht_01_mc";
            this.gewicht_01_mc.setTransform(251.4,40.6,1,1,0,0,0,38.1,40.6);
        
            this.timeline.addTween(cjs.Tween.get(this.gewicht_01_mc).to({y:50.35},30).wait(30));
        
            // gewicht_2
            this.gewicht_02_mc = new lib.gewicht_02_mc();
            this.gewicht_02_mc.name = "gewicht_02_mc";
            this.gewicht_02_mc.setTransform(251.4,171.4,1,1,0,0,0,38.1,40.6);
        
            this.timeline.addTween(cjs.Tween.get(this.gewicht_02_mc).to({y:181.6},31).to({y:186.55},28).wait(1));
        
            // gewicht_3
            this.gewicht_03_mc = new lib.gewicht_03_mc();
            this.gewicht_03_mc.name = "gewicht_03_mc";
            this.gewicht_03_mc.setTransform(251.4,302.5,1,1,0,0,0,38.1,40.6);
        
            this.timeline.addTween(cjs.Tween.get(this.gewicht_03_mc).to({y:312.5},31).to({y:322.55},28).wait(1));
        
            // fipro_black
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#000000").ss(14,2,0,3).p("AyeAAMAk9AAA");
            this.shape.setTransform(252.25,230.55);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f().s("#000000").ss(14,2,0,3).p("AyeAAQSeAESegE");
            this.shape_1.setTransform(252.25,230.6602);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f().s("#000000").ss(14,2,0,3).p("AyegBQSeAJSegJ");
            this.shape_2.setTransform(252.25,230.7705);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f().s("#000000").ss(14,2,0,3).p("AyegCQSfAOSdgO");
            this.shape_3.setTransform(252.2501,230.8809);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f().s("#000000").ss(14,2,0,3).p("AyegDQSfASSdgS");
            this.shape_4.setTransform(252.2501,230.9802);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f().s("#000000").ss(14,2,0,3).p("AyegEQSfAXSdgX");
            this.shape_5.setTransform(252.2502,231.0907);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f().s("#000000").ss(14,2,0,3).p("AyegGQSgAdScgd");
            this.shape_6.setTransform(252.2503,231.2012);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f().s("#000000").ss(14,2,0,3).p("AyegHQSgAiScgi");
            this.shape_7.setTransform(252.2504,231.3117);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f().s("#000000").ss(14,2,0,3).p("AyegIQSgAnScgn");
            this.shape_8.setTransform(252.2505,231.4224);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f().s("#000000").ss(14,2,0,3).p("AyegJQShAsSbgs");
            this.shape_9.setTransform(252.2506,231.533);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f().s("#000000").ss(14,2,0,3).p("AyegKQShAxSbgx");
            this.shape_10.setTransform(252.2508,231.6438);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f().s("#000000").ss(14,2,0,3).p("AyegLQShA1Sbg1");
            this.shape_11.setTransform(252.2509,231.7435);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f().s("#000000").ss(14,2,0,3).p("AyegMQSiA6Sag6");
            this.shape_12.setTransform(252.2511,231.8544);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f().s("#000000").ss(14,2,0,3).p("AyegNQSiA/Sag/");
            this.shape_13.setTransform(252.2514,231.9653);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f().s("#000000").ss(14,2,0,3).p("AyegOQSjBESZhE");
            this.shape_14.setTransform(252.2516,232.0762);
        
            this.shape_15 = new cjs.Shape();
            this.shape_15.graphics.f().s("#000000").ss(14,2,0,3).p("AyegPQSjBJSZhJ");
            this.shape_15.setTransform(252.2517,232.1874);
        
            this.shape_16 = new cjs.Shape();
            this.shape_16.graphics.f().s("#000000").ss(14,2,0,3).p("AyegQQSjBOSZhO");
            this.shape_16.setTransform(252.252,232.2985);
        
            this.shape_17 = new cjs.Shape();
            this.shape_17.graphics.f().s("#000000").ss(14,2,0,3).p("AyegSQSkBTSYhT");
            this.shape_17.setTransform(252.2523,232.4096);
        
            this.shape_18 = new cjs.Shape();
            this.shape_18.graphics.f().s("#000000").ss(14,2,0,3).p("AyegTQSkBYSYhY");
            this.shape_18.setTransform(252.2524,232.5098);
        
            this.shape_19 = new cjs.Shape();
            this.shape_19.graphics.f().s("#000000").ss(14,2,0,3).p("AyegUQSkBdSYhd");
            this.shape_19.setTransform(252.2528,232.6211);
        
            this.shape_20 = new cjs.Shape();
            this.shape_20.graphics.f().s("#000000").ss(14,2,0,3).p("AyegVQSlBiSXhi");
            this.shape_20.setTransform(252.2531,232.7325);
        
            this.shape_21 = new cjs.Shape();
            this.shape_21.graphics.f().s("#000000").ss(14,2,0,3).p("AyegWQSlBnSXhn");
            this.shape_21.setTransform(252.2533,232.844);
        
            this.shape_22 = new cjs.Shape();
            this.shape_22.graphics.f().s("#000000").ss(14,2,0,3).p("AyegXQSlBrSXhr");
            this.shape_22.setTransform(252.2537,232.9555);
        
            this.shape_23 = new cjs.Shape();
            this.shape_23.graphics.f().s("#000000").ss(14,2,0,3).p("AyegYQSmBwSWhw");
            this.shape_23.setTransform(252.2541,233.0671);
        
            this.shape_24 = new cjs.Shape();
            this.shape_24.graphics.f().s("#000000").ss(14,2,0,3).p("AyegZQSmB1SWh1");
            this.shape_24.setTransform(252.2542,233.1788);
        
            this.shape_25 = new cjs.Shape();
            this.shape_25.graphics.f().s("#000000").ss(14,2,0,3).p("AyegaQSmB6SWh6");
            this.shape_25.setTransform(252.2546,233.2794);
        
            this.shape_26 = new cjs.Shape();
            this.shape_26.graphics.f().s("#000000").ss(14,2,0,3).p("AyegbQSnB/SVh/");
            this.shape_26.setTransform(252.2551,233.3911);
        
            this.shape_27 = new cjs.Shape();
            this.shape_27.graphics.f().s("#000000").ss(14,2,0,3).p("AyegdQSnCESViE");
            this.shape_27.setTransform(252.2552,233.5031);
        
            this.shape_28 = new cjs.Shape();
            this.shape_28.graphics.f().s("#000000").ss(14,2,0,3).p("AyegeQSoCJSUiJ");
            this.shape_28.setTransform(252.2558,233.6154);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape}]},31).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_8}]},1).to({state:[{t:this.shape_9}]},1).to({state:[{t:this.shape_10}]},1).to({state:[{t:this.shape_11}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_13}]},1).to({state:[{t:this.shape_14}]},1).to({state:[{t:this.shape_15}]},1).to({state:[{t:this.shape_16}]},1).to({state:[{t:this.shape_17}]},1).to({state:[{t:this.shape_18}]},1).to({state:[{t:this.shape_19}]},1).to({state:[{t:this.shape_20}]},1).to({state:[{t:this.shape_21}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_23}]},1).to({state:[{t:this.shape_24}]},1).to({state:[{t:this.shape_25}]},1).to({state:[{t:this.shape_26}]},1).to({state:[{t:this.shape_27}]},1).to({state:[{t:this.shape_28}]},1).wait(1));
        
            // fipro_weiss
            this.shape_29 = new cjs.Shape();
            this.shape_29.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyeAAMAk9AAA");
            this.shape_29.setTransform(252.525,230.55);
        
            this.shape_30 = new cjs.Shape();
            this.shape_30.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyeAAQShAEScgE");
            this.shape_30.setTransform(252.5251,230.6528);
        
            this.shape_31 = new cjs.Shape();
            this.shape_31.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegBQSkAJSZgJ");
            this.shape_31.setTransform(252.5254,230.7453);
        
            this.shape_32 = new cjs.Shape();
            this.shape_32.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegCQSnAOSWgO");
            this.shape_32.setTransform(252.5259,230.8481);
        
            this.shape_33 = new cjs.Shape();
            this.shape_33.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegDQSpASSUgS");
            this.shape_33.setTransform(252.5266,230.9509);
        
            this.shape_34 = new cjs.Shape();
            this.shape_34.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegEQSsAXSRgX");
            this.shape_34.setTransform(252.5276,231.0536);
        
            this.shape_35 = new cjs.Shape();
            this.shape_35.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegFQSvAcSOgc");
            this.shape_35.setTransform(252.5286,231.1461);
        
            this.shape_36 = new cjs.Shape();
            this.shape_36.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegGQSyAhSLgh");
            this.shape_36.setTransform(252.5299,231.2488);
        
            this.shape_37 = new cjs.Shape();
            this.shape_37.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegHQS0AmSJgm");
            this.shape_37.setTransform(252.5313,231.3516);
        
            this.shape_38 = new cjs.Shape();
            this.shape_38.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegIQS3ArSGgr");
            this.shape_38.setTransform(252.533,231.4544);
        
            this.shape_39 = new cjs.Shape();
            this.shape_39.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegJQS6AwSDgw");
            this.shape_39.setTransform(252.5348,231.5572);
        
            this.shape_40 = new cjs.Shape();
            this.shape_40.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegKQS9A1SAg1");
            this.shape_40.setTransform(252.5368,231.6496);
        
            this.shape_41 = new cjs.Shape();
            this.shape_41.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegLQS/A5R+g5");
            this.shape_41.setTransform(252.5388,231.7524);
        
            this.shape_42 = new cjs.Shape();
            this.shape_42.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegMQTCA+R7g+");
            this.shape_42.setTransform(252.5413,231.8552);
        
            this.shape_43 = new cjs.Shape();
            this.shape_43.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegNQTFBDR4hD");
            this.shape_43.setTransform(252.5439,231.958);
        
            this.shape_44 = new cjs.Shape();
            this.shape_44.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegOQTIBIR1hI");
            this.shape_44.setTransform(252.5464,232.0505);
        
            this.shape_45 = new cjs.Shape();
            this.shape_45.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegPQTLBNRyhN");
            this.shape_45.setTransform(252.5493,232.1533);
        
            this.shape_46 = new cjs.Shape();
            this.shape_46.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegQQTNBSRvhS");
            this.shape_46.setTransform(252.5522,232.2562);
        
            this.shape_47 = new cjs.Shape();
            this.shape_47.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegRQTQBXRshX");
            this.shape_47.setTransform(252.5554,232.3487);
        
            this.shape_48 = new cjs.Shape();
            this.shape_48.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegSQTSBbRqhb");
            this.shape_48.setTransform(252.5585,232.4517);
        
            this.shape_49 = new cjs.Shape();
            this.shape_49.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegTQTVBgRnhg");
            this.shape_49.setTransform(252.5621,232.5545);
        
            this.shape_50 = new cjs.Shape();
            this.shape_50.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegUQTYBlRkhl");
            this.shape_50.setTransform(252.5656,232.6576);
        
            this.shape_51 = new cjs.Shape();
            this.shape_51.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegVQTbBqRhhq");
            this.shape_51.setTransform(252.5695,232.7605);
        
            this.shape_52 = new cjs.Shape();
            this.shape_52.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegWQTdBvRfhv");
            this.shape_52.setTransform(252.573,232.8532);
        
            this.shape_53 = new cjs.Shape();
            this.shape_53.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegXQTgB0Rch0");
            this.shape_53.setTransform(252.5772,232.9562);
        
            this.shape_54 = new cjs.Shape();
            this.shape_54.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegYQTjB5RZh5");
            this.shape_54.setTransform(252.5811,233.0594);
        
            this.shape_55 = new cjs.Shape();
            this.shape_55.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegZQTmB+RWh+");
            this.shape_55.setTransform(252.5856,233.1625);
        
            this.shape_56 = new cjs.Shape();
            this.shape_56.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegaQToCCRUiC");
            this.shape_56.setTransform(252.5896,233.2553);
        
            this.shape_57 = new cjs.Shape();
            this.shape_57.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegbQTrCHRRiH");
            this.shape_57.setTransform(252.5943,233.3549);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_29}]}).to({state:[{t:this.shape_29}]},31).to({state:[{t:this.shape_30}]},1).to({state:[{t:this.shape_31}]},1).to({state:[{t:this.shape_32}]},1).to({state:[{t:this.shape_33}]},1).to({state:[{t:this.shape_34}]},1).to({state:[{t:this.shape_35}]},1).to({state:[{t:this.shape_36}]},1).to({state:[{t:this.shape_37}]},1).to({state:[{t:this.shape_38}]},1).to({state:[{t:this.shape_39}]},1).to({state:[{t:this.shape_40}]},1).to({state:[{t:this.shape_41}]},1).to({state:[{t:this.shape_42}]},1).to({state:[{t:this.shape_43}]},1).to({state:[{t:this.shape_44}]},1).to({state:[{t:this.shape_45}]},1).to({state:[{t:this.shape_46}]},1).to({state:[{t:this.shape_47}]},1).to({state:[{t:this.shape_48}]},1).to({state:[{t:this.shape_49}]},1).to({state:[{t:this.shape_50}]},1).to({state:[{t:this.shape_51}]},1).to({state:[{t:this.shape_52}]},1).to({state:[{t:this.shape_53}]},1).to({state:[{t:this.shape_54}]},1).to({state:[{t:this.shape_55}]},1).to({state:[{t:this.shape_56}]},1).to({state:[{t:this.shape_57}]},1).wait(1));
        
            // pvc_magenta2
            this.shape_58 = new cjs.Shape();
            this.shape_58.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMAAMAmZAAA");
            this.shape_58.setTransform(252.775,358);
        
            this.shape_59 = new cjs.Shape();
            this.shape_59.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgBQTNAHTMgH");
            this.shape_59.setTransform(252.775,358.1919);
        
            this.shape_60 = new cjs.Shape();
            this.shape_60.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgDQTNAQTMgQ");
            this.shape_60.setTransform(252.775,358.3958);
        
            this.shape_61 = new cjs.Shape();
            this.shape_61.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgFQTNAYTMgY");
            this.shape_61.setTransform(252.775,358.5878);
        
            this.shape_62 = new cjs.Shape();
            this.shape_62.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgHQTOAgTLgg");
            this.shape_62.setTransform(252.7751,358.7919);
        
            this.shape_63 = new cjs.Shape();
            this.shape_63.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgJQTOAoTLgo");
            this.shape_63.setTransform(252.7751,358.984);
        
            this.shape_64 = new cjs.Shape();
            this.shape_64.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgLQTOAxTLgx");
            this.shape_64.setTransform(252.7752,359.1881);
        
            this.shape_65 = new cjs.Shape();
            this.shape_65.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgNQTPA5TKg5");
            this.shape_65.setTransform(252.7752,359.3803);
        
            this.shape_66 = new cjs.Shape();
            this.shape_66.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgPQTPBBTKhB");
            this.shape_66.setTransform(252.7753,359.5726);
        
            this.shape_67 = new cjs.Shape();
            this.shape_67.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgRQTPBJTKhJ");
            this.shape_67.setTransform(252.7754,359.777);
        
            this.shape_68 = new cjs.Shape();
            this.shape_68.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgTQTQBRTJhR");
            this.shape_68.setTransform(252.7755,359.9693);
        
            this.shape_69 = new cjs.Shape();
            this.shape_69.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgVQTQBaTJha");
            this.shape_69.setTransform(252.7755,360.1738);
        
            this.shape_70 = new cjs.Shape();
            this.shape_70.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgXQTQBiTJhi");
            this.shape_70.setTransform(252.7756,360.3663);
        
            this.shape_71 = new cjs.Shape();
            this.shape_71.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgZQTRBqTIhq");
            this.shape_71.setTransform(252.7758,360.5709);
        
            this.shape_72 = new cjs.Shape();
            this.shape_72.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgbQTRByTIhy");
            this.shape_72.setTransform(252.7759,360.7636);
        
            this.shape_73 = new cjs.Shape();
            this.shape_73.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgdQTRB6TIh6");
            this.shape_73.setTransform(252.7759,360.9563);
        
            this.shape_74 = new cjs.Shape();
            this.shape_74.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgfQTSCDTHiD");
            this.shape_74.setTransform(252.7761,361.1611);
        
            this.shape_75 = new cjs.Shape();
            this.shape_75.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMghQTSCLTHiL");
            this.shape_75.setTransform(252.7763,361.3539);
        
            this.shape_76 = new cjs.Shape();
            this.shape_76.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgjQTSCTTHiT");
            this.shape_76.setTransform(252.7763,361.5589);
        
            this.shape_77 = new cjs.Shape();
            this.shape_77.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMglQTTCbTGib");
            this.shape_77.setTransform(252.7765,361.7518);
        
            this.shape_78 = new cjs.Shape();
            this.shape_78.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgnQTTCkTGik");
            this.shape_78.setTransform(252.7767,361.9569);
        
            this.shape_79 = new cjs.Shape();
            this.shape_79.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgpQTTCsTGis");
            this.shape_79.setTransform(252.7767,362.1501);
        
            this.shape_80 = new cjs.Shape();
            this.shape_80.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgqQTUCzTFiz");
            this.shape_80.setTransform(252.7769,362.3432);
        
            this.shape_81 = new cjs.Shape();
            this.shape_81.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgsQTUC8TFi8");
            this.shape_81.setTransform(252.7771,362.5485);
        
            this.shape_82 = new cjs.Shape();
            this.shape_82.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMguQTUDETFjE");
            this.shape_82.setTransform(252.7772,362.7419);
        
            this.shape_83 = new cjs.Shape();
            this.shape_83.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgwQTVDMTEjM");
            this.shape_83.setTransform(252.7774,362.9473);
        
            this.shape_84 = new cjs.Shape();
            this.shape_84.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgyQTVDUTEjU");
            this.shape_84.setTransform(252.7776,363.1407);
        
            this.shape_85 = new cjs.Shape();
            this.shape_85.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMg0QTVDdTEjd");
            this.shape_85.setTransform(252.7777,363.3464);
        
            this.shape_86 = new cjs.Shape();
            this.shape_86.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMg2QTWDlTDjl");
            this.shape_86.setTransform(252.7779,363.5459);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_58}]}).to({state:[{t:this.shape_58}]},31).to({state:[{t:this.shape_59}]},1).to({state:[{t:this.shape_60}]},1).to({state:[{t:this.shape_61}]},1).to({state:[{t:this.shape_62}]},1).to({state:[{t:this.shape_63}]},1).to({state:[{t:this.shape_64}]},1).to({state:[{t:this.shape_65}]},1).to({state:[{t:this.shape_66}]},1).to({state:[{t:this.shape_67}]},1).to({state:[{t:this.shape_68}]},1).to({state:[{t:this.shape_69}]},1).to({state:[{t:this.shape_70}]},1).to({state:[{t:this.shape_71}]},1).to({state:[{t:this.shape_72}]},1).to({state:[{t:this.shape_73}]},1).to({state:[{t:this.shape_74}]},1).to({state:[{t:this.shape_75}]},1).to({state:[{t:this.shape_76}]},1).to({state:[{t:this.shape_77}]},1).to({state:[{t:this.shape_78}]},1).to({state:[{t:this.shape_79}]},1).to({state:[{t:this.shape_80}]},1).to({state:[{t:this.shape_81}]},1).to({state:[{t:this.shape_82}]},1).to({state:[{t:this.shape_83}]},1).to({state:[{t:this.shape_84}]},1).to({state:[{t:this.shape_85}]},1).to({state:[{t:this.shape_86}]},1).wait(1));
        
            // pvc_magenta1
            this.shape_87 = new cjs.Shape();
            this.shape_87.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMAAMAmZAAA");
            this.shape_87.setTransform(252.775,365.25);
        
            this.shape_88 = new cjs.Shape();
            this.shape_88.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgBQTMAHTNgH");
            this.shape_88.setTransform(252.775,365.4539);
        
            this.shape_89 = new cjs.Shape();
            this.shape_89.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgDQTMAQTNgQ");
            this.shape_89.setTransform(252.775,365.6578);
        
            this.shape_90 = new cjs.Shape();
            this.shape_90.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgFQTMAYTNgY");
            this.shape_90.setTransform(252.775,365.8618);
        
            this.shape_91 = new cjs.Shape();
            this.shape_91.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgHQTMAhTNgh");
            this.shape_91.setTransform(252.775,366.0659);
        
            this.shape_92 = new cjs.Shape();
            this.shape_92.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgJQTMAqTNgq");
            this.shape_92.setTransform(252.7749,366.282);
        
            this.shape_93 = new cjs.Shape();
            this.shape_93.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgLQTMAyTNgy");
            this.shape_93.setTransform(252.7749,366.4862);
        
            this.shape_94 = new cjs.Shape();
            this.shape_94.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgNQTMA7TNg7");
            this.shape_94.setTransform(252.7749,366.6905);
        
            this.shape_95 = new cjs.Shape();
            this.shape_95.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgPQTLBDTOhD");
            this.shape_95.setTransform(252.7748,366.8948);
        
            this.shape_96 = new cjs.Shape();
            this.shape_96.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgRQTLBMTOhM");
            this.shape_96.setTransform(252.7748,367.0992);
        
            this.shape_97 = new cjs.Shape();
            this.shape_97.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgUQTLBVTOhV");
            this.shape_97.setTransform(252.7747,367.3036);
        
            this.shape_98 = new cjs.Shape();
            this.shape_98.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgWQTLBdTOhd");
            this.shape_98.setTransform(252.7747,367.5082);
        
            this.shape_99 = new cjs.Shape();
            this.shape_99.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgYQTKBmTPhm");
            this.shape_99.setTransform(252.7746,367.7127);
        
            this.shape_100 = new cjs.Shape();
            this.shape_100.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgaQTKBuTPhu");
            this.shape_100.setTransform(252.7745,367.9174);
        
            this.shape_101 = new cjs.Shape();
            this.shape_101.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgcQTKB3TPh3");
            this.shape_101.setTransform(252.7744,368.1342);
        
            this.shape_102 = new cjs.Shape();
            this.shape_102.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgeQTJCATQiA");
            this.shape_102.setTransform(252.7743,368.339);
        
            this.shape_103 = new cjs.Shape();
            this.shape_103.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMggQTJCITQiI");
            this.shape_103.setTransform(252.7743,368.5439);
        
            this.shape_104 = new cjs.Shape();
            this.shape_104.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgiQTJCRTQiR");
            this.shape_104.setTransform(252.7741,368.7488);
        
            this.shape_105 = new cjs.Shape();
            this.shape_105.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgkQTJCZTQiZ");
            this.shape_105.setTransform(252.7741,368.9538);
        
            this.shape_106 = new cjs.Shape();
            this.shape_106.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgmQTIChTRih");
            this.shape_106.setTransform(252.7739,369.1589);
        
            this.shape_107 = new cjs.Shape();
            this.shape_107.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgoQTICqTRiq");
            this.shape_107.setTransform(252.7739,369.3641);
        
            this.shape_108 = new cjs.Shape();
            this.shape_108.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgqQTICyTRiy");
            this.shape_108.setTransform(252.7738,369.5694);
        
            this.shape_109 = new cjs.Shape();
            this.shape_109.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgsQTIC7TRi7");
            this.shape_109.setTransform(252.7737,369.7747);
        
            this.shape_110 = new cjs.Shape();
            this.shape_110.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMguQTIDDTRjD");
            this.shape_110.setTransform(252.7736,369.9802);
        
            this.shape_111 = new cjs.Shape();
            this.shape_111.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgwQTHDMTSjM");
            this.shape_111.setTransform(252.7734,370.1977);
        
            this.shape_112 = new cjs.Shape();
            this.shape_112.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMgzQTHDVTSjV");
            this.shape_112.setTransform(252.7734,370.4033);
        
            this.shape_113 = new cjs.Shape();
            this.shape_113.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMg1QTHDeTSje");
            this.shape_113.setTransform(252.7732,370.6089);
        
            this.shape_114 = new cjs.Shape();
            this.shape_114.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMg3QTHDmTSjm");
            this.shape_114.setTransform(252.7732,370.8147);
        
            this.shape_115 = new cjs.Shape();
            this.shape_115.graphics.f().s("#DD0061").ss(5,2,0,3).p("AzMg5QTGDvTTjv");
            this.shape_115.setTransform(252.7729,371.0251);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_87}]}).to({state:[{t:this.shape_87}]},31).to({state:[{t:this.shape_88}]},1).to({state:[{t:this.shape_89}]},1).to({state:[{t:this.shape_90}]},1).to({state:[{t:this.shape_91}]},1).to({state:[{t:this.shape_92}]},1).to({state:[{t:this.shape_93}]},1).to({state:[{t:this.shape_94}]},1).to({state:[{t:this.shape_95}]},1).to({state:[{t:this.shape_96}]},1).to({state:[{t:this.shape_97}]},1).to({state:[{t:this.shape_98}]},1).to({state:[{t:this.shape_99}]},1).to({state:[{t:this.shape_100}]},1).to({state:[{t:this.shape_101}]},1).to({state:[{t:this.shape_102}]},1).to({state:[{t:this.shape_103}]},1).to({state:[{t:this.shape_104}]},1).to({state:[{t:this.shape_105}]},1).to({state:[{t:this.shape_106}]},1).to({state:[{t:this.shape_107}]},1).to({state:[{t:this.shape_108}]},1).to({state:[{t:this.shape_109}]},1).to({state:[{t:this.shape_110}]},1).to({state:[{t:this.shape_111}]},1).to({state:[{t:this.shape_112}]},1).to({state:[{t:this.shape_113}]},1).to({state:[{t:this.shape_114}]},1).to({state:[{t:this.shape_115}]},1).wait(1));
        
            // pvc_weiss
            this.shape_116 = new cjs.Shape();
            this.shape_116.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyeAAMAk9AAA");
            this.shape_116.setTransform(252.525,361.75);
        
            this.shape_117 = new cjs.Shape();
            this.shape_117.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegBQSeAHSfgH");
            this.shape_117.setTransform(252.525,361.9146);
        
            this.shape_118 = new cjs.Shape();
            this.shape_118.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegCQSdAPSggP");
            this.shape_118.setTransform(252.5248,362.0794);
        
            this.shape_119 = new cjs.Shape();
            this.shape_119.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegEQScAXShgX");
            this.shape_119.setTransform(252.5245,362.2443);
        
            this.shape_120 = new cjs.Shape();
            this.shape_120.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegFQSbAeSige");
            this.shape_120.setTransform(252.5241,362.3992);
        
            this.shape_121 = new cjs.Shape();
            this.shape_121.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegHQSaAmSjgm");
            this.shape_121.setTransform(252.5236,362.5645);
        
            this.shape_122 = new cjs.Shape();
            this.shape_122.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegJQSZAvSkgv");
            this.shape_122.setTransform(252.523,362.7301);
        
            this.shape_123 = new cjs.Shape();
            this.shape_123.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegKQSZA2Skg2");
            this.shape_123.setTransform(252.5225,362.8959);
        
            this.shape_124 = new cjs.Shape();
            this.shape_124.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegMQSYA+Slg+");
            this.shape_124.setTransform(252.5217,363.062);
        
            this.shape_125 = new cjs.Shape();
            this.shape_125.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegOQSXBHSmhH");
            this.shape_125.setTransform(252.5209,363.2282);
        
            this.shape_126 = new cjs.Shape();
            this.shape_126.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegPQSWBOSnhO");
            this.shape_126.setTransform(252.5199,363.3947);
        
            this.shape_127 = new cjs.Shape();
            this.shape_127.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegRQSVBWSohW");
            this.shape_127.setTransform(252.5188,363.5509);
        
            this.shape_128 = new cjs.Shape();
            this.shape_128.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegTQSUBeSphe");
            this.shape_128.setTransform(252.5177,363.7178);
        
            this.shape_129 = new cjs.Shape();
            this.shape_129.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegUQSTBmSqhm");
            this.shape_129.setTransform(252.5165,363.885);
        
            this.shape_130 = new cjs.Shape();
            this.shape_130.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegWQSSBuSrhu");
            this.shape_130.setTransform(252.5151,364.0524);
        
            this.shape_131 = new cjs.Shape();
            this.shape_131.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegYQSRB2Ssh2");
            this.shape_131.setTransform(252.5137,364.2201);
        
            this.shape_132 = new cjs.Shape();
            this.shape_132.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegZQSQB+Sth+");
            this.shape_132.setTransform(252.5122,364.388);
        
            this.shape_133 = new cjs.Shape();
            this.shape_133.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegbQSPCGSuiG");
            this.shape_133.setTransform(252.5107,364.5563);
        
            this.shape_134 = new cjs.Shape();
            this.shape_134.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegdQSOCOSviO");
            this.shape_134.setTransform(252.5091,364.7142);
        
            this.shape_135 = new cjs.Shape();
            this.shape_135.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegeQSNCVSwiV");
            this.shape_135.setTransform(252.5074,364.8829);
        
            this.shape_136 = new cjs.Shape();
            this.shape_136.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyeggQSMCdSxid");
            this.shape_136.setTransform(252.5057,365.0519);
        
            this.shape_137 = new cjs.Shape();
            this.shape_137.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegiQSLCmSyim");
            this.shape_137.setTransform(252.5044,365.2215);
        
            this.shape_138 = new cjs.Shape();
            this.shape_138.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyegjQSKCtSzit");
            this.shape_138.setTransform(252.5026,365.3911);
        
            this.shape_139 = new cjs.Shape();
            this.shape_139.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AyeglQSJC1S0i1");
            this.shape_139.setTransform(252.5007,365.5609);
        
            this.shape_140 = new cjs.Shape();
            this.shape_140.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AydgnQSIC+S0i+");
            this.shape_140.setTransform(252.4987,365.7311);
        
            this.shape_141 = new cjs.Shape();
            this.shape_141.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AydgoQSHDFS1jF");
            this.shape_141.setTransform(252.4968,365.8909);
        
            this.shape_142 = new cjs.Shape();
            this.shape_142.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AydgqQSGDNS2jN");
            this.shape_142.setTransform(252.4948,366.0616);
        
            this.shape_143 = new cjs.Shape();
            this.shape_143.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AydgsQSFDVS3jV");
            this.shape_143.setTransform(252.4927,366.2327);
        
            this.shape_144 = new cjs.Shape();
            this.shape_144.graphics.f().s("#FFFFFF").ss(21,2,0,3).p("AydguQSEDdS4jd");
            this.shape_144.setTransform(252.4907,366.4001);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_116}]}).to({state:[{t:this.shape_116}]},31).to({state:[{t:this.shape_117}]},1).to({state:[{t:this.shape_118}]},1).to({state:[{t:this.shape_119}]},1).to({state:[{t:this.shape_120}]},1).to({state:[{t:this.shape_121}]},1).to({state:[{t:this.shape_122}]},1).to({state:[{t:this.shape_123}]},1).to({state:[{t:this.shape_124}]},1).to({state:[{t:this.shape_125}]},1).to({state:[{t:this.shape_126}]},1).to({state:[{t:this.shape_127}]},1).to({state:[{t:this.shape_128}]},1).to({state:[{t:this.shape_129}]},1).to({state:[{t:this.shape_130}]},1).to({state:[{t:this.shape_131}]},1).to({state:[{t:this.shape_132}]},1).to({state:[{t:this.shape_133}]},1).to({state:[{t:this.shape_134}]},1).to({state:[{t:this.shape_135}]},1).to({state:[{t:this.shape_136}]},1).to({state:[{t:this.shape_137}]},1).to({state:[{t:this.shape_138}]},1).to({state:[{t:this.shape_139}]},1).to({state:[{t:this.shape_140}]},1).to({state:[{t:this.shape_141}]},1).to({state:[{t:this.shape_142}]},1).to({state:[{t:this.shape_143}]},1).to({state:[{t:this.shape_144}]},1).wait(1));
        
            // slider_balken
            this.instance = new lib.sliderBalcken();
            this.instance.setTransform(53.55,375,1,1,0,0,0,14,3.5);
        
            this.drag_mc = new lib.sliderBalcken();
            this.drag_mc.name = "drag_mc";
            this.drag_mc.setTransform(53.55,175,1,1,0,0,0,14,3.5);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance}]}).to({state:[{t:this.drag_mc}]},59).wait(1));
            this.timeline.addTween(cjs.Tween.get(this.instance).to({_off:true,y:175},59).wait(1));
        
            // statisch
            this.shape_145 = new cjs.Shape();
            this.shape_145.graphics.f("#FFFFFF").s().p("AofArIAAhVIQ+AAIAABVgAoQAdIQhAAIAAg5IwhAAg");
            this.shape_145.setTransform(251.9305,100.4878,2.37,2.37);
        
            this.shape_146 = new cjs.Shape();
            this.shape_146.graphics.f("#FFFFFF").s().p("AghHTIAAulIBDAAIAAOlgAgNG/IAbAAIAAt9IgbAAg");
            this.shape_146.setTransform(53.7396,277.7042,2.37,2.37);
        
            this.shape_147 = new cjs.Shape();
            this.shape_147.graphics.f("#FFFFFF").s().p("AhABrIAAhHIAeAAIAAAqIBFAAIAAibIhUAAIAAgdIByAAIAADVg");
            this.shape_147.setTransform(380.9767,362.313,2.37,2.37);
        
            this.shape_148 = new cjs.Shape();
            this.shape_148.graphics.f("#FFFFFF").s().p("AgOA+IAAh7IAdAAIAAB7g");
            this.shape_148.setTransform(369.186,338.9686,2.37,2.37);
        
            this.shape_149 = new cjs.Shape();
            this.shape_149.graphics.f("#FFFFFF").s().p("Ag/BrIAAjVIBxAAIAAAdIhUAAIAACbIBFAAIAAgqIAdAAIAABHg");
            this.shape_149.setTransform(121.877,362.313,2.37,2.37);
        
            this.shape_150 = new cjs.Shape();
            this.shape_150.graphics.f("#FFFFFF").s().p("AgOA+IAAh7IAdAAIAAB7g");
            this.shape_150.setTransform(133.6085,338.9686,2.37,2.37);
        
            this.shape_151 = new cjs.Shape();
            this.shape_151.graphics.f("#FFFFFF").s().p("AhABrIAAhHIAeAAIAAAqIBFAAIAAibIhUAAIAAgdIByAAIAADVg");
            this.shape_151.setTransform(380.9767,231.43,2.37,2.37);
        
            this.shape_152 = new cjs.Shape();
            this.shape_152.graphics.f("#FFFFFF").s().p("AgOA+IAAh7IAdAAIAAB7g");
            this.shape_152.setTransform(369.186,208.1448,2.37,2.37);
        
            this.shape_153 = new cjs.Shape();
            this.shape_153.graphics.f("#FFFFFF").s().p("Ag/BrIAAjVIBxAAIAAAdIhUAAIAACbIBFAAIAAgqIAdAAIAABHg");
            this.shape_153.setTransform(121.877,231.43,2.37,2.37);
        
            this.shape_154 = new cjs.Shape();
            this.shape_154.graphics.f("#FFFFFF").s().p("AgOA+IAAh7IAdAAIAAB7g");
            this.shape_154.setTransform(133.6085,208.1448,2.37,2.37);
        
            this.shape_155 = new cjs.Shape();
            this.shape_155.graphics.f("#FFFFFF").s().p("AhABrIAAhHIAeAAIAAAqIBFAAIAAibIhUAAIAAgeIByAAIAADWg");
            this.shape_155.setTransform(380.9767,100.6063,2.37,2.37);
        
            this.shape_156 = new cjs.Shape();
            this.shape_156.graphics.f("#FFFFFF").s().p("AgOA+IAAh7IAdAAIAAB7g");
            this.shape_156.setTransform(369.186,77.3211,2.37,2.37);
        
            this.shape_157 = new cjs.Shape();
            this.shape_157.graphics.f("#FFFFFF").s().p("Ag/BrIAAjWIBxAAIAAAeIhUAAIAACbIBFAAIAAgqIAdAAIAABHg");
            this.shape_157.setTransform(121.877,100.6063,2.37,2.37);
        
            this.shape_158 = new cjs.Shape();
            this.shape_158.graphics.f("#FFFFFF").s().p("AgOA+IAAh7IAdAAIAAB7g");
            this.shape_158.setTransform(133.6085,77.3211,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_158},{t:this.shape_157},{t:this.shape_156},{t:this.shape_155},{t:this.shape_154},{t:this.shape_153},{t:this.shape_152},{t:this.shape_151},{t:this.shape_150},{t:this.shape_149},{t:this.shape_148},{t:this.shape_147},{t:this.shape_146},{t:this.shape_145}]}).wait(60));
        
            // sliderInhalt
            this.instance_1 = new lib.sliderInhalt_mc();
            this.instance_1.setTransform(53.05,384.75,1,0.0573,0,0,0,3.5,213.7);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).to({scaleY:0.9838},59).wait(1));
        
            // hand_mc
            this.hand_ani_mc = new lib.hand_ani_mc();
            this.hand_ani_mc.name = "hand_ani_mc";
            this.hand_ani_mc.setTransform(34.75,425.8,1,1,-1.4681,0,0,18.9,21.5);
        
            this.timeline.addTween(cjs.Tween.get(this.hand_ani_mc).wait(60));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(11.4,0,384.90000000000003,437.5);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.firstTouch = true;
                this.goHome=function(){
                    this.firstTouch = true;
                    this.sub_ani_01_mc.hand_ani_mc.gotoAndStop(0);
                    this.sub_ani_01_mc.gotoAndStop(0);
                }
                // this.goHome();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // sub_animation
            this.sub_ani_01_mc = new lib.sub_animatin_01();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(214.95,207.5,1,1,0,0,0,198.1,207.5);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(0,0,500,727.6), null);
        

    /* IMPORT END 
// stage content: ...
*/


    return lib
}