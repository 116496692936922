import Papa from 'papaparse'
import { config } from '../../config';

export const getCustomerData = ({ qrcode, setCustomer }) => {

    qrcodeApi(qrcode, setCustomer)
    return

    // Papa.parse('/customers.csv', {
    //     header: true,
    //     download: true,
    //     skipEmptyLines: true,
    //     complete: function (results) {
    //         // console.log(results.data)
    //         var customerFound = {}
    //         results.data.forEach(customer => {
    //             // console.log(customer, qrcode)
    //             if (customer.Code === qrcode) {
    //                 customerFound = customer
    //             }
    //         });
    //         setCustomer(customerFound)
    //     },
    // });
}

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,OPTIONS',
}

export const qrcodeApi = async (qrcode = '', setCustomer) => {

    var url = `http://${config.ip}/api/qrcode`

    var options = {
        method: 'POST',
        headers: headers,
        mode: 'cors',
        body: `{"qrcode": "${qrcode}"}`
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return { error: true, text: `${error}` }
    })

    const data = await response;

    if (data.result && data.result === 'error') {
        console.log('qrcodeApi API Error:', data)
    } else {
        console.log('qrcodeApi:', data)

        // debug
        if(data.error){
            setCustomer({ id: '1', Code: '123', Name: 'Test', Language: 'de', Gender: 'm', categories: 'test1\nTest2', Company: 'smmd' })
            return
        } else {
            setCustomer(data)
        }
        
    }
    return data
}