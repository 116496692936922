import * as createjs from 'createjs-module'
import { saveMcPos } from '../animateHelper'
import { animateBtnLanguage } from './navigationAnimation'
import { createButton } from './navigationButton'
import { buttonAnimation } from './navigationAnimation'
import { getContent } from '../../config/getContent'
import { switchLanguage } from './language'
import { goBack, openPage } from './/navigationAction'

// Flash
import { navigation } from '../../assets/animate/ui/navigation';
import { config } from '../../config'
import { fanApi } from '../fanApi'

export const initNavigation = (root) => {

    const mouseEvent = (root.mode === 'screen')
        ? config.screen.mouseEvent // mousedown
        : config.mobile.mouseEvent // click

    // Navigation
    const navigation_lib = navigation()
    root.navigation = new navigation_lib.navigation();
    if (root.mode === 'mobile') {
        root.navigation.setTransform(50, -350);
    } else {
        root.navigation.setTransform(768, 63);
    }
    root.timeline.addTween(createjs.Tween.get(root.navigation).wait(1));
    // saveMcPos(root, 'navigation')

    // console.log(root.navigation)

    // Alle Level erzeugen
    const content = getContent()

    // Hauptmenu
    createLevel(root, '', true)
    // submenus
    content.forEach((menu) => {
        if (menu.content && menu.content.length > 0) {
            createLevel(root, menu.id)
        }
    })

    // Platzhalter ausblenden
    root.navigation.btn.visible = false

    root.navigation.btn_language.x = 165
    root.navigation.btn_language.scale = 1.5

    // Alle Positionen speichern
    saveMcPos(root, 'navigation')

    // Home
    root.navigation.btn_home.on(mouseEvent, function (e) {
        // console.log('home', root)
        if (!this.timeStamp || this.timeStamp < e.timeStamp - 1500) {
            console.log('home', this.timeStamp, e.timeStamp)
            openPage(root, 'home')
        }
        this.timeStamp = e.timeStamp
    })
    root.navigation.btn_home.alpha = 0

    // Back
    root.navigation.btn_back.on(mouseEvent, function (e) {
        // console.log('back', root)
        if (!this.timeStamp || this.timeStamp < e.timeStamp - 1500) {
            console.log('back', this.timeStamp, e.timeStamp)
            goBack(root)
        }
        this.timeStamp = e.timeStamp
    })
    root.navigation.btn_back.alpha = 0

    // Language
    root.navigation.btn_language.on(mouseEvent, function (e) {

        if (!this.timeStamp || this.timeStamp < e.timeStamp - 1500) {
            console.log('language', this.timeStamp, e.timeStamp)
            switchLanguage(root)
        }
        this.timeStamp = e.timeStamp
    })
    root.navigation.btn_language.alpha = 0
    root.navigation.btn_language.en.alpha = 0

    animateBtnLanguage(root, 'in')

    fanApi('off')
}

// Menu erzeugen
export const createLevel = (root, id, show = false) => {

    // Set Buttons
    const content = getContent(id)
    const isMain = (id === '')

    // console.log('createLevel', root, id, content, isMain)

    // Rastergröße
    const addX = 120
    const addY = 160

    // Position des Platzhalters
    let btnX = root.navigation.btn.x + ((root.mode === 'mobile') ? 0 : 705)
    let btnY = root.navigation.btn.y + ((root.mode === 'mobile') ? -430 : 0)
    var posX = 0
    var posY = 0


    let posMainArr = [
        { x: 1, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: 2, y: 1 },
        { x: 1, y: 2 }
    ]

    var posArr = [
        { x: 1, y: 0 },
        { x: 0, y: 1 },
        { x: 2, y: 1 },
        { x: 1, y: 2 }
    ]

    if (content.length > 4) {
        posArr = [
            { x: 0, y: 0.5 },
            { x: 1, y: 0 },
            { x: 2, y: 0.5 },
            { x: 0, y: 1.5 },
            { x: 2, y: 1.5 },
            { x: 1, y: 2 }
        ]
    }

    if (content.length === 2) {
        posArr = [
            { x: 0, y: 1 },
            { x: 2, y: 1 }
        ]
    }

    content.forEach((menu, key) => {

        if (posArr[key] && !isMain) {
            posX = posArr[key].x
            posY = posArr[key].y
        } else if (posMainArr[key]) {
            posX = posMainArr[key].x
            posY = posMainArr[key].y
        }
        // console.log(menu, key, posX)


        // console.log(menu)
        root['btn_' + menu.id] = createButton(root, menu)
        root['btn_' + menu.id].setTransform(
            btnX + (posX * addX),
            btnY + (posY * addY)
        );
        root.timeline.addTween(createjs.Tween.get(root['btn_' + menu.id]).wait(1));

        // console.log('btn_' + menu.id, root)

        // Pos
        saveMcPos(root, 'btn_' + menu.id)

        // init Animation
        if (show) {
            buttonAnimation(root, menu, key, 'show', (isMain ? 'on' : 'off'))
        }

        // 3x3 Raster
        posX += 1
        if (posX > 2) {
            posX = 0
            posY += 1
        }
    });
}
