var dragArea = {
    topMin: 900,
    topMax: 1100,
    leftMin: 70,
    leftMax: 1900
}

export const mouseDrag = (root, mouse) => {

    // console.log(root, root.status.currentAnimationRight)
    let curAni = 'animations_' + root.status.currentAnimationRight

    if (root.mode === 'mobile') {
        if (root.status.currentAnimationRight === 'innovatives/stabilitaet') {
            dragArea = {
                topMin: 385,
                topMax: 620,
                leftMin: 655,
                leftMax: 690
            }
        }
        if (root.status.currentAnimationRight === 'innovatives/thermik') {
            dragArea = {
                topMin: 460,
                topMax: 675,
                leftMin: 655,
                leftMax: 690
            }
        }
        
    }

    let totalFrames = (root[curAni] && root[curAni].sub_ani_01_mc)
        ? root[curAni].sub_ani_01_mc.totalFrames - 1
        : 0

    if (totalFrames > 1 && root[curAni].sub_ani_01_mc.drag_mc) {

        // console.log(root[curAni].sub_ani_01_mc)

        let factor = totalFrames - (((mouse.top - dragArea.topMin) * totalFrames) / (dragArea.topMax - dragArea.topMin))
        // let gewicht = 150 - (((mouse.top - dragArea.topMin) * 150) / (dragArea.topMax - dragArea.topMin))

        // 250      mouse.top - dragArea.topMin
        // 60       x

        if (root && mouse.left >= dragArea.leftMin && mouse.left <= dragArea.leftMax && mouse.top >= dragArea.topMin && mouse.top <= dragArea.topMax) {
            // console.log(root[curAni].sub_ani_01_mc) // .totalFrames, Math.round(factor))
            root[curAni].sub_ani_01_mc.gotoAndStop(Math.round(factor))

            if (root[curAni].sub_ani_01_mc.gewicht_01_mc) {
                root[curAni].sub_ani_01_mc.gewicht_01_mc.txt_01.text = Math.round(33 + 2 * factor) + 'kg'
            }
            if (root[curAni].sub_ani_01_mc.gewicht_02_mc) {
                root[curAni].sub_ani_01_mc.gewicht_02_mc.txt_01.text = Math.round(33 + 2 * factor) + 'kg'
            }
            if (root[curAni].sub_ani_01_mc.gewicht_03_mc) {
                root[curAni].sub_ani_01_mc.gewicht_03_mc.txt_01.text = Math.round(33 + 2 * factor) + 'kg'
            }

            if (root[curAni].sub_ani_01_mc.temp_txt) {
                root[curAni].sub_ani_01_mc.temp_txt.text = Math.round(-10 + 2.5 * factor / 2) + '° C'
            }

            // 
            // gewicht_03_mc
        }
    }
}