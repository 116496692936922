/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'
import { uiSound } from '../../../../actions/audio/audioPlayer'
import { setHeadlineContent } from '../../../../actions/uiHelpers'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */

    (lib.wolkeButton_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // Ebene_3
        this.shape = new cjs.Shape();
        this.shape.graphics.f("rgba(255,255,255,0.008)").s().p("AmUF8IAAr3IMpAAIAAL3g");
        this.shape.setTransform(27.525, 24.05);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(2));

        // flash0_ai
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f().s("#FFFFFF").ss(1, 1, 1).p("AA4gBQgIAAgGAEIAAgDQAAgSgOgNQgOgOgRAAQgOAAgMAHQgLAIgFAMIgCAAQgNAAgKAKQgJAIAAAOQAAANAJAKQAKAJANAAIBnAAQAKAAAHgHQAHgHAAgKQAAgKgHgHQgHgGgKAAg");
        this.shape_1.setTransform(25.9439, 25.7864, 2.3699, 2.3699);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f().s("#FFFFFF").ss(1, 1, 0, 3.9).p("AAhARQAAgOgKgIQgKgKgNAAQgNAAgJAKQgKAIAAAO");
        this.shape_2.setTransform(14.687, 25.1939, 2.3699, 2.3699);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }] }).wait(2));

        // Ebene_1
        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhiBjIDFAAIAAjFIjFAAg");
        this.shape_3.setTransform(26.0026, 25.9941, 2.3856, 2.3903);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("rgba(52,165,140,0.898)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_4.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_3 }] }).to({ state: [{ t: this.shape_4 }] }, 1).wait(1));

        // Ebene_2
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("rgba(0,0,0,0.008)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_5.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get(this.shape_5).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-12.9, -13.9, 80.9, 76);


    (lib.testBtn = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#000000").s().p("AldFAIAAp/IK7AAIAAJ/g");
        this.shape.setTransform(9.05, 8.05);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.testBtn, new cjs.Rectangle(-25.9, -23.9, 70, 64), null);


    (lib.sonneButton_mc_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // Ebene_3
        this.shape = new cjs.Shape();
        this.shape.graphics.f("rgba(255,255,255,0.008)").s().p("AmUF8IAAr3IMpAAIAAL3g");
        this.shape.setTransform(27.525, 24.05);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(2));

        // flash0_ai
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgHgKIAIgDIAHAYIgIACg");
        this.shape_1.setTransform(30.0817, 41.3772, 2.3694, 2.3694);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgHALIAHgYIAIADIgHAXg");
        this.shape_2.setTransform(22.0257, 41.3772, 2.3694, 2.3694);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgLAGIARgRIAGAGIgRARg");
        this.shape_3.setTransform(14.9175, 37.29, 2.3694, 2.3694);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgMAAIAXgHIACAIIgXAHg");
        this.shape_4.setTransform(10.8895, 30.3002, 2.3694, 2.3694);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgMABIACgIIAXAHIgCAIg");
        this.shape_5.setTransform(10.8895, 22.1257, 2.3694, 2.3694);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgLgFIAGgGIARARIgGAGg");
        this.shape_6.setTransform(14.9175, 15.136, 2.3694, 2.3694);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgHgKIAIgDIAHAYIgIADg");
        this.shape_7.setTransform(22.0257, 11.0487, 2.3694, 2.3694);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgHALIAHgYIAIADIgHAYg");
        this.shape_8.setTransform(30.0817, 11.0487, 2.3694, 2.3694);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgLAGIARgRIAGAGIgRARg");
        this.shape_9.setTransform(37.1308, 15.136, 2.3694, 2.3694);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AgMAAIAXgHIADAIIgYAHg");
        this.shape_10.setTransform(41.1588, 22.1257, 2.3694, 2.3694);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AgMABIACgIIAYAHIgDAIg");
        this.shape_11.setTransform(41.1588, 30.3002, 2.3694, 2.3694);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#FFFFFF").s().p("AgLgFIAGgGIARARIgGAGg");
        this.shape_12.setTransform(37.1308, 37.29, 2.3694, 2.3694);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#DD0061").s().p("Ag/BAQgcgaAAgmQAAglAcgbQAagaAlAAQAmAAAaAaQAcAbAAAlQAAAmgcAaQgaAbgmAAQglAAgagbgAAABGQAdAAAUgUQAUgWABgcQgBgdgUgUQgUgUgdAAQAdAAAUAUQAUAUABAdQgBAcgUAWQgUAUgdAAQgcAAgUgUQgWgWAAgcQAAgdAWgUQAUgUAcAAQgcAAgUAUQgWAUAAAdQAAAcAWAWQAUAUAcAAIAAAAg");
        this.shape_13.setTransform(26.05, 26.225);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_13 }, { t: this.shape_12 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }] }).wait(2));

        // Ebene_1
        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhiBjIDFAAIAAjFIjFAAg");
        this.shape_14.setTransform(26.0019, 25.9937, 2.3855, 2.3902);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("rgba(52,165,140,0.898)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_15.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_14 }] }).to({ state: [{ t: this.shape_15 }] }, 1).wait(1));

        // Ebene_2
        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("rgba(0,0,0,0.008)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_16.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get(this.shape_16).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-12.9, -13.9, 80.9, 76);


    (lib.sonneButton_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // Ebene_3
        this.shape = new cjs.Shape();
        this.shape.graphics.f("rgba(255,255,255,0.008)").s().p("AmUF8IAAr3IMpAAIAAL3g");
        this.shape.setTransform(27.525, 24.05);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(2));

        // flash0_ai
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgHgKIAIgDIAHAYIgIACg");
        this.shape_1.setTransform(30.0827, 41.3787, 2.3695, 2.3695);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgHALIAHgYIAIADIgHAXg");
        this.shape_2.setTransform(22.0264, 41.3787, 2.3695, 2.3695);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgLAGIARgRIAGAGIgRARg");
        this.shape_3.setTransform(14.9178, 37.2913, 2.3695, 2.3695);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgMAAIAXgHIACAIIgXAHg");
        this.shape_4.setTransform(10.8896, 30.3012, 2.3695, 2.3695);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgMABIACgIIAXAHIgCAIg");
        this.shape_5.setTransform(10.8896, 22.1264, 2.3695, 2.3695);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgLgFIAGgGIARARIgGAGg");
        this.shape_6.setTransform(14.9178, 15.1363, 2.3695, 2.3695);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgHgKIAIgDIAHAYIgIADg");
        this.shape_7.setTransform(22.0264, 11.0489, 2.3695, 2.3695);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgHALIAHgYIAIADIgHAYg");
        this.shape_8.setTransform(30.0827, 11.0489, 2.3695, 2.3695);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgLAGIARgRIAGAGIgRARg");
        this.shape_9.setTransform(37.1321, 15.1363, 2.3695, 2.3695);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AgMAAIAXgHIADAIIgYAHg");
        this.shape_10.setTransform(41.1603, 22.1264, 2.3695, 2.3695);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AgMABIACgIIAYAHIgDAIg");
        this.shape_11.setTransform(41.1603, 30.3012, 2.3695, 2.3695);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#FFFFFF").s().p("AgLgFIAGgGIARARIgGAGg");
        this.shape_12.setTransform(37.1321, 37.2913, 2.3695, 2.3695);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#FFFFFF").s().p("AgaAbQgMgLAAgQQAAgPAMgLQALgMAPAAQAQAAALAMQAMALAAAPQAAAQgMALQgLAMgQAAQgPAAgLgMgAgUgUQgJAIAAAMQAAAMAJAJQAJAJALAAQAMAAAJgJQAIgJAAgMQAAgMgIgIQgJgJgMAAQgLAAgJAJg");
        this.shape_13.setTransform(26.0564, 26.2157, 2.3698, 2.3698);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_13 }, { t: this.shape_12 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }] }).wait(2));

        // Ebene_1
        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhiBjIDFAAIAAjFIjFAAg");
        this.shape_14.setTransform(26.0034, 25.9949, 2.3856, 2.3903);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("rgba(52,165,140,0.898)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_15.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_14 }] }).to({ state: [{ t: this.shape_15 }] }, 1).wait(1));

        // Ebene_2
        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("rgba(0,0,0,0.008)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_16.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get(this.shape_16).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-12.9, -13.9, 80.9, 76);


    (lib.schalkreisAni_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#34A58C").s().p("EAnEAAKIAAgTIAoAAIAAATgEAl0AAKIAAgTIAoAAIAAATgEAkkAAKIAAgTIAoAAIAAATgEAjUAAKIAAgTIAoAAIAAATgEAiEAAKIAAgTIAoAAIAAATgEAg0AAKIAAgTIAoAAIAAATgAfkAKIAAgTIAoAAIAAATgAeUAKIAAgTIAoAAIAAATgAdEAKIAAgTIAoAAIAAATgAb0AKIAAgTIAoAAIAAATgAakAKIAAgTIAoAAIAAATgAZUAKIAAgTIAoAAIAAATgAYEAKIAAgTIAoAAIAAATgAW0AKIAAgTIAoAAIAAATgAVkAKIAAgTIAoAAIAAATgAUUAKIAAgTIAoAAIAAATgATEAKIAAgTIAoAAIAAATgAR0AKIAAgTIAoAAIAAATgAQkAKIAAgTIAoAAIAAATgAPUAKIAAgTIAoAAIAAATgAOEAKIAAgTIAoAAIAAATgAM0AKIAAgTIAoAAIAAATgALkAKIAAgTIAoAAIAAATgAKUAKIAAgTIAoAAIAAATgAJEAKIAAgTIAoAAIAAATgAH0AKIAAgTIAoAAIAAATgAGkAKIAAgTIAoAAIAAATgAFUAKIAAgTIAoAAIAAATgAEEAKIAAgTIAoAAIAAATgAC0AKIAAgTIAoAAIAAATgABkAKIAAgTIAoAAIAAATgAAUAKIAAgTIAoAAIAAATgAg7AKIAAgTIAoAAIAAATgAiLAKIAAgTIAoAAIAAATgAjbAKIAAgTIAoAAIAAATgAkrAKIAAgTIAoAAIAAATgAl7AKIAAgTIAoAAIAAATgAnLAKIAAgTIAoAAIAAATgAobAKIAAgTIAoAAIAAATgAprAKIAAgTIAoAAIAAATgAq7AKIAAgTIAoAAIAAATgAsLAKIAAgTIAoAAIAAATgAtbAKIAAgTIAoAAIAAATgAurAKIAAgTIAoAAIAAATgAv7AKIAAgTIAoAAIAAATgAxLAKIAAgTIAoAAIAAATgAybAKIAAgTIAoAAIAAATgAzrAKIAAgTIAoAAIAAATgA07AKIAAgTIAoAAIAAATgA2LAKIAAgTIAoAAIAAATgA3bAKIAAgTIAoAAIAAATgA4rAKIAAgTIAoAAIAAATgA57AKIAAgTIAoAAIAAATgA7LAKIAAgTIAoAAIAAATgA8bAKIAAgTIAoAAIAAATgA9rAKIAAgTIAoAAIAAATgA+7AKIAAgTIAoAAIAAATgEggLAAKIAAgTIAoAAIAAATgEghbAAKIAAgTIAoAAIAAATgEgirAAKIAAgTIAoAAIAAATgEgj7AAKIAAgTIAoAAIAAATgEglLAAKIAAgTIAoAAIAAATgEgmbAAKIAAgTIAoAAIAAATgEgnrAAKIAAgTIAoAAIAAATg");
        this.shape.setTransform(254, 1);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(5).to({ x: 258 }, 0).wait(5));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0, 0, 512, 2);


    (lib.mondButton_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // Ebene_3
        this.shape = new cjs.Shape();
        this.shape.graphics.f("rgba(255,255,255,0.008)").s().p("AmUF8IAAr3IMpAAIAAL3g");
        this.shape.setTransform(27.525, 24.05);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(2));

        // flash0_ai
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f().s("#FFFFFF").ss(1, 1, 0, 3.9).p("AgNg/QgQAAgNAIQAPAHAKAPQAKAQAAARQAAASgKAQQgKAPgPAIQANAHAQAAQAZAAATgTQASgSAAgbQAAgZgSgTQgTgTgZAAg");
        this.shape_1.setTransform(24.9404, 25.7845, 2.37, 2.37);

        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(2));

        // Ebene_1
        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhiBjIDFAAIAAjFIjFAAg");
        this.shape_2.setTransform(26.0026, 25.9941, 2.3856, 2.3903);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("rgba(52,165,140,0.898)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_3.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_2 }] }).to({ state: [{ t: this.shape_3 }] }, 1).wait(1));

        // Ebene_2
        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("rgba(0,0,0,0.008)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_4.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get(this.shape_4).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-12.9, -13.9, 80.9, 76);


    (lib.hand_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgZA3IgKgKIBcAAQAAgEgDgDQgDgCgEAAIgWAAQgFgBgDgDQgEgDAAgFQAAgGAEgDIA0gzIABgDIgBgEQgEgDgDADIg0AzIgHgHIARgQQAEgDgEgEQgDgDgEADIgFAFIgBACIgKAKIgHgGIALgNQAEgDgEgDQgCgEgEAEIgFAEIgBACIgGAGIgHgIIAGgEIABgDQABAAAAAAQAAAAAAgBQABAAAAgBQAAAAAAgBQAAAAAAgBQAAAAgBgBQAAAAAAAAQAAgBgBAAQgDgDgEADIgwAvIgHgHIAxgvQAEgFAGAAQAGAAAEAFIAEAFQAHgEAHAIIAEAFQAIgDAHAGIADAEIAZgZQAEgEAGAAQAGAAAEAEQAFAFAAAGQAAAGgFAEIgzAzQgBAAAAABQAAAAAAABQAAAAAAABQAAAAABABIABAAIAWAAQAJgBAFAHQAGAFAAAIIAAAKg");
        this.shape.setTransform(18.9007, 13.0242, 2.37, 2.37);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.hand_mc, new cjs.Rectangle(0, 0, 37.8, 26.1), null);


    (lib.direction_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#34A58C").s().p("AgfgDIA/gBIggA6gAgIgMIAAgpIAQAAIAAApg");
        this.shape.setTransform(3.2, 5.375);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("rgba(52,165,140,0.698)").s().p("AgfgDIA/gBIggA6gAgIgMIAAgpIAQAAIAAApg");
        this.shape_1.setTransform(3.2, 5.375);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape }] }).to({ state: [{ t: this.shape_1 }] }, 3).wait(3));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0, 0, 6.4, 10.8);


    (lib.wolken_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // directions
        this.instance = new lib.direction_mc();
        this.instance.setTransform(259.8, 157.45, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_1 = new lib.direction_mc();
        this.instance_1.setTransform(162.8, 157.45, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_2 = new lib.direction_mc();
        this.instance_2.setTransform(82.8, 157.45, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_3 = new lib.direction_mc();
        this.instance_3.setTransform(299.6, 263.1, 1, 1, 0, 89.9948, -90.0052, 2.8, 5.7);

        this.instance_4 = new lib.direction_mc();
        this.instance_4.setTransform(299.6, 184.6, 1, 1, 0, 89.9948, -90.0052, 2.8, 5.7);

        this.instance_5 = new lib.direction_mc();
        this.instance_5.setTransform(310.55, 32, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_6 = new lib.direction_mc();
        this.instance_6.setTransform(161.8, 119.4, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_6 }, { t: this.instance_5 }, { t: this.instance_4 }, { t: this.instance_3 }, { t: this.instance_2 }, { t: this.instance_1 }, { t: this.instance }] }).wait(1));

        // maske (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AgwDtIAAnZIBhAAIAAHZg");
        mask.setTransform(169.875, 137.75);

        // s3
        this.instance_7 = new lib.schalkreisAni_mc();
        this.instance_7.setTransform(170.25, -102.15, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_7];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(1));

        // maske (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("ApYMpIAAi+IefAAIAAC+g");
        mask_1.setTransform(135.125, 80.875);

        // s_1_2
        this.instance_8 = new lib.schalkreisAni_mc();
        this.instance_8.setTransform(268.4, -189.25, 1, 1, 90, 0, 0, 2, 1);

        this.instance_9 = new lib.schalkreisAni_mc();
        this.instance_9.setTransform(76.4, -189.25, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_8, this.instance_9];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_9 }, { t: this.instance_8 }] }).wait(1));

        // maske (mask)
        var mask_2 = new cjs.Shape();
        mask_2._off = true;
        mask_2.graphics.p("Ag+TLMAAAgmVIB8AAMAAAAmVg");
        mask_2.setTransform(314, 145.075);

        // s6
        this.instance_10 = new lib.schalkreisAni_mc();
        this.instance_10.setTransform(315.65, -76.2, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_10];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(1));

        // maske (mask)
        var mask_3 = new cjs.Shape();
        mask_3._off = true;
        mask_3.graphics.p("ArSVzIAAudMAkBAAAIAAOdg");
        mask_3.setTransform(158.3112, 139.5);

        // w2
        this.instance_11 = new lib.schalkreisAni_mc();
        this.instance_11.setTransform(-1.5, 267.75, 1, 1, 0, 0, 0, 2, 1);

        this.instance_12 = new lib.schalkreisAni_mc();
        this.instance_12.setTransform(-1.5, 191, 1, 1, 0, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_11, this.instance_12];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_12 }, { t: this.instance_11 }] }).wait(1));

        // maske (mask)
        var mask_4 = new cjs.Shape();
        mask_4._off = true;
        mask_4.graphics.p("AvJAoIAAhPIeTAAIAABPg");
        mask_4.setTransform(172.25, 142);

        // w1
        this.instance_13 = new lib.schalkreisAni_mc();
        this.instance_13.setTransform(-85.5, 141.75, 1, 1, 0, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_13];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(1));

        // schaltkreis
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").p("Aq0p3IAATvIVpAAIAAz0");
        this.shape.setTransform(164.2849, 171.9187, 2.37, 2.37);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#34A58C").s().p("Ah8AbIAAg2ID5AAIAAA2g");
        this.shape_1.setTransform(318.6, 12.2, 2.37, 7.11, 0, 0, 0, 0.1, 2.7);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f().s("#FFFFFF").p("ADjCqIAAAxInFAAIAAm6");
        this.shape_2.setTransform(87.3495, 74.5949, 2.37, 2.37);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.wolken_mc, new cjs.Rectangle(-1, -26.5, 349, 349.2), null);


    (lib.tag_2_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_2
        this.instance = new lib.direction_mc();
        this.instance.setTransform(160.55, 118.9, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_1 = new lib.direction_mc();
        this.instance_1.setTransform(261.05, 154.4, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_2 = new lib.direction_mc();
        this.instance_2.setTransform(161.05, 154.4, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_3 = new lib.direction_mc();
        this.instance_3.setTransform(84.05, 154.4, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_4 = new lib.direction_mc();
        this.instance_4.setTransform(71.7, 120.45, 1, 1, 0, 89.9948, -90.0052, 2.8, 5.7);

        this.instance_5 = new lib.direction_mc();
        this.instance_5.setTransform(40.45, 31.9, 1, 1, 0, 180, 0, 3.2, 5.4);

        this.instance_6 = new lib.direction_mc();
        this.instance_6.setTransform(136.05, 117.9, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_6 }, { t: this.instance_5 }, { t: this.instance_4 }, { t: this.instance_3 }, { t: this.instance_2 }, { t: this.instance_1 }, { t: this.instance }] }).wait(1));

        // Maske_Kopie (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AgRBLIAAiVIAiAAIAACVg");
        mask.setTransform(141, 120);

        // s4_2
        this.instance_7 = new lib.schalkreisAni_mc();
        this.instance_7.setTransform(141.25, -106.15, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_7];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(1));

        // maske (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("AgwLNIAA2ZIBhAAIAAWZg");
        mask_1.setTransform(169.875, 186.225);

        // s3
        this.instance_8 = new lib.schalkreisAni_mc();
        this.instance_8.setTransform(170.25, -102.15, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_8];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(1));

        // maske (mask)
        var mask_2 = new cjs.Shape();
        mask_2._off = true;
        mask_2.graphics.p("ApYT3IAAxDIefAAIAARDg");
        mask_2.setTransform(135.125, 127.122);

        // s_1_2
        this.instance_9 = new lib.schalkreisAni_mc();
        this.instance_9.setTransform(268.4, -189.25, 1, 1, 90, 0, 0, 2, 1);

        this.instance_10 = new lib.schalkreisAni_mc();
        this.instance_10.setTransform(76.4, -189.25, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_9, this.instance_10];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_10 }, { t: this.instance_9 }] }).wait(1));

        // maske_Kopie (mask)
        var mask_3 = new cjs.Shape();
        mask_3._off = true;
        mask_3.graphics.p("Ag+IQIAAwfIB8AAIAAQfg");
        mask_3.setTransform(34, 74.75);

        // s6_2
        this.instance_11 = new lib.schalkreisAni_mc();
        this.instance_11.setTransform(33.9, -76.2, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_11];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(1));

        // maske_Kopie (mask)
        var mask_4 = new cjs.Shape();
        mask_4._off = true;
        mask_4.graphics.p("AoiAkIAAhHIRFAAIAABHg");
        mask_4.setTransform(87.75, 127.875);

        // w2_2
        this.instance_12 = new lib.schalkreisAni_mc();
        this.instance_12.setTransform(-249, 126.75, 1, 1, 0, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_12];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(1));

        // maske (mask)
        var mask_5 = new cjs.Shape();
        mask_5._off = true;
        mask_5.graphics.p("AvJAoIAAhPIeTAAIAABPg");
        mask_5.setTransform(172.25, 142);

        // w1
        this.instance_13 = new lib.schalkreisAni_mc();
        this.instance_13.setTransform(-85.5, 141.75, 1, 1, 0, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_13];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(1));

        // schaltkreis
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").p("AjiCqIAAAxIHFAAIAAm6");
        this.shape.setTransform(250.1682, 74.5949, 2.37, 2.37);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f().s("#FFFFFF").p("AApi9IAAF2IhWAA");
        this.shape_1.setTransform(306.0647, 224.0068, 2.37, 2.37);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f().s("#FFFFFF").p("AAslnIAALKIhcAA");
        this.shape_2.setTransform(305.3537, 106.8698, 2.37, 2.37);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f().s("#FFFFFF").p("Aq0p3IAATvIVpAAIAAz0");
        this.shape_3.setTransform(164.2849, 171.9187, 2.37, 2.37);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#34A58C").s().p("Ah8AbIAAg2ID5AAIAAA2g");
        this.shape_4.setTransform(318.8, 12.05, 2.37, 7.4547, 0, 0, 0, 0.2, 2.6);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.tag_2_mc, new cjs.Rectangle(-1, -27.8, 349, 350.5), null);


    (lib.tag_1_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_2
        this.instance = new lib.direction_mc();
        this.instance.setTransform(160.55, 118.9, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_1 = new lib.direction_mc();
        this.instance_1.setTransform(261.05, 154.4, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_2 = new lib.direction_mc();
        this.instance_2.setTransform(161.05, 154.4, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_3 = new lib.direction_mc();
        this.instance_3.setTransform(84.05, 154.4, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_4 = new lib.direction_mc();
        this.instance_4.setTransform(203.05, 117.9, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.instance_5 = new lib.direction_mc();
        this.instance_5.setTransform(263.15, 119.95, 1, 1, -89.9948, 0, 0, 2.8, 5.7);

        this.instance_6 = new lib.direction_mc();
        this.instance_6.setTransform(297.55, 31.9, 1, 1, 0, 180, 0, 3.2, 5.4);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_6 }, { t: this.instance_5 }, { t: this.instance_4 }, { t: this.instance_3 }, { t: this.instance_2 }, { t: this.instance_1 }, { t: this.instance }] }).wait(1));

        // Maske (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AgRBLIAAiVIAiAAIAACVg");
        mask.setTransform(196, 120);

        // s4
        this.instance_7 = new lib.schalkreisAni_mc();
        this.instance_7.setTransform(196.25, -106.15, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_7];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(1));

        // maske (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("AgwLwIAA3fIBhAAIAAXfg");
        mask_1.setTransform(171.375, 189.8);

        // s3
        this.instance_8 = new lib.schalkreisAni_mc();
        this.instance_8.setTransform(172.25, -102.15, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_8];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(1));

        // maske (mask)
        var mask_2 = new cjs.Shape();
        mask_2._off = true;
        mask_2.graphics.p("ApYUSIAAyYIefAAIAASYg");
        mask_2.setTransform(135.125, 129.7883);

        // s_1_2
        this.instance_9 = new lib.schalkreisAni_mc();
        this.instance_9.setTransform(268.4, -189.25, 1, 1, 90, 0, 0, 2, 1);

        this.instance_10 = new lib.schalkreisAni_mc();
        this.instance_10.setTransform(76.4, -189.25, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_9, this.instance_10];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_10 }, { t: this.instance_9 }] }).wait(1));

        // maske (mask)
        var mask_3 = new cjs.Shape();
        mask_3._off = true;
        mask_3.graphics.p("Ag+IQIAAwfIB8AAIAAQfg");
        mask_3.setTransform(304, 74.75);

        // s6
        this.instance_11 = new lib.schalkreisAni_mc();
        this.instance_11.setTransform(303.9, -76.2, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_11];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(1));

        // maske (mask)
        var mask_4 = new cjs.Shape();
        mask_4._off = true;
        mask_4.graphics.p("AojAkIAAhHIRHAAIAABHg");
        mask_4.setTransform(250.25, 127.875);

        // w2
        this.instance_12 = new lib.schalkreisAni_mc();
        this.instance_12.setTransform(-86.5, 126.75, 1, 1, 0, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_12];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(1));

        // maske (mask)
        var mask_5 = new cjs.Shape();
        mask_5._off = true;
        mask_5.graphics.p("AvJAoIAAhPIeTAAIAABPg");
        mask_5.setTransform(172.25, 142);

        // w1
        this.instance_13 = new lib.schalkreisAni_mc();
        this.instance_13.setTransform(-85.5, 141.75, 1, 1, 0, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_13];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(1));

        // schaltkreis
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").p("AApi9IAAF2IhWAA");
        this.shape.setTransform(306.0647, 224.0068, 2.37, 2.37);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f().s("#FFFFFF").p("AAslnIAALKIhcAA");
        this.shape_1.setTransform(305.3537, 106.8698, 2.37, 2.37);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f().s("#FFFFFF").p("Aq0p3IAATvIVpAAIAAz0");
        this.shape_2.setTransform(164.2849, 171.9187, 2.37, 2.37);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#34A58C").s().p("Ah8AbIAAg2ID5AAIAAA2g");
        this.shape_3.setTransform(318.3908, 6.4993, 2.37, 2.37);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f().s("#FFFFFF").p("ADjCqIAAAxInFAAIAAm6");
        this.shape_4.setTransform(87.3495, 74.5949, 2.37, 2.37);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.tag_1_mc, new cjs.Rectangle(-1, 0, 349, 322.7), null);


    (lib.nacht1_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // directions
        this.instance = new lib.direction_mc();
        this.instance.setTransform(299.6, 263.1, 1, 1, 0, 89.9948, -90.0052, 2.8, 5.7);

        this.instance_1 = new lib.direction_mc();
        this.instance_1.setTransform(299.6, 184.6, 1, 1, 0, 89.9948, -90.0052, 2.8, 5.7);

        this.instance_2 = new lib.direction_mc();
        this.instance_2.setTransform(310.55, 32, 1, 1, 0, 0, 0, 3.2, 5.4);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_2 }, { t: this.instance_1 }, { t: this.instance }] }).wait(1));

        // maske (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("Ag+TLMAAAgmVIB8AAMAAAAmVg");
        mask.setTransform(314, 145.075);

        // s6
        this.instance_3 = new lib.schalkreisAni_mc();
        this.instance_3.setTransform(315.65, -76.2, 1, 1, 90, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_3];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(1));

        // maske (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        mask_1.graphics.p("Aq8VzIAAudMAjVAAAIAAOdg");
        mask_1.setTransform(156.1054, 139.5);

        // w2
        this.instance_4 = new lib.schalkreisAni_mc();
        this.instance_4.setTransform(-1.5, 267.75, 1, 1, 0, 0, 0, 2, 1);

        this.instance_5 = new lib.schalkreisAni_mc();
        this.instance_5.setTransform(-1.5, 191, 1, 1, 0, 0, 0, 2, 1);

        var maskedShapeInstanceList = [this.instance_4, this.instance_5];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_5 }, { t: this.instance_4 }] }).wait(1));

        // schaltkreis
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").p("AjiCqIAAAxIHFAAIAAm6");
        this.shape.setTransform(250.1682, 74.5949, 2.37, 2.37);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f().s("#FFFFFF").p("AAABVIAAip");
        this.shape_1.setTransform(170.1216, 133.9633, 2.37, 2.37);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f().s("#FFFFFF").p("AmUAfIAAg/IMpAAIAABH");
        this.shape_2.setTransform(172.3139, 149.9015, 2.37, 2.37);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f().s("#FFFFFF").p("Aq0p3IAATvIVpAAIAAz0");
        this.shape_3.setTransform(164.2849, 171.9187, 2.37, 2.37);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#34A58C").s().p("Ah8AbIAAg2ID5AAIAAA2g");
        this.shape_4.setTransform(318.6, 12.45, 2.37, 5.5299, 0, 0, 0, 0.1, 2.7);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f().s("#FFFFFF").p("ADjCqIAAAxInFAAIAAm6");
        this.shape_5.setTransform(87.3495, 74.5949, 2.37, 2.37);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.nacht1_mc, new cjs.Rectangle(-1, -17.7, 349, 340.4), null);


    (lib.hand_2mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.hand_mc = new lib.hand_mc();
        this.hand_mc.name = "hand_mc";
        this.hand_mc.setTransform(18.9, 21.4, 1.2, 1.2, 0, 0, 0, 18.9, 21.4);

        this.timeline.addTween(cjs.Tween.get(this.hand_mc).wait(79).to({ scaleX: 1, scaleY: 1 }, 20, cjs.Ease.quadInOut).to({ scaleX: 1.2, scaleY: 1.2 }, 20, cjs.Ease.quadInOut).to({ scaleX: 1, scaleY: 1 }, 20, cjs.Ease.quadInOut).to({ scaleX: 1.2, scaleY: 1.2 }, 21, cjs.Ease.quadInOut).wait(118));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-3.8, -4.3, 45.4, 31.400000000000002);


    (lib.hand_ani_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }
        this.frame_19 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(19).call(this.frame_19).wait(1));

        // Ebene_1
        this.instance = new lib.hand_2mc();
        this.instance.setTransform(18.9, 13, 1, 1, 0, 0, 0, 18.9, 13);

        this.hand_mc = new lib.hand_mc();
        this.hand_mc.name = "hand_mc";
        this.hand_mc.setTransform(18.9, 21.4, 1, 1, 0, 0, 0, 18.9, 21.4);
        this.hand_mc.alpha = 0;

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance }] }).to({ state: [{ t: this.hand_mc }] }, 19).wait(1));
        this.timeline.addTween(cjs.Tween.get(this.instance).to({ _off: true, regY: 21.4, y: 21.4, alpha: 0 }, 19).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-3.8, -4.3, 45.4, 39.3);


    (lib.sub_ani_01_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
            if (this.isLoaded) {
                return
            }
            var rehau = false;
            var firstTouch = true;
            var hand_mc = this.hand_ani_mc;
            var aktuellerMc = this.tag_1_mc;
            var aktuellerBtn = this.sonne1_btn;

            this.goBack = function () {

                rehau = false;
                firstTouch = true;
                this.sonne1_btn.gotoAndStop(1);
                this.sonne2_btn.gotoAndStop(0);
                this.wolke_btn.gotoAndStop(0);
                this.mond1_btn.gotoAndStop(0);

                this.tag_1_mc.visible = true;
                this.tag_2_mc.visible = false;
                this.wolken_mc.visible = false;
                this.nacht_1_mc.visible = false;

                aktuellerMc = this.tag_1_mc;
                aktuellerBtn = this.sonne1_btn;

                hand_mc.gotoAndStop(0);
            }

            this.goBack();

            function changeMc(mc, btn) {
                handWeg();
                aktuellerMc.visible = false;
                aktuellerBtn.gotoAndStop(0);

                mc.visible = true;
                btn.gotoAndStop(1);

                aktuellerMc = mc;
                aktuellerBtn = btn;

            }

            function clickHandler_test() {
                this.parent.goHome();
            }
            this.testBtn.addEventListener("mousedown", clickHandler_test.bind(this));
            this.sonne1_btn.addEventListener("mousedown", clickHandler_sonne1.bind(this));
            this.sonne2_btn.addEventListener("mousedown", clickHandler_sonne2.bind(this));
            this.wolke_btn.addEventListener("mousedown", clickHandler_wolke.bind(this));
            this.mond1_btn.addEventListener("mousedown", clickHandler_mond1.bind(this));

            function clickHandler_test() {
                this.parent.goHome();
            }
            function clickHandler_sonne1() {
                if (aktuellerMc !== this.tag_1_mc) {
                    uiSound(this.parent.parent, 'clack')
                }
                changeMc(this.tag_1_mc, this.sonne1_btn);
                if (this.parent.visible) {
                    // console.log(this.parent.parent.status)
                    setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.headline.sonne1, 'flash nutzungsoptionen sonne1')
                }
            }
            function clickHandler_sonne2() {
                if (aktuellerMc !== this.tag_2_mc) {
                    uiSound(this.parent.parent, 'clack')
                }
                changeMc(this.tag_2_mc, this.sonne2_btn);
                if (this.parent.visible) {
                    // console.log(this.parent.parent.status)
                    setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.headline.sonne2, 'flash nutzungsoptionen sonne2')
                }
            }
            function clickHandler_wolke() {
                if (aktuellerMc !== this.wolken_mc) {
                    uiSound(this.parent.parent, 'clack')
                }
                changeMc(this.wolken_mc, this.wolke_btn);
                if (this.parent.visible) {
                    // console.log(this.parent.parent.status)
                    setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.headline.wolke, 'flash nutzungsoptionen wolke')
                }
            }
            function clickHandler_mond1() {
                if (aktuellerMc !== this.nacht_1_mc) {
                    uiSound(this.parent.parent, 'clack')
                }
                changeMc(this.nacht_1_mc, this.mond1_btn);
                if (this.parent.visible) {
                    // console.log(this.parent.parent.status)
                    setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.headline.mond1, 'flash nutzungsoptionen mond1')
                }
            }
            function handWeg() {
                if (firstTouch == true) {
                    hand_mc.play();
                    firstTouch = false;
                }
            }
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(3));

        // icon_02
        this.testBtn = new lib.testBtn();
        this.testBtn.name = "testBtn";
        this.testBtn.setTransform(-92.25, 420.3, 1, 1, 0, 0, 0, 13.5, 13.5);

        this.timeline.addTween(cjs.Tween.get(this.testBtn).to({ _off: true }, 2).wait(1));

        // flash0_ai
        this.hand_ani_mc = new lib.hand_ani_mc();
        this.hand_ani_mc.name = "hand_ani_mc";
        this.hand_ani_mc.setTransform(384.4, 382.3, 1, 1, -100.0035, 0, 0, 18.9, 21.4);

        this.timeline.addTween(cjs.Tween.get(this.hand_ani_mc).to({ _off: true }, 2).wait(1));

        // standart_fenster
        this.mond1_btn = new lib.mondButton_mc();
        this.mond1_btn.name = "mond1_btn";
        this.mond1_btn.setTransform(323.2, 362.75, 1, 1, 0, 0, 0, 26, 26);

        this.wolke_btn = new lib.wolkeButton_mc();
        this.wolke_btn.name = "wolke_btn";
        this.wolke_btn.setTransform(230.65, 362.75, 1, 1, 0, 0, 0, 26, 26);

        this.sonne2_btn = new lib.sonneButton_mc_2();
        this.sonne2_btn.name = "sonne2_btn";
        this.sonne2_btn.setTransform(138.2, 362.75, 1, 1, 0, 0, 0, 26, 26);

        this.sonne1_btn = new lib.sonneButton_mc();
        this.sonne1_btn.name = "sonne1_btn";
        this.sonne1_btn.setTransform(45.75, 362.75, 1, 1, 0, 0, 0, 26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.sonne1_btn }, { t: this.sonne2_btn }, { t: this.wolke_btn }, { t: this.mond1_btn }] }).to({ state: [] }, 2).wait(1));

        // statischeElemente
        this.txt_06 = new cjs.Text("Licht", "12px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_06.name = "txt_06";
        this.txt_06.textAlign = "center";
        this.txt_06.lineHeight = 15;
        this.txt_06.lineWidth = 76;
        this.txt_06.parent = this;
        this.txt_06.setTransform(286.75, 278.2);

        this.txt_05 = new cjs.Text("Aktoren", "12px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_05.name = "txt_05";
        this.txt_05.textAlign = "center";
        this.txt_05.lineHeight = 15;
        this.txt_05.lineWidth = 76;
        this.txt_05.parent = this;
        this.txt_05.setTransform(191.75, 278.2);

        this.txt_04 = new cjs.Text("Sensoren", "12px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_04.name = "txt_04";
        this.txt_04.textAlign = "center";
        this.txt_04.lineHeight = 15;
        this.txt_04.lineWidth = 76;
        this.txt_04.parent = this;
        this.txt_04.setTransform(95.75, 278.2);

        this.txt_03 = new cjs.Text("Smart Glass", "12px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_03.name = "txt_03";
        this.txt_03.textAlign = "center";
        this.txt_03.lineHeight = 15;
        this.txt_03.lineWidth = 76;
        this.txt_03.parent = this;
        this.txt_03.setTransform(289.25, 199.1);

        this.txt_01 = new cjs.Text("Smart Glass", "12px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_01.name = "txt_01";
        this.txt_01.textAlign = "center";
        this.txt_01.lineHeight = 15;
        this.txt_01.lineWidth = 76;
        this.txt_01.parent = this;
        this.txt_01.setTransform(95.75, 198.75);

        this.txt_02 = new cjs.Text("Induktives Laden\nSmartphones", "12px 'Brix Sans Regular'", "#FFFFFF");
        this.txt_02.name = "txt_02";
        this.txt_02.textAlign = "center";
        this.txt_02.lineHeight = 14;
        this.txt_02.lineWidth = 86;
        this.txt_02.parent = this;
        this.txt_02.setTransform(192.25, 197.75);

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgKAJIAJgTIAMAVg");
        this.shape.setTransform(195.4459, 262.4911, 2.3693, 2.3693);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgLAXQAMgHADgPQAEgNgHgNIAFgDQAJAPgEAQQgFARgOAJg");
        this.shape_1.setTransform(199.1184, 256.3308, 2.3693, 2.3693);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AACgKIAJATIgVACg");
        this.shape_2.setTransform(197.8152, 246.4981, 2.3693, 2.3693);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgcAJQAKgOAQgFQAQgEAPAJIgDAFQgNgHgNAEQgPADgHAMg");
        this.shape_3.setTransform(190.1741, 247.3273, 2.3693, 2.3693);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgMAJIAMgRIANARg");
        this.shape_4.setTransform(182.7107, 252.3029, 2.3693, 2.3693);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgIAJQgMgMAAgRIAGAAQAAAOALAKQAJALAPAAIAAAGQgRAAgMgMg");
        this.shape_5.setTransform(186.9163, 259.5294, 2.3693, 2.3693);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgQAGIAAgLIAhAAIAAALg");
        this.shape_6.setTransform(337.8432, -19.9167, 2.3693, 2.3693);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f().s("#FFFFFF").p("AArAAQAAARgNAOQgNAMgRAAQgRAAgNgMQgMgOAAgRQAAgRAMgNQANgMARAAQARAAANAMQANANAAARg");
        this.shape_7.setTransform(337.9024, -19.9167, 2.3693, 2.3693);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f().s("#FFFFFF").ss(0.5, 1, 0, 3.9).p("AAAhVIAACq");
        this.shape_8.setTransform(358.5414, -19.2247, 2.3687, 2.3687);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f().s("#FFFFFF").ss(0.5, 1, 0, 3.9).p("AAAhVIAACq");
        this.shape_9.setTransform(316.3781, -19.2247, 2.3687, 2.3687);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AgbALIAAgVIA4AAIAAAVg");
        this.shape_10.setTransform(358.5414, -51.2618, 2.3687, 2.3687);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AgcALIAAgVIA4AAIAAAVg");
        this.shape_11.setTransform(317.0887, -51.2618, 2.3687, 2.3687);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f().s("#FFFFFF").ss(1, 0, 0, 3.9).p("ACfANIk9AAIAAgZIE9AAg");
        this.shape_12.setTransform(337.3413, -45.2215, 2.3687, 2.3687);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f().s("#FFFFFF").ss(1, 0, 0, 3.9).p("ACfANIk9AAIAAgZIE9AAg");
        this.shape_13.setTransform(337.3413, 6.0615, 2.3687, 2.3687);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AiGBhIAAjBIENAAIAADBgAhyBNIDlAAIAAiZIjlAAg");
        this.shape_14.setTransform(337.3413, -19.9353, 2.3687, 2.3687);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f().s("#FFFFFF").ss(1, 0, 0, 3.9).p("AAYAjIgvAAIAAhFIAvAAg");
        this.shape_15.setTransform(36.1767, -1.6422, 2.3699, 2.3699);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f().s("#FFFFFF").ss(1, 0, 0, 3.9).p("AATAYIglAAIAAgvIAlAAg");
        this.shape_16.setTransform(49.3892, -22.6163, 2.3699, 2.3699);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f().s("#FFFFFF").ss(1, 0, 0, 3.9).p("AATAYIglAAIAAgvIAlAAg");
        this.shape_17.setTransform(23.616, -22.6163, 2.3699, 2.3699);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f().s("#FFFFFF").ss(1, 0, 0, 3.9).p("AB5AyIh4hhIh5Bh");
        this.shape_18.setTransform(36.4776, -45.0152, 2.3699, 2.3699);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#FFFFFF").s().p("AiGCkIAAiqIgYAAIAAgaICfiDICeCDIAAAaIgYAAIAACqgAiFgaIATAAIAACqIDlAAIAAiqIATAAIiFhvg");
        this.shape_19.setTransform(36.4729, -29.4299, 2.3699, 2.3699);

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#DD0061").s().p("Ag2gmIAigJIAPA0IAygNIAKAhIhWAXg");
        this.shape_20.setTransform(80.8, -5.45);

        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#DD0061").s().p("AghBnIAshSIhSAAIBPiNIAeARIgwBaIBSAAIhKCGg");
        this.shape_21.setTransform(81.625, -15.325);

        this.shape_22 = new cjs.Shape();
        this.shape_22.graphics.f().s("#FFFFFF").ss(0.5, 1, 0, 3.9).p("AhdBeIC7i7");
        this.shape_22.setTransform(204.6248, 27.132, 2.37, 2.37);

        this.shape_23 = new cjs.Shape();
        this.shape_23.graphics.f().s("#FFFFFF").ss(0.5, 1, 0, 3.9).p("Ag5A6IBzhz");
        this.shape_23.setTransform(199.1738, 20.496, 2.37, 2.37);

        this.shape_24 = new cjs.Shape();
        this.shape_24.graphics.f().s("#FFFFFF").ss(0.5, 1, 0, 3.9).p("Ag8A9IB5h5");
        this.shape_24.setTransform(171.0301, -34.8434, 2.37, 2.37);

        this.shape_25 = new cjs.Shape();
        this.shape_25.graphics.f().s("#FFFFFF").ss(0.5, 1, 0, 3.9).p("AglAmIBLhL");
        this.shape_25.setTransform(165.5199, -41.1239, 2.37, 2.37);

        this.shape_26 = new cjs.Shape();
        this.shape_26.graphics.f().s("#FFFFFF").ss(2, 1, 0, 3.9).p("AAAgmIAABN");
        this.shape_26.setTransform(141.0497, -0.8932, 2.37, 2.37);

        this.shape_27 = new cjs.Shape();
        this.shape_27.graphics.f().s("#FFFFFF").ss(1, 0, 0, 3.9).p("AC9FkIl5AAIAArHIF5AAg");
        this.shape_27.setTransform(190.8196, -1.071, 2.37, 2.37);

        this.shape_28 = new cjs.Shape();
        this.shape_28.graphics.f().s("#FFFFFF").ss(2, 0, 0, 3.9).p("ADsGRInXAAIAAshIHXAAg");
        this.shape_28.setTransform(190.8196, -0.8932, 2.37, 2.37);

        this.shape_29 = new cjs.Shape();
        this.shape_29.graphics.f("#FFFFFF").s().p("AgGADQgEgDAAgFIAHAAQAAAFADAAQAEAAAAgFIAHAAQAAAFgDADQgDACgFAAQgDAAgDgCg");
        this.shape_29.setTransform(287.8501, 265.512, 2.3693, 2.3693);

        this.shape_30 = new cjs.Shape();
        this.shape_30.graphics.f("#FFFFFF").s().p("AgMADQgBAAgBAAQAAAAgBgBQAAAAAAgBQgBAAAAgBQAAAAABgBQAAAAAAAAQABgBAAAAQABAAABAAIAZAAQABAAABAAQAAAAABABQAAAAAAAAQABABAAAAQAAABgBAAQAAABAAAAQgBABAAAAQgBAAgBAAg");
        this.shape_30.setTransform(287.9094, 262.6096, 2.3693, 2.3693);

        this.shape_31 = new cjs.Shape();
        this.shape_31.graphics.f("#FFFFFF").s().p("AgMADQgBAAgBAAQAAAAgBAAQAAgBAAAAQgBgBAAgBQAAAAABAAQAAgBAAAAQABgBAAAAQABAAABAAIAZAAQABAAABAAQAAAAABABQAAAAAAABQABAAAAAAQAAABgBABQAAAAAAABQgBAAAAAAQgBAAgBAAg");
        this.shape_31.setTransform(287.9094, 260.3587, 2.3693, 2.3693);

        this.shape_32 = new cjs.Shape();
        this.shape_32.graphics.f("#FFFFFF").s().p("AALAjQAAgJAHgIQANgLgFgPQgFgQgRgDQgLgDgKAJQgKAJAAAMQAAALAJAHQAHAIAAAJIgHAAQAAgHgFgEQgKgKAAgOQAAgQALgKQAMgKAPACQAKACAHAGQAIAHACAJQAGATgPAPQgFAEAAAHg");
        this.shape_32.setTransform(287.9108, 250.4667, 2.3693, 2.3693);

        this.shape_33 = new cjs.Shape();
        this.shape_33.graphics.f("#FFFFFF").s().p("AAbAyIAAgHIAQAAIAAgxIgrgjIgqAjIAAAxIARAAIAAAHIgYAAIAAg7IAxgoIAyAoIAAA7gAANAyIAAgKIgZAAIAAAKIgHAAIAAgQIAKAAIAAgOQAAgEADgDQADgDADAAQAEAAADADQADADAAAEIAAAOIAKAAIAAAQgAgCAUIAAAOIAFAAIAAgOQAAgBAAgBQAAAAAAgBQgBAAAAAAQgBAAgBAAQAAAAAAAAQgBAAAAAAQgBABAAAAQAAABAAABgAARAVIAAgDIANAAIAAADgAgdAVIAAgDIAOAAIAAADgAALAHIAJgIIACABIgJAJgAgVAAIACgBIAJAIIgCACgAgBADIAAgMIADAAIAAAMg");
        this.shape_33.setTransform(96.1383, 254.5923, 2.37, 2.37);

        this.shape_34 = new cjs.Shape();
        this.shape_34.graphics.f("#34A58C").s().p("AhPBQIAAifICfAAIAACfg");
        this.shape_34.setTransform(287.6932, 254.5923, 2.37, 2.37);

        this.shape_35 = new cjs.Shape();
        this.shape_35.graphics.f("#34A58C").s().p("AhPBQIAAifICfAAIAACfg");
        this.shape_35.setTransform(191.8861, 254.5923, 2.37, 2.37);

        this.shape_36 = new cjs.Shape();
        this.shape_36.graphics.f("#34A58C").s().p("AhPBQIAAifICfAAIAACfg");
        this.shape_36.setTransform(96.1383, 254.5923, 2.37, 2.37);

        this.shape_37 = new cjs.Shape();
        this.shape_37.graphics.f("#FFFFFF").s().p("AgqArIAAhBIBBAAIAABBgAgjAkIA0AAIAAg0Ig0AAgAgHAcIAMgNIADADIgMANgAgbAZIAgggIADACIggAggAAkAXIAAg7Ig3AAIAAAHIgGAAIAAgNIBEAAIAABBgAgaACIANgMIACACIgNAMg");
        this.shape_37.setTransform(95.3681, 174.4272, 2.37, 2.37);

        this.shape_38 = new cjs.Shape();
        this.shape_38.graphics.f("#FFFFFF").s().p("AgWA0QgEAAgDgDQgDgCAAgEIAAhHQAAgEADgDQADgDAEAAIAKAAIAAgHQAAgGAGAAIAMAAQAHAAAAAGIAAAHIAKAAQAEAAADADQADADAAAEIAABHQAAAEgDACQgDADgEAAgAgZgcIAABHQAAAAAAABQAAABABAAQAAAAABABQAAAAABAAIAtAAQABAAAAAAQABgBAAAAQABAAAAgBQAAgBAAAAIAAhHQAAgBAAgBQAAAAgBgBQAAAAgBgBQAAAAgBAAIgtAAQgBAAAAAAQgBABAAAAQgBABAAAAQAAABAAABgAgGgmIAMAAIAAgHIgMAAgAgTAdIAAgGIAnAAIAAAGgAgTAKIAAgHIAnAAIAAAHgAgTgJIAAgGIAnAAIAAAGg");
        this.shape_38.setTransform(191.8269, 174.9012, 2.37, 2.37);

        this.shape_39 = new cjs.Shape();
        this.shape_39.graphics.f("#FFFFFF").s().p("AgXAYQgKgKAAgOQAAgNAKgKQAKgKANAAQAOAAAKAKQAKAKAAANQAAAOgKAKQgKAKgOAAQgNAAgKgKgAgSgTQgJAJAAAKQAAALAJAIQAHAIALAAQALAAAIgIQAIgIAAgLQAAgKgIgJQgIgHgLgBQgKABgIAHg");
        this.shape_39.setTransform(287.9894, 180.8854, 2.37, 2.37);

        this.shape_40 = new cjs.Shape();
        this.shape_40.graphics.f("#FFFFFF").s().p("AAfAwIAAgHIAKAAIAAgvIgpggIgoAgIAAAvIAKAAIAAAHIgRAAIAAg5IAvgmIAwAmIAAA5g");
        this.shape_40.setTransform(287.9302, 172.2942, 2.37, 2.37);

        this.shape_41 = new cjs.Shape();
        this.shape_41.graphics.f("#FFFFFF").s().p("AgCgEIAGAHIgIABg");
        this.shape_41.setTransform(290.5589, 183.3418, 2.3687, 2.3687);

        this.shape_42 = new cjs.Shape();
        this.shape_42.graphics.f("#FFFFFF").s().p("AgEAJQAFgDABgFQACgFgDgFIACgCQADAHgBAFQgCAHgFAEg");
        this.shape_42.setTransform(290.7899, 181.3876, 2.3687, 2.3687);

        this.shape_43 = new cjs.Shape();
        this.shape_43.graphics.f("#FFFFFF").s().p("AAAgEIAFAGIgIADg");
        this.shape_43.setTransform(289.3746, 177.0647, 2.3687, 2.3687);

        this.shape_44 = new cjs.Shape();
        this.shape_44.graphics.f("#FFFFFF").s().p("AgLADQAEgEAGgDQAHgBAGADIgCACQgEgDgGACQgGABgDAFg");
        this.shape_44.setTransform(287.2427, 177.7812, 2.3687, 2.3687);

        this.shape_45 = new cjs.Shape();
        this.shape_45.graphics.f("#FFFFFF").s().p("AAAgDIAFAFIgJACg");
        this.shape_45.setTransform(284.341, 181.1508, 2.3687, 2.3687);

        this.shape_46 = new cjs.Shape();
        this.shape_46.graphics.f("#FFFFFF").s().p("AAIAJQgHAAgEgFQgFgEAAgIIAAAAIAEAAIAAAAQAAAHADACQAEAFAFAAIABAAIAAADgAgCAEQADAEAHAAIAAgBQgGgBgDgDQgFgEAAgGIgCAAQABAHAFAEg");
        this.shape_46.setTransform(285.9399, 182.6312, 2.3687, 2.3687);

        this.shape_47 = new cjs.Shape();
        this.shape_47.graphics.f("#FFFFFF").s().p("AgCAEQgGgFAAgHIADAAQAAAHAEADQAEAEAFAAIAAACQgGAAgEgEg");
        this.shape_47.setTransform(285.9399, 182.6312, 2.3687, 2.3687);

        this.shape_48 = new cjs.Shape();
        this.shape_48.graphics.f("#34A58C").s().p("AhPBQIAAifICfAAIAACfg");
        this.shape_48.setTransform(287.6932, 174.9604, 2.37, 2.37);

        this.shape_49 = new cjs.Shape();
        this.shape_49.graphics.f("#34A58C").s().p("AhPBQIAAifICfAAIAACfg");
        this.shape_49.setTransform(191.8861, 174.9604, 2.37, 2.37);

        this.shape_50 = new cjs.Shape();
        this.shape_50.graphics.f("#34A58C").s().p("AhPBQIAAifICfAAIAACfg");
        this.shape_50.setTransform(96.1383, 174.9604, 2.37, 2.37);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_50 }, { t: this.shape_49 }, { t: this.shape_48 }, { t: this.shape_47 }, { t: this.shape_46 }, { t: this.shape_45 }, { t: this.shape_44 }, { t: this.shape_43 }, { t: this.shape_42 }, { t: this.shape_41 }, { t: this.shape_40 }, { t: this.shape_39 }, { t: this.shape_38 }, { t: this.shape_37 }, { t: this.shape_36 }, { t: this.shape_35 }, { t: this.shape_34 }, { t: this.shape_33 }, { t: this.shape_32 }, { t: this.shape_31 }, { t: this.shape_30 }, { t: this.shape_29 }, { t: this.shape_28 }, { t: this.shape_27 }, { t: this.shape_26 }, { t: this.shape_25 }, { t: this.shape_24 }, { t: this.shape_23 }, { t: this.shape_22 }, { t: this.shape_21 }, { t: this.shape_20 }, { t: this.shape_19 }, { t: this.shape_18 }, { t: this.shape_17 }, { t: this.shape_16 }, { t: this.shape_15 }, { t: this.shape_14 }, { t: this.shape_13 }, { t: this.shape_12 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }, { t: this.txt_02 }, { t: this.txt_01 }, { t: this.txt_03 }, { t: this.txt_04 }, { t: this.txt_05 }, { t: this.txt_06 }] }).to({ state: [] }, 2).wait(1));

        // nacht_1_mc
        this.nacht_1_mc = new lib.nacht1_mc();
        this.nacht_1_mc.name = "nacht_1_mc";
        this.nacht_1_mc.setTransform(224.55, 148.95, 1, 1, 0, 0, 0, 204.8, 161.1);

        this.timeline.addTween(cjs.Tween.get(this.nacht_1_mc).to({ _off: true }, 2).wait(1));

        // wolken_mc
        this.wolken_mc = new lib.wolken_mc();
        this.wolken_mc.name = "wolken_mc";
        this.wolken_mc.setTransform(224.55, 148.95, 1, 1, 0, 0, 0, 204.8, 161.1);

        this.timeline.addTween(cjs.Tween.get(this.wolken_mc).to({ _off: true }, 2).wait(1));

        // tag_2
        this.tag_2_mc = new lib.tag_2_mc();
        this.tag_2_mc.name = "tag_2_mc";
        this.tag_2_mc.setTransform(224.55, 148.95, 1, 1, 0, 0, 0, 204.8, 161.1);

        this.timeline.addTween(cjs.Tween.get(this.tag_2_mc).to({ _off: true }, 2).wait(1));

        // tag_1
        this.tag_1_mc = new lib.tag_1_mc();
        this.tag_1_mc.name = "tag_1_mc";
        this.tag_1_mc.setTransform(224.55, 148.95, 1, 1, 0, 0, 0, 204.8, 161.1);

        this.timeline.addTween(cjs.Tween.get(this.tag_1_mc).to({ _off: true }, 2).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-231.2, -203.4, 755.5, 650.3);


    (lib.main_animation = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        this.isSingleFrame = false;
        // timeline functions:
        this.frame_0 = function () {
            if (this.isLoaded) {
                return
            }
            if (this.isSingleFrame) {
                return;
            }
            if (this.totalFrames == 1) {
                this.isSingleFrame = true;
            }
            this.goHome = function () {
                this.sub_ani_01_mc.goBack();
            }
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

        // subAnimation_mc
        this.sub_ani_01_mc = new lib.sub_ani_01_mc();
        this.sub_ani_01_mc.name = "sub_ani_01_mc";
        this.sub_ani_01_mc.setTransform(177.65, 324.8, 1, 1, 0, 0, 0, 114.4, 160.6);

        this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(-168, -39.2, 755.5, 650.3000000000001), null);

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}