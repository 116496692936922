/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { setLamellenState } from '../../../actions/window/windowHelper'
import { getMCSymbolPrototype } from './../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p


    (lib.txt_mc_04 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.text = new cjs.Text("Willkommen", "31px 'Brix Sans Regular'", "#FFFFFF");
        this.text.lineHeight = 39;
        this.text.lineWidth = 340;
        this.text.parent = this;
        this.text.setTransform(-76.95, -206);

        this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.txt_mc_04, new cjs.Rectangle(-78.9, -208, 344, 77), null);


    (lib.txt_mc_01 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.txt_01 = new cjs.Text("Future Window Vision", "bold 37px 'Brix Sans Black'", "#FFFFFF");
        this.txt_01.name = "txt_01";
        this.txt_01.lineHeight = 46;
        this.txt_01.lineWidth = 379;
        this.txt_01.parent = this;
        this.txt_01.setTransform(-68, 2);

        this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.txt_mc_01, new cjs.Rectangle(-70, 0, 383, 56.1), null);


    (lib.btn_innovativ_icon_w = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("Ah2B3IAAiUICUAAIAACUgAhoBoIB3AAIAAh3Ih3AAgAgnBXIAegeIAFAFIgeAegAhUBPIBLhKIAFAFIhLBKgAA8BKIAAiFIh+AAIAAAPIgPAAIAAgdICbAAIAACTgAhUAbIAegdIAFAFIgeAdgABoAeIAAiGIh+AAIAAAQIgOAAIAAgeICbAAIAACUg");
        this.shape.setTransform(29.8497, 29.8497, 2.5031, 2.5031);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_innovativ_icon_w, new cjs.Rectangle(0, 0, 59.7, 59.7), null);


    (lib.btn_icon_windowid_icon_w = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgkAsIAAhXIAeAAQAqAAAAArQABAXgLAKQgLALgVAAgAgPAcIAHAAQANAAAFgGQAFgGAAgQQAAgOgFgGQgFgHgNAAIgHAAg");
        this.shape.setTransform(26.8855, 33.6132, 2.5017, 2.5025);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgJAsIAAhXIATAAIAABXg");
        this.shape_1.setTransform(11.3748, 33.6132, 2.5017, 2.5025);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AhXBaIAAizICvgsIAAELgAhIhOIAACdICRAlIAAjng");
        this.shape_2.setTransform(21.8929, 33.6261, 2.5023, 2.5032);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgWBdIAAgOIAfAAIAAidIgfAAIAAgPIAuAAIAAC6g");
        this.shape_3.setTransform(47.7917, 34.4396, 2.5023, 2.5032);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_icon_w, new cjs.Rectangle(0, 0, 53.7, 67.3), null);


    (lib.btn_icon_windowid_icon = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#1D1D1C").s().p("AgjAsIAAhXIAcAAQArAAAAArQAAAXgKAKQgLALgWAAgAgPAbIAHAAQANAAAFgGQAGgGAAgPQAAgOgGgGQgFgHgNAAIgHAAg");
        this.shape.setTransform(26.7974, 33.5478, 2.4993, 2.4993);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#1D1D1C").s().p("AgJAsIAAhXIATAAIAABXg");
        this.shape_1.setTransform(11.3644, 33.5478, 2.4993, 2.4993);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#1D1D1C").s().p("AhWBaIAAizICtgsIAAELgAhIhOIAACdICRAlIAAjng");
        this.shape_2.setTransform(21.8045, 33.5539, 2.4996, 2.4996);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#1D1D1C").s().p("AgXBdIAAgOIAgAAIAAidIggAAIAAgPIAuAAIAAC6g");
        this.shape_3.setTransform(47.7376, 34.3663, 2.4996, 2.4996);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_icon, new cjs.Rectangle(0, 0, 53.6, 67.1), null);


    (lib.btn_icon_windowid_hl = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#56A28D").s().p("AgaBbIAAi1IA1AAIAAC1g");
        this.shape.setTransform(6.725, 22.6875, 2.5, 2.5);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid_hl, new cjs.Rectangle(0, 0, 13.5, 45.4), null);


    (lib.btn_icon_smart_icon_w = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("ABFCBIAAgRIArAAIAAiBIhwhZIhvBZIAACBIArAAIAAARIg8AAIAAiaICAhnICBBnIAACagAAiCBIAAgaIhDAAIAAAaIgSAAIAAgrIAaAAIAAgiQAAgLAIgIQAHgHAKAAQALAAAHAHQAIAIAAALIAAAiIAZAAIAAArgAgFAtQgDADAAAEIAAAiIAQAAIABgiQgBgJgIAAQgDAAgCACgAArA4IAAgJIAiAAIAAAJgAhMA4IAAgJIAiAAIAAAJgAAbASIAZgXIAGAFIgZAYgAg5AAIAGgFIAZAXIgGAGgAgDAJIAAgiIAHAAIAAAig");
        this.shape.setTransform(32.1625, 32.1746, 2.5, 2.5006);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_icon_w, new cjs.Rectangle(0, 0, 64.4, 64.4), null);


    (lib.btn_icon_smart_icon = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#1D1D1C").s().p("ABFCBIAAgSIArAAIAAiAIhwhZIhvBZIAACAIArAAIAAASIg8AAIAAiaICAhnICBBnIAACagAAiCBIAAgaIhDAAIAAAaIgSAAIAAgrIAaAAIAAgiQAAgLAIgIQAHgHAKAAQALAAAHAHQAIAIAAALIAAAiIAZAAIAAArgAgFAtQgDADAAAEIAAAiIARAAIAAgiQAAgEgDgDQgDgCgDAAQgDAAgCACgAArA4IAAgJIAiAAIAAAJgAhMA4IAAgJIAiAAIAAAJgAAbASIAZgXIAGAFIgZAYgAg5AAIAGgFIAZAXIgGAGgAgDAIIAAghIAHAAIAAAhg");
        this.shape.setTransform(32.2485, 32.2977, 2.5031, 2.5037);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_icon, new cjs.Rectangle(0, 0, 64.5, 64.6), null);


    (lib.btn_icon_smart_hl = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#CB2C61").s().p("Ag8A9IAAgzIAeAAIAAgnQABgNAIgJQAJgJAMAAQAMAAAJAJQAKAJgBANIAAAnIAfAAIAAAzg");
        this.shape.setTransform(15.2665, 15.2544, 2.5031, 2.5037);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_hl, new cjs.Rectangle(0, 0, 30.6, 30.6), null);


    (lib.btn_icon_regenerative_icon_w = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgbgTIARgEIAIAZIAagGIAEARIgsALg");
        this.shape.setTransform(31.9901, 43.4877, 2.4995, 2.4995);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgQA1IAWgqIgqAAIAohIIAQAJIgYAuIApAAIglBEg");
        this.shape_1.setTransform(33.0524, 30.7403, 2.4995, 2.4995);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AhGBrIAJgOQAeAUAjAAQAbAAAZgMQAZgMAQgYQAbgmgIgtQgIgvgngbQgegVgjAAQgaAAgZAMQgZAOgQAWQgRAZgDAdQgDAdAMAcIAZgMIgJA/IgygjIATgJQgOggAEgiQADghAUgcQASgbAdgOQAcgOAeAAQApAAAiAYQAsAeAJA1QAJA1gfArQgTAbgcAPQgcAOgfAAQgpAAghgXg");
        this.shape_2.setTransform(33.5656, 32.5, 2.5, 2.5);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_icon_w, new cjs.Rectangle(0, 0, 67.2, 65), null);


    (lib.btn_icon_regenerative_icon = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#020203").s().p("AgbgTIARgEIAIAZIAagGIAEAQIgrAMg");
        this.shape.setTransform(31.8356, 43.5515, 2.4993, 2.5001);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#020203").s().p("AgRA1IAWgpIgpAAIAohJIAQAJIgZAuIAqAAIgmBEg");
        this.shape_1.setTransform(33.0228, 30.7387, 2.4993, 2.5001);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#020203").s().p("AhGBqIAJgNQAnAbAtgIQAugIAcgnQAbgmgIgtQgJgvgmgbQgmgbguAIQguAIgcAnQgRAYgDAdQgDAdAMAcIAagMIgKA/IgxgjIATgJQgOggADgiQAEghATgcQAfgsA1gJQA0gJAsAfQAsAeAKA2QAJA0gfAsQgfAsg1AJQgMACgLAAQgnAAgigYg");
        this.shape_2.setTransform(33.4781, 32.5368, 2.5, 2.5006);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_icon, new cjs.Rectangle(0, 0, 67, 65.1), null);


    (lib.btn_icon_regenerative_hl = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#56A28D").s().p("AgggWIAVgGIAIAfIAegIIAGAUIgzAOg");
        this.shape.setTransform(9.4843, 33.039, 2.4993, 2.5001);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#56A28D").s().p("AgUA+IAagxIgwAAIAuhUIATAKIgdA2IAxAAIgsBPg");
        this.shape_1.setTransform(10.7964, 18.1011, 2.4993, 2.5001);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_hl, new cjs.Rectangle(0, 0, 21.6, 40.3), null);


    (lib.btn_icon_innovativ_icon = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#020203").s().p("Ah2B3IAAiUICUAAIAACUgAhoBoIB3AAIAAh3Ih3AAgAgnBWIAegdIAFAFIgeAegAhUBPIBLhLIAFAGIhLBKgAA8BKIAAiGIh+AAIAAARIgPAAIAAgfICbAAIAACUgAhUAbIAegdIAFAFIgeAdgABoAdIAAiFIh+AAIAAAQIgOAAIAAgeICbAAIAACTg");
        this.shape.setTransform(29.8497, 29.7745, 2.5031, 2.5037);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_icon, new cjs.Rectangle(0, 0, 59.7, 59.6), null);


    (lib.btn_icon_innovativ_hl = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#CB2C61").s().p("AhGBHIAAiNICNAAIAACNg");
        this.shape.setTransform(17.7183, 17.7109, 2.5031, 2.5037);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_hl, new cjs.Rectangle(0, 0, 35.5, 35.4), null);


    (lib.btn_icon_climate_icon_w = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("ABCFKIBHicIAmBBQBig6AehuQAehtg6hjIAsgZQBDB1giCBQgjCDh1BDIAlBBgAjBDmQhfhfAAiHIhLAAIBkiNIBkCNIhLAAQAAByBSBQQBQBRBxAAIAAAyQiFAAhhhfgAhTCDIAAkSIDhAAIAAESgAAqBqIBLAAIAAh8IhLAAgAg6BqIBKAAIAAh8IhKAAgAAqgrIBLAAIAAhLIhLAAgAg6grIBKAAIAAhLIhKAAgAjwiiQBCh0CEgjQCBgjB0BDIgDAGIAohHIBICeIisAQIAmhBQhjg5htAeQhuAcg6Bjg");
        this.shape.setTransform(36.3501, 34.675);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_climate_icon_w, new cjs.Rectangle(0, 0, 72.7, 69.4), null);


    (lib.btn_icon_climate_icon = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#1D1D1C").s().p("AgEAyIAAhjIAJAAIAABjg");
        this.shape.setTransform(39.2721, 34.0364, 2.5023, 2.5032);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#1D1D1C").s().p("AgsA3IAAhtIBZAAIAABtgAgiAtIBFAAIAAhZIhFAAg");
        this.shape_1.setTransform(39.2721, 34.0364, 2.5023, 2.5032);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#1D1D1C").s().p("AgnAFIAAgJIBPAAIAAAJg");
        this.shape_2.setTransform(39.2721, 31.5332, 2.5023, 2.5032);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#1D1D1C").s().p("AghAcIAcg+IAnBFg");
        this.shape_3.setTransform(51.4709, 60.6952, 2.5023, 2.5032);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#1D1D1C").s().p("AglBIQAngXAMgsQAMgrgXgoIARgKQAbAvgOAzQgOA0guAbg");
        this.shape_4.setTransform(63.2387, 40.6072, 2.5023, 2.5032);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#1D1D1C").s().p("AAGgiIAcA+IhDAHg");
        this.shape_5.setTransform(58.9778, 8.6918, 2.5023, 2.5032);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#1D1D1C").s().p("AhYAcQAbguA0gOQAzgOAvAbIgKASQgogXgrAMQgsALgXAng");
        this.shape_6.setTransform(34.4552, 11.4727, 2.5023, 2.5032);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#1D1D1C").s().p("AgnAcIAng3IAoA3g");
        this.shape_7.setTransform(9.9952, 27.5907, 2.5023, 2.5032);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#1D1D1C").s().p("AgaAbQgmglAAg2IAUAAQAAAuAgAfQAgAgAtAAIAAAUQg1AAgmgmg");
        this.shape_8.setTransform(23.7578, 50.9328, 2.5023, 2.5032);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_climate_icon, new cjs.Rectangle(0, 0, 72.7, 69.4), null);


    (lib.btn_icon_climate_hl = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#57A28D").s().p("AgvA0IAAhmIBgAAIAABmg");
        this.shape.setTransform(12.1458, 12.903, 2.5031, 2.5037);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_climate_hl, new cjs.Rectangle(0, 0, 24.3, 25.8), null);


    (lib.btn_frame = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AjHDIIAAmPIGPAAIAAGPgAizC0IFnAAIAAlnIlnAAg");
        this.shape.setTransform(49.9887, 50, 2.5026, 2.5);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_frame, new cjs.Rectangle(0, 0, 100, 100), null);


    (lib.btn_icon_windowid = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // icon_w
        this.icon_w = new lib.btn_icon_windowid_icon_w();
        this.icon_w.name = "icon_w";
        this.icon_w.setTransform(51.55, 49.95, 1, 1, 0, 0, 0, 26.9, 33.6);

        this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));

        // icon
        this.icon = new lib.btn_icon_windowid_icon();
        this.icon.name = "icon";
        this.icon.setTransform(51.55, 50, 1, 1, 0, 0, 0, 26.8, 33.5);

        this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));

        // hl
        this.hl = new lib.btn_icon_windowid_hl();
        this.hl.name = "hl";
        this.hl.setTransform(77.45, 55.7, 1, 1, 0, 0, 0, 6.8, 22.7);

        this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_windowid, new cjs.Rectangle(24.7, 16.4, 59.5, 67.19999999999999), null);


    (lib.btn_icon_smart_privacy = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // icon_w
        this.icon_w = new lib.btn_icon_smart_icon_w();
        this.icon_w.name = "icon_w";
        this.icon_w.setTransform(50, 49.5, 1, 1, 0, 0, 0, 32.1, 32.1);

        this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));

        // icon
        this.icon = new lib.btn_icon_smart_icon();
        this.icon.name = "icon";
        this.icon.setTransform(49.95, 49.7, 1, 1, 0, 0, 0, 32.2, 32.3);

        this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));

        // hl
        this.hl = new lib.btn_icon_smart_hl();
        this.hl.name = "hl";
        this.hl.setTransform(54.5, 71.3, 1, 1, 0, 0, 0, 15.2, 15.2);

        this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_smart_privacy, new cjs.Rectangle(17.8, 17.4, 64.5, 69.30000000000001), null);


    (lib.btn_icon_regenerative_energie = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // icon_w
        this.icon_w = new lib.btn_icon_regenerative_icon_w();
        this.icon_w.name = "icon_w";
        this.icon_w.setTransform(50.5, 50, 1, 1, 0, 0, 0, 33.5, 32.5);

        this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));

        // icon
        this.icon = new lib.btn_icon_regenerative_icon();
        this.icon.name = "icon";
        this.icon.setTransform(50.65, 49.95, 1, 1, 0, 0, 0, 33.5, 32.5);

        this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));

        // hl
        this.hl = new lib.btn_icon_regenerative_hl();
        this.hl.name = "hl";
        this.hl.setTransform(54.8, 48, 1, 1, 0, 0, 0, 10.8, 20.1);

        this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_regenerative_energie, new cjs.Rectangle(17, 17.5, 67.2, 65), null);


    (lib.btn_icon_lueften_heizen_kuehlen = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // icon_w
        this.icon_w = new lib.btn_icon_climate_icon_w();
        this.icon_w.name = "icon_w";
        this.icon_w.setTransform(48.3, 49, 1, 1, 0, 0, 0, 36.4, 34.6);

        this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));

        // icon
        this.icon = new lib.btn_icon_climate_icon();
        this.icon.name = "icon";
        this.icon.setTransform(48.3, 49, 1, 1, 0, 0, 0, 36.4, 34.6);

        this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));

        // hl
        this.hl = new lib.btn_icon_climate_hl();
        this.hl.name = "hl";
        this.hl.setTransform(57, 52.45, 1, 1, 0, 0, 0, 12.2, 12.9);

        this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_lueften_heizen_kuehlen, new cjs.Rectangle(11.9, 14.4, 72.69999999999999, 69.39999999999999), null);


    (lib.btn_icon_innovativ_icon_w = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.instance = new lib.btn_innovativ_icon_w();
        this.instance.setTransform(29.9, 29.9, 1, 1, 0, 0, 0, 29.9, 29.9);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_innovativ_icon_w, new cjs.Rectangle(0, 0, 59.7, 59.7), null);


    (lib.icon_05_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }
        this.frame_29 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));

        // Ebene_2
        this.icon_windowid = new lib.btn_icon_windowid();
        this.icon_windowid.name = "icon_windowid";
        this.icon_windowid.setTransform(56.5, 26.55, 1, 1, 0, 0, 0, 50, 50);

        this.frame = new lib.btn_frame();
        this.frame.name = "frame";
        this.frame.setTransform(56.5, 26.55, 1, 1, 0, 0, 0, 50, 50);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.frame }, { t: this.icon_windowid }] }).wait(30));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(6.5, -23.4, 100, 100);


    (lib.icon_03_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }
        this.frame_29 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));

        // Ebene_2
        this.icon_smart_privacy = new lib.btn_icon_smart_privacy();
        this.icon_smart_privacy.name = "icon_smart_privacy";
        this.icon_smart_privacy.setTransform(57.5, 27.55, 1, 1, 0, 0, 0, 50, 50.1);

        this.frame = new lib.btn_frame();
        this.frame.name = "frame";
        this.frame.setTransform(57.5, 27.55, 1, 1, 0, 0, 0, 50, 50);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.frame }, { t: this.icon_smart_privacy }] }).wait(30));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(7.5, -22.4, 100, 100);


    (lib.icon_02_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }
        this.frame_29 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));

        // Ebene_2
        this.icon_regenerative_energie = new lib.btn_icon_regenerative_energie();
        this.icon_regenerative_energie.name = "icon_regenerative_energie";
        this.icon_regenerative_energie.setTransform(0.5, 0.55, 1, 1, 0, 0, 0, 50, 50);

        this.frame = new lib.btn_frame();
        this.frame.name = "frame";
        this.frame.setTransform(0.5, 0.55, 1, 1, 0, 0, 0, 50, 50);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.frame }, { t: this.icon_regenerative_energie }] }).wait(30));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-49.5, -49.4, 100, 100);


    (lib.icon_01_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }
        this.frame_29 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));

        // Ebene_3
        this.icon_lueften_heizen_kuehlen = new lib.btn_icon_lueften_heizen_kuehlen();
        this.icon_lueften_heizen_kuehlen.name = "icon_lueften_heizen_kuehlen";
        this.icon_lueften_heizen_kuehlen.setTransform(55.5, 28.65, 1, 1, 0, 0, 0, 50, 50.1);

        this.frame = new lib.btn_frame();
        this.frame.name = "frame";
        this.frame.setTransform(55.5, 28.55, 1, 1, 0, 0, 0, 50, 50);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.frame }, { t: this.icon_lueften_heizen_kuehlen }] }).wait(30));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(5.5, -21.4, 100, 100);


    (lib.btn_icon_innovatives = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // icon_w
        this.icon_w = new lib.btn_icon_innovativ_icon_w();
        this.icon_w.name = "icon_w";
        this.icon_w.setTransform(50.05, 50.05, 1, 1, 0, 0, 0, 29.9, 29.9);

        this.timeline.addTween(cjs.Tween.get(this.icon_w).wait(1));

        // icon
        this.icon = new lib.btn_icon_innovativ_icon();
        this.icon.name = "icon";
        this.icon.setTransform(50.05, 50.1, 1, 1, 0, 0, 0, 29.9, 29.8);

        this.timeline.addTween(cjs.Tween.get(this.icon).wait(1));

        // hl
        this.hl = new lib.btn_icon_innovativ_hl();
        this.hl.name = "hl";
        this.hl.setTransform(44.85, 66.15, 1, 1, 0, 0, 0, 17.7, 17.7);

        this.timeline.addTween(cjs.Tween.get(this.hl).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.btn_icon_innovatives, new cjs.Rectangle(20.2, 20.2, 59.7, 63.7), null);


    (lib.icon_04_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }
        this.frame_29 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));

        // Ebene_2
        this.icon_innovatives = new lib.btn_icon_innovatives();
        this.icon_innovatives.name = "icon_innovatives";
        this.icon_innovatives.setTransform(56.5, 26.65, 1, 1, 0, 0, 0, 50, 50.1);

        this.frame = new lib.btn_frame();
        this.frame.name = "frame";
        this.frame.setTransform(56.5, 26.55, 1, 1, 0, 0, 0, 50, 50);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.frame }, { t: this.icon_innovatives }] }).wait(30));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(6.5, -23.4, 100, 100);


    (lib.sub_ani_01_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
            console.log('#### Screensaver')
        }
        this.frame_2 = function () {
            // console.log('#### Screensaver 2', this.parent.visible)
            if (this.parent.visible) {
                setLamellenState(this.parent.parent, 'left-logo-top')
                setLamellenState(this.parent.parent, 'full', 1)
                setLamellenState(this.parent.parent, 'full', 2)
            }
        }
        this.frame_227 = function () {
            // console.log('#### Screensaver 3', this.parent.visible)
            if (this.parent.visible) {
                setLamellenState(this.parent.parent, 'left-logoinvert')
            }
        }
        this.frame_346 = function () {
            // console.log('#### Screensaver 4', this.parent.visible)
            if (this.parent.visible) {
                setLamellenState(this.parent.parent, 'top-bottom', 1)
            }
        }
        this.frame_696 = function () {
            // console.log('#### Screensaver 5', this.parent.visible)
            if (this.parent.visible) {
                setLamellenState(this.parent.parent, 'top-3/4-invert', 2)
            }
        }
        this.frame_930 = function () {
            this.gotoAndPlay(1);
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2).call(this.frame_2).wait(226).call(this.frame_227).wait(119).call(this.frame_346).wait(350).call(this.frame_696).wait(234).call(this.frame_930).wait(153));

        // Ebene_8
        this.instance = new lib.icon_05_mc();
        this.instance.setTransform(189.95, 467.9, 1, 1, 0, 0, 0, 56.5, 26.6);
        this.instance.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(696).to({ alpha: 1 }, 30).wait(30).to({ alpha: 0 }, 30).wait(21).to({ alpha: 1 }, 28).wait(34).to({ alpha: 0 }, 33).to({ _off: true }, 28).wait(153));

        // Ebene_7
        this.instance_1 = new lib.icon_04_mc();
        this.instance_1.setTransform(318.5, 299.95, 1, 1, 0, 0, 0, 56.5, 26.6);
        this.instance_1.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(586).to({ alpha: 1 }, 25).wait(25).to({ alpha: 0 }, 30).wait(141).to({ alpha: 1 }, 28).wait(34).to({ alpha: 0 }, 33).to({ _off: true }, 28).wait(153));

        // Ebene_6
        this.instance_2 = new lib.icon_03_mc();
        this.instance_2.setTransform(189.95, 299.95, 1, 1, 0, 0, 0, 57.5, 27.6);
        this.instance_2.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(466).to({ alpha: 1 }, 30).wait(30).to({ alpha: 0 }, 30).wait(251).to({ alpha: 1 }, 28).wait(34).to({ alpha: 0 }, 33).to({ _off: true }, 28).wait(153));

        // Ebene_5
        this.instance_3 = new lib.icon_02_mc();
        this.instance_3.setTransform(57.05, 299.95, 1, 1, 0, 0, 0, 0.5, 0.6);
        this.instance_3.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(346).to({ alpha: 1 }, 30).wait(30).to({ alpha: 0 }, 30).wait(371).to({ alpha: 1 }, 28).wait(34).to({ alpha: 0 }, 33).to({ _off: true }, 28).wait(153));

        // Ebene_4
        this.instance_4 = new lib.icon_01_mc();
        this.instance_4.setTransform(191.05, 134.05, 1, 1, 0, 0, 0, 55.5, 28.6);
        this.instance_4.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(227).to({ alpha: 1 }, 29).wait(30).to({ alpha: 0.8281 }, 0).to({ alpha: 0 }, 30).wait(491).to({ alpha: 1 }, 28).wait(34).to({ alpha: 0 }, 33).to({ _off: true }, 28).wait(153));

        // txt_04
        this.txt_mc_04 = new lib.txt_mc_04();
        this.txt_mc_04.name = "txt_mc_04";
        this.txt_mc_04.setTransform(269.8, 305.7, 1, 1, 0, 0, 0, 145.8, 21.8);
        this.txt_mc_04.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.txt_mc_04).wait(53).to({ y: 313.8 }, 0).to({ x: 231.8, alpha: 1 }, 57, cjs.Ease.cubicOut).to({ alpha: 0.9414 }, 31).to({ alpha: 0 }, 60).to({ _off: true }, 732).wait(150));

        // txt_01
        this.txt_mc_01 = new lib.txt_mc_01();
        this.txt_mc_01.name = "txt_mc_01";
        this.txt_mc_01.setTransform(269.8, 26.05, 1, 1, 0, 0, 0, 145.8, 19.8);
        this.txt_mc_01.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.txt_mc_01).wait(23).to({ x: 219.8, alpha: 1 }, 57, cjs.Ease.cubicOut).wait(30).to({ alpha: 0.9414 }, 31).to({ alpha: 0 }, 60).wait(605).to({ x: 269.8 }, 0).wait(129).to({ x: 219.8, alpha: 1 }, 57, cjs.Ease.cubicOut).wait(30).to({ alpha: 0 }, 34).wait(27));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-408.3, -37.7, 1637, 768.5);


    (lib.main_animation = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // subAnimation_mc
        this.sub_ani_01_mc = new lib.sub_ani_01_mc();
        this.sub_ani_01_mc.name = "sub_ani_01_mc";
        this.sub_ani_01_mc.setTransform(177.65, 224.8, 1, 1, 0, 0, 0, 114.4, 160.6);

        this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(70.3, 70.5, 430, 511.6), null);


    return lib
}
