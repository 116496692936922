import * as createjs from 'createjs-module'

import { uiSound } from './../audio/audioPlayer'
import { getContent } from '../../config/getContent'


export const showLevel = (root, menu = null) => {

    const content = getContent((menu) ? menu.id : '')
    // console.log('showLevel', menu, content)

    // Unterpunkte
    content.forEach((menuObj, key) => {
        let btn = root['btn_' + menuObj.id]
        if (btn) {
            // key + 2 = verzögertes einblenden
            buttonAnimation(root, menuObj, key + 1, 'show', (menu) ? 'off' : 'on')
        }
    })

    // Hauptpunkt mittig
    if (menu) {
        buttonCenterAnimation(root, menu, 'show')
    }

    // Sound
    uiSound(root, 'fadein')
}

// Buttons ausblenden
export const hideLevel = (root, menu = null, btnMenu = null) => {
    const content = getContent((menu) ? menu.id : '')

    // console.log('hideLevel', menu, content)

    content.forEach((menuObj, key) => {

        let btn = root['btn_' + menuObj.id]

        if (btnMenu) {
            // console.log(btnMenu.id, menuObj.id)
        }

        if ((btn && !btnMenu) || (btn && btnMenu && btnMenu.id !== menuObj.id)) {
            buttonAnimation(root, menuObj, key / 4, 'hide')
        }
    })
}

// Button Animationen
export const buttonAnimation = (root, menu, key = 0, dir = 'show', state = 'off') => {

    let mcPos = root.mcPos
    let btnName = 'btn_' + menu.id
    let iconName = (menu.btn.icon ?? '')

    let btn = root['btn_' + menu.id]

    let mc_bg_white = btnName + '.bg_white'
    let mc_frame = btnName + '.frame'
    let mc_label = btnName + '.label'
    let mc_label_l2 = btnName + '.label.l2'
    let mc_icon_hl = btnName + '.' + iconName + '.hl'
    let mc_icon = btnName + '.' + iconName + '.icon'
    let mc_icon_w = btnName + '.' + iconName + '.icon_w'

    // Verzögerung Animationen
    let wait = key * 300

    let isMain = (menu && menu.content && menu.content.length > 0)

    // console.log('buttonAnimation', root, root.mcPos, menu, iconName, key)

    if (iconName === '') {
        return
    }

    if (dir === 'show') {
        btn.visible = true
        btn.x = mcPos[btnName].x
        btn.y = mcPos[btnName].y

        btn.label.y = mcPos[mc_label].y + 20;
        btn.label.alpha = 0;
        btn.label.l2.y = mcPos[mc_label_l2].y + 20;
        btn.label.l2.alpha = 0;

        btn.bg_white.x = mcPos[mc_bg_white].x;
        btn.bg_white.scale = 1
        btn.bg_white.alpha = 0;

        btn.frame.y = mcPos[mc_frame].y + 20;
        btn.frame.x = mcPos[mc_frame].x;
        btn.frame.scale = 1
        btn.frame.alpha = 0;

        // .hl 
        if (btn[iconName].hl) {
            btn[iconName].hl.y = mcPos[mc_icon_hl].y + 10;
            btn[iconName].hl.x = mcPos[mc_icon_hl].x;
            btn[iconName].hl.alpha = 0;
        }

        // icon
        btn[iconName].icon.y = mcPos[mc_icon].y - 10;
        btn[iconName].icon.alpha = 0;

        // icon_w
        btn[iconName].icon_w.y = mcPos[mc_icon_w].y - 10;
        btn[iconName].icon_w.alpha = 0;


        // Haupt Btn
        if (state === 'on') {
            btn.bg_white.y = mcPos[mc_bg_white].y + 20;
            createjs.Tween.get(btn.bg_white)
                .wait(wait)
                .to({
                    y: mcPos[mc_bg_white].y,
                    alpha: 1
                }, 400, createjs.Ease.quadOut);
        } else {
            createjs.Tween.get(btn.frame)
                .wait(wait)
                .to({
                    y: mcPos[mc_frame].y,
                    alpha: 1
                }, 400, createjs.Ease.quadOut);
        }

        // Haupt Btn
        createjs.Tween.get(btn.label)
            .wait(wait)
            .to({
                y: mcPos[mc_label].y,
                alpha: 1
            }, 1200, createjs.Ease.quadOut);
        // Haupt Btn
        createjs.Tween.get(btn.label.l2)
            .wait(wait)
            .to({
                y: mcPos[mc_label_l2].y,
                alpha: 1
            }, 1400, createjs.Ease.quadOut);



        // je Icon
        if (state === 'on') {
            // .hl
            createjs.Tween.get(btn[iconName].hl)
                .wait(wait)
                .to({
                    y: mcPos[mc_icon_hl].y,
                    alpha: 1
                }, 600, createjs.Ease.quadOut);

            // .icon
            createjs.Tween.get(btn[iconName].icon)
                .wait(wait)
                .to({
                    y: mcPos[mc_icon].y,
                    alpha: 1
                }, 800, createjs.Ease.quadOut);
        }
        if (state === 'off') {
            createjs.Tween.get(btn[iconName].icon_w)
                .wait(wait)
                .to({
                    y: mcPos[mc_icon_w].y,
                    alpha: 1
                }, 800, createjs.Ease.quadOut);
        }

    } else

        // ausblenden
        if (dir === 'hide') {
            // Haupt Btn
            createjs.Tween.get(btn.bg_white)
                .wait(wait)
                .to({
                    y: mcPos[mc_bg_white].y - 20,
                    alpha: 0
                }, 400, createjs.Ease.quadOut);

            // Haupt Btn
            createjs.Tween.get(btn.label)
                .wait(wait)
                .to({
                    y: mcPos[mc_label].y - 20,
                    alpha: 0
                }, 1200, createjs.Ease.quadOut);

            // Haupt Btn
            createjs.Tween.get(btn.label.l2)
                .wait(wait)
                .to({
                    y: mcPos[mc_label_l2].y - 10,
                    alpha: 0
                }, 1000, createjs.Ease.quadOut);

            // je Icon
            createjs.Tween.get(btn[iconName].hl)
                .wait(wait)
                .to({
                    y: mcPos[mc_icon_hl].y - 30,
                    alpha: 0
                }, 600, createjs.Ease.quadOut);

            // je Icon
            createjs.Tween.get(btn[iconName].icon)
                .wait(wait)
                .to({
                    y: mcPos[mc_icon].y - 20,
                    alpha: 0
                }, 800, createjs.Ease.quadOut);

            // icon_w
            createjs.Tween.get(btn[iconName].icon_w)
                .wait(wait)
                .to({
                    y: mcPos[mc_icon_w].y - 20,
                    alpha: 0
                }, 800, createjs.Ease.quadOut).call((ev) => {
                    btn.visible = false
                });

            // .frame
            createjs.Tween.get(btn.frame)
                .wait(wait)
                .to({
                    y: mcPos[mc_frame].y - 20,
                    alpha: 0
                }, 800, createjs.Ease.quadOut)
        } else
            // Aktiv
            if (dir === 'active' && btn.visible && !isMain) {

                btn.bg_white.y = mcPos[mc_bg_white].y
                btn.bg_white.scale = 1.2;
                createjs.Tween.get(btn.bg_white)
                    .wait(wait)
                    .to({
                        scale: 1,
                        alpha: 1
                    }, 400, createjs.Ease.quadOut);

                btn.frame.scale = 1
                createjs.Tween.get(btn.frame)
                    .wait(wait)
                    .to({
                        scale: 1.2,
                        // scaleY: 1.2,
                        alpha: 0
                    }, 400, createjs.Ease.quadOut);


                // .hl
                btn[iconName].hl.y = mcPos[mc_icon_hl].y + 10;
                createjs.Tween.get(btn[iconName].hl)
                    .wait(wait)
                    .to({
                        y: mcPos[mc_icon_hl].y,
                        alpha: 1
                    }, 900, createjs.Ease.quadOut);

                // .icon_w
                btn[iconName].icon_w.y = mcPos[mc_icon_w].y
                createjs.Tween.get(btn[iconName].icon_w)
                    .wait(wait)
                    .to({
                        alpha: 0
                    }, 800, createjs.Ease.quadOut);

                // .icon
                btn[iconName].icon.y = mcPos[mc_icon].y
                createjs.Tween.get(btn[iconName].icon)
                    .wait(wait)
                    .to({
                        alpha: 1
                    }, 800, createjs.Ease.quadOut);

            } else
                // Inaktiv
                if (dir === 'inactive' && btn.visible && !isMain) {


                    btn.bg_white.scale = 1;
                    createjs.Tween.get(btn.bg_white)
                        .wait(wait)
                        .to({
                            scale: 0.8,
                            alpha: 0
                        }, 400, createjs.Ease.quadOut);

                    btn.frame.scale = 1.2
                    createjs.Tween.get(btn.frame)
                        .wait(wait)
                        .to({
                            scale: 1,
                            alpha: 1
                        }, 400, createjs.Ease.quadOut);


                    // .hl
                    // btn[iconName].hl.y = mcPos[mc_icon_hl].y + 10;
                    createjs.Tween.get(btn[iconName].hl)
                        .wait(wait)
                        .to({
                            y: mcPos[mc_icon_hl].y - 10,
                            alpha: 0
                        }, 600, createjs.Ease.quadOut);

                    // .icon
                    createjs.Tween.get(btn[iconName].icon_w)
                        .wait(wait)
                        .to({
                            alpha: 1
                        }, 800, createjs.Ease.quadOut);
                    createjs.Tween.get(btn[iconName].icon)
                        .wait(wait)
                        .to({
                            alpha: 0
                        }, 800, createjs.Ease.quadOut);

                }

}

export const animateBtnHome = (root, dir) => {
    if (dir === 'in') {
        root.navigation.btn_home.alpha = 0
        root.navigation.btn_home.y = root.mcPos['navigation.btn_home'].y + 20

        animateBtnLanguage(root, 'right')

        createjs.Tween.get(root.navigation.btn_home)
            .wait(500)
            .to({
                y: root.mcPos['navigation.btn_home'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
    }

    if (dir === 'out') {
        animateBtnLanguage(root, 'left')

        createjs.Tween.get(root.navigation.btn_home)
            .wait(100)
            .to({
                y: root.mcPos['navigation.btn_home'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut);
    }
}


export const animateBtnBack = (root, dir) => {

    // if (root.mode === 'mobile') {
    //     return
    // }

    if (dir === 'in') {
        root.navigation.btn_back.alpha = 0
        root.navigation.btn_back.y = root.mcPos['navigation.btn_back'].y + 20

        createjs.Tween.get(root.navigation.btn_back)
            .wait(1000)
            .to({
                y: root.mcPos['navigation.btn_back'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
    }

    if (dir === 'out') {
        createjs.Tween.get(root.navigation.btn_back)
            .wait(300)
            .to({
                y: root.mcPos['navigation.btn_back'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut);
    }
}


const buttonCenterAnimation = (root, menu, dir) => {

    let btnX = root.navigation.btn.x + ((root.mode === 'mobile') ? 0 : 705)
    let btnY = root.navigation.btn.y + ((root.mode === 'mobile') ? -430 : 0)

    if (menu) {

        let btn = root['btn_' + menu.id]
        let iconName = (menu.btn.icon ?? '')

        // console.log('buttonCenterAnimation', root, menu, dir, root['btn_' + menu.id])

        // root['btn_' + menu.id].x = root.mcPos['btn_' + menu.id].x + 120
        // root['btn_' + menu.id].y = root.mcPos['btn_' + menu.id].y + 20

        createjs.Tween.get(btn)
            .wait(400)
            .to({
                y: root.mcPos['btn_' + menu.id].y - 20,
                alpha: 0
            }, 600, createjs.Ease.quadOut).call((ev) => {
                ev.target.x = btnX + 120;
                ev.target.y = btnY
            }).to({
                y: btnY + 180,
                alpha: 0
            }, 60, createjs.Ease.quadOut).to({
                y: btnY + 160,
                alpha: 1
            }, 600, createjs.Ease.quadOut);

        // .bg_white
        createjs.Tween.get(btn.bg_white)
            .wait(200)
            .to({
                alpha: 0
            }, 400, createjs.Ease.quadOut);

        // .icon
        createjs.Tween.get(btn[iconName].icon)
            .wait(800)
            .to({
                alpha: 0
            }, 800, createjs.Ease.quadOut);

        // .icon_w
        createjs.Tween.get(btn[iconName].icon_w)
            .wait(800)
            .to({
                alpha: 1
            }, 800, createjs.Ease.quadOut);
    }

}


export const animateBtnLanguage = (root, dir) => {
    if (dir === 'left') {
        root.navigation.btn_language.y = root.mcPos['navigation.btn_language'].y
        createjs.Tween.get(root.navigation.btn_language)
            .wait(300)
            .to({
                x: root.mcPos['navigation.btn_language'].x, //  - 90,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
    }

    if (dir === 'right') {
        root.navigation.btn_language.y = root.mcPos['navigation.btn_language'].y

        createjs.Tween.get(root.navigation.btn_language)
            .wait(300)
            .to({
                x: root.mcPos['navigation.btn_language'].x,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
    }

    if (dir === 'in') {
        root.navigation.btn_language.alpha = 0
        root.navigation.btn_language.y = root.mcPos['navigation.btn_language'].y + 20
        root.navigation.btn_language.x = root.mcPos['navigation.btn_language'].x // - 50

        createjs.Tween.get(root.navigation.btn_language)
            .wait(500)
            .to({
                y: root.mcPos['navigation.btn_language'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
    }

    if (dir === 'en') {
        createjs.Tween.get(root.navigation.btn_language.en)
            .to({
                // y: root.mcPos['navigation.btn_language'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
        createjs.Tween.get(root.navigation.btn_language.de)
            .to({
                // y: root.mcPos['navigation.btn_language'].y,
                alpha: 0
            }, 400, createjs.Ease.quadOut);
    }

    if (dir === 'de') {
        createjs.Tween.get(root.navigation.btn_language.en)
            .to({
                // y: root.mcPos['navigation.btn_language'].y,
                alpha: 0
            }, 400, createjs.Ease.quadOut);
        createjs.Tween.get(root.navigation.btn_language.de)
            .to({
                // y: root.mcPos['navigation.btn_language'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
    }
}

export const showHideNavi = (root, dir) => {


    if (dir === 'out') {
        // console.log('showHideNavi', root.mcPos, dir, root.status.level)

        // mittel Menüpunkt
        let mcName = 'btn_' + root.status.level.id
        createjs.Tween.get(root[mcName])
            .to({
                alpha: 0
            }, 200, createjs.Ease.quadOut).call((ev) => {
                root[mcName].visible = false
                root[mcName].alpha = 1
            })

        // subemnüpunkte
        root.status.level.content.forEach(mc => {

            let mcName = 'btn_' + mc.id
            if (root[mcName] && root[mcName].frame) {
                createjs.Tween.get(root[mcName])
                    .to({
                        alpha: 0
                    }, 200, createjs.Ease.quadOut).call((ev) => {
                        root[mcName].visible = false
                        root[mcName].alpha = 1
                    })
            }
        });
    } else
        if (dir === 'in' && root.status.level) {
            // mittel Menüpunkt
            let mcName = 'btn_' + root.status.level.id
            root[mcName].alpha = 0
            root[mcName].visible = true
            createjs.Tween.get(root[mcName])
                .wait(800)
                .to({
                    alpha: 1
                }, 400, createjs.Ease.quadOut)

            // subemnüpunkte
            root.status.level.content.forEach(mc => {
                let mcName = 'btn_' + mc.id
                root[mcName].alpha = 0
                if (root[mcName] && root[mcName].frame) {
                    root[mcName].visible = true
                    createjs.Tween.get(root[mcName])
                        .wait(500)
                        .to({
                            alpha: 1
                        }, 500, createjs.Ease.quadOut)
                }
            })
        }
}