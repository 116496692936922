import { config } from '../../config';

// Flash
import { navigation } from '../../assets/animate/ui/navigation';
import { openPage } from './navigationAction';


// neuer Button
export const createButton = (root, menu) => {

    const navigation_lib = navigation()
    var btn = new navigation_lib.btn()
    const lang = root.status.language
    const mouseEvent = (root.mode === 'screen')
        ? config.screen.mouseEvent // mousedown
        : config.mobile.mouseEvent // click

    // console.log(menu.btn.label, (menu.btn.label[lang]))

    // EN vorhanden?
    let l1 = (menu.btn.label[lang]) ? menu.btn.label[lang].l1 : menu.btn.label.l1
    let l2 = (menu.btn.label[lang]) ? menu.btn.label[lang].l2 : menu.btn.label.l2

    btn.visible = false
    btn.cursor = "pointer"
    btn.frame.alpha = 0
    btn.label.l1.txt.text = l1 // .toUpperCase()
    btn.label.l2.txt.text = l2

    // console.log('btn 1', btn.children)
    btn.children.forEach(icon => {
        if (!icon.name) {
            console.log('Error, kein MC Name: ', icon)
        }
        if (icon.name && icon.name.includes('icon_') && !icon.name.endsWith(menu.btn.icon)) {
            // console.log('btn 2', menu.btn.icon)
            icon.visible = false
        }
    });

    // Action
    btn.on(mouseEvent, function () {
        // console.log('button', root, menu)
        openPage(root, menu.id)
    })

    return btn
}