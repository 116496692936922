import { config } from "../config";
import { playLedAnimation } from "./led/ledHelper";
import { hideOutside } from "./outside/outsideHelper";
import { switchProjector } from "./viewsonicApi";
import { send2WS } from "./websocket";
import { setLamellenState } from "./window/windowHelper";

export const initScreensaver = (root, mode) => {
    // console.log('initScreensaver', mode, config.isLocal)
    if (mode === 'screen' && config.isLocal) {
        if (root.screensaverTimer) {
            clearTimeout(root.screensaverTimer)
        }
        startScreensaver(root)
    }
}

const startScreensaver = (root) => {
    // console.log('startScreensaver')
    root.screensaverTimer = setTimeout(function () {
        console.log('######startScreensaver')
        playScreensaver(root)
    }, config.screensaver * 1000);
}

// PLAY
export const playScreensaver = (root) => {
    if (root.type === 'screen') {
        setLamellenState(root, 'full', 1)
        setLamellenState(root, 'full', 2)
        // setLamellenState(root, 'left-logo-top')
        switchProjector(root, 'outside')
        send2WS(root, { screensaver: 'start', app: 'screen' })
        playLedAnimation('Welcome')
    }
    if (root.type === 'outside') {
        hideOutside(root)
        switchProjector(root, 'outside')
        root.screensaver.visible = true
        root.screensaver.sub_ani_01_mc.gotoAndPlay(0)
    }
}

export const stopScreensaver = (root) => {
    if (root.type === 'screen') {
        send2WS(root, { screensaver: 'stop', app: 'screen' })
    }
    if (root.type === 'outside') {
        root.screensaver.visible = false
        root.screensaver.sub_ani_01_mc.gotoAndStop(0)
    }
}

export const resetScreensaver = (root, mode) => {
    console.log('resetScreensaver', mode, config.isLocal)
    if (mode === 'screen' && config.isLocal) {
        send2WS(root, { screensaver: 'stop', app: 'screen' })
        if (root.screensaverTimer) {
            clearTimeout(root.screensaverTimer)
        }
        if (root.type === 'outside') {
            root.screensaver.sub_ani_01_mc.gotoAndStop(0)
        }
        startScreensaver(root)
    }
}