/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../actions/animateHelper'

const logoColor = "rgba(255,255,255,1)"

export const animation = () => {

    var lib = {}
    var p

    (lib.logo_bg = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("rgba(255,255,255,0.247)").s().p("Egl4BclMAAAi5JMBLxAAAMAAAC5Jg");
            this.shape.setTransform(242.5,592.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.logo_bg, new cjs.Rectangle(0,0,485,1185), null);

    (lib.logo_u = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("Am5GfQgngFghgNQgsgUghgoQghgogMgvQgFgYgChGIAAk4QAChFAFgXQANgxAggoQAhgnAsgTQAhgOAogFQAggEAygBIPpABIAAFWIvgAAQgaAAgVARQgaAUAAAmIAAADQAAAnAaAUQAVAQAaAAIPgAAIAAFWIvpAAQgyABghgFg");
        this.shape.setTransform(155.4989, 101.4479, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_u, new cjs.Rectangle(0, 0, 311, 202.9), null);


    (lib.logo_r = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AqCGlIAAlZIIWAAIAAhVQAAgggQgOQgQgOgjgBInTAAIAAleIG6AAQBRAAA/A4QA5A0AHBBQAHhBA6g0QA9g4BRAAIDaAAQBWABA/A3QA9A2AABGIAAKVgACCg7QgQALgDAWIAABmIFHAAIABhVQAAg+hDABIjDAAIgDAAQgbAAgRALg");
        this.shape.setTransform(155.4989, 101.9163, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_r, new cjs.Rectangle(0, 0, 311, 203.9), null);


    (lib.logo_h = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AqCGqIAAleIIWAAIAAiWIoWgBIAAlfIUFABIAAFeIoUAAIAACXIIUAAIAAFeg");
        this.shape.setTransform(155.4989, 103.1604, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_h, new cjs.Rectangle(0, 0, 311, 206.3), null);


    (lib.logo_e = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AqCkmID4AAIABDvIEdAAIAAjMIDbAAIAADMIEbgBIAAjuID5AAIAAJMI0FABg");
        this.shape.setTransform(155.4989, 71.2679, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_e, new cjs.Rectangle(0, 0, 311, 142.6), null);


    (lib.logo_a = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AGxGiIwzAAIAAldIIWAAIAAiNIoWAAIAAlZIUFAAIAAKIQgBBSg9A2Qg7AzhWAAIgDAAgABvBGIEFAAQAkAAAPgOQAOgOAAghIAAhPIlGAAg");
        this.shape.setTransform(155.5292, 101.16, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_a, new cjs.Rectangle(0, 0, 311.1, 202.3), null);

    (lib.logo = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.r = new lib.logo_r();
        this.r.name = "r";
        this.r.setTransform(163.75, 1051.75, 1, 1, 0, 0, 0, 155.5, 101.9);

        this.e = new lib.logo_e();
        this.e.name = "e";
        this.e.setTransform(163.75, 841.85, 1, 1, 0, 0, 0, 155.5, 71.2);

        this.h = new lib.logo_h();
        this.h.name = "h";
        this.h.setTransform(163.75, 630.9, 1, 1, 0, 0, 0, 155.5, 103.2);

        this.a = new lib.logo_a();
        this.a.name = "a";
        this.a.setTransform(163.75, 390.1, 1, 1, 0, 0, 0, 155.5, 101.2);

        this.u = new lib.logo_u();
        this.u.name = "u";
        this.u.setTransform(163.75, 151, 1, 1, 0, 0, 0, 155.5, 101.5);


        this.bg = new lib.logo_bg();
        this.bg.name = "bg";
        this.bg.setTransform(249.8,600,1,1,0,0,0,242.5,592.5);
    
        // this.timeline.addTween(cjs.Tween.get(this.bg).wait(1));

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.bg }, { t: this.u }, { t: this.a }, { t: this.h }, { t: this.e }, { t: this.r }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo, new cjs.Rectangle(7.3, 7.5, 485, 1185), null);


    (lib.rehaulogo = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);


        // logo
        this.logo = new lib.logo();
        this.logo.name = "logo";
        this.logo.setTransform(155.5, 552.1, 1, 1, 0, 0, 0, 155.5, 552.1);

        this.timeline.addTween(cjs.Tween.get(this.logo).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.rehaulogo, new cjs.Rectangle(-0.4, -0.4, 1543.6000000000001, 1200.9), null);

    return lib
}
