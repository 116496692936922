/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */

    (lib.txt_mc_05 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Gesamtertrag pro Tag 0,2 kWh", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_05, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_04 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Ertrag bis zu 0,80 kWh/m2 pro Tag", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_04, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Gesamtleistung 50 Watt", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_03, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.txt_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Aktive Fläche der Fenterbank 0,25 m2", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 357;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_02, new cjs.Rectangle(0,0,360.5,20.8), null);
        
        
        (lib.txt_mc_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Max. Leistung 200 Watt pro m2", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 374;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_01, new cjs.Rectangle(0,0,378,20.8), null);
        
        
        (lib.testBtn = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#000000").s().p("AjYDjIAAnFIGwAAIAAHFg");
            this.shape.setTransform(19.05,22.025);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.testBtn, new cjs.Rectangle(-2.6,-0.6,43.300000000000004,45.300000000000004), null);
        
        
        (lib.iconRahmen_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhYBZICxAAIAAixIixAAg");
            this.shape.setTransform(25.7717,25.7717,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.iconRahmen_mc, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_color_shape = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#DD0061").s().p("AkBECIAAoDIIDAAIAAIDg");
            this.shape.setTransform(25.775,25.775);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.icon_color_shape, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_mc_07 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                this.stop();
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AAEALIgEgIIgDAIIgGAAIAHgLIgHgKIAGAAIADAHIAEgHIAGAAIgHAKIAHALg");
            this.shape.setTransform(24,13.1,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AAFALIgBgEIgHAAIgCAEIgFAAIAIgVIAFAAIAIAVgAgCACIAFAAIgDgHg");
            this.shape_1.setTransform(19.85,13.1,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AAJALIgCgPIgEAPIgFAAIgEgPIgBAPIgFAAIACgVIAHAAIADAPIAEgPIAHAAIACAVg");
            this.shape_2.setTransform(14.8,13.1,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgCAVIAAgpIAFAAIAAApg");
            this.shape_3.setTransform(10.4,24.85,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgrAWIAAgGIAeAAIAwgcIgDgCQgBgBgBAAQAAAAgBABQAAAAgBAAQAAAAgBABIgVALIgYAAIAAgHIAXAAIATgKQACgCAEAAQAHAAAEAHIADAFIg3Afg");
            this.shape_4.setTransform(21.05,23.25,2,2);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgXALIAAgGIAEAAIAQgPIAbAAIAAAHQAAADgEAFQgEADgFAAIAAgHQAGAAAAgEIgRAAIgQAOg");
            this.shape_5.setTransform(17,21.05,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_06 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                this.stop();
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgIAJQgDgEgBgFQABgEADgEQAEgDAEgBQAFABAEADQADAEABAEIgHAAQAAgGgGABQgFgBAAAGQAAAGAFAAIAAAHQgEgBgEgDg");
            this.shape.setTransform(19.25,13.15,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgIAJQgEgEABgFQgBgEAEgEQAEgEAEAAQAFAAAEAEQAEAEAAAEQAAAFgEAEQgEAEgFAAQgEAAgEgEgAgDgDQgBAAAAABQgBAAAAABQAAAAAAABQgBAAAAAAQAAAGAGAAQAGAAAAgGQAAgFgGAAQAAAAAAAAQgBAAAAAAQgBABAAAAQgBAAAAABg");
            this.shape_1.setTransform(22.45,16.3,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgCAUIAAgnIAFAAIAAAng");
            this.shape_2.setTransform(11.1,26.05,2,2);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgrAWIAAgGIAeAAIAwgbQgDgGgFADIgVAMIgYAAIAAgHIAXAAIATgKQACgCAEAAQAHAAAEAGIADAGIg3Afg");
            this.shape_3.setTransform(21.75,24.45,2,2);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgXALIAAgGIAEAAIAQgPIAbAAIAAAGQAAAFgEADQgEAEgFAAIAAgHQAGABAAgGIgRAAIgQAPg");
            this.shape_4.setTransform(17.7,22.25,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgLgIIAHgCIADALIALgCIACAGIgSAGg");
            this.shape.setTransform(19.25,23.75,2,2);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgHAXIAJgSIgRAAIAQgfIAIAEIgKAUIARAAIgQAdg");
            this.shape_1.setTransform(19.6,19.3,2,2);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgeAuIAEgGQAOAJAOAAQAZAAAPgVQAMgQgEgTQgDgUgRgMQgNgJgPAAQgYAAgPAVQgQAWALAZIALgFIgEAbIgWgPIAIgEQgMgcASgaQARgYAcAAQARAAAPAKQATAOAEAXQAEAWgNATQgRAXgdAAQgRAAgPgKg");
            this.shape_2.setTransform(19.7914,19.9,2,2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgsAtIAAhEIBEAAIAABEgAglAmIA2AAIAAg3Ig2AAgAgHAeIANgOIACACIgNAOgAgcAaIAighIACACIghAigAAmAYIAAg9Ig6AAIAAAHIgGAAIAAgOIBHAAIAABEgAgcACIAOgNIADADIgOANg");
            this.shape.setTransform(19.4,20.4,2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.icon_mc_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var mc = this;
                this.startMc = function(time){
                    setTimeout(this.mcPlay,time);	
                }
                this.mcPlay = function(){
                    mc.play();
                }
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgpAEIAAgHIBSAAIAAAHg");
            this.shape.setTransform(21.9851,29.0694,1.3649,1.3649);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgzACIAAgDIBnAAIAAADg");
            this.shape_1.setTransform(21.9851,23.7804,1.3649,1.3649);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgsACIAAgDIBZAAIAAADg");
            this.shape_2.setTransform(22.0192,21.2212,1.3649,1.3649);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgFgcIAEgBIAHA7IgDAAg");
            this.shape_3.setTransform(26.0116,22.7567,1.3649,1.3649);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgBAeIAAg7IADAAIAAA7g");
            this.shape_4.setTransform(22.0192,22.7567,1.3649,1.3649);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgGAeIAIg7IAFABIgIA6g");
            this.shape_5.setTransform(17.9586,22.7567,1.3649,1.3649);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("Ag/AiIAUhDIBXAAIAUBDgAg0AaIBoAAIgOgyIhLAAg");
            this.shape_6.setTransform(22.0192,22.7567,1.3649,1.3649);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#FFFFFF").s().p("AgKAFIAPgPIAGAGIgPAPg");
            this.shape_7.setTransform(10.4175,25.2477,1.3649,1.3649);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#FFFFFF").s().p("AgLAAIAUgGIADAIIgVAFg");
            this.shape_8.setTransform(8.4383,21.733,1.3649,1.3649);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#FFFFFF").s().p("AgLACIACgIIAVAFIgDAIg");
            this.shape_9.setTransform(8.4383,17.7065,1.3649,1.3649);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#FFFFFF").s().p("AgKgEIAGgGIAPAPIgGAGg");
            this.shape_10.setTransform(10.4175,14.1919,1.3649,1.3649);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#FFFFFF").s().p("AgGgIIAHgDIAGAVIgHACg");
            this.shape_11.setTransform(13.9321,12.1786,1.3649,1.3649);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#FFFFFF").s().p("AgGAKIAGgVIAHADIgGAUg");
            this.shape_12.setTransform(17.9586,12.1786,1.3649,1.3649);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f("#FFFFFF").s().p("AgKAFIAPgPIAGAGIgPAPg");
            this.shape_13.setTransform(21.4733,14.1919,1.3649,1.3649);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f("#FFFFFF").s().p("AggAGQAAgPALgMQALgLAQAAQAQAAALALIgGAGQgJgJgMAAQgMAAgJAJQgJAJAAAMQAAANAJAJIgGAFQgLgKAAgRg");
            this.shape_14.setTransform(15.1947,18.9349,1.3649,1.3649);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AjHDIIAAmPIGPAAIAAGPg");
            mask.setTransform(19.9999,19.9999);
        
            // green
            this.instance = new lib.icon_color_shape();
            this.instance.setTransform(62,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({x:20},27,cjs.Ease.quadOut).wait(2));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(20,20,0.7759,0.7759,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,40,40);
        
        
        (lib.iconAni_mc_05 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_07();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_05();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_04 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_06();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_04();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_03();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_03();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.iconAni_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_02();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_02();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,484.5,31.1);
        
        
        (lib.iconAni_mc_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_38 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(38).call(this.frame_38).wait(1));
        
            // icon
            this.icon = new lib.icon_mc_01();
            this.icon.name = "icon";
            this.icon.setTransform(53.75,96.35,0.7759,0.7759,0,0,0,114.4,124.2);
            this.icon.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.icon).wait(1).to({x:88.75,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            // txt
            this.txt_mc = new lib.txt_mc_01();
            this.txt_mc.name = "txt_mc";
            this.txt_mc.setTransform(234.8,27.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc.alpha = 0.1992;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc).wait(1).to({x:189.8,alpha:1},36,cjs.Ease.quadOut).wait(2));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-35,0,502,31.1);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {play_icon_1:134,play_icon_2:145,play_icon_3:158,play_icon_4:171,play_icon_5:183};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var time2wait = 1000;
                var subAniMc = this;
                this.wait = function(){
                    if(subAniMc.parent.setTime == true){
                        subAniMc.play()
                    }
                }
                
                
                this.goBack = function() {
                    this.parent.setTime = false;
                    for(var i = 1;i<6;i++){
                        this["mc_"+i].icon.gotoAndStop(0);
                    }
                    subAniMc.gotoAndPlay(1);
                }
                this.testBtn.addEventListener("mousedown", clickHandler_test.bind(this));
                function clickHandler_test(){
                    this.parent.goHome();
                }
            }
            this.frame_131 = function() {
                this.stop();
                this.parent.setTime = true;
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_134 = function() {
                this.stop();
                this.mc_1.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_145 = function() {
                this.stop();
                this.mc_2.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_158 = function() {
                this.stop();
                this.mc_3.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_171 = function() {
                this.stop();
                this.mc_4.icon.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_183 = function() {
                this.stop();
                this.mc_5.icon.play();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(131).call(this.frame_131).wait(3).call(this.frame_134).wait(11).call(this.frame_145).wait(13).call(this.frame_158).wait(13).call(this.frame_171).wait(12).call(this.frame_183).wait(25));
        
            // Ebene_11
            this.testBtn = new lib.testBtn();
            this.testBtn.name = "testBtn";
            this.testBtn.setTransform(368,471.5,1,1,0,0,0,13.5,13.5);
        
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#DD0061").s().p("AgNAdQgGgFgEgHQgCgIAAgJQAAgJACgHQAEgIAGgEQAGgEAJAAQAIAAAHADQAFADAEAHIgIAFQgCgEgFgCQgDgCgFAAQgGAAgEADQgDADgCAFQgCAFAAAGQAAAHACAFQACAFADADQAEADAGAAQAFAAADgCQAFgCACgEIAIAFQgEAHgFADQgHADgIAAQgJAAgGgEg");
            this.shape.setTransform(-101.3,1.825);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#DD0061").s().p("AAkAhIAAgoQAAgHgDgEQgDgEgIAAQgGAAgEADQgDACgDADIAAAvIgLAAIAAguQgCgEgDgCQgEgDgFAAQgGAAgEADIgGAFIAAAvIgMAAIAAhAIAMAAIAAAIQADgDAEgDQAFgDAGAAQAIAAAEADQAFADADAEQADgEAGgDQAFgDAHAAQAMABAGAFQAFAHABALIAAApg");
            this.shape_1.setTransform(-110.15,1.75);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_2.setTransform(-119.35,5.85);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#DD0061").s().p("AAJAvIAAhSIgaALIgCgKIAdgMIAKAAIAABdg");
            this.shape_3.setTransform(-126.05,0.275);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#DD0061").s().p("AgTArQgHgGgEgLQgDgLAAgPQAAgOADgLQAEgLAIgGQAHgGALAAQAMAAAIAGQAHAGAEALQADALAAAOQAAAPgDALQgEALgIAGQgHAGgMAAQgLAAgIgGgAgQgbQgFAJAAASQAAASAFAKQAFAKALAAQAMAAAFgKQAFgKAAgSQAAgSgFgJQgFgKgMAAQgLAAgFAKg");
            this.shape_4.setTransform(-132.475,0.275);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_5.setTransform(-140.15,5.85);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#DD0061").s().p("AgFAvIAAg/IAKAAIAAA/gAgFghQgCgBAAgEQAAgFACgCQACgBADAAQADAAACABQACACABAFQgBAEgCABQgCACgDAAQgDAAgCgCg");
            this.shape_6.setTransform(-145.15,0.275);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#DD0061").s().p("AAQAhIAAgnQAAgIgDgDQgEgEgIgBQgFAAgEADQgFACgCADIAAAvIgLAAIAAhAIALAAIAAAIQACgEAGgCQAFgDAHAAQALABAGAFQAFAHAAAMIAAAog");
            this.shape_7.setTransform(-150.2479,1.75);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#DD0061").s().p("AgUAcQgFgEAAgJQAAgGAEgFQAEgEAGgBQAHgDAHgBIALgBIAAgGQABgFgDgDQgDgDgIAAQgFAAgEACQgDACAAAEIAAADIgKAAIAAgCIAAgCQAAgIAGgEQAGgEALAAQALAAAGAFQAHAFAAAKIAAAsIgLAAIAAgIQgDADgFADQgGADgGAAQgKAAgFgFgAAEACQgIABgFADQgEADAAAGQAAAEACADQADACAGAAQAEAAAFgCQAEgCADgEIAAgPg");
            this.shape_8.setTransform(-157.375,1.825);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_9.setTransform(-164,5.85);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#DD0061").s().p("AgKAuQgFgCgDgCIgDAGIgGAAIAAhgIALAAIAAAmQACgCAFgCQAEgDAGAAQAJAAAGAFQAGADADAIQADAGAAAKQAAALgDAHQgDAIgGADQgGAFgJAAQgHAAgEgDgAgIgFIgFADIgDACIAAAfQACADAEACQAEADAGAAQAGgBAEgDQAEgCABgGQACgFAAgHQAAgHgCgEQgCgFgEgCQgDgDgGgBQgFAAgDACg");
            this.shape_10.setTransform(-170.925,0.25);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#DD0061").s().p("AgVAaQgFgGAAgMIAAgoIALAAIAAAnQAAAIADADQADAEAJAAQAEAAAFgCQAFgCACgDIAAgvIALAAIAAA/IgLAAIAAgHQgCAEgGACQgGADgGAAQgMAAgFgHg");
            this.shape_11.setTransform(-178.35,1.875);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#DD0061").s().p("AgLAfQgFgBgDgDIgFgFIAHgGIAGAGQAEACAHAAQAGAAAEgCQADgCABgFQAAgEgDgCQgCgCgFgBIgKgDQgIgCgEgDQgEgFAAgGQAAgFADgEQACgFAFgCQAGgDAHAAQAJAAAFACIAJAGIgHAHIgHgFIgJgCQgGABgDACQgDADAAAEQAAADACACQACACAEACIAKACQAJACAFAEQAEAEAAAHQAAAFgDAFQgDAEgFACQgGADgIAAQgGAAgFgCg");
            this.shape_12.setTransform(-184.975,1.825);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape},{t:this.testBtn}]}).wait(208));
        
            // Ebene_7
            this.mc_7 = new lib.iconAni_mc_05();
            this.mc_7.name = "mc_7";
            this.mc_7.setTransform(216,342.5,1,1,0,0,0,216,48.2);
            this.mc_7.alpha = 0;
        
            this.mc_5 = new lib.iconAni_mc_05();
            this.mc_5.name = "mc_5";
            this.mc_5.setTransform(216,258.5,1,1,0,0,0,216,48.2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.mc_7}]}).to({state:[]},1).to({state:[{t:this.mc_5}]},86).wait(121));
        
            // Ebene_6
            this.mc_6 = new lib.iconAni_mc_04();
            this.mc_6.name = "mc_6";
            this.mc_6.setTransform(216,293.45,1,1,0,0,0,216,48.2);
            this.mc_6.alpha = 0;
        
            this.mc_4 = new lib.iconAni_mc_04();
            this.mc_4.name = "mc_4";
            this.mc_4.setTransform(216,205.95,1,1,0,0,0,216,48.2);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.mc_6}]}).to({state:[]},1).to({state:[{t:this.mc_4}]},66).wait(141));
        
            // Ebene_3
            this.mc_3 = new lib.iconAni_mc_03();
            this.mc_3.name = "mc_3";
            this.mc_3.setTransform(216,146.3,1,1,0,0,0,216,48.2);
            this.mc_3.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_3).to({_off:true},1).wait(48).to({_off:false,y:153.3,alpha:1},0).wait(159));
        
            // Ebene_2
            this.mc_2 = new lib.iconAni_mc_02();
            this.mc_2.name = "mc_2";
            this.mc_2.setTransform(216,97.25,1,1,0,0,0,216,48.2);
            this.mc_2.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_2).to({_off:true},1).wait(28).to({_off:false,y:100.75,alpha:1},0).wait(179));
        
            // icon_1
            this.mc_1 = new lib.iconAni_mc_01();
            this.mc_1.name = "mc_1";
            this.mc_1.setTransform(204.8,48.2,1,1,0,0,0,204.8,48.2);
            this.mc_1.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.mc_1).to({_off:true},1).wait(8).to({_off:false,alpha:1},0).wait(199));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-189.9,-10.6,656.9,513.3);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.setTime = false;
                this.time2wait = 2000;
                this.goHome=function(){
                    this.sub_ani_01_mc.goBack();
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(195.65,235.75,1,1,0,0,0,114.4,160.6);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(-108.7,64.6,657,513.3), null);
        

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}