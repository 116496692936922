import { FC, useEffect, useState } from 'react'
import { xLightApi } from '../actions/led/xLightApi'
import { config } from '../config'

import './LedController.scss'





const LedController: FC = () => {

    const [statusText, setStatusText] = useState({ error: false, text: 'ip, pw' })
    const [connected, setConnected] = useState(false)
    const [playlist, setPlaylist] = useState({ playlists: [] })
    const [activePlaylist, setActivePlaylist] = useState('')

    const xLightCommands = async (todo: string = '', playlistName: string = '') => {

        if (todo === 'play') {
            setActivePlaylist(playlistName)
        }
        if (todo === 'stop') {
            setActivePlaylist('')
        }

        let xlight: any = await xLightApi(todo, playlistName)
        if (todo === 'playlist') {
            console.log(xlight.playlists)
            setPlaylist(xlight)
        }

        setConnected((!xlight.result || (xlight && xlight.result === 'ok')))
        setStatusText({ error: false, text: '' })
    }

    // const xLightApi = async (todo: string = '', playlistName: string = '') => {

    //     var url = `${config.apiUrl}/xlights/playlist`
    //     var body = null

    //     if (todo === 'stop' || todo === 'play') {
    //         url = `${config.apiUrl}/xlights/playlist/${todo}`
    //         body = {
    //             playlist: playlistName
    //         }
    //         if (todo === 'play') {
    //             setActivePlaylist(playlistName)
    //         }
    //         if (todo === 'stop') {
    //             setActivePlaylist('')
    //         }
    //     }

    //     var options: RequestInit = {
    //         method: 'POST',
    //         headers: headers,
    //         mode: 'cors',
    //         body: (body) ? JSON.stringify(body) : ''
    //     }

    //     if (todo === 'playlist') {
    //         options = {
    //             method: 'GET',
    //             headers: headers,
    //             mode: 'cors'
    //         }
    //     }

    //     const response = await fetch(url, options).then(response => {
    //         return response.json()
    //     }).catch((error) => {
    //         setStatusText({ error: true, text: `${error}` })
    //     })

    //     const data = await response;

    //     if (todo === 'playlist') {
    //         console.log(data.playlists)
    //         setPlaylist(data)
    //     }

    //     setConnected((!data.result || data.result === 'ok'))

    //     setStatusText({ error: false, text: '' })
    // }

    useEffect(() => {
        xLightCommands('playlist')
        return () => { }
    }, [])


    return (
        <div className='led'>
            <h3>LedController</h3>
            {/* <div>{config.url}: {connected ? 'verbunden' : 'getrennt'}</div> */}
            <div className='playlist'>
                {
                    (playlist.playlists)
                        ?
                        playlist.playlists.map((list: any, lkey) => (
                            <div key={lkey}>
                                <strong>{list.name}</strong>
                                <button
                                    onClick={() => { xLightCommands('play', list.name) }}
                                    className={(activePlaylist === list.name ? 'active' : '')}
                                >Play</button>
                                <button onClick={() => { xLightCommands('stop', list.name) }}>Stop</button>
                            </div>
                        ))
                        : ''
                }
            </div>
            <div className='status'>{statusText.text}</div>
        </div>
    );
}

export default LedController