import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { config } from '../config';
import { getContent } from '../config/getContent';
import { connectWS } from '../actions/websocket'
import { getCustomerData } from './../actions/qrcode/getCustomerData'

import './CheckIn.scss'
import { powerApi } from '../actions/adminHelper';
import { setWindowSeqment } from '../actions/window/windowHelper';

interface CheckInProps {
    qrcode?: string
}

var ws: WebSocket

const CheckIn: FC<CheckInProps> = ({ qrcode }) => {
    const emptyCustomer = { id: '', Code: '', Name: '', Language: '', Gender: '', categories: '', Company: '' }
    const content = getContent('', true)
    const [lang, setLang] = useState('de')
    const [mute, setMute] = useState(localStorage.getItem('isMute') && localStorage.getItem('isMute') === '1')
    const [customer, setCustomer] = useState(emptyCustomer)
    const [scanning, setScanning] = useState(false)
    const [isAdmin, setAdmin] = useState(false)
    const [isOn, setOn] = useState(true)
    const [getStatus, setGetStatus] = useState(false)

    const [mouseTimeout, setMouseTimeout] = useState<any>()


    let params = useParams()

    useEffect(() => {
        if (config.isLocal) {
            ws = connectWS({
                type: 'checkin',
                qrcode: ((params && params.qrcode) ? params.qrcode : '')
            })

            if (params && params.qrcode && params.qrcode !== '') {
                console.log('scannner', ws.readyState)
                setScanning(true)

                if (ws) {
                    console.log('websocket ok', params)
                    getCustomerData({ qrcode: params.qrcode ?? '', setCustomer: setCustomer })

                }
            }
        } else {
            getCustomerData({ qrcode: params.qrcode ?? '', setCustomer: setCustomer })
        }

        window.addEventListener("contextmenu", (e:any) => e.preventDefault());
    }, [params])

    useEffect(() => {
        if (customer.Language && customer.Language !== '') {
            setLang(customer.Language)

            if (ws) {
                ws.send(JSON.stringify({ qrcode: customer.Code, app: 'checkin', countdown: true, mute: mute }));
            }
            setScanning(false)
        }
    }, [customer])


    const setMuteLocal = (mute: boolean) => {
        localStorage.setItem('isMute', mute ? '1' : '0')
        setMute(mute)
    }

    const getAdmin = async () => {
        let pA = await powerApi()
        console.log(pA)
        if (pA.serverStatus === 'on') {
            setOn(true)
        } else {
            setOn(false)
        }
        setAdmin(!isAdmin)
        setGetStatus(false)
    }

    return (
        <div className={'checkin ' + (config.isLocal ? 'local' : '')}>
            {
                (!config.isLocal) ? <h2>CheckIn {config.version}</h2> : ''
            }

            <div
                className='title'
                onTouchStart={() => {
                    setMouseTimeout(setTimeout(function () {
                        console.log('###### Admin')
                        getAdmin()
                    }, !isAdmin ? 2000 : 50))
                }}
                onMouseDown={() => {
                    setMouseTimeout(setTimeout(function () {
                        console.log('###### Admin')
                        getAdmin()
                    }, !isAdmin ? 2000 : 50))
                }}
                
                onTouchEnd={() => {
                    if (mouseTimeout) {
                        clearTimeout(mouseTimeout)
                    }
                }}
                onMouseUp={() => {
                    if (mouseTimeout) {
                        clearTimeout(mouseTimeout)
                    }
                }}
            >
                <div className='l1'>
                    {content.checkinPage.title[lang].l1}
                </div>
                <div className='l2'>
                    {content.checkinPage.title[lang].l2}
                </div>
            </div>
            {
                (customer?.Name)
                    ? <div className='welcome'>
                        {
                            content.checkinPage.welcome.nameStr[lang]
                                .replace('{Name}', customer?.Name)
                                .replace('{Gender}', customer?.Gender === 'm' ? content.checkinPage.welcome.gender[lang].m : content.checkinPage.welcome.gender[lang].f)
                        }
                        <div className='start' onClick={() => {
                            setCustomer(emptyCustomer)
                            if (ws) {
                                ws.send(JSON.stringify({ qrcode: params.qrcode, app: 'checkin', start: true, mute: mute }));
                            }
                        }}>
                            <CountdownCircleTimer
                                isPlaying
                                duration={15}
                                colors={['#56A28D', '#CB2C61']}
                                colorsTime={[10, 0]}
                                size={160}
                                strokeWidth={5}
                                trailStrokeWidth={0}
                                strokeLinecap={'square'}
                                onComplete={() => {
                                    setCustomer(emptyCustomer)
                                    if (ws) {
                                        ws.send(JSON.stringify({ qrcode: params.qrcode, app: 'checkin', start: true, mute: mute }));
                                    }
                                }}
                            >
                                {({ remainingTime }) => 'START'}
                            </CountdownCircleTimer>
                        </div>
                    </div>
                    : (
                        (!isAdmin)
                            ? <div>
                                <div className='qrcode'>
                                    <img src={require('./../assets/images/qr-scan-ani.gif')} alt='QRCode' />
                                </div>

                                <div className='info'>
                                    {
                                        !scanning
                                            ? content.checkinPage.info[lang]
                                            : (
                                                (lang === 'de')
                                                    ? <div>QR Code erkannt,<br />Daten werden geladen...</div>
                                                    : <div>QR Code is detected,<br />data will be loaded...</div>
                                            )
                                    }
                                </div>
                            </div>
                            : <div className='admin'>
                                <div>Admin-Mode</div>
                                <div className={`power ${getStatus ? 'disabled' : ''}`}>
                                    <div
                                        className={`button ${isOn ? 'on disabled' : ''}`}
                                        onClick={() => { 
                                            setOn(true) 
                                            setGetStatus(true)
                                            powerApi('on')
                                            if (ws) {
                                                ws.send(JSON.stringify({ power: 'on', app: 'checkin' }));
                                            }
                                        }}
                                    >ON</div>
                                    <div
                                        className={`button ${!isOn ? 'off disabled' : ''}`}
                                        onClick={() => { 
                                            setOn(false) 
                                            setGetStatus(true)
                                            powerApi('off')
                                            if (ws) {
                                                ws.send(JSON.stringify({ power: 'off', app: 'checkin' }));
                                            }
                                        }}
                                    >OFF</div>
                                </div>
                            </div>
                    )
            }
            <div
                className='audio'
                onClick={() => { setMuteLocal((mute) ? false : true) }}
            >
                <img src={require(`./../assets/images/mute${mute ? '' : '_off'}.png`)} alt='Sound' />
            </div>
            <div
                className='language'
                onClick={() => { setLang((lang === 'de') ? 'en' : 'de') }}
            >
                <img src={require('./../assets/images/btn_language_' + lang + '.png')} alt='Sprache' />
            </div>
        </div >
    )
}

export default CheckIn