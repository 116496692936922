import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Admin from './pages/Admin';
import Mobile from './pages/Mobile';
import Screen from './pages/Screen';
import CheckIn from './pages/CheckIn';
import LedController from './pages/LedController';
import Outside from './pages/Outside';
import { config } from './config'
import './global.scss';


// import { connectWS } from './actions/websocket'

console.log('RHFW', config.version, config.isLocal ? 'local' : 'online')

if (!config.isLocal) {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path={`${config.path}/demo20220307/`} element={<Screen />} />
        <Route path={`${config.path}/`} element={
          (config.isLocal) ? <Mobile /> : <Mobile />
        } />
        <Route path={`${config.path}/screen/`} element={<Screen />} />
        <Route path={`${config.path}/screen/:id`} element={<Screen />} />
        <Route path={`${config.path}/mobile/`} element={<Mobile />} />
        <Route path={`${config.path}/mobile/:id`} element={<Mobile />} />
        <Route path={`${config.path}/led`} element={<LedController />} />
        {
          (config.isLocal) ? <Route path={`${config.path}/admin/`} element={<Admin />} /> : ''
        }
        <Route path={`${config.path}/checkin/`} element={<CheckIn />} />
        <Route path={`${config.path}/checkin/:qrcode`} element={<CheckIn />} />
        <Route path={`${config.path}/outside/`} element={<Outside />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

