import { FC, useEffect, useState } from 'react'
import { config } from './../config';
import Canvas from '../ui/Canvas';
import './Mobile.scss'
import { connectWS } from '../actions/websocket';

// const ws = config.isLocal ? connectWS('mobile') : undefined

const Mobile: FC = () => {

    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    })

    function handleMouseMove(ev: any) { setMousePosition({ left: ev.pageX, top: ev.pageY }); }

    return (
        <div
            onMouseMove={(ev) => handleMouseMove(ev)}
        >
            {
                (!config.isLocal)
                    ? <div><h2> Mobile {config.version} {/* x: {MousePosition.left}, y: {MousePosition.top} */}</h2></div>
                    : ''
            }
            <Canvas
                type={'mobile'}
                mouse={MousePosition}
            // ws={ws}
            />
        </div>
    );
}

export default Mobile