/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from './../../../actions/animateHelper'

const logoColor = '#ffffff'

export const animation = () => {

    var lib = {}
    var p
    
    
(lib.slogan = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#57A28D").s().p("AimAVIAAgpIFOAAIAAApg");
        this.shape.setTransform(163.9928,5.1747,2.4477,2.4439);
    
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("Aj6AVIAAgpIH1AAIAAApg");
        this.shape_1.setTransform(61.4938,5.1747,2.4477,2.4439);
    
        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgSAQIAHgIQAGAFAFAAQAFAAAAgDQAAgBAAAAQAAgBgBAAQAAgBgBAAQAAAAgBAAIgHgCQgMgDAAgJQAAgGAFgEQAFgFAIAAQALAAAGAGIgHAJQgFgFgFAAQgEAAAAADQAAABAAAAQAAABABAAQAAAAABABQAAAAABAAIAHACQAMADAAAKQAAAOgTAAQgMAAgGgHg");
        this.shape_2.setTransform(144.6158,59.6213,2.4475,2.4434);
    
        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAGgGQAFgHAJAAQATAAAAATIgBAHIgZAAQACAIAGAAQAFAAAGgEIAGAJQgHAGgKAAQgVAAAAgXgAgGgEIANAAIAAgBQAAgGgGAAQgFAAgCAHg");
        this.shape_3.setTransform(133.9079,59.6213,2.4475,2.4434);
    
        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgIAWIgPgrIAOAAIAJAeIAJgeIAPAAIgQArg");
        this.shape_4.setTransform(122.6494,59.6213,2.4475,2.4434);
    
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgGAiIAAgsIANAAIAAAsgAgHgZQAAgHAHgBQAIABAAAHQAAAIgIAAQgHAAAAgIg");
        this.shape_5.setTransform(113.7771,56.8725,2.4475,2.4434);
    
        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AADAiQgMAAAAgOIAAg1IANAAIAAAzQAAABAAAAQABABAAAAQABAAAAABQABAAABAAIACAAIAAANg");
        this.shape_6.setTransform(108.1479,56.9336,2.4475,2.4434);
    
        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgRASIAMAAQAAAEAGAAQAHAAAAgGIAAgHQgFAFgFAAQgSAAAAgXQAAgXASAAQAFAAAGAFIAAgEIAMAAIAAAuQAAASgUAAQgSAAAAgPgAgHgJQAAAKAIAAQAEAAADgBIAAgRQgCgCgFAAQgIAAAAAKg");
        this.shape_7.setTransform(94.0747,61.9425,2.4475,2.4434);
    
        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AAHAXIAAgZQAAgHgHAAQgDAAgDACIAAAeIgOAAIAAgsIAOAAIAAAFQAEgGAHAAQAPAAABASIAAAbg");
        this.shape_8.setTransform(82.0207,59.4991,2.4475,2.4434);
    
        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgFAiIAAgsIAMAAIAAAsgAgHgZQAAgHAHgBQAIABAAAHQAAAIgIAAQgHAAAAgIg");
        this.shape_9.setTransform(72.9649,56.8725,2.4475,2.4434);
    
        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQAOAAAFAKIgKAHQgEgFgEAAQgHAAAAAKQAAALAHAAQAFAAADgFIAKAHQgFAKgOAAQgUAAAAgXg");
        this.shape_10.setTransform(65.0717,59.6213,2.4475,2.4434);
    
        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AAHAXIAAgZQAAgHgHAAQgDAAgDACIAAAeIgOAAIAAgsIAOAAIAAAFQAEgGAIAAQAPAAAAASIAAAbg");
        this.shape_11.setTransform(53.446,59.4991,2.4475,2.4434);
    
        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#FFFFFF").s().p("AgPAUQgEgEAAgGQAAgNAVgBIAEAAIAAgCQAAgFgGAAQgFAAAAADIAAACIgMAAIAAgDQAAgGAFgEQAFgDAIAAQATAAAAAQIAAAcIgNAAIAAgFQgFAGgIAAQgFAAgEgDgAACADQgHACAAAEQAAAEAFAAQADAAADgDIAAgHg");
        this.shape_12.setTransform(41.4532,59.6213,2.4475,2.4434);
    
        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#FFFFFF").s().p("AAHAiIAAgaQAAgHgHAAQgEAAgCACIAAAfIgOAAIAAhDIAOAAIAAAbQAFgFAHAAQAPAAAAARIAAAcg");
        this.shape_13.setTransform(29.9499,56.8725,2.4475,2.4434);
    
        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AAHAXIAAgZQAAgHgHAAQgDAAgDACIAAAeIgOAAIAAgsIAOAAIAAAFQAEgGAIAAQAPAAAAASIAAAbg");
        this.shape_14.setTransform(17.7124,59.4991,2.4475,2.4434);
    
        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#FFFFFF").s().p("AgVAhIAAhBIArAAIAAAMIgcAAIAAAPIAXAAIAAALIgXAAIAAAOIAcAAIAAANg");
        this.shape_15.setTransform(5.2913,57.0558,2.4475,2.4434);
    
        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#FFFFFF").s().p("AgSAPIAHgIQAFAGAGAAQAFAAAAgDQAAgBAAAAQAAAAgBgBQAAAAgBgBQAAAAgBgBIgHgBQgMgDAAgJQAAgPASAAQALAAAGAHIgHAIQgFgFgGAAQgDAAAAADQAAABAAAAQAAABABAAQAAABABAAQAAAAABABIAGABQANADAAAJQAAAPgTAAQgNAAgFgIg");
        this.shape_16.setTransform(200.1742,33.6605,2.4475,2.4434);
    
        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#FFFFFF").s().p("AgSAPIAHgIQAFAGAGAAQAFAAAAgDQAAgBAAAAQAAAAgBgBQAAAAgBgBQAAAAgBgBIgHgBQgMgDAAgJQAAgGAEgEQAFgFAJAAQALAAAGAHIgHAIQgFgFgGAAQgDAAAAADQAAABAAAAQAAABABAAQAAABAAAAQABAAABABIAGABQANADAAAJQAAAPgTAAQgNAAgFgIg");
        this.shape_17.setTransform(189.7723,33.6605,2.4475,2.4434);
    
        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQATAAAAATIgBAIIgYAAQABAHAGAAQAGgBAFgDIAGAIQgHAHgKAAQgVAAAAgXgAgFgEIAMAAIAAgBQAAgGgGAAQgFAAgBAHg");
        this.shape_18.setTransform(179.0033,33.6605,2.4475,2.4434);
    
        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#FFFFFF").s().p("AgOAWIAAgqIANAAIAAAGQAEgHAHgBIAFABIgBAOIgIgBQgFAAgBAEIAAAag");
        this.shape_19.setTransform(169.5804,33.5383,2.4475,2.4434);
    
        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#FFFFFF").s().p("AgRARIAMAAQAAAFAGAAQAHAAAAgGIAAgHQgFAEgFAAQgSAAAAgWQAAgXASAAQAGAAAFAEIAAgDIAMAAIAAAuQAAAIgFAFQgGAFgJAAQgSAAAAgQgAgGgJQAAAKAHAAQAFAAACgBIAAgSQgDgBgEAAQgHAAAAAKg");
        this.shape_20.setTransform(158.4442,36.0427,2.4475,2.4434);
    
        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#FFFFFF").s().p("AgUAAQAAgWAUAAQAVAAAAAWQAAAXgVAAQgUAAAAgXgAgGAAQAAALAGAAQAHAAAAgLQAAgKgHAAQgGAAAAAKg");
        this.shape_21.setTransform(146.5738,33.6605,2.4475,2.4434);
    
        this.shape_22 = new cjs.Shape();
        this.shape_22.graphics.f("#FFFFFF").s().p("AgOAWIAAgqIANAAIAAAGQAEgHAHgBIAFABIgBAOIgIgBQgEAAgCAEIAAAag");
        this.shape_22.setTransform(136.8449,33.5383,2.4475,2.4434);
    
        this.shape_23 = new cjs.Shape();
        this.shape_23.graphics.f("#FFFFFF").s().p("AgVAhIAAhAIAOAAIAAADQAEgEAGAAQASAAAAAXQAAAWgSAAQgFAAgFgDIAAAXgAgHgRIAAARIAHABQAIAAgBgKQABgLgIAAQgEAAgDADg");
        this.shape_23.setTransform(126.0759,36.1038,2.4475,2.4434);
    
        this.shape_24 = new cjs.Shape();
        this.shape_24.graphics.f("#FFFFFF").s().p("AgRARIALAAQAAAFAHAAQAHAAAAgGIAAgHQgFAEgEAAQgUAAAAgWQAAgXAUAAQAFAAAFAEIAAgDIAMAAIAAAuQABAIgGAFQgGAFgJAAQgSAAAAgQgAgGgJQAAAKAHAAQAFAAACgBIAAgSIgHgBQgHAAAAAKg");
        this.shape_24.setTransform(108.9433,36.0427,2.4475,2.4434);
    
        this.shape_25 = new cjs.Shape();
        this.shape_25.graphics.f("#FFFFFF").s().p("AAHAWIAAgYQAAgHgHAAQgCAAgEACIAAAdIgOAAIAAgqIAOAAIAAAEQADgFAJgBQAPAAAAASIAAAag");
        this.shape_25.setTransform(96.8893,33.5383,2.4475,2.4434);
    
        this.shape_26 = new cjs.Shape();
        this.shape_26.graphics.f("#FFFFFF").s().p("AgGAhIAAgrIANAAIAAArgAgHgZQAAgHAHAAQAIAAAAAHQAAAIgIAAQgHAAAAgIg");
        this.shape_26.setTransform(87.8335,30.9117,2.4475,2.4434);
    
        this.shape_27 = new cjs.Shape();
        this.shape_27.graphics.f("#FFFFFF").s().p("AgOAWIAAgqIAOAAIAAAGQADgHAHgBIAFABIgBAOIgIgBQgFAAgBAEIAAAag");
        this.shape_27.setTransform(81.1641,33.5383,2.4475,2.4434);
    
        this.shape_28 = new cjs.Shape();
        this.shape_28.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQATAAAAATIgBAIIgYAAQABAHAHAAQAFgBAFgDIAGAIQgHAHgJAAQgWAAAAgXgAgFgEIAMAAIABgBQAAgGgHAAQgFAAgBAHg");
        this.shape_28.setTransform(70.8233,33.6605,2.4475,2.4434);
    
        this.shape_29 = new cjs.Shape();
        this.shape_29.graphics.f("#FFFFFF").s().p("AgTAAQAAgJAFgGQAGgHAJAAQAJAAAGAGQAEAGAAAHIgBAIIgYAAQAAAHAHAAQAGgBAFgDIAGAIQgHAHgKAAQgVAAAAgXgAgFgEIANAAIAAgBQgBgGgGAAQgGAAAAAHg");
        this.shape_29.setTransform(59.626,33.6605,2.4475,2.4434);
    
        this.shape_30 = new cjs.Shape();
        this.shape_30.graphics.f("#FFFFFF").s().p("AAHAWIAAgYQAAgHgHAAQgDAAgDACIAAAdIgOAAIAAgqIAOAAIAAAEQADgFAJgBQAPAAAAASIAAAag");
        this.shape_30.setTransform(47.9391,33.5383,2.4475,2.4434);
    
        this.shape_31 = new cjs.Shape();
        this.shape_31.graphics.f("#FFFFFF").s().p("AgGAhIAAgrIANAAIAAArgAgHgZQAAgHAHAAQAIAAAAAHQAAAIgIAAQgHAAAAgIg");
        this.shape_31.setTransform(38.8833,30.9117,2.4475,2.4434);
    
        this.shape_32 = new cjs.Shape();
        this.shape_32.graphics.f("#FFFFFF").s().p("AgSARIANAAQAAAFAFAAQAIAAgBgGIAAgHQgEAEgFAAQgTAAABgWQgBgXATAAQAGAAAEAEIAAgDIAOAAIAAAuQgBAIgFAFQgGAFgKAAQgSAAAAgQgAgGgJQAAAKAGAAQAGAAABgBIAAgSQgCgBgFAAQgGAAAAAKg");
        this.shape_32.setTransform(29.7664,36.0427,2.4475,2.4434);
    
        this.shape_33 = new cjs.Shape();
        this.shape_33.graphics.f("#FFFFFF").s().p("AAHAWIAAgYQAAgHgHAAQgCAAgEACIAAAdIgOAAIAAgqIAOAAIAAAEQADgFAJgBQAPAAAAASIAAAag");
        this.shape_33.setTransform(17.7124,33.5383,2.4475,2.4434);
    
        this.shape_34 = new cjs.Shape();
        this.shape_34.graphics.f("#FFFFFF").s().p("AgVAhIAAhBIArAAIAAAMIgcAAIAAAPIAXAAIAAALIgXAAIAAAPIAcAAIAAAMg");
        this.shape_34.setTransform(5.2913,31.0338,2.4475,2.4434);
    
        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_34},{t:this.shape_33},{t:this.shape_32},{t:this.shape_31},{t:this.shape_30},{t:this.shape_29},{t:this.shape_28},{t:this.shape_27},{t:this.shape_26},{t:this.shape_25},{t:this.shape_24},{t:this.shape_23},{t:this.shape_22},{t:this.shape_21},{t:this.shape_20},{t:this.shape_19},{t:this.shape_18},{t:this.shape_17},{t:this.shape_16},{t:this.shape_15},{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.slogan, new cjs.Rectangle(-0.1,0,205.1,70), null);
    
    
    (lib.logoklein = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AhHEoQghgCgKgCQgVgFgTgQQgSgOgJgVQgLgZAAguIAAnMICeAAIgBHIQAAAMAIAJQAJAMASABIAAAAQASgBAKgMQAHgJAAgMIAAnIICdAAIABHMQAAAsgLAbQgJAVgSAOQgSAQgWAFQgKACghACg");
        this.shape.setTransform(264.225,60.4);
    
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AiGEoIAApPIEOAAIAABzIhuAAIAACCIBdAAIAABlIhdAAIAACCIBuAAIAABzg");
        this.shape_1.setTransform(132.95,60.4);
    
        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AAhEoIAAjXQgBgQgGgHQgHgIgOABIgnAAIAAD1IifAAIAApPIEwAAQAgAAAZAdQAZAdAAAnIABBkQAAAlgaAcQgYAbgeAEQAeACAYAaQAZAeAAAkIABDMgAgigyIAuAAQAWgCgBgcIAAhaQAAgegcAAIgnAAg");
        this.shape_2.setTransform(93.075,60.4);
    
        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AAiEoIAAj1IhBAAIAAD1IigAAIAAnuQAAgoAYgbQAYgdAmgBIEpAAIAAJPgAgYjBQgHAGAAARIAAB4IBAAAIAAiWIgkAAQgPAAgGAHg");
        this.shape_3.setTransform(218.775,60.4);
    
        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AAjEoIAAj1IhFAAIAAD1IihAAIAApPIChAAIAAD1IBFAAIAAj1ICgAAIABJPg");
        this.shape_4.setTransform(173.025,60.4);
    
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AAZgWQhdhPhnghQBjgXBVAEQApAEAkAXQAkAXAUAlQAVArAEA/QADAlgDBNQg0hihehOg");
        this.shape_5.setTransform(42.2818,15.4138);
    
        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgmB0QgpgKgwgYQgdgPg2giQDvABC2iaQghBogqA/QgfArgtATQgdAMgfAAQgSAAgUgFg");
        this.shape_6.setTransform(36.8,47.8573);
    
        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AhXBDQgTgsAIgxQAIg0AhgiQAfghAtgcQAZgQA6gfQg7BhgVBxQgWB0AXBzQhHhMgnhOg");
        this.shape_7.setTransform(10.1836,26.125);
    
        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.logoklein, new cjs.Rectangle(0,0,283.5,90), null);
    
    
    (lib.logo_u = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#555554").s().p("Am5GfQgngFghgNQgsgUghgoQghgogMgvQgFgYgChGIAAk4QAChFAFgXQANgxAggoQAhgnAsgTQAhgOAogFQAggEAygBIPpABIAAFWIvgAAQgaAAgVARQgaAUAAAmIAAADQAAAnAaAUQAVAQAaAAIPgAAIAAFWIvpAAQgyABghgFg");
        this.shape.setTransform(155.4989,101.4479,2.4183,2.4183);
    
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.logo_u, new cjs.Rectangle(0,0,311,202.9), null);
    
    
    (lib.logo_r = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#555554").s().p("AqCGlIAAlZIIWAAIAAhVQAAgggQgOQgQgOgjgBInTAAIAAleIG6AAQBRAAA/A4QA5A0AHBBQAHhBA6g0QA9g4BRAAIDaAAQBWABA/A3QA9A2AABGIAAKVgACCg7QgQALgDAWIAABmIFHAAIABhVQAAg+hDABIjDAAIgDAAQgbAAgRALg");
        this.shape.setTransform(155.4989,101.9163,2.4183,2.4183);
    
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.logo_r, new cjs.Rectangle(0,0,311,203.9), null);
    
    
    (lib.logo_h = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#555554").s().p("AqCGqIAAleIIWAAIAAiWIoWgBIAAlfIUFABIAAFeIoUAAIAACXIIUAAIAAFeg");
        this.shape.setTransform(155.4989,103.1604,2.4183,2.4183);
    
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.logo_h, new cjs.Rectangle(0,0,311,206.3), null);
    
    
    (lib.logo_e = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#555554").s().p("AqCkmID4AAIABDvIEdAAIAAjMIDbAAIAADMIEbgBIAAjuID5AAIAAJMI0FABg");
        this.shape.setTransform(155.4989,71.2679,2.4183,2.4183);
    
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.logo_e, new cjs.Rectangle(0,0,311,142.6), null);
    
    
    (lib.logo_a = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#555554").s().p("AGxGiIwzAAIAAldIIWAAIAAiNIoWAAIAAlZIUFAAIAAKIQgBBSg9A2Qg7AzhWAAIgDAAgABvBGIEFAAQAkAAAPgOQAOgOAAghIAAhPIlGAAg");
        this.shape.setTransform(155.5292,101.16,2.4183,2.4183);
    
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.logo_a, new cjs.Rectangle(0,0,311.1,202.3), null);
    
    
    (lib.linie = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").ss(2,1,1).p("A+6AAMA91AAA");
        this.shape.setTransform(197.925,0);
    
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.linie, new cjs.Rectangle(-1,-1,397.9,2), null);
    
    
    (lib.headline_l5 = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.txt = new cjs.Text("Energieerzeugung und Nutzung", "25px 'Brix Sans Regular'", "#FFFFFF");
        this.txt.name = "txt";
        this.txt.lineHeight = 34;
        this.txt.lineWidth = 441;
        this.txt.parent = this;
        this.txt.setTransform(2,2);
    
        this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.headline_l5, new cjs.Rectangle(0,0,445,39.9), null);
    
    
    (lib.headline_l4 = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.txt = new cjs.Text("Willkommen", "bold 35px 'Brix Sans Black'", "#FFFFFF");
        this.txt.name = "txt";
        this.txt.lineHeight = 46;
        this.txt.lineWidth = 441;
        this.txt.parent = this;
        this.txt.setTransform(2,2);
    
        this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.headline_l4, new cjs.Rectangle(0,0,445,67.4), null);
    
    
    (lib.headline_l3 = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.txt = new cjs.Text("Energieerzeugung und Nutzung", "25px 'Brix Sans Regular'", "#FFFFFF");
        this.txt.name = "txt";
        this.txt.lineHeight = 34;
        this.txt.lineWidth = 441;
        this.txt.parent = this;
        this.txt.setTransform(2,2);
    
        this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.headline_l3, new cjs.Rectangle(0,0,445,39.9), null);
    
    
    (lib.headline_l2 = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.txt = new cjs.Text("Future Windows Vision", "40px 'Brix Sans Regular'", "#FFFFFF");
        this.txt.name = "txt";
        this.txt.lineHeight = 52;
        this.txt.lineWidth = 441;
        this.txt.parent = this;
        this.txt.setTransform(2,2);
    
        this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.headline_l2, new cjs.Rectangle(0,0,445,55), null);
    
    
    (lib.headline_l1 = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.txt = new cjs.Text("Willkommen", "bold 53px 'Brix Sans Black'", "#FFFFFF");
        this.txt.name = "txt";
        this.txt.lineHeight = 68;
        this.txt.lineWidth = 441;
        this.txt.parent = this;
        this.txt.setTransform(2,2);
    
        this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.headline_l1, new cjs.Rectangle(0,0,445,67.4), null);
    
    
    (lib.frame = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#666666").ss(0.8).p("EB4kBclIgEAAQgKAAgKAAIAAAAQgVAAgUAAMhMAAAAMAAAi5JMBLyAAAMAAAC5JEAqYhdvMBOIAAAMAAAC6UEAnEhdvIDUAAMAAAC7fIAAABEgqXhdvIDTAAMAAAC7fIjTAAMhOIAAAMAAAi7fMBOIAAAMAAAC7fEgriBclMhLyAAAMAAAi5JMBLyAAAgEArjBdxIAAgBIAAhLEArjBdwIhLAAIjUAAIAAABEAnEBdwIhLAAMhM9AAAEAl5BclIAABLIAAABEgnEhdvMBOIAAAMAAAC7fEgl4hckMBLxAAAEAl5BclMhLxAAAMAAAitlEAl5hRAMAAACtlEB4kBdwMhNBAAA");
        this.shape.setTransform(771.2,600);
    
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#CCCCCC").s().p("EAqWBdwIAAAAIBLAAIAAAAgEAqWBdwIjUAAIAAAAIhLAAIAAhLMAAAitlIAArkMhLxAAAIAALkMAAACtlMBLxAAAIAABLMhM8AAAMAAAi7fMAAAC7fIjUAAMAAAi7fIDUAAMBOHAAAMAAAC7fMAAAi7fIDUAAMAAAC7fIjUAAIDUAAIAAAAgEAnCBdwIhLAAIAAAAIBLAAIAAAAgEArhBdwIAAhLIAABLIhLAAMAAAi7fMBOIAAAMAAAC6UIgUAAIAAAAIgpAAIApAAIAAAAIAUAAIAEAAIAABLgEArhBclMBMAAAAIgOgBMAAAi5IMhLyAAAgEAqWBdwgEh4hBdwMAAAi7fMBOIAAAMAAAC7fgEh3WBclMBLyAAAMAAAi5JMhLyAAAgEAl3Bclg");
        this.shape_1.setTransform(771.375,600.025);
    
        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.frame, new cjs.Rectangle(-1,-1,1544.8,1202.1), null);
    
    
    (lib.logo = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.r = new lib.logo_r();
        this.r.name = "r";
        this.r.setTransform(163.75,1051.75,1,1,0,0,0,155.5,101.9);
    
        this.e = new lib.logo_e();
        this.e.name = "e";
        this.e.setTransform(163.75,841.85,1,1,0,0,0,155.5,71.2);
    
        this.h = new lib.logo_h();
        this.h.name = "h";
        this.h.setTransform(163.75,630.9,1,1,0,0,0,155.5,103.2);
    
        this.a = new lib.logo_a();
        this.a.name = "a";
        this.a.setTransform(163.75,390.1,1,1,0,0,0,155.5,101.2);
    
        this.u = new lib.logo_u();
        this.u.name = "u";
        this.u.setTransform(163.75,151,1,1,0,0,0,155.5,101.5);
    
        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.u},{t:this.a},{t:this.h},{t:this.e},{t:this.r}]}).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.logo, new cjs.Rectangle(8.3,49.5,311,1104.2), null);
    
    
    (lib.headline = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // l1
        this.l4 = new lib.headline_l4();
        this.l4.name = "l4";
        this.l4.setTransform(222.5,154,1,1.0096,0,0,0,222.5,33.7);
    
        this.l1 = new lib.headline_l1();
        this.l1.name = "l1";
        this.l1.setTransform(222.5,-46,1,1.0096,0,0,0,222.5,33.7);
    
        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.l1},{t:this.l4}]}).wait(1));
    
        // l3
        this.linie = new lib.linie();
        this.linie.name = "linie";
        this.linie.setTransform(197.95,83.95,1,1,0,0,0,197.9,0);
    
        this.l5 = new lib.headline_l5();
        this.l5.name = "l5";
        this.l5.setTransform(222.5,191.5,1,1,0,0,0,222.5,27.5);
    
        this.l3 = new lib.headline_l3();
        this.l3.name = "l3";
        this.l3.setTransform(222.5,53.5,1,1,0,0,0,222.5,27.5);
    
        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.l3},{t:this.l5},{t:this.linie}]}).wait(1));
    
        // l2
        this.l2 = new lib.headline_l2();
        this.l2.name = "l2";
        this.l2.setTransform(222.5,9.5,1,1,0,0,0,222.5,27.5);
    
        this.timeline.addTween(cjs.Tween.get(this.l2).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.headline, new cjs.Rectangle(-0.9,-80,445.9,283.9), null);
    
    
    (lib.outside = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // FlashAICB
        this.logo_small = new lib.logoklein();
        this.logo_small.name = "logo_small";
        this.logo_small.setTransform(887.3,269.3,0.5503,0.5497,0,0,0,142.3,45);
    
        this.timeline.addTween(cjs.Tween.get(this.logo_small).wait(1));
    
        // slogan
        this.slogan = new lib.slogan();
        this.slogan.name = "slogan";
        this.slogan.setTransform(167.45,97.05,1,1,0,0,0,102.5,35);
    
        this.timeline.addTween(cjs.Tween.get(this.slogan).wait(1));
    
        // headline
        this.headline = new lib.headline();
        this.headline.name = "headline";
        this.headline.setTransform(785.15,535.55,1,1,0,0,0,222.5,58.5);
    
        this.timeline.addTween(cjs.Tween.get(this.headline).wait(1));
    
        // logo
        this.logo = new lib.logo();
        this.logo.name = "logo";
        this.logo.setTransform(1386.35,552.1,1,1,0,0,180,155.5,552.1);
    
        this.timeline.addTween(cjs.Tween.get(this.logo).wait(1));
    
        // frame
        this.frame = new lib.frame();
        this.frame.name = "frame";
        this.frame.setTransform(771.4,600,1,1,0,0,0,771.4,600);
    
        this.timeline.addTween(cjs.Tween.get(this.frame).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.outside, new cjs.Rectangle(-0.4,-0.4,1543.6000000000001,1200.9), null);
    

    return lib
}
