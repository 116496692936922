import { config } from '../config';
import { resetScreensaver } from './screensaver';
import * as Tuio from './tuio/Tuio'


function click(canvas, x, y) {
    document
        .getElementById('canvas').dispatchEvent(
            new MouseEvent(
                "mousedown", // or "mousedown" if the canvas listens for such an event
                {
                    clientX: x,
                    clientY: y,
                    bubbles: true
                }
            )
        );
    // console.log(document.getElementById('canvas'))
}


function move(canvas, x, y) {
    document
        .getElementById('canvas').dispatchEvent(
            new MouseEvent(
                "mousemove", // or "mousedown" if the canvas listens for such an event
                {
                    clientX: x,
                    clientY: y,
                    bubbles: true
                }
            )
        );
    // console.log(document.getElementById('canvas'))
}

// 1920    1850
// 1200    1156
const offX = 215
const offY = 20
const offMoveY = 80

export const radarTouch = (canvas, context) => {

    // console.log(config.radarTouch.url + ':' + config.radarTouch.port)

    var client = new Tuio.Client({
        host: config.radarTouch.url + ':' + config.radarTouch.port // "http://192.168.20.119:5005"
    }),

        objSize = 10,
        clickTimeout = null,
        lockHand = false,
        screenW = 1470, // window.innerWidth,
        screenH = 1200, // window.innerHeight,

        onAddTuioCursor = function (addCursor) {
            let x = offX + addCursor.getScreenX(screenW)
            let y = screenH + offY - addCursor.getScreenY(screenH)
            // if (document) {
            //     // document.elementFromPoint(x, y).click();
            // }
            // clickTimeout = setTimeout(function () {
            //     lockHand = false
            // }, 500);
            // if (!lockHand) {
                click(canvas, x, y)
                console.log('click')
                // lockHand = true
            // }
            console.log('onAddTuioCursor', addCursor, x, y);
        },

        onUpdateTuioCursor = function (updateCursor) {
            //   console.log('onUpdateTuioCursor', updateCursor);
            let x = offX + updateCursor.getScreenX(screenW)
            let y = screenH + offY + offMoveY - updateCursor.getScreenY(screenH)
            let yClick = screenH + offY - updateCursor.getScreenY(screenH)

            if (!updateCursor.hasCLicked && updateCursor.startTime.seconds + 1 === updateCursor.currentTime.seconds ) {
                console.log('CLICK MOVE', y, x, updateCursor.currentTime.seconds)
                updateCursor.hasCLicked = true
                click(canvas, x, yClick)
            }

            if (updateCursor.startTime.seconds + 5 === updateCursor.currentTime.seconds ) {
                console.log('CLICK RESET')
                window.location.reload()
            }

            // console.log('Y', y, x, updateCursor.hasCLicked)
            move(canvas, x, y)
        },

        onRemoveTuioCursor = function (removeCursor) {
            console.log('onRemoveTuioCursor', removeCursor);
            let x = offX + removeCursor.getScreenX(screenW)
            let y = screenH + offY - removeCursor.getScreenY(screenH)
            
            // clickTimeout = setTimeout(function () {
            //     lockHand = false
            // }, 500);
            // if (!lockHand) {
                // click(canvas, x, y)
                // lockHand = true
            // }
        },

        onAddTuioObject = function (addObject) {
            console.log('onAddTuioObject', addObject);

        },

        onUpdateTuioObject = function (updateObject) {
            // console.log('onUpdateTuioObject', updateObject);
        },

        onRemoveTuioObject = function (removeObject) {
            // console.log('onRemoveTuioObject', removeObject);
        },

        drawCursor = function (cursor) {
            let x = offX + cursor.getScreenX(screenW)
            let y = screenH + offY - cursor.getScreenY(screenH)
            context.fillStyle = "#848484";
            context.beginPath();
            context.arc(
                x,
                y,
                objSize * 0.5,
                0,
                Math.PI * 2
            );
            // console.log(cursor.getScreenX(screenW), cursor.getScreenY(screenH))
            context.closePath();
            context.fill();
            // console.log(cursor.state)
            // document.elementFromPoint(x, y).click();
        },


        drawObject = function (object) {
            context.save();
            console.log('cccccc')

            context.translate(
                object.getScreenX(screenW) + objSize * 0.5,
                object.getScreenY(screenH) + objSize * 0.5
            );
            context.rotate(object.getAngle());

            context.fillStyle = "#ffff00";
            context.fillRect(-objSize * 0.5, -objSize * 0.5, objSize, objSize);

            context.restore();
        },

        onRefresh = function (time) {
            draw()
            // console.log('time', time);
        },

        draw = function () {
            requestAnimationFrame(draw);
            var cursors = client.getTuioCursors()
            // console.log('cursors', cursors);

            // var objects = client.getTuioObjects();

            for (var i in cursors) {
                drawCursor(cursors[i]);

            }

            // for (var i in objects) {
            //     drawObject(objects[i]);
            // }
        };

    client.on("addTuioCursor", onAddTuioCursor);
    client.on("updateTuioCursor", onUpdateTuioCursor);
    client.on("removeTuioCursor", onRemoveTuioCursor);
    client.on("addTuioObject", onAddTuioObject);
    client.on("updateTuioObject", onUpdateTuioObject);
    client.on("removeTuioObject", onRemoveTuioObject);
    client.on("refresh", onRefresh);
    client.on("connect", () => { console.log('Radartouch connected') });
    client.connect();
}