import * as createjs from 'createjs-module'
import { getContent } from './../../config/getContent'
import { setHeadlineContent, setHeadlineMain } from './../uiHelpers'
import { animateBtnLanguage } from './navigationAnimation'
import { uiSound } from './../audio/audioPlayer'

// mc1.mc2.mc3 Deep MC return
function resolve(path, obj = [], separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export const switchLanguage = (root) => {
    // console.log('switchLanguage', root.status.language, root.status.currentPage)

    // Sound
    uiSound(root, 'click')

    root.status.language = (root.status.language === 'de') ? 'en' : 'de'
    const lang = root.status.language

    const content = getContent((root.status.currentPage) ? root.status.currentPage.id : '', !(root.status.currentPage))

    // console.log('switchLanguage', root.status.language, root.status.currentPage, content)

    // Headline Mitte
    if (!root.status.level) {
        setHeadlineMain(root, content.headline)
    } else {
        // wenn mittle Animation
        if (root.status.currentAnimationCenter !== '') {
            setHeadlineMain(root, root.status.currentPage.headline, true)
        } else {
            setHeadlineMain(root, root.status.level.headline, true)
        }
    }

    // Navigation
    const contentNavi = getContent()
    switchButtonLanguage(root, contentNavi, lang)

    // headline rechts, nur wenn Subseite
    if (root.status.currentPage && root.status.level && root.status.currentPage.id !== root.status.level.id) {
        // nur, wenn keine Center Animation
        if (root.status.currentAnimationCenter === '') {
            setHeadlineContent(root, root.status.currentPage.headline, 'switchLanguage')
        }
    }

    // Animationen
    if (root.status && root.status.currentPage && root.status.currentPage.animation && root.status.currentPage.animation.texte) {
        let ani = 'animations_' + root.status.currentPage.animation.right

        Object.keys(root.status.currentPage.animation.texte).forEach(function (mc) {
            let txtObj = root.status.currentPage.animation.texte[mc]

            // console.log(txtObj, mc, ani)
            if (root[ani].sub_ani_01_mc && root[ani].sub_ani_01_mc[mc] && root[ani].sub_ani_01_mc[mc].text) {
                root[ani].sub_ani_01_mc[mc].text = txtObj[lang] ? txtObj[lang] : 'tbd: ' + mc + ':' + lang
            } else {

                let mcText = resolve(mc, root[ani])
                if (mcText && mcText.text) {
                    mcText.text = txtObj[lang] ? txtObj[lang] : 'tbd: ' + lang
                } else if (root[ani].sub_ani_01_mc) {
                    // console.log('Text not found', ani, mc, root[ani])
                    let mcText = resolve(mc, root[ani].sub_ani_01_mc)
                    if (mcText && mcText.text) {
                        mcText.text = txtObj[lang] ? txtObj[lang] : 'tbd: ' + lang
                    } else if (mcText) {
                        // console.log('xxx', mcText)
                        mcText = txtObj[lang] ? txtObj[lang] : 'tbd: ' + lang
                    } else {
                        console.log('Text not found 1', mcText, ani, mc, root[ani])
                    }
                } else {
                    console.log('Text not found 2', ani, mc, root[ani])
                }
            }
        });
    }

    // switch de|en Button
    animateBtnLanguage(root, root.status.language)
}

const switchButtonLanguage = (root, contentNavi, lang) => {

    contentNavi.forEach((menu) => {
        // setHeadlineMain(root, menu.headline)

        let btn = root['btn_' + menu.id]
        // console.log('switchLanguage 2', menu, btn)

        let l1 = (menu.btn.label[lang]) ? menu.btn.label[lang].l1 : menu.btn.label.l1
        let l2 = (menu.btn.label[lang]) ? menu.btn.label[lang].l2 : menu.btn.label.l2


        createjs.Tween.get(btn.label)
            .to({
                alpha: 0
            }, 400, createjs.Ease.quadOut).call((ev) => {

                ev.target.l1.txt.text = l1
                ev.target.l2.txt.text = l2
            }).to({
                alpha: 1
            }, 400, createjs.Ease.quadOut);


        // SubNavi
        const contentSubNavi = getContent(menu.id)
        switchButtonLanguage(root, contentSubNavi, lang)
    })
}

export const translate = (root, str) => {
    if (root.status && root.status.currentPage && root.status.currentPage.animation) {
        const lang = root.status.language
        return root.status.currentPage.animation.texte[str][lang] ?? 'tbd: ' + lang + ': ' + str

    } else {

        return str
    }


}