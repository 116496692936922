import * as createjs from 'createjs-module'
import { setWindow, setWindowSeqment } from './../window/windowHelper'

// Flash
import { animation as safearea } from './../../assets/animate/helper/safearea';

// Anzahl Lamellen/ Segmente
const lamellenCount = 16

// Logo
const logoBgWindow = 4

// Logo R E H A U
const logoWindow = 3
const logoParts = ['r', 'e', 'h', 'a', 'u']

// Segmente, Lamellen
const segmentWindows = [2, 1]
const segmentWindowsX = [720, 1240]


// Debug Buttons
export function initDebug(root) {

    // console.log(root)

    // Logo
    root.safearea.logo.bg.on('click', function () {
        // console.log('bg')
        if (this.alpha === 1) {
            setWindow(logoBgWindow, 'off')
            this.alpha = 0.5
        } else {
            setWindow(logoBgWindow, 'bg')
            this.alpha = 1
        }


    })

    // Logo R E H A U
    logoParts.forEach((part, nr) => {
        root.safearea.logo[part].on('click', function () {
            if (part === 'r' && this.alpha === 1) {
                setWindow(logoWindow, 'off')
                setButtonView(root, logoParts, -1)
            } else {
                setWindow(logoWindow, part)
                this.alpha = 1
                setButtonView(root, logoParts, nr)
            }
        })
    });

    // Lamellen
    const safearea_lib = safearea()

    var lamelleY = 0
    var wHeight = 1200
    var lHeight = (wHeight / lamellenCount)

    segmentWindows.forEach((windowNr, nr) => {
        var lamelleX = segmentWindowsX[nr]

        for (let l = lamellenCount; l > 0; l--) {
            root['lamelle_' + windowNr + '_' + l] = new safearea_lib.lamelle()
            root['lamelle_' + windowNr + '_' + l].setTransform(
                lamelleX,
                lamelleY + (wHeight - (l * lHeight))
            );
            root['lamelle_' + windowNr + '_' + l].on('click', function () {
                // console.log('lamelle', windowNr, l)
                if (l === 1 && this.alpha === 1) {
                    setWindowSeqment(windowNr, 0)
                    setSegmentView(root, windowNr, 0)
                } else {
                    setWindowSeqment(windowNr, l+1)
                    setSegmentView(root, windowNr, l)
                }

            })
            root.timeline.addTween(createjs.Tween.get(root['lamelle_' + windowNr + '_' + l]).wait(1));
            // console.log(windowNr, lamelleY + (l * lHeight))
        }
    });

    root.safearea.lamelle.visible = false
}



// Debug Buttons
export function initScreenDebug(root) {

    // console.log(root)
    root.safearea.logo.bg.visible = false
    root.safearea.logo.alpha = 0.2
    root.safearea.lamelle.visible = false
}


const setButtonView = (root, parts, pos) => {
    parts.forEach((part, nr) => {
        root.safearea.logo[part].alpha = (pos >= nr) ? 1 : 0.2
    });
}

const setSegmentView = (root, windowNr, pos) => {
    for (let l = lamellenCount; l > 0; l--) {
        // console.log('lamelle_' + windowNr + '_' + l)
        root['lamelle_' + windowNr + '_' + l].alpha = (pos >= l) ? 1 : 0.2
    };
}