import * as createjs from 'createjs-module'

import { saveMcPos } from './../animateHelper'
import { getContent } from '../../config/getContent'
import { setHeadlineContent, setHeadlineMain } from '../uiHelpers';
import { showHideNavi } from './../navigation/navigationAnimation'

import { animationLib } from './../../assets/animate/animationLib'
import { setLamellenState, setWindowState } from '../window/windowHelper';
import { config } from '../../config';
import { fanApi } from '../fanApi';
import { animateScreenLogo } from '../logoHelper';

// const contentFolder = "assets/animate/content"

var animationPos = {
    top: 600,
    left: 1230
}
const aniPosCenterX = 720
const aniPosRightX = 1230

const aniPosCenterMobileX = 0
const aniPosRightMobileX = 500

// mc1.mc2.mc3 Deep MC return
function resolve(path, obj = [], separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

// fade in
export const showAnimation = (root, nameAni, pos = 'right', secondAni = '', animationData = {}, menu = {}) => {

    let ani = 'animations_' + nameAni
    const lang = root.status.language

    const mouseEvent = (root.mode === 'screen')
        ? config.screen.mouseEvent // mousedown
        : config.mobile.mouseEvent // click

    // reset Spracherkennung
    root.status.speech = ''

    console.log('showAnimation', pos, ani)

    if (root[ani]) {
        root[ani].visible = true
        root[ani].alpha = 0
        root[ani].y = root.mcPos[ani].y + 30

        if (pos === 'center') {
            root[ani].x = (root.mode !== 'mobile') ? aniPosCenterX : aniPosCenterMobileX
        } else {
            // root[ani].x = animationPos.left

            // Übersetzung
            console.log(animationData)
            if (animationData.texte) {
                Object.keys(animationData.texte).forEach(function (mc) {
                    let txtObj = animationData.texte[mc]

                    // console.log(txtObj, mc)
                    if (root[ani].sub_ani_01_mc && root[ani].sub_ani_01_mc[mc] && root[ani].sub_ani_01_mc[mc].text) {
                        root[ani].sub_ani_01_mc[mc].text = txtObj[lang] ? txtObj[lang] : 'tbd: ' + lang
                    } else {

                        let mcText = resolve(mc, root[ani])
                        if (mcText && mcText.text) {
                            mcText.text = txtObj[lang] ? txtObj[lang] : 'tbd: ' + lang
                        } else if (root[ani].sub_ani_01_mc) {
                            // console.log('Text not found', ani, mc, root[ani])
                            let mcText = resolve(mc, root[ani].sub_ani_01_mc)
                            if (mcText && mcText.text) {
                                mcText.text = txtObj[lang] ? txtObj[lang] : 'tbd: ' + lang
                            } else if (mcText) {
                                mcText = txtObj[lang] ? txtObj[lang] : 'tbd: ' + lang
                            } else {
                                console.log('Text not found 1', mcText, ani, mc, root[ani])
                            }
                        } else {
                            console.log('Text not found 2', ani, mc, root[ani])
                        }
                    }
                });
            }
        }

        // console.log('showAnimation', root[ani])

        if ((pos === 'right' && secondAni === '') || pos === 'center') {
            // Play auto right
            root[ani].gotoAndPlay(0)
            if (root[ani].sub_ani_01_mc) {
                root[ani].sub_ani_01_mc.gotoAndPlay(0)

                if (root[ani].sub_ani_01_mc.frame_0) {
                    root[ani].sub_ani_01_mc.isLoaded = true
                }

                if (root[ani].sub_ani_01_mc.mc_04 && root[ani].sub_ani_01_mc.mc_04.txt_mc && root[ani].sub_ani_01_mc.mc_04.txt_mc.txt_01) {
                    root[ani].sub_ani_01_mc.mc_04.txt_mc.txt_01.text = 'Hello World'
                }
            }

            if (root[ani].goHome) {
                root[ani].goHome()
            }
        } else if (pos === 'right' && secondAni !== '') {
            // console.log('stop', root[ani])

            if (!root[ani].isAutoplay) {
                root[ani].gotoAndStop(0)
                root[ani].stop()
                if (root[ani].sub_ani_01_mc) {
                    root[ani].sub_ani_01_mc.gotoAndStop(0)
                }
            } else {
                root[ani].gotoAndPlay(0)
                root[ani].play()
                if (root[ani].sub_ani_01_mc) {
                    root[ani].sub_ani_01_mc.gotoAndPlay(0)
                }
            }

        }

        if (pos === 'center' && secondAni !== '') {
            if (root[ani].startTwin) {
                root[ani].startTwin = function () {
                    // console.log('GO TWIN GO', root, this.parent)
                    root['animations_' + secondAni].gotoAndPlay(0)
                    root['animations_' + secondAni].goHome()

                    // Rechte Headline
                    if (root.status.currentPage) {
                        setHeadlineContent(root, root.status.currentPage.headline, 'animationHelper center')
                    }

                    // mitte wieder Menü einblenden
                    showHideNavi(root, 'in')

                    // ANi mitte ausblenden
                    hideAnimation(root, nameAni)

                    // console.log('###### LOGO ', menu.logo)

                    if (menu.logo) {
                        animateScreenLogo(root, menu.logo)
                    } else {
                        if (!menu.window) {
                            // Lamelle rechts
                            setWindowState(root, 'center-right')
                        } else {
                            // spezielle WIndow ANi
                            menu.window.forEach((w) => {
                                setLamellenState(root, w.state, w.window)
                            });
                        }

                    }

                    // Mitte Titel
                    if (root.status.level) {
                        setHeadlineMain(root, root.status.level.headline, true)
                    }
                }
            }

            if (root[ani].startRight && root[ani].visible) {
                // console.log('#############', root['animations_' + secondAni].y)
                // root['animations_' + secondAni].y = 200
                root[ani].startRight = function () {
                    // console.log('GO RIGHT GO', root, this.parent)
                    root['animations_' + secondAni].gotoAndPlay(0)
                    root['animations_' + secondAni].goHome()
                    // root['animations_' + secondAni].y = 200

                    // spezielle WIndow ANi
                    menu.window.forEach((w) => {
                        setLamellenState(root, w.state, w.window)
                    })
                }

            }
        }

        if (pos === 'right') {
            if (menu.logo) {
                animateScreenLogo(root, menu.logo)
            } else {
                animateScreenLogo(root, 'out')
            }
            // if (root[ani].sub_ani_01_mc && root[ani].sub_ani_01_mc.action_btn) {
            // root[ani].btnIsActive = false
            // root[ani].sub_ani_01_mc.action_btn.on(mouseEvent, function () {
            //     // root[ani].goHome();
            //     // this.dispatchEvent(new Event("mousedown"))
            //     // console.log('heizen', root[ani].btnIsActive)
            //     if (menu.color && !root[ani].btnIsActive) {
            //         // fanApi(menu.color)
            //     } else {
            //         // fanApi('off')
            //     }
            //     root[ani].btnIsActive = !root[ani].btnIsActive
            // })
            // }
        }



        root._renderFirstFrame()

        // console.log(root[ani].isRight,pos)

        createjs.Tween.get(root[ani])
            .wait(500)
            .to({
                y: root[ani].isRight && pos === 'right' ? 200 : root.mcPos[ani].y,
                alpha: 1
            }, 700, createjs.Ease.quadOut);

        if (pos === 'right') {
            root.status.currentAnimationRight = nameAni
        }
        if (pos === 'center') {
            root.status.currentAnimationCenter = nameAni
        }

    } else {
        console.log('showAnimation not found', ani, root)
    }

}

// Animation ausblenden
export const hideAnimation = (root, nameAni) => {
    let ani = 'animations_' + nameAni

    // console.log('hideAnimation', ani, root, root[ani])

    if (root[ani]) {
        // console.log('hideAnimation', ani, root[ani])

        createjs.Tween.get(root[ani])
            .wait(500)
            .to({
                y: root.mcPos[ani].y - 30,
                alpha: 0
            }, 500, createjs.Ease.quadOut).call((ev) => {
                // Stop 
                root[ani].gotoAndStop(0)
                root[ani].stop()
                root[ani].visible = false
                console.log('#######STOP', root[ani], nameAni)
                if (root[ani].sub_ani_01_mc) {
                    root[ani].sub_ani_01_mc.gotoAndStop(0)
                }
            })

        if (root.status.currentAnimationRight === nameAni) {
            root.status.currentAnimationRight = ''
        }
        if (root.status.currentAnimationCenter === nameAni) {
            root.status.currentAnimationCenter = ''
        }
    }
}

// import alle Animations
export function initAnimations(root) {

    // return
    if (root.mode === 'mobile') {
        animationPos = {
            top: 190,
            left: aniPosRightMobileX
        }
    }

    const content = getContent()


    content.forEach((menu) => {
        if (menu.content && menu.content.length > 0) {


            if (menu.animation) {
                loadAnimationFiles(root, menu)
            }
            // Submenu L1
            menu.content.forEach((submenu) => {
                if (submenu.animation) {
                    loadAnimationFiles(root, submenu)
                }
            })

        }
    })

    // console.log(root.mcPos)
}


const loadAnimationFiles = (root, submenu) => {
    const aniPos = ['right', 'center']

    aniPos.forEach((pos) => {

        if (pos === 'center') {
            animationPos = {
                top: animationPos.top,
                left: (root.mode !== 'mobile') ? aniPosCenterX : aniPosCenterMobileX
            }
        } else if (pos === 'right') {
            animationPos = {
                top: animationPos.top,
                left: (root.mode !== 'mobile') ? aniPosRightX : aniPosRightMobileX
            }
        }

        // console.log(animationPos, pos)

        let animation = submenu.animation[pos]

        if (animationLib[animation]) {
            const aniJs = animationLib[animation]

            // import(`./../../${contentFolder}/${submenu.animation}`).then(aniJs => {
            const animation_lib = aniJs.animation()
            if (!animation_lib.main_animation) {
                console.log('Error MC Container: animations_' + animation)
                return
            }
            root['animations_' + animation] = new animation_lib.main_animation();
            root['animations_' + animation].name = 'animations_' + animation
            root['animations_' + animation].setTransform(
                animationPos.left,
                animationPos.top,
                // 1, 1, 0, 0, 0, 0, 0
            );

            root.timeline.addTween(createjs.Tween.get(root['animations_' + animation]).wait(1));

            // maske (mask)
            var mask = new createjs.Shape();
            mask._off = true;
            mask.graphics.p("EgnDAu4MAAAhdvMBOHAAAMAAABdvg");
            mask.setTransform(animationPos.left + 250, animationPos.top + 300); // 250, 300

            // Maske nur, wenn kein SOnderformat
            // console.log('######', root['animations_' + animation], root['animations_' + animation].isRight)

            if (!root['animations_' + animation].isRight) {

                var maskedShapeInstanceList = [root['animations_' + animation]];
                for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                    maskedShapeInstanceList[shapedInstanceItr].mask = mask;
                }
            } else {
                root['animations_' + animation].y = 100
            }


            root._renderFirstFrame()

            root['animations_' + animation]._renderFirstFrame();
            root['animations_' + animation].visible = false
            // root['animations_' + animation].alpha = 0.2
            root['animations_' + animation].stop()

            saveMcPos(root, 'animations_' + animation)

            // console.log('INIT 1', animation, root['animations_' + animation])
            // })

        } else if (pos === 'right') {
            console.log('animation not found', submenu.animation, pos)
        }
    })
}

export const loadAnimation = (root, animation) => {
    if (animation && animation !== "" && !root['animations_' + animation]) {

        if (root.mode === 'mobile') {
            animationPos = {
                top: 170,
                left: 500
            }
        }

        if (animationLib[animation]) {
            const aniJs = animationLib[animation]

            // import(`./../../${contentFolder}/${animation}`).then(aniJs => {
            const animation_lib = aniJs.animation()
            if (!animation_lib.main_animation) {
                console.log('Error MC Container: animations_' + animation)
                return
            }
            root['animations_' + animation] = new animation_lib.main_animation();
            root['animations_' + animation].name = 'animations_' + animation
            root['animations_' + animation].setTransform(
                animationPos.left,
                animationPos.top,
                // 1, 1, 0, 0, 0, 0, 0
            );

            root.timeline.addTween(createjs.Tween.get(root['animations_' + animation]).wait(1));
            root._renderFirstFrame()

            root['animations_' + animation]._renderFirstFrame();
            root['animations_' + animation].visible = true

            saveMcPos(root, 'animations_' + animation)

            // var tl = root.timeline, pos = tl.rawPosition;
            // tl.setPosition(0, true, true, root._bound_resolveState);
            // tl.rawPosition = pos;
            // root._renderFirstFrame();

            console.log('loadAnimation:', animation, root['animations_' + animation])

            showAnimation(root, animation)
            // })
        }
    }
}
