/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    
    function getMCSymbolPrototype ....
    */

   
(lib.girlTowel_mc = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AmVV2QgPAAgBgNQgPhVgChYQgDgrACgtQgPACgNAAQggAAgbgMQhVgqgVisQgMhhAKi/QAKiqAxihIAAgBQARgrAbgwQA2heA/goQBWg2CJgdIggiJQgJAEgKAAQgSAAgdgOQhOglhMhjQhYhzgXiCQgIgxABgwQhFgigdg1QgyhXA3h5QAjhPBIhPQARgTATgSQg2hGAbhAQAghIBqghQBMgWBBAJIAEABQAUAEASAGIADABIACABQANAEALAHIACAAIADACQALAHALAIIACACIACABQALAJAKALQArAAAjAQQA7AdAVBNQAhABAdAGQA7AOAhAnQAXAbAKAtQARBAgZA9QALAVAAARQgBAngtAgQgRALgkARIA4A1QBLBIAdAgIBNAAQANAAAHAMQAGALgGALIgcA0QAaBCAGBIQAGBQgcA7QgcA6g7AgQhAAihEALIALBqQBVAbBVAiQCKA6AyAtQByBmAiCFQBbGNiXIpIgEAOIgCAFQACAHgEAHQgFAJgMADQgHACgKgBIhggCQgIAAgFgCQAAAFgDAEQgEAGgGABIgEABQgNAAgCgMQgniKACh7QgUAfgcAaQgiAfgpAUQAEAnAHAoQAEAcgRAXQgSAWgdACInLAeIAAABQAAAHgFAEQgFAFgGAAQgHAAgEgFQgFgDAAgHIhgAHQAAAOgOACgAEYFHIgCAGIgDAIIATgSIgOAEgAC5E5IABALIAKgSg");
        this.shape.setTransform(68.3996,139.8542);
    
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.girlTowel_mc, new cjs.Rectangle(0,0,136.8,279.7), null);
    
    
    (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        // timeline functions:
        this.frame_0 = function() {
            this.stop();
        }
        this.frame_131 = function() {
            this.stop();
        }
    
        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(131).call(this.frame_131).wait(1));
    
        // girlTowel
        this.instance = new lib.girlTowel_mc();
        this.instance.setTransform(-74.1,209.3,1,1,0,0,0,68.4,139.8);
        this.instance._off = true;
    
        this.timeline.addTween(cjs.Tween.get(this.instance).wait(2).to({_off:false},0).to({x:249.9},128).wait(2));
    
        this._renderFirstFrame();
    
    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-142.5,0,460.8,349.2);
    
    
    (lib.main_animation = function(mode,startPosition,loop,reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this,[props]);
    
        this.isSingleFrame = false;
        // timeline functions:
        this.frame_0 = function() {
            if (this.isLoaded) {
                return
            }
            if(this.isSingleFrame) {
                return;
            }
            if(this.totalFrames == 1) {
                this.isSingleFrame = true;
            }
            this.goHome=function(){
                this.sub_ani_01_mc.gotoAndPlay(0);
            }
            // this.goHome();
            
            this.startTwin = function(){
            
            }
        }
    
        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
    
        // subAnimation_mc
        this.sub_ani_01_mc = new lib.sub_ani_01_mc();
        this.sub_ani_01_mc.name = "sub_ani_01_mc";
        this.sub_ani_01_mc.setTransform(114.4,160.6,1,1,0,0,0,114.4,160.6);
    
        this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
    
        this._renderFirstFrame();
    
    }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(0,0,0,0), null);
    
        
    /* IMPORT END 
// stage content: ...
*/


return lib
}