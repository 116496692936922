import { config } from './../config'
import { audioPlayer, uiSound } from './audio/audioPlayer';
import { playLedAnimation } from './led/ledHelper';
import { openPage } from './navigation/navigationAction';
import { startOutside } from './outside/outsideHelper';
import { playScreensaver, stopScreensaver } from './screensaver';
import { handleSpeech } from './speechHelper';
import { hideScreen, switchProjector } from './viewsonicApi';
import { setWindowState } from './window/windowHelper';

// Connect WS
export const connectWS = (root) => {
    const ws = new WebSocket(config.webSocket.url + ':' + config.webSocket.port);
    const app = (root) ? root.type : 'screen'
    ws.onopen = function () {
        console.log("Websocket connected");

        // sende QR Code an alle
        if (root.qrcode) {
            ws.send(JSON.stringify({ qrcode: root.qrcode, app }));
        } else {
            ws.send(JSON.stringify({ page: '', app }));
        }

    };
    ws.onclose = function (e) {
        console.log('Socket closed. Reconnect...', e.reason);
        this.timerId = setTimeout(function () {
            connectWS(app);
        }, 1000);
    };
    ws.onmessage = function (e) {

        if (root && root.type === 'debug') {
            console.log('Websocket Message:', e.data);
            let json = JSON.parse(e.data)

            if (json.speech && json.speech !== '' && json.app === 'speech' && root.type === 'debug') {
                handleSpeech(root, json.speech)
                return
            }
            return
        }

        console.log('Websocket Message:', root.type, e.data);
        let json = JSON.parse(e.data)
        if (json.page && json.page !== '' && json.app !== root.type && json.app === 'mobile' && root.type === 'screen') {
            openPage(root, json.page)
            switchProjector(root, 'screen', true)
            return
        }
        if (json.speech && json.speech !== '' && json.app === 'speech' && root.type === 'screen') {
            handleSpeech(root, json.speech)
            return
        }

        if (json.qrcode && json.qrcode !== '' && json.start && (root.type === 'screen' || root.type === 'outside')) {
            if (json.mute) {
                console.log('Mute Welcome Start')
                root.isMute = true
            } else {
                root.isMute = false
            }
            startOutside(root, json.qrcode)
            setWindowState(root, 'center')
            switchProjector(root, 'outside')
            playLedAnimation('Keyvisual')
            stopScreensaver(root)
            if (root.type === 'screen' && !json.mute) {
                // audioPlayer(root, 'sound/ui/Fentura_Welcome_to_WOW.mp3')    
                uiSound(root, 'intro')
            }
            return
        }

        if (json.qrcode && json.qrcode !== '' && json.countdown && (root.type === 'screen' || root.type === 'outside')) {
            if (json.mute) {
                console.log('Mute Welcome Load')
                root.isMute = true
            } else {
                root.isMute = false
            }
            startOutside(root, json.qrcode)
            setWindowState(root, 'full')
            switchProjector(root, 'outside')
            stopScreensaver(root)
            playLedAnimation('Welcome')
            return
        }

        if (json.showScreen && json.showScreen !== '' && (root.type === 'screen' || root.type === 'outside')) {
            // switchProjector(root, json.showScreen)
        }
        if (json.hideScreen && json.hideScreen !== '' && (root.type === 'screen' || root.type === 'outside')) {
            hideScreen(root, json.hideScreen)
            return
        }

        if (json.screensaver && json.screensaver === "start" && root.type === 'outside') {
            console.log('screensaver play outside')
            playScreensaver(root)
            return
        }

        if (json.screensaver && json.screensaver === "stop" && root.type === 'outside') {
            console.log('screensaver stop outside')
            stopScreensaver(root)
            return
        }

        if (json.power && json.power === "off" && root.type === 'outside') {
            console.log('screensaver power off outside')
            stopScreensaver(root)
            return
        }
        if (json.power && json.power === "on" && root.type === 'outside') {
            console.log('screensaver power on outside')
            window.location.reload()
            return
        }

        if (json.power && json.power === "off" && root.type === 'screen') {
            console.log('screensaver power off screen')
            stopScreensaver(root)
            if (root.screensaverTimer) {
                clearTimeout(root.screensaverTimer)
            }
            playLedAnimation('Stop')
            setWindowState(root, 'full')
            return
        }
        if (json.power && json.power === "on" && root.type === 'screen') {
            console.log('screensaver power on screen')
            playLedAnimation('Welcome')
            setWindowState(root, 'full')
            setTimeout(function () {
                setWindowState(root, 'logo-center')
                window.location.reload()
            }, 5000);
            return
        }

    }
    ws.onerror = function (err) {
        console.error('Socket error: ', err.message, 'Closing socket');
        ws.close();
    };
    return ws
}

// Send to WS
export const sendWS = (root, menuId) => {
    // console.log(root.webSocket)
    if (root.webSocket && root.webSocket.readyState !== 0) {
        root.webSocket.send(JSON.stringify({ page: menuId, app: root.mode }));
    }
}


export const send2WS = (root, data) => {
    // console.log('send2WS', data)
    if (root.webSocket && root.webSocket.readyState !== 0) {
        root.webSocket.send(JSON.stringify(data));
    }
}