/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */


    (lib.txt_mc_04 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Querlüftung durch den gemeinsamen Betrieb mehrerer miteinander korrespondierender Lüftungsgeräte in unterschiedlichen Räumen", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 340;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,-15);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_04, new cjs.Rectangle(0,-17,344,77), null);
        
        
        (lib.txt_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Kontrollierte Be- und Entlüftung auch wenn man nicht zuhause ist - bei vollem Einbruchschutz", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 335;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,-8.5);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_03, new cjs.Rectangle(0,-10.5,339,76.8), null);
        
        
        (lib.txt_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Reduzierter Energieverbrauch durch Vermeidung von ineffektivem, zu langem Lüften durch geöffnete bzw. gekippte Fenster", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 288;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,1.5);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_02, new cjs.Rectangle(0,-0.5,291.5,95.5), null);
        
        
        (lib.txt_mc_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Reduzierung der Heizkosten durch\nWärmerückgewinnung aus der Abluft", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 288;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_01, new cjs.Rectangle(0,0,291.5,39.5), null);
        
        
        (lib.testBtn = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#000000").s().p("Ak/E7IAAp1IJ/AAIAAJ1g");
            this.shape.setTransform(5,6.55);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.testBtn, new cjs.Rectangle(-27,-24.9,64,63), null);
        
        
        (lib.iconRahmen_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhYBZICxAAIAAixIixAAg");
            this.shape.setTransform(25.7717,25.7717,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.iconRahmen_mc, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_green_shape = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#34A58C").s().p("AkBECIAAoDIIDAAIAAIDg");
            this.shape.setTransform(25.775,25.775);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.icon_green_shape, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_04_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgJAKQgEgEAAgGQAAgFAEgEQAEgEAFAAQAGAAAEAEQAEAEAAAFQAAAGgEAEQgEAEgGAAQgFAAgEgEgAgGAAQAAAHAGAAQAHAAAAgHQAAgGgHAAQgGAAAAAGg");
            this.shape.setTransform(35.7884,31.4989,2.3698,2.3698);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgJAKQgEgEAAgGQAAgFAEgEQAEgEAFAAQAGAAAEAEQAEAEAAAFQAAAGgEAEQgEAEgGAAQgFAAgEgEgAgGAAQAAAHAGAAQAHAAAAgHQAAgGgHAAQgGAAAAAGg");
            this.shape_1.setTransform(15.4084,31.4989,2.3698,2.3698);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgJAKQgEgFAAgFQAAgEAEgFQAEgEAFAAQAGAAAEAEQAEAFAAAEQAAAFgEAFQgEAEgGAAQgFAAgEgEgAgGAAQAAAGAGABQAHgBAAgGQAAgGgHAAQgGAAAAAGg");
            this.shape_2.setTransform(25.4799,15.9177,2.3698,2.3698);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AAJAaIABgHQAAgNgHgKQgHgKgMgGIADgGQANAGAIANQAJALAAAPIgBAIg");
            this.shape_3.setTransform(33.3002,22.6715,2.3698,2.3698);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AglgHIAHgFQAMARAVAAQARAAANgKIAFAFQgOANgVAAQgZAAgPgUg");
            this.shape_4.setTransform(24.7098,36.0607,2.3698,2.3698);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgTAYQAAgRALgNQAJgNARgEIACAHQgOADgJAMQgJALAAAOg");
            this.shape_5.setTransform(18.4891,21.4274,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(85.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.icon_03_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgDAKIAAgTIAHAAIAAATg");
            this.shape.setTransform(25.0262,35.5233,2.3698,2.3698);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AANAUIAAgUQgBgEgDgDQgEgEgFgBQgEABgEAEQgDADAAAEIAAAUIgJAAIAAgUQAAgHAHgHQAFgFAIAAQAJAAAFAFQAGAHABAHIAAAUg");
            this.shape_1.setTransform(25.0262,25.2741,2.3698,2.3698);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgcAbIAAg1IA5AAIAAA1gAgUASIAoAAIAAgkIgoAAg");
            this.shape_2.setTransform(25.0854,35.5233,2.3698,2.3698);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AAlA6IAAgJIANAAIAAg5IgygmIgwAmIAAA5IAMAAIAAAJIgVAAIAAhGIA5gtIA6AtIAABGg");
            this.shape_3.setTransform(25.0262,24.3262,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(85.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.icon_02_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgcACIAAgDIA5AAIAAADg");
            this.shape.setTransform(30.4712,26.1682,2.3698,2.3698);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgBA2IAAhrIADAAIAABrg");
            this.shape_1.setTransform(30.5304,26.1682,2.3698,2.3698);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgghCIBBANIAABrIhBANgAgYA5IAxgKIAAhdIgxgKg");
            this.shape_2.setTransform(30.5304,26.2274,2.3698,2.3698);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgSA2IAAhrIAlAAIAAAIIgcAAIAABbIAcAAIAAAIg");
            this.shape_3.setTransform(16.1933,26.2867,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(85.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.icon_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgKALQgEgEAAgHQAAgFAEgFQAEgEAGgBQAGABAFAEQAEAFAAAFIgHAAQAAgGgIAAQgCgBgCADQgDACAAACQAAAIAHAAIAAAHQgGABgEgFg");
            this.shape.setTransform(24.1872,15.073,2.3697,2.3697);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgKALQgEgEAAgHQAAgFAEgFQAFgEAFAAQAHAAAEAEQAFAFgBAFQABAHgFAEQgEAEgHAAQgFAAgFgEgAgGAAQgBADADADQACACACAAQAIAAgBgIQABgHgIAAQgGAAAAAHg");
            this.shape_1.setTransform(28.7488,19.6347,2.3697,2.3697);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgDAZIAAgxIAHAAIAAAxg");
            this.shape_2.setTransform(12.3386,33.7937,2.3697,2.3697);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("Ag1AbIAAgIIAkAAIA9giQgCgCgDgBQgDgBgDACIgZAOIgeAAIAAgHIAcAAIAXgOQADgCAFAAQAJAAAEAIIAEAHIhFAmg");
            this.shape_3.setTransform(27.801,31.5425,2.3697,2.3697);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgcANIAAgHIAFAAIATgTIAhAAIAAAIQAAAFgFAFQgEAEgGAAIAAgHQAHAAABgHIgXAAIgTASg");
            this.shape_4.setTransform(21.8767,28.3434,2.3697,2.3697);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(80.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {play_icon_1:123,play_icon_2:134,play_icon_3:147,play_icon_4:160};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var subAniMc = this;
                this.wait = function(){
                        if(subAniMc.parent.setTime == true){
                        subAniMc.play();
                    }
                }
                /*
                var txt_01 = "Hallo Welt";
                this.txt_mc_01.txt_01.text = txt_01;
                */
                this.goBack = function() {
                    
                    this.parent.setTime = false;
                    for(var i = 1;i<5;i++){
                        this["icon_"+i].gotoAndStop(0);
                    }
                    subAniMc.gotoAndPlay(1);
                }
                this.testBtn.addEventListener("mousedown", clickHandler_test.bind(this));
                function clickHandler_test(){
                    this.parent.goHome();
                }
            }
            this.frame_120 = function() {
                this.stop();
                this.parent.setTime = true;
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_123 = function() {
                this.stop();
                this.icon_1.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_134 = function() {
                this.stop();
                this.icon_2.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_147 = function() {
                this.stop();
                this.icon_3.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_160 = function() {
                this.stop();
                this.icon_4.play();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(120).call(this.frame_120).wait(3).call(this.frame_123).wait(11).call(this.frame_134).wait(13).call(this.frame_147).wait(13).call(this.frame_160).wait(11));
        
            // mc_txt
            this.testBtn = new lib.testBtn();
            this.testBtn.name = "testBtn";
            this.testBtn.setTransform(409,506.5,1,1,0,0,0,13.5,13.5);
        
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#DD0061").s().p("AgSAYQgHgJAAgPQAAgOAHgJQAHgJANAAQARAAAHANIgIAFQgFgIgKAAQgRAAAAAWQAAAXARAAQAKAAAFgIIAIAFQgHANgRAAQgNAAgHgJg");
            this.shape.setTransform(530.5,18.625);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#DD0061").s().p("AAkAgIAAgnQAAgPgOAAQgKABgHAHIAAAuIgKAAIAAgtQgDgJgLAAQgJABgHAHIAAAuIgMAAIAAg/IAMAAIAAAIQAHgIALAAQANAAAHAJQAIgJANAAQAYgBAAAYIAAAog");
            this.shape_1.setTransform(521.65,18.55);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_2.setTransform(512.45,22.65);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#DD0061").s().p("AAIAvIAAhSIgYALIgEgKIAegMIALAAIAABdg");
            this.shape_3.setTransform(505.8,17.075);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#DD0061").s().p("AghAAQAAgwAhAAQAiAAAAAwQAAAxgiAAQghAAAAgxgAgVAAQAAAmAVAAQAWAAAAgmQAAgmgWAAQgVAAAAAmg");
            this.shape_4.setTransform(499.425,17.075);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_5.setTransform(491.75,22.65);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#DD0061").s().p("AgFAvIAAg/IAKAAIAAA/gAgHgnQAAgHAHAAQAIAAAAAHQAAAIgIAAQgHAAAAgIg");
            this.shape_6.setTransform(486.75,17.075);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#DD0061").s().p("AAQAgIAAgmQAAgIgDgDQgDgFgJAAQgJABgHAHIAAAuIgLAAIAAg/IALAAIAAAIQAGgJAOABQAWgBAAAZIAAAng");
            this.shape_7.setTransform(481.65,18.55);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#DD0061").s().p("AgUAcQgFgEAAgJQAAgQAcgEIALgBIAAgGQAAgLgNAAQgMAAAAAIIAAADIgKAAIAAgEQAAgIAGgEQAGgEALAAQAYAAAAAUIAAAsIgLAAIAAgIQgIAJgNAAQgJAAgFgFgAAEACQgRADAAAKQAAAJALAAQAJAAAHgIIAAgPg");
            this.shape_8.setTransform(474.575,18.625);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_9.setTransform(467.95,22.65);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#DD0061").s().p("AgSAqIgDAFIgGAAIAAhfIALAAIAAAnQAGgIALABQAbgBAAAhQAAAPgHAIQgHAKgNAAQgMAAgHgHgAgQABIAAAdQAFAJALgBQARAAAAgWQAAgXgRAAQgLAAgFAIg");
            this.shape_10.setTransform(461.025,17.05);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#DD0061").s().p("AgaAIIAAgoIALAAIAAAnQAAAIADADQAEAEAHAAQAKAAAHgHIAAgvIALAAIAAA/IgLAAIAAgHQgGAJgOAAQgWAAAAgZg");
            this.shape_11.setTransform(453.6,18.675);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#DD0061").s().p("AgYAXIAGgHQAIAIAKAAQAOAAAAgJQAAgHgKgCIgLgDQgPgDAAgNQAAgIAFgFQAHgGALAAQAPAAAIAIIgHAHQgHgHgKAAQgLAAAAAKQAAAHAIACIAKACQASADAAAOQAAAIgHAGQgHAFgLAAQgQAAgIgKg");
            this.shape_12.setTransform(447,18.625);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape},{t:this.testBtn}]}).wait(171));
        
            // icon_4
            this.icon_4 = new lib.icon_04_mc();
            this.icon_4.name = "icon_4";
            this.icon_4.setTransform(74.4,402.15,1,1,0,0,0,114.4,124.2);
            this.icon_4.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_4).wait(53).to({x:114.4,alpha:1},57,cjs.Ease.cubicOut).wait(61));
        
            // txt_04
            this.txt_mc_04 = new lib.txt_mc_04();
            this.txt_mc_04.name = "txt_mc_04";
            this.txt_mc_04.setTransform(269.8,305.7,1,1,0,0,0,145.8,21.8);
            this.txt_mc_04.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_04).wait(53).to({y:313.8},0).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(61));
        
            // icon_03
            this.icon_3 = new lib.icon_03_mc();
            this.icon_3.name = "icon_3";
            this.icon_3.setTransform(74.4,309.5,1,1,0,0,0,114.4,124.2);
            this.icon_3.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_3).wait(43).to({x:114.4,alpha:1},57,cjs.Ease.cubicOut).wait(71));
        
            // txt_03
            this.txt_mc_03 = new lib.txt_mc_03();
            this.txt_mc_03.name = "txt_mc_03";
            this.txt_mc_03.setTransform(269.8,213.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc_03.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_03).wait(43).to({y:221.8},0).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(71));
        
            // icon_02
            this.icon_2 = new lib.icon_02_mc();
            this.icon_2.name = "icon_2";
            this.icon_2.setTransform(-14.2,118.35,1,1,0,0,0,25.8,25.8);
            this.icon_2.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_2).wait(33).to({x:25.8,alpha:1},57,cjs.Ease.cubicOut).wait(81));
        
            // txt_02
            this.txt_mc_02 = new lib.txt_mc_02();
            this.txt_mc_02.name = "txt_mc_02";
            this.txt_mc_02.setTransform(269.8,120.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc_02.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_02).wait(33).to({y:110.05},0).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(81));
        
            // icon_1
            this.icon_1 = new lib.icon_01_mc();
            this.icon_1.name = "icon_1";
            this.icon_1.setTransform(74.4,124.2,1,1,0,0,0,114.4,124.2);
            this.icon_1.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_1).wait(23).to({x:114.4,alpha:1},57,cjs.Ease.cubicOut).wait(91));
        
            // txt_01
            this.txt_mc_01 = new lib.txt_mc_01();
            this.txt_mc_01.name = "txt_mc_01";
            this.txt_mc_01.setTransform(269.8,26.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc_01.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_01).wait(23).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(91));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-40,0,603,531.1);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.setTime = false;
                this.time2wait = 2000;
                this.goHome=function(){
                    this.sub_ani_01_mc.goBack();
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(177.65,224.8,1,1,0,0,0,114.4,160.6);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(23.3,64.2,603,531.0999999999999), null);
            

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}