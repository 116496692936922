/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */


    (lib.txt_mc_04 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Schutz vor Schimmel: Lüftung bei\nÜberschreitung der Grenzwerte für Raumfeuchte", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 288;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_04, new cjs.Rectangle(0,0,291.5,43.6), null);
        
        
        (lib.txt_mc_03 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Heiz- und Kühlfunktion: Immer die\nrichtige Temperatur der Zuluft", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 288;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_03, new cjs.Rectangle(0,0,291.5,39.5), null);
        
        
        (lib.txt_mc_02 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Schallschutz: Frische Luft auch\nbei geschlossenem Fenster", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 288;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_02, new cjs.Rectangle(0,0,291.5,39.5), null);
        
        
        (lib.txt_mc_01 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.txt_01 = new cjs.Text("Frische und saubere Luft durch\nintegrierten Pollenfilter", "14px 'Brix Sans Regular'", "#FFFFFF");
            this.txt_01.name = "txt_01";
            this.txt_01.lineHeight = 19;
            this.txt_01.lineWidth = 288;
            this.txt_01.parent = this;
            this.txt_01.setTransform(2,2);
        
            this.timeline.addTween(cjs.Tween.get(this.txt_01).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.txt_mc_01, new cjs.Rectangle(0,0,291.5,39.5), null);
        
        
        (lib.testBtn = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#000000").s().p("AmAGaIAAszIMBAAIAAMzg");
            this.shape.setTransform(0.5,4.05);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.testBtn, new cjs.Rectangle(-38,-36.9,77,82), null);
        
        
        (lib.iconRahmen_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhYBZICxAAIAAixIixAAg");
            this.shape.setTransform(25.7717,25.7717,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.iconRahmen_mc, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_green_shape = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#34A58C").s().p("AkBECIAAoDIIDAAIAAIDg");
            this.shape.setTransform(25.775,25.775);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.icon_green_shape, new cjs.Rectangle(0,0,51.6,51.6), null);
        
        
        (lib.icon_4 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgGAIQgEgEAAgEQAAgDAEgDQADgEADAAQAEAAAEAEQADADAAADQAAAFgDADQgDADgFAAQgDAAgDgDgAgDgCQAAAAAAABQgBAAAAAAQAAABAAAAQAAAAAAAAQAAAFAEAAQAFAAAAgFQAAgEgFAAIgDACg");
            this.shape.setTransform(21.5361,21.8656,2.3698,2.3698);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgGAIQgEgDAAgFQABgKAJAAQAEAAAEADQADAEAAADQAAAFgDADQgEADgEAAQgDAAgDgDgAgEAAQABAFADAAQAFAAAAgFQAAgDgFgBQgEAAAAAEg");
            this.shape_1.setTransform(28.8157,29.0934,2.3698,2.3698);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgPAaIAYgxQAAAAABgBQAAAAAAgBQABAAABAAQAAAAABAAIADAAIgXAvQgCAEgDAAg");
            this.shape_2.setTransform(25.2018,25.5388,2.3698,2.3698);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgGAiQgJgCgDgIQgEgIACgJQACgHAGgMIAKgSIACgEIABACIASAiQAFAKgEAKQgFANgPAAIgGgBgAgRAKIAAABQgBANALAEIABAAIAEAAIgDgDQgGgCgCgGIgBgHIgBgCg");
            this.shape_3.setTransform(36.3303,33.6552,2.3698,2.3698);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgXA6QgSgHgKgRQgLgPABgUQAAgSALgQQALgQATgHQALgEAJAAQAVAAARANQARAOAFAUQADANgBAMIgBACIgEgHQgDgEAAgDQAAgWgRgPQgQgPgVAAIgBAAQgTACgOAMQgPAMgEAVQgDAVANASQAMARAUAEQAGACAFAAQAHAAAJgDIADgBQABAAAAABQAAAAAAAAQAAAAAAABQABAAAAAAIAAAFQAAAAAAAAQAAABAAAAQAAABgBAAQAAAAgBAAQgLADgIAAQgLAAgMgFg");
            this.shape_4.setTransform(25.1991,25.4795,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(85.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.icon_3 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgQAoQgEgHADgHQADgIAHgDQAEgCAAgEIAAg2QAAgFADAAIAAAAQAEAAAAAFIAAA2QAAAFADABQANAFgBAPQgBAFgEAFQgEAFgGAAIgEABQgLAAgFgLgAgPAgIADADIABAAQABAAAAAAQABAAAAgBQABAAAAAAQAAgBAAgBQABgFAGgEIABgCIAAgCQAAgBAAAAQAAAAAAgBQgBAAAAAAQgBAAgBAAIAAAAQgLACgBANg");
            this.shape.setTransform(25.1229,27.3232,2.3698,2.3698);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgDADQgBAAAAgBQAAAAgBAAQAAgBAAAAQgBgBAAAAQAAAAAAgBQAAAAAAgBQABAAAAAAQABAAABAAIAHAAQABAAAAAAQABAAAAAAQAAABABAAQAAABAAAAQAAABAAAAQgBABAAAAQAAAAgBABQAAAAgBAAIgEAAIgCAAg");
            this.shape_1.setTransform(32.944,17.4295,2.3698,2.3698);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AADADIgDAAIgDAAQAAAAgBAAQgBAAAAgBQAAAAgBgBQAAAAAAgBQAAAAAAgBQABAAAAAAQAAgBABAAQAAAAABAAIAHAAQAAAAABAAQAAAAABABQAAAAAAAAQABABAAAAQAAABAAAAQgBABAAAAQAAABgBAAQAAAAgBAAg");
            this.shape_2.setTransform(32.9703,23.4724,2.3698,2.3698);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AAIADIgDAAIgJAAIgDAAQgBAAgBAAQAAAAgBgBQAAAAAAAAQgBgBAAgBQAAAAABAAQAAgBAAAAQABgBAAAAQABAAABAAIAPAAQABAAABAAQAAAAABABQAAAAAAABQABAAAAAAQAAABgBABQAAAAAAAAQgBABAAAAQgBAAgBAAg");
            this.shape_3.setTransform(34.0959,26.3161,2.3698,2.3698);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgIADQgBAAAAAAQgBAAAAgBQAAAAAAgBQgBAAAAgBQAAAAABAAQAAgBAAAAQAAgBABAAQAAAAABAAIAQAAQABAAABAAQAAAAABABQAAAAAAABQABAAAAAAQAAABgBAAQAAABAAAAQgBABAAAAQgBAAgBAAg");
            this.shape_4.setTransform(34.0959,20.4509,2.3698,2.3698);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgHADQgBAAgBAAQAAAAgBgBQAAAAAAgBQgBAAAAgBQAAAAABAAQAAgBAAAAQABAAAAgBQABAAABAAIAMAAIADAAIAAAAQABAAABAAQAAAAABABQAAAAAAABQABAAAAAAQAAABgBABQAAAAAAAAQgBABAAAAQgBAAgBAAg");
            this.shape_5.setTransform(34.0959,14.3488,2.3698,2.3698);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("AAABEQgSAAgIgSQgEgJACgKQADgKAIgGQADgCAAgEIAAg9QAAgNAMgDIACAAQAKAAAEAJIABAJIAAA7QAAADADACQAQANgFAVQgDAIgHAGQgIAGgIAAgAgGg6QgCADAAAFIAAA+QAAAEgEADQgHAFgDAHQgCAIADAIQACAGAHAFQAGAFAGAAIACAAQAIgBAGgFQAGgFABgHQADgSgOgJQgDgBAAgEIAAhCQgBgHgGgBIgCgBQgEAAgCAEg");
            this.shape_6.setTransform(25.199,25.7829,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(85.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.icon_2 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // illu
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgFABQAAgMAHgGIAEAEQAAAAAAAAQAAAAgBABQAAAAAAABQgBABAAAAQgHAKAHALQAAAAABABQAAABAAAAQABAAAAABQAAAAAAAAIgEAEQgHgFAAgMg");
            this.shape.setTransform(40.4713,25.592,2.3696,2.3696);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgIACQgBgTANgNQAEADAAABIgCAEQgIAKABAMQAAANAHAKIADAEIgEAEQgMgKgBgTg");
            this.shape_1.setTransform(37.2458,25.6512,2.3696,2.3696);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AADAnQgNgRAAgWQAAgVANgQIADgFIAFAFIgDAEQgMAPAAATQAAATAMAPIADADQgCAEgCABIgEgEg");
            this.shape_2.setTransform(33.8857,25.5328,2.3696,2.3696);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AgMgEQgBgJAIgFQAIgEAHAHQAGAIgFAHIgPAUIgCABg");
            this.shape_3.setTransform(16.3262,24.8589,2.3696,2.3696);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AgCA6IAAhzQAAgBAAgBQAAgBABAAQAAgBABAAQAAAAAAAAQADAAAAAEIAABzQAAAEgDAAQgCAAAAgEg");
            this.shape_4.setTransform(26.5398,25.7697,2.3696,2.3696);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AgJAmQgJgCgCgJQAAAAAAgBQAAAAAAgBQAAAAAAAAQABgBAAAAQABAAABAAQAAAAABAAQABAAAAAAQAAABABAAQADAHAFAAQAEAAADgGIARgaQAHgKgHgLQgGgKgLAAQgJAAgGAGQgGAGgBAJQAAABAAABQAAABAAAAQgBABAAAAQAAAAgBAAQgEACAAgFQAAgTASgIQAIgDAIADQAJACAFAGQANAQgLAPIgSAcQgEAHgHAAIgDAAg");
            this.shape_5.setTransform(16.326,25.4854,2.3696,2.3696);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#FFFFFF").s().p("AAAA6IAAhzQAAgBAAgBQABgBAAAAQAAgBABAAQAAAAABAAQADAAAAAEIAABzQAAAEgDAAQgDAAAAgEgAAlAmQgOgRAAgVQAAgWAOgQIADgFIAFAFIgDAEQgNAPAAATQABATANAPIACADQgCAEgCABIgEgEgAgxAlQgJgCgCgJQAAAAAAgBQAAAAAAgBQAAAAAAgBQABAAAAAAQABgBABAAQABAAAAABQABAAAAAAQAAABABAAQADAHAFAAQAEAAAEgGIARgZQAHgLgHgLQgGgKgLAAQgKAAgGAGQgGAGgBAJQAAABAAABQAAAAAAABQgBAAAAABQAAAAgBAAQgEACAAgFQAAgTASgIQAIgDAJADQAJACAFAGQANAQgLAPIgSAcQgEAHgIAAIgDAAgAAoACQgCgUAPgMQAEACAAABIgDAEQgIAKAAANQAAANAJAKIACADIgEAEQgMgJgBgTgAA4AAQAAgMAIgGIAEAEQAAAAAAAAQAAABgBAAQAAABAAAAQgBABAAABQgIAKAIAKQAAABABAAQAAABAAAAQABABAAAAQAAAAAAABIgEADQgIgFAAgMgAg0gIQgBgJAIgFQAJgEAHAHQAGAIgFAHIgQAUIgCABg");
            this.shape_6.setTransform(25.7796,25.7697,2.3696,2.3696);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(85.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.icon_1 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_29 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(29).call(this.frame_29).wait(1));
        
            // flash0_ai
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("AgBALQgCgBgBgEIADgMQABgEADABQADACgBADIgCALQgBAEgCAAIgBAAg");
            this.shape.setTransform(24.9584,42.1347,2.3698,2.3698);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#FFFFFF").s().p("AgFAJQgBAAAAgBQAAAAgBgBQAAAAAAgBQAAgBAAAAQAFgIAEgFQABAAAAAAQABgBAAAAQABABAAAAQABAAAAABQABAAAAAAQABABAAAAQAAABAAAAQAAABAAABQgDAEgGAIIgCABIgCgBg");
            this.shape_1.setTransform(20.5818,40.6849,2.3698,2.3698);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#FFFFFF").s().p("AgBAHIgEgMQgBgDAEgBQACgCACADIAEAOQAAAEgDAAIgBABQgBAAgBgBQAAAAAAAAQAAgBAAAAQgBgBAAgBg");
            this.shape_2.setTransform(29.823,42.7517,2.3698,2.3698);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#FFFFFF").s().p("AAOAFQgKgFgNAEIgGACQgDACgCgEQgBgBAAgBQAAAAABgBQAAAAAAgBQABAAABAAQAIgFAKAAQAMgBAIAGQABABAAAAQAAABAAAAQABABAAAAQAAABAAAAQgBADgEAAg");
            this.shape_3.setTransform(27.3433,30.8652,2.3698,2.3698);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#FFFFFF").s().p("AAgA+IgDgBQgPgLgWAFQgPAEgIgCQgQgEgFgOQgFgOAMgOIAEgEQAHgEAEgMIAPgwIACgDQAAgBABAAQAAgBABAAQAAAAABAAQAAAAABAAQABAAAAAAQAAABABAAQAAAAAAABQAAABAAABIgCAKIgRAzQAAAEgFACQgLAHgDAJQgEALAKAIQAIAHAJgBIAOgDQAVgFARAKQAFADACAAQACgBADgFQAFgKgBgEQABgKgJgFIgCgBQgEgDABgDQACgDAFACQAHADAEAHQAEAIgBAIQgCANgJAJQgDADgDAAIgFgBg");
            this.shape_4.setTransform(25.4924,22.329,2.3698,2.3698);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#FFFFFF").s().p("AAQBNIgEgNQgBgEAEgBQADgBACADIAEAPQAAADgDABIgBAAQgBAAAAAAQgBAAAAgBQgBAAAAgBQAAAAgBgBgAgDBNQgDAAgBgEIADgOQADgDABAAQAEACgBAEIgDAMQAAADgCAAIgBAAgAgaBGQAAAAgBgBQAAAAAAgBQAAAAgBgBQAAgBAAgBQAFgJAFgEQABgBABAAQAAAAABAAQAAAAABABQAAAAAAAAQABABAAAAQABABAAAAQAAABAAAAQAAABAAAAQgDAFgHAIIgCABIgCAAgAAgAtIgDgBQgPgLgWAFQgPAEgIgCQgQgEgFgOQgFgOAMgNIAEgEQAHgEAEgNIAPgwIACgDQAAgBABAAQAAgBABAAQAAAAABAAQAAAAABAAQABAAAAABQAAAAABAAQAAABAAAAQAAABAAABIgCAKIgRA0QAAAEgFACQgLAHgDAIQgEALAKAIQAIAHAJgBIAOgDQAVgFARAKQAFADACAAQACgBADgFQAFgKgBgEQABgKgJgFIgCgBQgEgCABgDQACgDAFACQAHADAEAGQAEAIgBAIQgCANgJAJQgDADgDAAIgFgBgAAWAYQgKgGgNAFIgGACQgEACgCgEQAAgBAAAAQAAgBAAgBQABAAAAgBQABAAAAgBQAJgFAJAAQANgBAIAHQAAABABAAQAAABAAAAQAAABAAAAQABABgBAAQgBADgEAAg");
            this.shape_5.setTransform(25.4924,26.3072,2.3698,2.3698);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(30));
        
            // maske (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("AkBECIAAoDIIDAAIAAIDg");
            mask.setTransform(25.775,25.775);
        
            // green
            this.instance = new lib.icon_green_shape();
            this.instance.setTransform(85.8,25.8,1,1,0,0,0,25.8,25.8);
        
            var maskedShapeInstanceList = [this.instance];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({x:25.8},29).wait(1));
        
            // rahmen
            this.instance_1 = new lib.iconRahmen_mc();
            this.instance_1.setTransform(25.8,25.8,1,1,0,0,0,25.8,25.8);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(30));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(0,0,51.6,51.6);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {play_icon_1:127,play_icon_2:138,play_icon_3:151,play_icon_4:164};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
                if (this.isLoaded) {
                    return
                }
                var subAniMc = this;
                this.wait = function(){
                        if(subAniMc.parent.setTime == true){
                        subAniMc.play();
                    }
                }
                /*
                var txt_01 = "Hallo Welt";
                this.txt_mc_01.txt_01.text = txt_01;
                */
                this.goBack = function() {
                    
                    this.parent.setTime = false;
                    for(var i = 1;i<5;i++){
                        this["icon_"+i].gotoAndStop(0);
                    }
                    subAniMc.gotoAndPlay(1);
                }
                this.testBtn.addEventListener("mousedown", clickHandler_test.bind(this));
                function clickHandler_test(){
                    this.parent.goHome();
                }
            }
            this.frame_124 = function() {
                this.stop();
                this.parent.setTime = true;
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_127 = function() {
                this.stop();
                this.icon_1.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_138 = function() {
                this.stop();
                this.icon_2.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_151 = function() {
                this.stop();
                this.icon_3.play();
                setTimeout(this.wait, this.parent.time2wait);
            }
            this.frame_164 = function() {
                this.stop();
                this.icon_4.play();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(124).call(this.frame_124).wait(3).call(this.frame_127).wait(11).call(this.frame_138).wait(13).call(this.frame_151).wait(13).call(this.frame_164).wait(11));
        
            // mc_txt
            this.testBtn = new lib.testBtn();
            this.testBtn.name = "testBtn";
            this.testBtn.setTransform(409,506.5,1,1,0,0,0,13.5,13.5);
        
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#DD0061").s().p("AgSAYQgHgJAAgPQAAgOAHgJQAHgJANAAQARAAAHANIgIAFQgFgIgKAAQgRAAAAAWQAAAXARAAQAKAAAFgIIAIAFQgHANgRAAQgNAAgHgJg");
            this.shape.setTransform(530.5,18.625);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#DD0061").s().p("AAkAgIAAgnQAAgPgOAAQgKABgHAHIAAAuIgKAAIAAgtQgDgJgLAAQgJABgHAHIAAAuIgMAAIAAg/IAMAAIAAAIQAHgIALAAQANAAAHAJQAIgJANAAQAYgBAAAYIAAAog");
            this.shape_1.setTransform(521.65,18.55);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_2.setTransform(512.45,22.65);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#DD0061").s().p("AAIAvIAAhSIgYALIgEgKIAegMIALAAIAABdg");
            this.shape_3.setTransform(505.8,17.075);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f("#DD0061").s().p("AghAAQAAgwAhAAQAiAAAAAwQAAAxgiAAQghAAAAgxgAgVAAQAAAmAVAAQAWAAAAgmQAAgmgWAAQgVAAAAAmg");
            this.shape_4.setTransform(499.425,17.075);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_5.setTransform(491.75,22.65);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f("#DD0061").s().p("AgFAvIAAg/IAKAAIAAA/gAgHgnQAAgHAHAAQAIAAAAAHQAAAIgIAAQgHAAAAgIg");
            this.shape_6.setTransform(486.75,17.075);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#DD0061").s().p("AAQAgIAAgmQAAgIgDgDQgDgFgJAAQgJABgHAHIAAAuIgLAAIAAg/IALAAIAAAIQAGgJAOABQAWgBAAAZIAAAng");
            this.shape_7.setTransform(481.65,18.55);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f("#DD0061").s().p("AgUAcQgFgEAAgJQAAgQAcgEIALgBIAAgGQAAgLgNAAQgMAAAAAIIAAADIgKAAIAAgEQAAgIAGgEQAGgEALAAQAYAAAAAUIAAAsIgLAAIAAgIQgIAJgNAAQgJAAgFgFgAAEACQgRADAAAKQAAAJALAAQAJAAAHgIIAAgPg");
            this.shape_8.setTransform(474.575,18.625);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f("#DD0061").s().p("AgbAEIAAgHIA3AAIAAAHg");
            this.shape_9.setTransform(467.95,22.65);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#DD0061").s().p("AgSAqIgDAFIgGAAIAAhfIALAAIAAAnQAGgIALABQAbgBAAAhQAAAPgHAIQgHAKgNAAQgMAAgHgHgAgQABIAAAdQAFAJALgBQARAAAAgWQAAgXgRAAQgLAAgFAIg");
            this.shape_10.setTransform(461.025,17.05);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#DD0061").s().p("AgaAIIAAgoIALAAIAAAnQAAAIADADQAEAEAHAAQAKAAAHgHIAAgvIALAAIAAA/IgLAAIAAgHQgGAJgOAAQgWAAAAgZg");
            this.shape_11.setTransform(453.6,18.675);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#DD0061").s().p("AgYAXIAGgHQAIAIAKAAQAOAAAAgJQAAgHgKgCIgLgDQgPgDAAgNQAAgIAFgFQAHgGALAAQAPAAAIAIIgHAHQgHgHgKAAQgLAAAAAKQAAAHAIACIAKACQASADAAAOQAAAIgHAGQgHAFgLAAQgQAAgIgKg");
            this.shape_12.setTransform(447,18.625);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape},{t:this.testBtn}]}).wait(175));
        
            // icon_4
            this.icon_4 = new lib.icon_4();
            this.icon_4.name = "icon_4";
            this.icon_4.setTransform(74.4,402.15,1,1,0,0,0,114.4,124.2);
            this.icon_4.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_4).wait(58).to({x:114.4,alpha:1},57,cjs.Ease.cubicOut).wait(60));
        
            // txt_4
            this.txt_mc_04 = new lib.txt_mc_04();
            this.txt_mc_04.name = "txt_mc_04";
            this.txt_mc_04.setTransform(269.8,305.7,1,1,0,0,0,145.8,21.8);
            this.txt_mc_04.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_04).wait(58).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(60));
        
            // icon_3
            this.icon_3 = new lib.icon_3();
            this.icon_3.name = "icon_3";
            this.icon_3.setTransform(74.4,309.5,1,1,0,0,0,114.4,124.2);
            this.icon_3.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_3).wait(48).to({x:114.4,alpha:1},57,cjs.Ease.cubicOut).wait(70));
        
            // txt_3
            this.txt_mc_03 = new lib.txt_mc_03();
            this.txt_mc_03.name = "txt_mc_03";
            this.txt_mc_03.setTransform(269.8,213.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc_03.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_03).wait(48).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(70));
        
            // icon_2
            this.icon_2 = new lib.icon_2();
            this.icon_2.name = "icon_2";
            this.icon_2.setTransform(-14.2,118.35,1,1,0,0,0,25.8,25.8);
            this.icon_2.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_2).wait(38).to({x:25.8,alpha:1},57,cjs.Ease.cubicOut).wait(80));
        
            // txt_2
            this.txt_mc_02 = new lib.txt_mc_02();
            this.txt_mc_02.name = "txt_mc_02";
            this.txt_mc_02.setTransform(269.8,120.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc_02.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_02).wait(38).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(80));
        
            // icon_1
            this.icon_1 = new lib.icon_1();
            this.icon_1.name = "icon_1";
            this.icon_1.setTransform(74.4,124.2,1,1,0,0,0,114.4,124.2);
            this.icon_1.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.icon_1).wait(28).to({x:114.4,alpha:1},57,cjs.Ease.cubicOut).wait(90));
        
            // txt_1
            this.txt_mc_01 = new lib.txt_mc_01();
            this.txt_mc_01.name = "txt_mc_01";
            this.txt_mc_01.setTransform(269.8,26.05,1,1,0,0,0,145.8,19.8);
            this.txt_mc_01.alpha = 0;
        
            this.timeline.addTween(cjs.Tween.get(this.txt_mc_01).wait(28).to({x:219.8,alpha:1},57,cjs.Ease.cubicOut).wait(90));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-40,0,603,538.1);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.setTime = false;
                this.time2wait = 2000;
                this.goHome=function(){
                    this.sub_ani_01_mc.goBack();
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(177.65,224.8,1,1,0,0,0,114.4,160.6);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(23.3,64.2,603,538.0999999999999), null);
            
        

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}