import * as createjs from 'createjs-module'
import { saveMcPos } from './animateHelper'
import { headline_content } from '../assets/animate/ui/headline_content';
import { induktion } from '../assets/animate/ui/icon_induktion'
import { getContent } from './../config/getContent'

/* UI */

export const initUI = (root) => {

    const content = getContent('', true)

    // Headline
    const headline_content_lib = headline_content()
    root.headlineContent = new headline_content_lib.headline();

    if (root.mode === 'mobile') {
        root.headlineContent.setTransform(550, 70);
    } else {
        root.headlineContent.setTransform(1280, 480);
    }

    root.timeline.addTween(createjs.Tween.get(root.headlineContent).wait(1));
    saveMcPos(root, 'headlineContent')

    // leeren
    root.headlineContent.l1.txt.text = ''
    root.headlineContent.l2.txt.text = ''

    root.navigation.headline.l1.txt.text = ''
    root.navigation.headline.l2.txt.text = ''
    root.navigation.headline.l3.txt.text = ''
    root.navigation.headline.l3.visible = false

    setHeadlineMain(root, content.headline)

    // Induktion
    const induktion_lib = induktion()
    root.induktion1 = new induktion_lib.induktion();
    root.timeline.addTween(createjs.Tween.get(root.induktion1).wait(1));
    root.induktion1.setTransform(1455, 1090);
    root.induktion1.scale = 1.4
    root.induktion1.alpha = 0

    root.induktion2 = new induktion_lib.induktion();
    root.timeline.addTween(createjs.Tween.get(root.induktion2).wait(1));
    root.induktion2.setTransform(936, 1090);
    root.induktion2.scale = 1.4
    root.induktion2.alpha = 0

    root.induktion3 = new induktion_lib.induktion();
    root.timeline.addTween(createjs.Tween.get(root.induktion3).wait(1));
    root.induktion3.setTransform(410, 1090);
    root.induktion3.scale = 1.4
    root.induktion3.alpha = 0

    saveMcPos(root, 'induktion1')
    saveMcPos(root, 'induktion2')
    saveMcPos(root, 'induktion3')
}

// Headline Content rechts setzen
export const setHeadlineContent = (root, content = null, from = '') => {

    var l1 = ''
    var l2 = ''
    var l2Only = false
    const lang = root.status.language

    if (content && content.label) {
        l1 = (content.label.l1) ? content.label.l1 : content.label[lang].l1
        l2 = (content.label.l2) ? content.label.l2 : content.label[lang].l2
    }

    if (content && !content.label) {
        l1 = ''
        l2 = (content[lang]) ? content[lang] : ''
        l2Only = true
    }

    if (!content) {
        // return
    }

    console.log('setHeadlineContent', from, content, lang, root)

    const headlineContent = root.headlineContent

    // L1
    if (!l2Only) {
        createjs.Tween.get(headlineContent.l1)
            .to({
                y: root.mcPos['headlineContent.l1'].y - 10,
                alpha: 0
            }, 600, createjs.Ease.quadOut).call((ev) => {
                ev.target.txt.text = l1
            }).to({
                y: root.mcPos['headlineContent.l1'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut);
    }


    // L2
    createjs.Tween.get(headlineContent.l2)
        .to({
            y: root.mcPos['headlineContent.l2'].y - 20,
            alpha: 0
        }, 400, createjs.Ease.quadOut).call((ev) => {
            ev.target.txt.text = l2
        }).to({
            y: root.mcPos['headlineContent.l2'].y,
            alpha: 1
        }, 800, createjs.Ease.quadOut);
}

// Headline Content rechts setzen
export const setHeadlineMain = (root, content = null, isSmall = false) => {

    if (!content) {
        return
    }

    const lang = root.status.language

    // console.log(root, content, lang, content.label[lang])

    let l1 = (content.label.l1) ? content.label.l1 : content.label[lang].l1
    let l2 = (content.label.l2) ? content.label.l2 : content.label[lang].l2
    let l3 = ''
    if (isSmall) {
        l3 = (content.label.l2) ? content.label.l2 : content.label[lang].l2
    }

    const headline = root.navigation.headline
    const nameHeadline = 'navigation.headline'

    // L1
    createjs.Tween.get(headline.l1)
        .to({
            y: root.mcPos[nameHeadline + '.l1'].y - 10,
            alpha: 0
        }, 600, createjs.Ease.quadOut).call((ev) => {
            ev.target.txt.text = l1
        }).to({
            y: root.mcPos[nameHeadline + '.l1'].y,
            alpha: 1
        }, 400, createjs.Ease.quadOut);

    if (l3 === '') {
        // L2
        createjs.Tween.get(headline.l2)
            .to({
                y: root.mcPos[nameHeadline + '.l2'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut).call((ev) => {
                ev.target.txt.text = l2
            }).to({
                y: root.mcPos[nameHeadline + '.l2'].y,
                alpha: 1
            }, 800, createjs.Ease.quadOut);

        // L3 out
        createjs.Tween.get(headline.l3)
            .to({
                y: root.mcPos[nameHeadline + '.l3'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut).call((ev) => {
                ev.target.txt.text = l3
            })

    } else {
        // L2 out
        createjs.Tween.get(headline.l2)
            .to({
                y: root.mcPos[nameHeadline + '.l2'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut).call((ev) => {
                ev.target.txt.text = l2
            })

        // L3
        headline.l3.visible = true
        createjs.Tween.get(headline.l3)
            .to({
                y: root.mcPos[nameHeadline + '.l3'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut).call((ev) => {
                ev.target.txt.text = l3
            }).to({
                y: root.mcPos[nameHeadline + '.l3'].y,
                alpha: 1
            }, 800, createjs.Ease.quadOut);
    }



}


export const animateInduktion = (root, dir) => {
    if (dir === 'in') {
        root.induktion1.alpha = 0
        root.induktion1.y = root.mcPos['induktion1'].y - 20
        createjs.Tween.get(root.induktion1)
            .to({
                y: root.mcPos['induktion1'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut)

        root.induktion2.alpha = 0
        // root.induktion2.y = root.mcPos['induktion2'].y - 20
        // createjs.Tween.get(root.induktion2)
        //     .wait(500)
        //     .to({
        //         y: root.mcPos['induktion2'].y,
        //         alpha: 1
        //     }, 400, createjs.Ease.quadOut)

        root.induktion3.alpha = 0
        root.induktion3.y = root.mcPos['induktion3'].y - 20
        createjs.Tween.get(root.induktion3)
            .wait(1000)
            .to({
                y: root.mcPos['induktion3'].y,
                alpha: 1
            }, 400, createjs.Ease.quadOut)
    }

    if (dir === 'out') {
        createjs.Tween.get(root.induktion1)
            .to({
                y: root.mcPos['induktion1'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut)

        createjs.Tween.get(root.induktion2)
            .wait(400)
            .to({
                y: root.mcPos['induktion2'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut)

        createjs.Tween.get(root.induktion3)
            .wait(800)
            .to({
                y: root.mcPos['induktion3'].y - 20,
                alpha: 0
            }, 400, createjs.Ease.quadOut)
    }
}