import { config } from './../config';
import { FC, useState } from 'react'
import Canvas from '../ui/Canvas';
import './Screen.scss'
// import { connectWS } from '../actions/websocket';


// interface ScreenProps {
//     ws?: WebSocket
// }

// const Screen: FC<ScreenProps> = ({ ws }) => {
const Screen: FC = () => {

    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    })

    // useEffect(() => {    
    // }, [ws])

    function handleMouseMove(ev: any) { setMousePosition({ left: ev.pageX, top: ev.pageY }); }

    return (
        <div
            onMouseMove={(ev) => handleMouseMove(ev)}
            className='screen'
        >
            {
                (!config.isLocal) ? <div>
                    <h2>Screen x: {MousePosition.left}, y: {MousePosition.top}</h2>
                    <h2>Version: {config.version}</h2>
                </div> : ''
            }
            <Canvas
                type={'screen'}
                mouse={MousePosition}
            // ws={ws}
            />
        </div>
    );
}

export default Screen