/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../actions/animateHelper'

export const induktion = () => {

    var lib = {}
    var p

    /* IMPORT START 
    
    function getMCSymbolPrototype ....
    */

    (lib.induktion = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgjgRIBHAAIgkAjg");
        this.shape.setTransform(17.5049, 53.8569, 2.3797, 2.3799);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgzA0QgWgVAAgfQAAgeAWgVQAWgWAdAAQAeAAAWAWQAWAWAAAdQAAAfgWAVQgWAWgeAAQgdAAgWgWgAgtgtQgTATAAAaQAAAbATATQATATAaAAQAaAAAUgTQASgTABgbQgBgagSgTQgUgTgaAAQgaAAgTATg");
        this.shape_1.setTransform(17.5147, 17.5281, 2.3796, 2.3797);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AAfAWQgGgBAAgHIAAgWIgBgCIgDgBQAAAAgBAAQAAABgBAAQAAAAAAABQAAAAAAABIAAAXQAAADgDACQgCADgDgBQgIAAAAgIIAAgWIgBgCIgCgBQAAAAgBAAQAAABgBAAQAAABAAAAQAAABAAAAIAAAXQAAADgDADQgDACgDgBQgHgBAAgHIAAgWIgBgCIgCgBQgBAAAAAAQgBABAAAAQAAAAgBABQAAAAAAABIAAAXQAAADgCACQgCACgEAAIAAAAQgDAAgCgCQgDgCAAgEIAAgXQAAgDgCgCQgCgDgDAAIgWAAIAAgEIAWAAQAFAAADADQAEAEAAAFIAAAXQAAABAAABQABAAAAABQAAAAABAAQABABAAAAIACgBIABgCIAAgXQAAgGAHgCQADAAADACQADADAAADIAAAWQAAABAAAAQABABAAAAQAAABABAAQAAAAABABIACgBIABgCIAAgXQAAgGAHgCQACgBADADQADACAAAEIAAAWQAAABAAABQAAAAABABQAAAAAAAAQABABABAAIACgBIABgCIAAgXQAAgGAGgCQAEgBADADQADACAAAEIAAAWQAAABAAAAQAAABAAAAQABABAAAAQABAAAAABIADgBIABgCIAAgYQAAgFAEgEQAEgDAFAAIAVAAIAAAEIgVAAQgIAAAAAIIAAAYQAAAEgDACQgBAAAAAAQgBABAAAAQgBAAAAAAQgBAAgBAAIgCAAg");
        this.shape_2.setTransform(17.4552, 18.395, 2.3796, 2.3797);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AAEAKIgHgMIAAAMIgFAAIAAgTIAGAAIAGALIAAgLIAEAAIAAATg");
        this.shape_3.setTransform(33.4582, 41.3254, 2.3796, 2.3797);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgIAAQAAgKAIAAQAJAAAAAKQAAALgJAAQgIAAAAgLgAgDAAQAAAHADgBQAEABAAgHQAAgFgEAAQgDAAAAAFg");
        this.shape_4.setTransform(28.5799, 41.2659, 2.3796, 2.3797);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgBAKIAAgTIADAAIAAATg");
        this.shape_5.setTransform(25.189, 41.3254, 2.3796, 2.3797);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgBAKIAAgQIgGAAIAAgDIAPAAIAAADIgGAAIAAAQg");
        this.shape_6.setTransform(22.2145, 41.3254, 2.3796, 2.3797);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AADAKIgDgJIgDADIAAAGIgFAAIAAgTIAFAAIAAAIIAGgIIAFAAIgHAIIAHALg");
        this.shape_7.setTransform(18.2286, 41.3254, 2.3796, 2.3797);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgHACIAAgLIAFAAIAAALQAAAEACAAQADAAAAgEIAAgLIAFAAIAAALQAAAIgIAAQgHAAAAgIg");
        this.shape_8.setTransform(13.4098, 41.3254, 2.3796, 2.3797);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgIAKIAAgTIAHAAQAJAAAAAJQAAAKgJAAgAgDAGIACAAQAFAAAAgGQAAgGgFAAIgCAAg");
        this.shape_9.setTransform(8.8291, 41.3254, 2.3796, 2.3797);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AAEAKIgHgMIAAAMIgEAAIAAgTIAEAAIAHALIAAgLIAFAAIAAATg");
        this.shape_10.setTransform(3.8319, 41.3254, 2.3796, 2.3797);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AgBAKIAAgTIADAAIAAATg");
        this.shape_11.setTransform(0.4409, 41.3254, 2.3796, 2.3797);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.induktion, new cjs.Rectangle(0, 0, 35.6, 58.2), null);


    /* IMPORT END 
// stage content: ...
*/

    return lib
}