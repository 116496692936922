// Dieb
import thecoming_mf7298 from './../sound/Dieb/thecoming_mf7298.mp3'
import REHAU_Seq_Alarm_rot from './../sound/Dieb/REHAU_Seq_Alarm_rot.mp3'
// dusche
import mf_SE4947taking_a_shower from './../sound/Dusche/mf_SE-4947-taking_a_shower.mp3'
// Grille
import mf_SE6200grasshopper_5 from './../sound/Natur/mf_SE-6200-grasshopper_5.mp3'
// Hahn
import mf_SE6054cock_crowing_on_dungheap from './../sound/Natur/mf_SE-6054-cock_crowing_on_dungheap.mp3' 
// Vögel
import mf_SE5539birds_in_the_morning from './Natur/mf_SE-5539-birds_in_the_morning.mp3'
// Stimmung, Morgens
import peacefulParadise_Mf_7752 from './../sound/Stimmung/peacefulParadise_Mf_7752.mp3'

import Button_6_Pack2 from './ui/Button_6_Pack2.wav' 
import Clack_MinimalUISounds from './ui/Clack_Minimal_UI_Sounds.wav'
// import MagicSpell_SimpleSwoosh_6 from './ui/Magic_Spell_Simple_Swoosh_6.wav'
// import Spaceshippassingby from './ui/Spaceship_passing_by.wav'
import mf_SE61885medieval_map_zoom_out_01 from './ui/mf_SE-61885-medieval_map_zoom_out_01.mp3'

import Fentura_Welcome_to_WOW from './ui/Fentura_Welcome_to_WOW.mp3'

export const audioLib = {
    'sound/Dieb/thecoming_mf7298.mp3': thecoming_mf7298,
    'sound/Dieb/REHAU_Seq_Alarm_rot.mp3': REHAU_Seq_Alarm_rot,
    'sound/Dusche/mf_SE-4947-taking_a_shower.mp3': mf_SE4947taking_a_shower,
    'sound/Natur/mf_SE-6200-grasshopper_5.mp3': mf_SE6200grasshopper_5,
    'sound/Natur/mf_SE-6054-cock_crowing_on_dungheap.mp3': mf_SE6054cock_crowing_on_dungheap,
    'sound/Stimmung/peacefulParadise_Mf_7752.mp3': peacefulParadise_Mf_7752,
    'sound/Natur/mf_SE-5539-birds_in_the_morning.mp3': mf_SE5539birds_in_the_morning
}

export const audioUILib = {
    'click': Button_6_Pack2,
    'clack': Clack_MinimalUISounds,
    'fadein': mf_SE61885medieval_map_zoom_out_01, // MagicSpell_SimpleSwoosh_6
    'intro': Fentura_Welcome_to_WOW
}