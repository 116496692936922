/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    
    function getMCSymbolPrototype ....
    */



    (lib.mc_button = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_2
        this.txt_title = new cjs.Text("Button Test", "18px 'Brix Sans Light'", "#FF0000");
        this.txt_title.name = "txt_title";
        this.txt_title.lineHeight = 24;
        this.txt_title.lineWidth = 146;
        this.txt_title.parent = this;
        this.txt_title.setTransform(-85, 35.65);

        this.timeline.addTween(cjs.Tween.get(this.txt_title).wait(1));

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").ss(1, 1, 1).p("AuhkNIdDAAIAAIbI9DAAg");

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#848484").s().p("AuhEOIAAobIdDAAIAAIbg");

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.mc_button, new cjs.Rectangle(-94, -28, 188, 87.2), null);


    (lib.kreis_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").ss(1, 1, 1).p("AHMAAQAAC/iHCGQiGCHi/AAQi+AAiHiHQiGiGAAi/QAAi+CGiHQCHiGC+AAQC/AACGCGQCHCHAAC+g");

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#848484").s().p("AlFFFQiGiHAAi+QAAi+CGiHQCHiGC+AAQC+AACHCGQCHCHAAC+QAAC+iHCHQiHCHi+AAQi+AAiHiHg");

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.kreis_mc, new cjs.Rectangle(-47, -47, 94, 94), null);


    (lib.kreis = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_69 = function () {
            this.gotoAndPlay(2);
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).wait(69).call(this.frame_69).wait(1));

        // Ebene_1
        this.instance = new lib.kreis_mc();

        this.timeline.addTween(cjs.Tween.get(this.instance).to({ x: -1.25, y: 92.1 }, 34).to({ x: 0, y: -1 }, 35).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-48.2, -47.5, 95.2, 186.1);


    (lib.main_animation = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        this.isSingleFrame = false;
        // timeline functions:
        this.frame_0 = function () {
            if (this.isLoaded) {
                return
            }
            if (this.isSingleFrame) {
                return;
            }
            if (this.totalFrames == 1) {
                this.isSingleFrame = true;
            }
            var _this = this;
            /*
            Verschiebt die angegebene Symbolinstanz an die angegebenen x- und y-Koordinaten.
            Ersetzen Sie den Wert 200 durch die x-Koordinate, bei der Sie das Objekt positionieren möchten.
            Ersetzen Sie den Wert 100 durch die y-Koordinate, bei der Sie das Objekt positionieren möchten.
            _this.kreis.x = 200;
            _this.kreis.y = 100;
            */
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

        // Ebene_1
        this.btn_1 = new lib.mc_button();
        this.btn_1.name = "btn_1";
        this.btn_1.setTransform(307.5, 33.45);

        this.kreis = new lib.kreis();
        this.kreis.name = "kreis";
        this.kreis.setTransform(55.55, 47.45);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.kreis }, { t: this.btn_1 }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(9.1, 1, 391.9, 93), null);

    /* IMPORT END 
    // stage content: ...
    */


    return lib
}
