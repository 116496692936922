/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'

import { config } from '../../config'
import { initUI } from '../../actions/uiHelpers'
import { initNavigation } from '../../actions/navigation/navigation'
import { initAnimations } from './../../actions/animation/animationHelper'
import { initDebug, initScreenDebug } from './../../actions/debug/debugHelper'


// Flash
import { animation as safearea } from './helper/safearea';
import { animation as outside } from './outside/outside';
import { initOutside } from '../../actions/outside/outsideHelper'
import { initScreenLogo } from '../../actions/logoHelper'
import { initScreensaver } from '../../actions/screensaver'
import { animation as screensaver } from './outside/screensaver';

var lib = {}
var p

// Copy von Animate 
(lib.AnMovieClip = function () {
    this.actionFrames = [];
    this.ignorePause = false;
    this.gotoAndPlay = function (positionOrLabel) {
        cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
    }
    this.play = function () {
        cjs.MovieClip.prototype.play.call(this);
    }
    this.gotoAndStop = function (positionOrLabel) {
        cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
    }
    this.stop = function () {
        cjs.MovieClip.prototype.stop.call(this);
    }
}).prototype = p = new cjs.MovieClip();


// stage content:
(lib.rhfwv1 = function (mode, startPosition, loop, reversed) {
    if (loop == null) { loop = true; }
    if (reversed == null) { reversed = false; }
    var props = new Object();
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    this.mode = mode

    // Original Positionen Elemente
    this.mcPos = []
    // Status
    this.status = {
        currentAnimationRight: '',
        currentAnimationCenter: '',
        currentPage: null,
        level: null,
        prevPage: [],
        language: 'de',
        speech: ''
    }

    // console.log('mode', mode)

    // safearea
    if (mode === 'debug') {
        const safearea_lib = safearea()
        this.safearea = new safearea_lib.safearea();
        this.safearea.setTransform(189, 0, 1, 1, 0, 0, 0, 0, 0);

        this.timeline.addTween(cjs.Tween.get(this.safearea).wait(1));

        initDebug(this)
    }

    if (mode === 'screen') {
        // initScreenDebug(this)
        initScreenLogo(this)
        initScreensaver(this, mode)
    }

    if (mode === 'outside') {
        const safearea_lib = safearea()
        this.safearea = new safearea_lib.safearea();
        this.safearea.lamelle.visible = false
        this.safearea.logo.visible = false
        this.safearea.setTransform(189, 0, 1, 1, 0, 0, 0, 0, 0);
        this.timeline.addTween(cjs.Tween.get(this.safearea).wait(1));
        // console.log(this.safearea)

        const outside_lib = outside()
        this.outside = new outside_lib.outside();
        this.outside.setTransform(189, 0, 1, 1, 0, 0, 0, 0, 0);
        this.timeline.addTween(cjs.Tween.get(this.outside).wait(1));

        const screensaver_lib = screensaver()
        this.screensaver = new screensaver_lib.main_animation();
        this.screensaver.setTransform(710, 400);
        this.timeline.addTween(cjs.Tween.get(this.screensaver).wait(1));

        initOutside(this)
    }

    if (mode === 'screen' || mode === 'mobile') {
        // Animations
        initAnimations(this)

        // Navigation
        initNavigation(this)

        // headline_content
        initUI(this)
    }

    var tl = this.timeline, pos = tl.rawPosition;
    tl.setPosition(0, true, true, this._bound_resolveState);
    tl.rawPosition = pos;

    this._renderFirstFrame();

}).prototype = p = new lib.AnMovieClip();
p.nominalBounds = new cjs.Rectangle(1024, 768, 0, 0);

// library properties:
lib.properties = {
    id: 'rehau22fw',
    width: config.screen.width,
    height: config.screen.height,
    fps: config.screen.fps,
    color: "#FFFFFF",
    opacity: 1.00,
    manifest: [],
    preloads: []
};

(lib.Stage = function (canvas) {
    cjs.Stage.call(this, canvas);
}).prototype = p = new cjs.Stage();

export default lib