import { config } from './../config'
import { send2WS } from './websocket'
import { goHome } from './navigation/navigationAction'
import { setWindowState } from './window/windowHelper'

interface apiProps {
    window: Number,
    lamellen: Number
}

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,OPTIONS',
}

export const viewsonicApi = async (todo: string = 'on', ip: string = '') => {

    var url = `http://${config.ip}/api/viewsonic/`
    var body = {
        "device": ip,
        "switch": todo
    }

    var options: RequestInit = {
        method: 'POST',
        headers: headers,
        mode: 'cors',
        body: (body) ? JSON.stringify(body) : ''
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return { error: true, text: `${error}` }
    })

    const data = await response;

    if (data.result && data.result === 'error') {
        console.log('Viewsonic Error:', data)
    } else {
        // console.log('Viewsonic:', data)
    }
    return data

}

// Beamer wechseln
export const switchProjector = (root: any, to: string = '', isMobile: boolean = false) => {

    if (!config.isLocal) {
        return
    }

    console.log('switchProjector', root.type, to)
    if (to === 'outside') {
        
        if (root.type === 'screen') {
            root.visible = false
        }
        if (root.type === 'outside') {
            root.visible = true
            // send2WS(root, { hideScreen: 'screen' })
        }
        // viewsonicApi('blankon', config.projectors.front)
        // viewsonicApi('blankoff', config.projectors.back)
    }
    if (to === 'screen') {
        if (root.type === 'screen') {
            root.visible = true
            send2WS(root, { hideScreen: 'outside' })
            if (!isMobile) {
                goHome(root)
            }
            setWindowState(root, 'logo-center')
        }
        if (root.type === 'outside') {
            root.visible = false
        }
        // viewsonicApi('blankon', config.projectors.back)
        // viewsonicApi('blankoff', config.projectors.front)
    }

    root.status.projector = to
}

export const hideScreen = (root: any, type: string) => {
    if (root.type === type) {
        root.visible = false
    }
}