import * as cjs from 'createjs-module'
import { animation as rehaulogo } from '../assets/animate/helper/rehaulogo';
import { setLamellenState, setWindowState } from './window/windowHelper';

export const initScreenLogo = (root) => {
    // console.log('initScreenLogo', root)

    const rehaulogo_lib = rehaulogo()
    root.rehaulogo = new rehaulogo_lib.rehaulogo();
    root.rehaulogo.setTransform(189, 0, 1, 1, 0, 0, 0, 0, 0);

    root.timeline.addTween(cjs.Tween.get(root.rehaulogo).wait(1));

    root.rehaulogo.logo.bg.alpha = 0.01

    root.rehaulogo.logo.x = 145
    root.rehaulogo.logo.y = 570
    root.rehaulogo.logo.scaleX = 0.95
    root.rehaulogo.logo.scaleY = 0.95

    root.rehaulogo.logo.u.y = 128
    root.rehaulogo.logo.a.y = 370
    root.rehaulogo.logo.h.y = 612
    root.rehaulogo.logo.e.y = 824
    root.rehaulogo.logo.r.y = 1033

    root.rehaulogo.logo.r.on('mousedown', function () {
        if (root.status.logo === 'clear') {
            setLamellenState(root, 'full', 3)
            root.status.logo = 'full'
            animateScreenLogo(root, 'in')
        } else {
            setLamellenState(root, 'clear', 3)
            root.status.logo = 'clear'
            animateScreenLogo(root, 'out')
        }
        // console.log('logo r', root)
    })

    root.rehaulogo.logo.e.on('mousedown', function () {
        if (root.status.logo === 'clear') {
            setLamellenState(root, 'full', 3)
            root.status.logo = 'full'
            animateScreenLogo(root, 'in')
        } else {
            setLamellenState(root, 'ehau', 3)
            root.status.logo = 'clear'
            animateScreenLogo(root, 'out')
        }
        // console.log('logo e', root)
    })

    root.rehaulogo.logo.bg.on('mousedown', function () {
        if (root.status.logobg === 'clear') {
            setLamellenState(root, 'full', 4)
            root.status.logobg = 'full'
        } else {
            setLamellenState(root, 'clear', 4)
            root.status.logobg = 'clear'
        }
        // console.log('logobg', root)
    })

    animateScreenLogo(root, 'in')
}

export const animateScreenLogo = (root, todo) => {

    // console.log('animateScreenLogo', todo)

    if (root.type !=='screen') {
        return
    }

    const logoArr = ['r', 'e', 'h', 'a', 'u']

    if (todo === 'logo') {
        setWindowState(root, 'logo-center-right')
        // logoArr.forEach((logo, nr) => {
        //     root.rehaulogo.logo[logo].alpha = 0
        //     root.rehaulogo.logo[logo].shape.graphics._fill.style = "#FFFFFF"
        //     cjs.Tween.get(root.rehaulogo.logo[logo])
        //         .wait((nr + 1) * 200)
        //         .to({
        //             alpha: 1
        //         }, 400, cjs.Ease.quadOut);
        // });
    }

    if (todo === 'in') {
        setWindowState(root, 'logo-center')
        logoArr.forEach((logo, nr) => {
            root.rehaulogo.logo[logo].alpha = 0
            root.rehaulogo.logo[logo].shape.graphics._fill.style = "#FFFFFF"
            cjs.Tween.get(root.rehaulogo.logo[logo])
                .wait((nr + 1) * 500)
                .to({
                    alpha: 1
                }, 400, cjs.Ease.quadOut);
        });
    }

    if (todo === 'out') {
        logoArr.forEach((logo, nr) => {
            root.rehaulogo.logo[logo].shape.graphics._fill.style = "#FFFFFF"
            cjs.Tween.get(root.rehaulogo.logo[logo])
                .wait((nr + 1) * 200)
                .to({
                    alpha: 0.01
                }, 400, cjs.Ease.quadOut);
        });
    }

    if (todo === 'alarm') {

        // console.log('alarm')

        setWindowState(root, 'logo-center-right')
        root.rehaulogo.logo.alpha = 0
        logoArr.forEach((logo, nr) => {
            root.rehaulogo.logo[logo].alpha = 1
            root.rehaulogo.logo[logo].shape.graphics._fill.style = "#CB2C61"
        });

        cjs.Tween.get(root.rehaulogo.logo)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 0
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 0
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 0
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut);
    }

    if (todo === 'loading') {

        // console.log('loading')

        setWindowState(root, 'logo-center-right')
        root.rehaulogo.logo.alpha = 0
        logoArr.forEach((logo, nr) => {
            root.rehaulogo.logo[logo].alpha = 1
            root.rehaulogo.logo[logo].shape.graphics._fill.style = "#25b4fe"
        });

        cjs.Tween.get(root.rehaulogo.logo)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 0
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 0
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 0
            }, 1000, cjs.Ease.quadOut)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut);
    }
}