/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'
import { animateInduktion, setHeadlineContent } from '../../../../actions/uiHelpers'

const createjs = cjs

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */



    (lib.welle_02 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_8 = function () {
            this.gotoAndPlay(0);
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).wait(8).call(this.frame_8).wait(1));

        // Ebene_3 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        var mask_graphics_0 = new cjs.Graphics().p("Au8CbIAAk1Id5AAIAAE1g");
        var mask_graphics_8 = new cjs.Graphics().p("Au8CbIAAk1Id5AAIAAE1g");

        this.timeline.addTween(cjs.Tween.get(mask).to({ graphics: mask_graphics_0, x: 95.616, y: -3.5 }).wait(8).to({ graphics: mask_graphics_8, x: 95.616, y: -3.5 }).wait(1));

        // Ebene_2
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#34A58C").ss(2).p("ASfA4QgVgDghgvQgjgxgZgBQgYAAgjAxQglAwgXABQgXgBgigxQgggygYAAQgXAAgjAwQgkAxgZAAQgYAAgigzQgjgxgYAAQgXABgkAwQgiAwgZAAQgZAAgkgyQglgxgXgBQgYABgiAwQghAwgZAAQgWABgmgyQgkgygYgBQgZAAghAyQgjAwgbAAQgDAAgDAAQgCAAgDAAQgbAAgjgwQghgygZAAQgYABgkAyQgmAygWgBQgZAAghgwQgigwgYgBQgXABglAxQgkAygZAAQgZAAgigwQgkgwgXgBQgYAAgjAxQgiAzgYAAQgZAAgkgxQgjgwgXAAQgYAAggAyQgiAxgXABQgXgBglgwQgjgxgYAAQgZABgjAxQghAvgVAD");
        this.shape.setTransform(118.325, -6.45);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f().s("#34A58C").ss(2).p("AyeA4QAVgDAhgvQAjgxAZgBQAYAAAjAxQAlAwAXABQAXgBAigxQAggyAYAAQAXAAAjAwQAkAxAZAAQAYAAAigzQAjgxAYAAQAXABAkAwQAiAwAZAAQAZAAAkgyQAlgxAXgBQAYABAiAwQAhAwAZAAQAWABAmgyQAkgyAYgBQAZAAAhAyQAjAwAbAAQAmAAAjgwQAhgyAZAAQAYABAkAyQAmAyAWgBQAZAAAhgwQAigwAYgBQAXABAlAxQAkAyAZAAQAZAAAigwQAkgwAXgBQAYAAAjAxQAiAzAYAAQAZAAAkgxQAjgwAXAAQAYAAAgAyQAiAxAXABQAXgBAlgwQAjgxAYAAQAZABAjAxQAhAvAVAD");
        this.shape_1.setTransform(115.325, -6.45);
        this.shape_1._off = true;

        var maskedShapeInstanceList = [this.shape, this.shape_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape, p: { x: 118.325 } }] }).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape, p: { x: 94.175 } }] }, 1).wait(1));
        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1).to({ _off: false }, 0).wait(1).to({ x: 112.275 }, 0).wait(1).to({ x: 109.275 }, 0).wait(1).to({ x: 106.275 }, 0).wait(1).to({ x: 103.225 }, 0).wait(1).to({ x: 100.225 }, 0).wait(1).to({ x: 97.175 }, 0).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-0.1, -17.1, 191.5, 18.700000000000003);


    (lib.welle_01 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_8 = function () {
            this.gotoAndPlay(0);
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).wait(8).call(this.frame_8).wait(1));

        // Ebene_3 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        var mask_graphics_0 = new cjs.Graphics().p("Au8CbIAAk1Id5AAIAAE1g");
        var mask_graphics_8 = new cjs.Graphics().p("Au8CbIAAk1Id5AAIAAE1g");

        this.timeline.addTween(cjs.Tween.get(mask).to({ graphics: mask_graphics_0, x: 95.6175, y: -3.5 }).wait(8).to({ graphics: mask_graphics_8, x: 95.6175, y: -3.5 }).wait(1));

        // Ebene_2
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#DD0261").ss(2).p("ASfA4QgVgDghgvQgjgxgZgBQgYAAgjAxQglAwgXABQgXgBgigxQgggygYAAQgXAAgjAwQgkAxgZAAQgYAAgigzQgjgxgYAAQgXABgkAwQgiAwgZAAQgZAAgkgyQglgxgXgBQgYABgiAwQghAwgZAAQgWABgmgyQgkgygYgBQgZAAghAyQgjAwgbAAQgDAAgDAAQgCAAgDAAQgbAAgjgwQghgygZAAQgYABgkAyQgmAygWgBQgZAAghgwQgigwgYgBQgXABglAxQgkAygZAAQgZAAgigwQgkgwgXgBQgYAAgjAxQgiAzgYAAQgZAAgkgxQgjgwgXAAQgYAAggAyQgiAxgXABQgXgBglgwQgjgxgYAAQgZABgjAxQghAvgVAD");
        this.shape.setTransform(118.325, -6.45);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f().s("#DD0261").ss(2).p("AyeA4QAVgDAhgvQAjgxAZgBQAYAAAjAxQAlAwAXABQAXgBAigxQAggyAYAAQAXAAAjAwQAkAxAZAAQAYAAAigzQAjgxAYAAQAXABAkAwQAiAwAZAAQAZAAAkgyQAlgxAXgBQAYABAiAwQAhAwAZAAQAWABAmgyQAkgyAYgBQAZAAAhAyQAjAwAbAAQAmAAAjgwQAhgyAZAAQAYABAkAyQAmAyAWgBQAZAAAhgwQAigwAYgBQAXABAlAxQAkAyAZAAQAZAAAigwQAkgwAXgBQAYAAAjAxQAiAzAYAAQAZAAAkgxQAjgwAXAAQAYAAAgAyQAiAxAXABQAXgBAlgwQAjgxAYAAQAZABAjAxQAhAvAVAD");
        this.shape_1.setTransform(115.325, -6.45);
        this.shape_1._off = true;

        var maskedShapeInstanceList = [this.shape, this.shape_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape, p: { x: 118.325 } }] }).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape_1 }] }, 1).to({ state: [{ t: this.shape, p: { x: 94.175 } }] }, 1).wait(1));
        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1).to({ _off: false }, 0).wait(1).to({ x: 112.275 }, 0).wait(1).to({ x: 109.275 }, 0).wait(1).to({ x: 106.275 }, 0).wait(1).to({ x: 103.225 }, 0).wait(1).to({ x: 100.225 }, 0).wait(1).to({ x: 97.175 }, 0).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-0.1, -17.1, 191.5, 18.700000000000003);


    (lib.solarButton_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // icon
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgpAEIAAgHIBSAAIAAAHg");
        this.shape.setTransform(31.4, 40.2276, 2.3698, 2.3698);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AgzACIAAgDIBnAAIAAADg");
        this.shape_1.setTransform(31.4, 31.0445, 2.3698, 2.3698);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgsACIAAgDIBZAAIAAADg");
        this.shape_2.setTransform(31.4593, 26.6011, 2.3698, 2.3698);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgFgcIAEgBIAHA7IgDAAg");
        this.shape_3.setTransform(38.391, 29.2672, 2.3698, 2.3698);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgBAeIAAg7IADAAIAAA7g");
        this.shape_4.setTransform(31.4593, 29.2672, 2.3698, 2.3698);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgGAeIAIg7IAFABIgIA6g");
        this.shape_5.setTransform(24.4091, 29.2672, 2.3698, 2.3698);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("Ag/AiIAUhDIBXAAIAUBDgAg0AaIBoAAIgOgyIhLAAg");
        this.shape_6.setTransform(31.4593, 29.2672, 2.3698, 2.3698);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgKAFIAPgPIAGAGIgPAPg");
        this.shape_7.setTransform(11.3159, 33.5921, 2.3698, 2.3698);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgLAAIAUgGIADAIIgVAFg");
        this.shape_8.setTransform(7.8796, 27.4898, 2.3698, 2.3698);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgLACIACgIIAVAFIgDAIg");
        this.shape_9.setTransform(7.8796, 20.4989, 2.3698, 2.3698);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AgKgEIAGgGIAPAPIgGAGg");
        this.shape_10.setTransform(11.3159, 14.3966, 2.3698, 2.3698);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AgGgIIAHgDIAGAVIgHACg");
        this.shape_11.setTransform(17.4181, 10.9011, 2.3698, 2.3698);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#FFFFFF").s().p("AgGAKIAGgVIAHADIgGAUg");
        this.shape_12.setTransform(24.4091, 10.9011, 2.3698, 2.3698);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#FFFFFF").s().p("AgKAFIAPgPIAGAGIgPAPg");
        this.shape_13.setTransform(30.5113, 14.3966, 2.3698, 2.3698);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AggAGQAAgPALgMQALgLAQAAQAQAAALALIgGAGQgJgJgMAAQgMAAgJAJQgJAJAAAMQAAANAJAJIgGAFQgLgKAAgRg");
        this.shape_14.setTransform(19.6102, 22.6317, 2.3698, 2.3698);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_14 }, { t: this.shape_13 }, { t: this.shape_12 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(2));

        // Ebene_1
        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhiBjIDFAAIAAjFIjFAAg");
        this.shape_15.setTransform(26.0066, 25.9977, 2.3859, 2.3906);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("rgba(221,0,97,0.898)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_16.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_15 }] }).to({ state: [{ t: this.shape_16 }] }, 1).wait(1));

        // Ebene_5
        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("rgba(0,0,0,0.008)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_17.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get(this.shape_17).wait(2));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0, 0, 52, 52);


    (lib.repeat_btn_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // flash0_ai
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AizB4IBQhQIAAA8IDHAAIAAhQIAoAAIAAB4IjvAAIAAA8gAiLgTIAAh4IDvAAIAAg8IBQBQIhQBQIAAg8IjHAAIAABQg");
        this.shape.setTransform(25.951, 25.4504, 0.9, 0.9);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(2));

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AkDEEIAAoHIIHAAIAAIHgAjrDsIHYAAIAAnXInYAAg");
        this.shape_1.setTransform(26, 26);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("rgba(221,0,97,0.898)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_2.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_1 }] }).to({ state: [{ t: this.shape_2 }] }, 1).wait(1));

        // Ebene_2
        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("rgba(52,165,140,0.839)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_3.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get(this.shape_3).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0, 0, 52, 52);


    (lib.pfeile = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AQuA7IAsg7Igrg6IAegVIBGBPIhGBQgAO4A7IAsg7Igsg6IAfgVIBGBPIhGBQgANCA7IAsg7Igsg6IAfgVIBFBPIhFBQgALMA7IAsg7Igsg6IAfgVIBFBPIhFBQgAJWA7IAsg7Igsg6IAegVIBGBPIhGBQgAHgA7IAtg7Igtg6IAegVIBGBPIhGBQgAFqA7IAsg7Igsg6IAegVIBGBPIhGBQgAD0A7IAsg7Igsg6IAegVIBGBPIhGBQgAB+A7IAsg7Igsg6IAegVIBHBPIhHBQgAAHA7IAtg7Igsg6IAegVIBGBPIhGBQgAhuA7IAtg7Igsg6IAegVIBGBPIhGBQgAjkA7IAtg7Igsg6IAegVIBGBPIhGBQgAlaA7IAtg7Igsg6IAdgVIBHBPIhHBQgAnQA7IAtg7Igsg6IAdgVIBHBPIhHBQgApFA7IAsg7Igsg6IAdgVIBHBPIhHBQgAq8A7IAtg7Igsg6IAdgVIBHBPIhHBQgAsyA7IAtg7Igsg6IAdgVIBHBPIhHBQgAunA7IAtg7Igsg6IAdgVIBHBPIhHBQgAwdA7IAtg7Igsg6IAegVIBGBPIhGBQgAySA7IAsg7Igrg6IAegVIBGBPIhGBQg");
        this.shape.setTransform(117.1, 8.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.pfeile, new cjs.Rectangle(0, 0, 234.2, 16.1), null);


    (lib.Path_6 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("Akml2IAQgHII9L0IgQAHg");
        this.shape.setTransform(29.525, 38.225);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_6, new cjs.Rectangle(0, 0, 59.1, 76.5), null);


    (lib.Path_5 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgGjIIAPgIIgCGYIgPAIg");
        this.shape.setTransform(0.9, 20.85);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_5, new cjs.Rectangle(0, 0, 1.8, 41.7), null);


    (lib.Path_4 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AiNgJIARgIIEJAbIgQAIg");
        this.shape.setTransform(14.15, 1.775);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_4, new cjs.Rectangle(0, 0, 28.3, 3.6), null);


    (lib.Path_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AiwByIEsldIA1BFIlbGRg");
        this.shape.setTransform(17.7, 23.55);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_1, new cjs.Rectangle(0, 0, 35.4, 47.1), null);


    (lib.Path = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AiPBzIEBkqIAeAnIkbFIg");
        this.shape.setTransform(14.375, 18.375);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path, new cjs.Rectangle(0, 0, 28.8, 36.8), null);


    (lib.Path_9 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AmvGNIg2vpIBMgjIA0PXINLD6IhkAugAnGpJIA0PAIMSDpIARgIIsTjpIgzvAg");
        this.shape.setTransform(48.575, 63.975);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_9, new cjs.Rectangle(0, 0, 97.2, 128), null);


    (lib.Path_7 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AAOhQIBUBtIjDA0g");
        this.shape.setTransform(9.775, 8.05);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_7, new cjs.Rectangle(0, 0, 19.6, 16.1), null);


    (lib.Path_4_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AlwFsIgzvAIEKAcII9L0IgBGZg");
        this.shape_1.setTransform(42.025, 59.725);

        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_4_1, new cjs.Rectangle(0, 0, 84.1, 119.5), null);


    (lib.Path_1_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AmfGTIg2wxIBEAQIA2PqIMwDzIAABQg");
        this.shape_1.setTransform(46.95, 67.125);

        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_1_1, new cjs.Rectangle(0, 0, 93.9, 134.3), null);


    (lib.Path_14 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AjQAVICWhKIELAbIh3BQg");
        this.shape.setTransform(20.925, 5.35);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_14, new cjs.Rectangle(0, 0, 41.9, 10.7), null);


    (lib.Path_12 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AiVALIAAg1IEqAgIAAA1g");
        this.shape.setTransform(14.95, 4.275);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_12, new cjs.Rectangle(0, 0, 29.9, 8.6), null);


    (lib.Path_11 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#DD0261").s().p("Ag7gPIB3hRIAABwIh3BRg");
        this.shape.setTransform(5.975, 9.675);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_11, new cjs.Rectangle(0, 0, 12, 19.4), null);


    (lib.Path_9_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#34A58C").s().p("Ag7AcIB3hQIAAAZIh3BQg");
        this.shape_1.setTransform(5.975, 5.325);

        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_9_1, new cjs.Rectangle(0, 0, 12, 10.7), null);


    (lib.Path_7_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("Ag7AcIB3hQIAAAZIh3BQg");
        this.shape_1.setTransform(5.975, 5.325);

        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_7_1, new cjs.Rectangle(0, 0, 12, 10.7), null);


    (lib.Path_5_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#34A58C").s().p("Ag7AcIB3hQIAAAZIh3BQg");
        this.shape_1.setTransform(5.975, 5.3);

        this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_5_1, new cjs.Rectangle(0, 0, 12, 10.6), null);


    (lib.Path_3 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("Ag7AcIB3hQIAAAZIh3BQg");
        this.shape.setTransform(5.975, 5.3);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.Path_3, new cjs.Rectangle(0, 0, 12, 10.6), null);


    (lib.corona2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").ss(2, 0, 0, 3.9).p("AAAl8IAAi1AC/lJIBaidAF+AAIC1AAAC/FKIBaCdAAAF+IAAC0AFLC/ICcBaAFLi9ICchcAi+lJIhaidAi+FKIhaCdAl9AAIi1AAAlKC/IicBaAlKi9Iichc");
        this.shape.setTransform(56.275, 56.225);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.corona2, new cjs.Rectangle(-1, -1, 114.6, 114.5), null);


    (lib.corona1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").ss(4, 0, 0, 3.9).p("AB1m1IA3jPAE/lAICZiXAG1h0IDQg4AG1B2IDQA3AB1G2IA3DPAE/FAICZCYAk/lAIiZiXAh1m1Ig4jPAh1G2Ig4DPAk/FAIiZCYAm1B2IjPA3Am1h0IjPg4");
        this.shape.setTransform(64.5238, 64.525);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.corona1, new cjs.Rectangle(-2, -2, 133.1, 133.1), null);


    (lib.batterieButton_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(2));

        // flash0_ai
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AAUAdQgOALgSAAQgVAAgQgPQgPgPAAgVQAAgWAPgQQAQgPAVAAQAVAAAPAPQAQAQAAAWQAAARgLAOIAkAkIgJAJgAgogoQgMAMAAARQAAAPAMAMQAMAMAQAAQAQAAAMgMQAMgMAAgPQAAgRgMgMQgMgMgQAAQgQAAgMAMg");
        this.shape.setTransform(27.0042, 27.7634, 2.3699, 2.3699);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AAGATIAAgNIgLAAIAAgLIALAAIAAALIANAAIAAANgAgSATIAAgNIANAAIAAANgAgFAGgAAGgFIAAgNIANAAIAAANgAAGgFg");
        this.shape_1.setTransform(18.8874, 19.7059, 2.3699, 2.3699);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AggAhIAAgNIA1AAIAAgzIALAAIAABAg");
        this.shape_2.setTransform(21.435, 22.2535, 2.3699, 2.3699);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }] }).wait(2));

        // Ebene_1
        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AhsBtIAAjZIDZAAIAADZgAhiBjIDFAAIAAjFIjFAAg");
        this.shape_3.setTransform(26.0054, 25.9969, 2.3858, 2.3905);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("rgba(221,0,97,0.898)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_4.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_3 }] }).to({ state: [{ t: this.shape_4 }] }, 1).wait(1));

        // Ebene_2
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("rgba(0,0,0,0.008)").s().p("AkDEEIAAoHIIHAAIAAIHg");
        this.shape_5.setTransform(26, 26);

        this.timeline.addTween(cjs.Tween.get(this.shape_5).to({ _off: true }, 1).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0, 0, 52, 52);


    (lib.window_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#020203").s().p("AgIgGIAGgBIACAHIAIAAIABAEIgNAEg");
        this.shape.setTransform(265.1056, 319.4432, 2.3697, 2.3697);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#020203").s().p("AgFASIAHgOIgNAAIAMgYIAGADIgIAQIANAAIgMAWg");
        this.shape_1.setTransform(265.4611, 315.3556, 2.3697, 2.3697);

        this.instance = new lib.Path();
        this.instance.setTransform(65.9, 75.4, 2.37, 2.37, 0, 0, 0, 14.3, 18.4);
        this.instance.alpha = 0.1992;

        this.instance_1 = new lib.Path_1();
        this.instance_1.setTransform(75.5, 103.65, 2.37, 2.37, 0, 0, 0, 17.7, 23.6);
        this.instance_1.alpha = 0.1016;

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AghncIARgHIAyPAIgQAHg");
        this.shape_2.setTransform(31.4024, 129.3599, 2.37, 2.37);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AmRhwIAQgHIMTDoIgQAIg");
        this.shape_3.setTransform(130.8237, 270.9079, 2.37, 2.37);

        this.instance_2 = new lib.Path_4();
        this.instance_2.setTransform(57, 18.85, 2.37, 2.37, 0, 0, 0, 14.2, 1.8);
        this.instance_2.alpha = 0.8008;

        this.instance_3 = new lib.Path_5();
        this.instance_3.setTransform(224.35, 250.2, 2.37, 2.37, 0, 0, 0, 0.9, 20.9);
        this.instance_3.alpha = 0.3984;

        this.instance_4 = new lib.Path_6();
        this.instance_4.setTransform(156.4, 111.8, 2.37, 2.37, 0, 0, 0, 29.5, 38.2);
        this.instance_4.alpha = 0.6016;

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgqgDIgBgBIAKgMQAVAOAYACQAQACANgCIADAOQgLADgLAAQgiAAgegUg");
        this.shape_4.setTransform(249.0865, 314.52, 2.37, 2.37);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgXADIAAgDQAAgEAFgBIAkgCQAGAAAAAFIAAACQABAFgGABIglACQgEAAgBgFg");
        this.shape_5.setTransform(274.561, 310.9937, 2.3698, 2.3698);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgXACIAAgCQAAgFAFABIAlgDQAFABAAAFIAAABQAAAGgFAAIgkACQgGAAAAgGg");
        this.shape_6.setTransform(275.0167, 319.9397, 2.3698, 2.3698);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgYAeQgJgIgBgMIgBgPQAAgMAIgJQAIgIAMgBIAngCIAEBJIgnACIgDAAQgKAAgIgIg");
        this.shape_7.setTransform(265.2582, 315.8318, 2.37, 2.37);

        this.instance_5 = new lib.Path_1_1();
        this.instance_5.setTransform(111.4, 167.05, 2.37, 2.37, 0, 0, 0, 47, 67.1);
        this.instance_5.alpha = 0.5;

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AhHAJIBHghIBIAQIhLAhg");
        this.shape_8.setTransform(16.9455, 5.9424, 2.37, 2.37);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AgwgPIBhgwIAABPIhhAwg");
        this.shape_9.setTransform(234.2148, 310.9015, 2.37, 2.37);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#DD0261").s().p("AmKF5Ig0vOIAkgQIA0PIIMlDuIgtAVgAm0pOIA0PAIMSDqIARgIIsTjpIgzvBg");
        this.shape_10.setTransform(126.9132, 156.4371, 2.37, 2.37);

        this.instance_6 = new lib.Path_9();
        this.instance_6.setTransform(131.2, 155.4, 2.37, 2.37, 0, 0, 0, 48.6, 64);
        this.instance_6.alpha = 0.75;

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#020203").s().p("AnYAKIBpjSINJD1IAABAIgSAsIiOAwg");
        this.shape_11.setTransform(133.6677, 285.0686, 2.37, 2.37);

        this.instance_7 = new lib.Path_4_1();
        this.instance_7.setTransform(122.9, 157.9, 2.37, 2.37, 0, 0, 0, 42, 59.7);
        this.instance_7.alpha = 0.1484;

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_7 }, { t: this.shape_11 }, { t: this.instance_6 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.instance_5 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.instance_4 }, { t: this.instance_3 }, { t: this.instance_2 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.instance_1 }, { t: this.instance }, { t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.window_mc, new cjs.Rectangle(0, 0, 280.7, 332.8), null);


    (lib.sonne_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // corona2
        this.instance = new lib.corona2();
        this.instance.setTransform(63.95, 63.25, 1, 1, 0, 0, 0, 56.2, 56.2);

        this.timeline.addTween(cjs.Tween.get(this.instance).to({ regX: 56.4, regY: 56.1, scaleX: 0.9999, scaleY: 0.9999, rotation: 28.9994, x: 64.1 }, 586).wait(1));

        // corona1
        this.instance_1 = new lib.corona1();
        this.instance_1.setTransform(64.5, 64.5, 1, 1, 0, 0, 0, 64.5, 64.5);

        this.timeline.addTween(cjs.Tween.get(this.instance_1).to({ rotation: -29.0002, x: 64.45 }, 586).wait(1));

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").ss(6, 0, 0, 3.9).p("AEgAAQAAh2hUhWQhVhUh3AAQh4AAhUBUQhUBWAAB2QAAB4BUBVQBVBTB3AAQB3AABVhTQBUhVAAh4g");
        this.shape.setTransform(63.05, 64.05);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#DD0261").s().p("AhUBVQgjgjAAgyQAAgxAjgjQAjgjAxAAQAyAAAjAjQAjAjAAAxQAAAygjAjQgjAjgyAAQgxAAgjgjg");
        this.shape_1.setTransform(52.4981, 64.5144, 2.3699, 2.3699);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_1 }, { t: this.shape }] }).wait(587));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-25.9, -25.9, 180.9, 180.8);


    (lib.pfeilani = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_2 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("AwmFMIAAqYMAhMAAAIAAKYg");
        mask.setTransform(116.25, 13.3);

        // Ebene_1
        this.instance = new lib.pfeile();
        this.instance.setTransform(117.1, 8, 1, 1, 0, 0, 0, 117.1, 8);

        var maskedShapeInstanceList = [this.instance];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance).to({ x: 129.1 }, 5).wait(1));

        // Ebene_3
        this.shape = new cjs.Shape();
        this.shape.graphics.f("rgba(255,255,255,0.898)").s().p("AyZBSIAAijMAkzAAAIAACjg");
        this.shape.setTransform(125.25, 7.775);

        var maskedShapeInstanceList = [this.shape];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(6));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(10, -0.4, 212.5, 16.5);


    (lib.batterie_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#FFFFFF").ss(2).p("Ansg4QAXiKByhRQByhQCKAXQA+ALA0AgQAzAeAnAvIFphpIkpDoQATBGgNBHQgXCLhyBRQhxBQiKgXQiJgYhRhyQhRhyAYiJg");
        this.shape.setTransform(118.1957, 80.0573, 2.37, 2.37);

        this.instance = new lib.welle_01();
        this.instance.setTransform(69.7, 73.75, 0.2528, 0.4154, 0, -89.0381, -88.7203, -0.1, -4.8);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#DD0261").s().p("AAKgsIArA7IhpAeg");
        this.shape_1.setTransform(69.3314, 76.2762, 0.9009, 0.9396, 0, -57.359, -49.4365);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AiVgMIABgGIEqAgIgBAEg");
        this.shape_2.setTransform(68.9739, 63.1085, 2.3699, 2.3699);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgJgCIAGAAIAAgMIAIABIAAANIAEAAIgJAOg");
        this.shape_3.setTransform(84.6687, 109.0769, 2.3697, 2.3697);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgJACIAAgFIATACIAAAFg");
        this.shape_4.setTransform(84.6, 101.127, 2.3695, 2.3695);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AAAAXQgIAAgGgIQgGgHAAgIQAAgLAHgGQAGgGAHABQAIABAHAHQAGAHAAAJQAAAKgHAGQgGAFgHAAgAgKgNQgFAGAAAHQAAAGAEAGQAFAGAGAAQAGAAAEgEQAGgEAAgIQAAgHgFgFQgEgGgHgBIgBAAQgDAAgGAEg");
        this.shape_5.setTransform(84.6592, 101.1101, 2.3695, 2.3695);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgDAOIAAgNIgFAAIAIgOIAJAQIgEAAIAAAMg");
        this.shape_6.setTransform(52.4998, 92.1334, 2.3697, 2.3697);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgCALIAAgVIAEAAIAAAVg");
        this.shape_7.setTransform(52.553, 99.9422, 2.3695, 2.3695);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AgJACIAAgEIATABIAAAEg");
        this.shape_8.setTransform(52.4938, 99.9422, 2.3695, 2.3695);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AAAAXQgIAAgGgIQgGgHAAgIQAAgKAGgHQAHgFAHAAQAIABAHAHQAGAHAAAJQAAAKgHAGQgGAFgHAAgAgKgNQgFAFAAAIQAAAGAEAGQAGAGAFAAQAHAAAEgDQAFgGAAgHQAAgHgFgFQgFgGgGgBIAAAAQgGAAgEAEg");
        this.shape_9.setTransform(52.553, 99.9373, 2.3695, 2.3695);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#FFFFFF").s().p("AgjA7IgQh8IBnAMIgPB3g");
        this.shape_10.setTransform(69.384, 37.8075, 2.3697, 2.3697);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AgpBcIATACIghkBIBvANIgiD6IAVACIgrA+gAAACbIAigxIgSgBIAhj7IhigLIAiEBIgRgCg");
        this.shape_11.setTransform(69.3887, 60.3239, 2.3699, 2.3699);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#FFFFFF").s().p("AgBCFIAAkKIADAAIAAEKg");
        this.shape_12.setTransform(104.4628, 98.5381, 2.3699, 2.3699);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#FFFFFF").s().p("Ag8AmIB2hQIADAFIh3BQg");
        this.shape_13.setTransform(118.6228, 57.3023, 2.3699, 2.3699);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AjaCkIAAkZICchOIADgBIACAAIEUAbIAAEYIh/BWgAjQhvIAAEKIEqAgIB3hRIAAkKIkLgag");
        this.shape_14.setTransform(83.1932, 85.6815, 2.3699, 2.3699);

        this.instance_1 = new lib.Path_3();
        this.instance_1.setTransform(118.5, 78.85, 2.3699, 2.3699, 0, 0, 0, 6, 5.3);
        this.instance_1.alpha = 0.1016;

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#020203").s().p("AiVgDIAAgYIErAeIAAAZg");
        this.shape_15.setTransform(69.0332, 84.7928, 2.3699, 2.3699);

        this.instance_2 = new lib.Path_5_1();
        this.instance_2.setTransform(118.5, 73.15, 2.3699, 2.3699, 0, 0, 0, 6, 5.4);
        this.instance_2.alpha = 0.5;

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#34A58C").s().p("AiVgCIAAgaIErAgIAAAZg");
        this.shape_16.setTransform(69.0332, 78.8089, 2.3699, 2.3699);

        this.instance_3 = new lib.Path_7_1();
        this.instance_3.setTransform(118.5, 117.6, 2.3699, 2.3699, 0, 0, 0, 6, 5.4);
        this.instance_3.alpha = 0.1016;

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#020203").s().p("AiVgCIAAgaIErAgIAAAZg");
        this.shape_17.setTransform(69.0332, 123.3625, 2.3699, 2.3699);

        this.instance_4 = new lib.Path_9_1();
        this.instance_4.setTransform(118.5, 111.65, 2.3699, 2.3699, 0, 0, 0, 6, 5.4);
        this.instance_4.alpha = 0.5;

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#34A58C").s().p("AiVgCIAAgaIErAfIAAAag");
        this.shape_18.setTransform(69.0332, 117.4378, 2.3699, 2.3699);

        this.instance_5 = new lib.Path_11();
        this.instance_5.setTransform(118.5, 95.4, 2.3699, 2.3699, 0, 0, 0, 6, 9.8);
        this.instance_5.alpha = 0.5;

        this.instance_6 = new lib.Path_12();
        this.instance_6.setTransform(69.25, 69.15, 2.3699, 2.3699, 0, 0, 0, 15.1, 4.2);
        this.instance_6.alpha = 0.5;

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#DD0261").s().p("AiVAoIAAhvIErAgIAABvg");
        this.shape_19.setTransform(69.0332, 101.0857, 2.3699, 2.3699);

        this.instance_7 = new lib.Path_14();
        this.instance_7.setTransform(83.2, 66.9, 2.3699, 2.3699, 0, 0, 0, 21, 5.4);
        this.instance_7.alpha = 0.25;

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.instance_7 }, { t: this.shape_19 }, { t: this.instance_6 }, { t: this.instance_5 }, { t: this.shape_18 }, { t: this.instance_4 }, { t: this.shape_17 }, { t: this.instance_3 }, { t: this.shape_16 }, { t: this.instance_2 }, { t: this.shape_15 }, { t: this.instance_1 }, { t: this.shape_14 }, { t: this.shape_13 }, { t: this.shape_12 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.instance }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.batterie_mc, new cjs.Rectangle(-1.4, -1, 233.70000000000002, 162.1), null);


    (lib.batterie_ani_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        this.isSingleFrame = false;
        // timeline functions:
        this.frame_0 = function () {
            if (this.isLoaded) {
                return
            }
            if (this.isSingleFrame) {
                return;
            }
            if (this.totalFrames == 1) {
                this.isSingleFrame = true;
            }
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

        // Ebene_1
        this.instance = new lib.batterie_mc();
        this.instance.setTransform(115.4, 80, 1, 1, 0, 0, 0, 115.4, 80);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.batterie_ani_mc, new cjs.Rectangle(-1.4, -1, 233.70000000000002, 162.1), null);


    (lib.sonnenWindAni_01 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // flash0_ai
        this.instance = new lib.pfeilani();
        this.instance.setTransform(44.25, 257.1, 0.6338, 1, 141.5604, 0, 0, 223.2, 8.2);

        this.instance_1 = new lib.Path_7();
        this.instance_1.setTransform(34.55, 261.95, 1.896, 1.896, 0, 0, 0, 9.8, 8.1);

        this.instance_2 = new lib.welle_02();
        this.instance_2.setTransform(224.95, 204.6, 0.34, 0.584, 0, -156.242, -159.7212, -0.8, -7);

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#34A58C").s().p("AAYhpIBlCNIj5BHg");
        this.shape.setTransform(228.252, 204.7958, 0.4996, 0.5607, 0, -125.8725, -115.1546);

        this.instance_3 = new lib.welle_02();
        this.instance_3.setTransform(90.15, 156, 0.2739, 0.4705, 0, 17.2544, 13.7784, 0.1, -6.5);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#34A58C").s().p("AAYhpIBlCNIj5BHg");
        this.shape_1.setTransform(87.3044, 156.1085, 0.4025, 0.4517, 0, 47.6206, 58.3418);

        this.instance_4 = new lib.welle_01();
        this.instance_4.setTransform(186.25, 240.2, 0.2618, 0.383, 0, -113.0388, -117.7914, -0.2, -5);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#DD0261").s().p("AAKgsIArA7IhpAeg");
        this.shape_2.setTransform(187.1669, 242.4532, 0.8548, 0.9334, -79.9861);

        this.instance_5 = new lib.welle_01();
        this.instance_5.setTransform(109.05, 105.3, 0.2886, 0.4223, 0, 61.4651, 56.7107, 0.4, -5.4);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#DD0261").s().p("AAKgsIArA7IhpAeg");
        this.shape_3.setTransform(107.6499, 102.8341, 0.9424, 1.029, 94.5221);

        this.instance_6 = new lib.pfeilani();
        this.instance_6.setTransform(230.6, 100.7, 0.507, 0.7999, 141.5617, 0, 0, 223.1, 8.2);

        this.instance_7 = new lib.Path_7();
        this.instance_7.setTransform(222.75, 104.8, 1.5167, 1.5167, 0, 0, 0, 9.8, 8.2);

        this.instance_8 = new lib.welle_01();
        this.instance_8.setTransform(206.65, 83.75, 0.5628, 0.7999, -35.403, 0, 0, -0.1, -5);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#DD0261").s().p("AAKgsIArA7IhpAeg");
        this.shape_4.setTransform(201.7001, 86.3628, 1.8958, 1.8958);

        this.instance_9 = new lib.welle_02();
        this.instance_9.setTransform(240.8, 127.15, 0.5759, 0.7999, -36.968, 0, 0, -0.7, -6.8);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#34A58C").s().p("AAYhpIBlCNIj5BHg");
        this.shape_5.setTransform(237.2265, 131.2348, 0.7999, 0.7999);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_5 }, { t: this.instance_9 }, { t: this.shape_4 }, { t: this.instance_8 }, { t: this.instance_7 }, { t: this.instance_6 }, { t: this.shape_3 }, { t: this.instance_5 }, { t: this.shape_2 }, { t: this.instance_4 }, { t: this.shape_1 }, { t: this.instance_3 }, { t: this.shape }, { t: this.instance_2 }, { t: this.instance_1 }, { t: this.instance }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.sonnenWindAni_01, new cjs.Rectangle(16, -1.6, 338.5, 280.5), null);


    (lib.solar_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.instance = new lib.pfeilani();
        this.instance.setTransform(213.2, 7.6, 0.4055, 0.6399, 121.5603, 0, 0, 222.7, 8.2);

        this.instance_1 = new lib.Path_7();
        this.instance_1.setTransform(208.45, 12.75, 1.2133, 1.2133, -19.999, 0, 0, 10, 8.2);

        this.instance_2 = new lib.welle_01();
        this.instance_2.setTransform(190.45, 1.45, 0.4502, 0.6399, -55.4039, 0, 0, 0, -5);

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#DD0261").s().p("AAKgsIArA7IhpAeg");
        this.shape.setTransform(187.353, 4.9104, 1.5166, 1.5166, -19.9989);

        this.instance_3 = new lib.welle_02();
        this.instance_3.setTransform(228, 24.75, 0.4607, 0.6399, -56.9701, 0, 0, -0.7, -6.6);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#34A58C").s().p("AAYhpIBlCNIj5BHg");
        this.shape_1.setTransform(226.5077, 28.8472, 0.6399, 0.6399, -19.9984);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AhkgbIACgBIDHA4IgCABg");
        this.shape_2.setTransform(183.7197, 58.8306, 2.3698, 2.3698);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AhkgbIADAAIDGA2IgCABg");
        this.shape_3.setTransform(190.7699, 55.5721, 2.3698, 2.3698);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AhkgZIADgCIDGA2IgDABg");
        this.shape_4.setTransform(197.7016, 52.3136, 2.3698, 2.3698);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AhjgZIACgBIDFA0IgCABg");
        this.shape_5.setTransform(204.3963, 49.1736, 2.3698, 2.3698);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AhKAjICShFIADABIiSBFg");
        this.shape_6.setTransform(207.0031, 58.0604, 2.3698, 2.3698);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AhKAiICShEIADABIiSBEg");
        this.shape_7.setTransform(197.4646, 55.4536, 2.3698, 2.3698);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AhLAhICThDIADACIiSBCg");
        this.shape_8.setTransform(188.1631, 52.8468, 2.3698, 2.3698);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AhLAhICThCIAEABIiTBCg");
        this.shape_9.setTransform(179.0393, 50.2993, 2.3698, 2.3698);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#020203").s().p("AgUAuIhmgdQgSgFgCgJQgDgHAPgGIBLghQANgGAWgBQAVgBASAFIBkAaQATAFAEAIQAFAHgNAHIhKAkQgOAHgYABIgHAAQgTAAgQgFg");
        this.shape_10.setTransform(193.6479, 54.3246, 2.3698, 2.3698);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#FFFFFF").s().p("AhagYIADgBICyAyIgCABg");
        this.shape_11.setTransform(139.6412, 46.2706, 2.3698, 2.3698);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#FFFFFF").s().p("AhagXIACgCICzAxIgCABg");
        this.shape_12.setTransform(146.8099, 43.2491, 2.3698, 2.3698);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#FFFFFF").s().p("AhagXIADgBICyAwIgDABg");
        this.shape_13.setTransform(153.8008, 40.4053, 2.3698, 2.3698);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#FFFFFF").s().p("AhagWIADgBICyAuIgDABg");
        this.shape_14.setTransform(160.614, 37.5023, 2.3698, 2.3698);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#FFFFFF").s().p("AhLAfICUg/IADABIiUA/g");
        this.shape_15.setTransform(161.7989, 45.5004, 2.3698, 2.3698);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#FFFFFF").s().p("AhMAfICVg+IADABIiUA+g");
        this.shape_16.setTransform(153.2084, 43.1898, 2.3698, 2.3698);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#FFFFFF").s().p("AhLAeICVg8IADABIiWA8g");
        this.shape_17.setTransform(144.7955, 40.82, 2.3698, 2.3698);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#FFFFFF").s().p("AhMAeICWg8IADABIiVA8g");
        this.shape_18.setTransform(136.5604, 38.5687, 2.3698, 2.3698);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#020203").s().p("AgaAqIhcgbQgQgEgBgIQgBgGAOgGIBMgeQAOgFAVgBQATgBARAEIBaAYQASAFACAHQADAGgNAGIhLAhQgPAGgWABIgEAAQgUAAgPgEg");
        this.shape_19.setTransform(149.8201, 42.1222, 2.3698, 2.3698);

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#FFFFFF").s().p("AhSgWIADgBICiAuIgDABg");
        this.shape_20.setTransform(99.9468, 34.8955, 2.3698, 2.3698);

        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#FFFFFF").s().p("AhRgWIACAAICiAsIgDABg");
        this.shape_21.setTransform(107.1155, 32.2294, 2.3698, 2.3698);

        this.shape_22 = new cjs.Shape();
        this.shape_22.graphics.f("#FFFFFF").s().p("AhSgVIADgBICiAsIgDABg");
        this.shape_22.setTransform(114.1657, 29.5634, 2.3698, 2.3698);

        this.shape_23 = new cjs.Shape();
        this.shape_23.graphics.f("#FFFFFF").s().p("AhSgUIADgBIChAqIgDABg");
        this.shape_23.setTransform(121.0974, 26.9566, 2.3698, 2.3698);

        this.shape_24 = new cjs.Shape();
        this.shape_24.graphics.f("#FFFFFF").s().p("AhMAcICWg4IADAAIiWA5g");
        this.shape_24.setTransform(120.9789, 34.2438, 2.3698, 2.3698);

        this.shape_25 = new cjs.Shape();
        this.shape_25.graphics.f("#FFFFFF").s().p("AhMAcICWg4IADABIiWA4g");
        this.shape_25.setTransform(113.2178, 32.111, 2.3698, 2.3698);

        this.shape_26 = new cjs.Shape();
        this.shape_26.graphics.f("#FFFFFF").s().p("AhMAcICWg3IADAAIiWA3g");
        this.shape_26.setTransform(105.6344, 29.9781, 2.3698, 2.3698);

        this.shape_27 = new cjs.Shape();
        this.shape_27.graphics.f("#FFFFFF").s().p("AhMAbICWg2IADABIiWA2g");
        this.shape_27.setTransform(98.1695, 27.9045, 2.3698, 2.3698);

        this.shape_28 = new cjs.Shape();
        this.shape_28.graphics.f("#020203").s().p("AgfAmIhTgYQgOgEAAgHQAAgFAOgGIBMgbQAOgFAUgBQATgBAPAEIBSAWQAPAEACAHQABAFgNAGIhMAeQgPAFgVABIgGAAQgSAAgMgEg");
        this.shape_28.setTransform(110.214, 31.1591, 2.3698, 2.3698);

        this.shape_29 = new cjs.Shape();
        this.shape_29.graphics.f("#FFFFFF").s().p("AhKgTIACgBICTAoIgDABg");
        this.shape_29.setTransform(63.9257, 24.646, 2.3698, 2.3698);

        this.shape_30 = new cjs.Shape();
        this.shape_30.graphics.f("#FFFFFF").s().p("AhKgTIACgBICTAoIgDABg");
        this.shape_30.setTransform(71.1536, 22.1577, 2.3698, 2.3698);

        this.shape_31 = new cjs.Shape();
        this.shape_31.graphics.f("#FFFFFF").s().p("AhKgTIADgBICSAoIgCABg");
        this.shape_31.setTransform(78.1446, 19.7287, 2.3698, 2.3698);

        this.shape_32 = new cjs.Shape();
        this.shape_32.graphics.f("#FFFFFF").s().p("AhKgSIACgBICTAmIgDABg");
        this.shape_32.setTransform(85.1355, 17.3589, 2.3698, 2.3698);

        this.shape_33 = new cjs.Shape();
        this.shape_33.graphics.f("#FFFFFF").s().p("AhMAaICWg0IADABIiWA0g");
        this.shape_33.setTransform(84.0098, 23.9943, 2.3698, 2.3698);

        this.shape_34 = new cjs.Shape();
        this.shape_34.graphics.f("#FFFFFF").s().p("AhMAaICWg0IADABIiWA0g");
        this.shape_34.setTransform(76.9596, 22.0985, 2.3698, 2.3698);

        this.shape_35 = new cjs.Shape();
        this.shape_35.graphics.f("#FFFFFF").s().p("AhMAaICWg0IADABIiWA0g");
        this.shape_35.setTransform(70.0279, 20.1434, 2.3698, 2.3698);

        this.shape_36 = new cjs.Shape();
        this.shape_36.graphics.f("#FFFFFF").s().p("AhMAZICWgyIADABIiWAyg");
        this.shape_36.setTransform(63.2147, 18.2476, 2.3698, 2.3698);

        this.shape_37 = new cjs.Shape();
        this.shape_37.graphics.f("#020203").s().p("AgjAjIhLgWQgNgDABgHQABgFAOgFIBNgZQANgFASAAQATgBAOADIBLAUQAOAEAAAGQABAFgOAFIhMAcQgPAFgTABIgFAAQgRAAgNgEg");
        this.shape_37.setTransform(74.2283, 21.1901, 2.3698, 2.3698);

        this.shape_38 = new cjs.Shape();
        this.shape_38.graphics.f("#FFFFFF").s().p("AhEgSIADgBICGAmIgDABg");
        this.shape_38.setTransform(31.1038, 15.3445, 2.3698, 2.3698);

        this.shape_39 = new cjs.Shape();
        this.shape_39.graphics.f("#FFFFFF").s().p("AhEgRIADgBICGAlIgDAAg");
        this.shape_39.setTransform(38.2725, 12.9747, 2.3698, 2.3698);

        this.shape_40 = new cjs.Shape();
        this.shape_40.graphics.f("#FFFFFF").s().p("AhEgRIADgBICGAkIgDABg");
        this.shape_40.setTransform(45.3819, 10.7826, 2.3698, 2.3698);

        this.shape_41 = new cjs.Shape();
        this.shape_41.graphics.f("#FFFFFF").s().p("AhEgQIADgCICGAjIgCACg");
        this.shape_41.setTransform(52.2544, 8.5313, 2.3698, 2.3698);

        this.shape_42 = new cjs.Shape();
        this.shape_42.graphics.f("#FFFFFF").s().p("AhMAXICWguIACAAIiVAvg");
        this.shape_42.setTransform(37.5615, 11.1974, 2.3698, 2.3698);

        this.shape_43 = new cjs.Shape();
        this.shape_43.graphics.f("#020203").s().p("AgmAgIhEgUQgMgDABgGQACgFAPgEIBMgYQANgEASAAQASgBAMADIBEASQANAEAAAFQAAAFgOAEIhMAaQgPAEgSABIgFAAQgRAAgLgDg");
        this.shape_43.setTransform(41.452, 12.1264, 2.3698, 2.3698);

        this.shape_44 = new cjs.Shape();
        this.shape_44.graphics.f("#FFFFFF").s().p("AoDhhIC0g3INTDbIjBBWg");
        this.shape_44.setTransform(122.1638, 36.2581, 2.3698, 2.3698);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_44 }, { t: this.shape_43 }, { t: this.shape_42 }, { t: this.shape_41 }, { t: this.shape_40 }, { t: this.shape_39 }, { t: this.shape_38 }, { t: this.shape_37 }, { t: this.shape_36 }, { t: this.shape_35 }, { t: this.shape_34 }, { t: this.shape_33 }, { t: this.shape_32 }, { t: this.shape_31 }, { t: this.shape_30 }, { t: this.shape_29 }, { t: this.shape_28 }, { t: this.shape_27 }, { t: this.shape_26 }, { t: this.shape_25 }, { t: this.shape_24 }, { t: this.shape_23 }, { t: this.shape_22 }, { t: this.shape_21 }, { t: this.shape_20 }, { t: this.shape_19 }, { t: this.shape_18 }, { t: this.shape_17 }, { t: this.shape_16 }, { t: this.shape_15 }, { t: this.shape_14 }, { t: this.shape_13 }, { t: this.shape_12 }, { t: this.shape_11 }, { t: this.shape_10 }, { t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.instance_3 }, { t: this.shape }, { t: this.instance_2 }, { t: this.instance_1 }, { t: this.instance }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.solar_mc, new cjs.Rectangle(0, -91, 292.4, 163.5), null);


    (lib.solar_ani_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        this.isSingleFrame = false;
        // timeline functions:
        this.frame_0 = function () {
            if (this.isLoaded) {
                return
            }
            if (this.isSingleFrame) {
                return;
            }
            if (this.totalFrames == 1) {
                this.isSingleFrame = true;
            }
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

        // Ebene_1
        this.instance = new lib.solar_mc();
        this.instance.setTransform(122.2, 36.2, 1, 1, 0, 0, 0, 122.2, 36.2);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.solar_ani_mc, new cjs.Rectangle(0, -91, 292.4, 163.5), null);


    (lib.sub_ani_01_mc = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_0 = function () {
            // createjs.Tween.removeTweens(this.parent.timeline)
            // console.log('#####ERNERGIE 0', this.parent.timeline.paused = true)
            if (this.isLoaded) {
                return
            }
            console.log('#####ERNERGIE')
            var firstTouch = true;
            var container = this;
            var batterieMc = this.batterie_mc;
            var solarMc = this.solar_mc;
            this.goBack = function () {
                firstTouch = true;
                batterieMc.gotoAndStop(0);
                solarMc.gotoAndStop(1);
                container.gotoAndPlay(1);
            }
            this.wait = function () {
                container.play();
            }
            this.repeat_btn.addEventListener("mousedown", ClickHandler_repeat.bind(this));

            function ClickHandler_repeat() {
                this.goBack();
            }

            this.solar_mc.visible = false
            this.batterie_mc.visible = false
            this.repeat_btn.visible = false

        }
        this.frame_81 = function () {
            console.log('#####ERNERGIE 81')
            this.stop();
            if (this.parent.visible && this.parent.parent.status.currentPage && this.parent.parent.status.currentPage.animation) {
                setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.animation.texte.gewinnung_fensterscheibe_txt, 'flash energiegewin frame_81')
            }
            setTimeout(this.wait, this.parent.time2wait);
        }
        this.frame_163 = function () {
            console.log('#####ERNERGIE 163')
            this.stop();
            if (this.parent.visible && this.parent.parent.status.currentPage && this.parent.parent.status.currentPage.animation) {
                setHeadlineContent(this.parent.parent, this.parent.parent.status.currentPage.animation.texte.gewinnung_glasscheibe_txt, 'flash energiegewin frame_163')
            }
            setTimeout(this.wait, this.parent.time2wait);
            this.solar_mc.gotoAndStop(0);
        }
        this.frame_221 = function () {
            console.log('#####ERNERGIE 221')
            this.stop();
            setTimeout(this.wait, this.parent.time2wait);
            this.batterie_mc.gotoAndStop(1);
        }
        this.frame_278 = function () {
            console.log('#####ERNERGIE 278')
            this.stop();
            this.batterie_mc.gotoAndStop(0);
            if (this.parent.visible && this.parent.alpha > 0) {
                console.log('energiegewinnung.js', this.parent.visible, this, this.parent)
                animateInduktion(this.parent.parent, 'in');
            }
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(81).call(this.frame_81).wait(82).call(this.frame_163).wait(58).call(this.frame_221).wait(57).call(this.frame_278).wait(19));

        // sonnenWind
        this.scheiben_ani_mc = new lib.sonnenWindAni_01();
        this.scheiben_ani_mc.name = "scheiben_ani_mc";
        this.scheiben_ani_mc.setTransform(181.35, 157.85, 1, 1, 0, 0, 0, 191.3, 132.3);
        this.scheiben_ani_mc.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.scheiben_ani_mc).wait(83).to({ alpha: 1 }, 72).wait(142));

        // batterie
        this.battAni_mc = new lib.batterie_ani_mc();
        this.battAni_mc.name = "battAni_mc";
        this.battAni_mc.setTransform(130.55, 408.95, 1, 1, 0, 0, 0, 115.4, 80);
        this.battAni_mc.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.battAni_mc).wait(166).to({ alpha: 1 }, 46).wait(15).to({ alpha: 0 }, 45).wait(25));

        // fenster
        this.instance = new lib.window_mc();
        this.instance.setTransform(162.45, 200.4, 1, 1, 0, 0, 0, 140.3, 166.3);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(297));

        // fensterbank
        this.solarAni_mc = new lib.solar_ani_mc();
        this.solarAni_mc.name = "solarAni_mc";
        this.solarAni_mc.setTransform(192.7, 295.7, 1, 1, 0, 0, 0, 122.2, 36.2);

        this.timeline.addTween(cjs.Tween.get(this.solarAni_mc).wait(83).to({ alpha: 0 }, 72).wait(142));

        // sonne
        this.instance_1 = new lib.sonne_mc();
        this.instance_1.setTransform(361, 15.25, 0.64, 0.64, 0, 0, 0, 64.6, 64.6);

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(297));

        // flash0_ai
        this.repeat_btn = new lib.repeat_btn_mc();
        this.repeat_btn.name = "repeat_btn";
        this.repeat_btn.setTransform(379.5, 413.95, 1, 1, 0, 0, 0, 26, 26);

        this.batterie_mc = new lib.batterieButton_mc();
        this.batterie_mc.name = "batterie_mc";
        this.batterie_mc.setTransform(313, 413.95, 1, 1, 0, 0, 0, 26, 26);

        this.solar_mc = new lib.solarButton_mc();
        this.solar_mc.name = "solar_mc";
        this.solar_mc.setTransform(244.95, 413.75, 1, 1, 0, 0, 0, 25.8, 25.8);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.solar_mc }, { t: this.batterie_mc }, { t: this.repeat_btn }] }).wait(297));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(6, -27.4, 399.5, 517.5);


    (lib.main_animation = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        this.isSingleFrame = false;
        // timeline functions:
        this.frame_0 = function () {
            if (this.isLoaded) {
                return
            }
            if (this.isSingleFrame) {
                return;
            }
            if (this.totalFrames == 1) {
                this.isSingleFrame = true;
            }
            this.time2wait = 5000;
            this.goHome = function () {
                this.sub_ani_01_mc.goBack();
            }
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

        // subAnimation_mc
        this.sub_ani_01_mc = new lib.sub_ani_01_mc();
        this.sub_ani_01_mc.name = "sub_ani_01_mc";
        this.sub_ani_01_mc.setTransform(177.65, 214.8, 1, 1, 0, 0, 0, 114.4, 160.6);

        this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(69.3, 26.8, 399.5, 517.5), null);




    /* IMPORT END 
    // stage content: ...
    */

    return lib
}