import * as cjs from 'createjs-module'
import { getContent } from '../../config/getContent'
import { getCustomerData } from '../qrcode/getCustomerData'
import { saveMcPos } from './../animateHelper'

export const initOutside = (root) => {

    saveMcPos(root, 'outside')

    // headline
    root.outside.headline.l1.txt.text = ''
    root.outside.headline.l2.txt.text = ''
    root.outside.headline.l3.txt.text = ''
    root.outside.headline.l4.txt.text = ''
    root.outside.headline.l5.txt.text = ''
    root.outside.headline.linie.alpha = 0
    root.outside.logo_small.alpha = 0

    root.outside.logo.x = 1410
    root.outside.logo.a.y = 385

    root.outside.frame.visible = false

    root.safearea.visible = false

    // console.log(root)
}

export const hideOutside = (root) => {

    // headline
    root.outside.headline.l1.txt.text = ''
    root.outside.headline.l2.txt.text = ''
    root.outside.headline.l3.txt.text = ''
    root.outside.headline.l4.txt.text = ''
    root.outside.headline.l5.txt.text = ''
    root.outside.headline.linie.alpha = 0
    root.outside.logo_small.alpha = 0
}

export const startOutside = (root, qrcode) => {

    if (root.type !== 'outside') {
        return
    }

    hideOutside(root)
    const content = getContent('', true)

    const setCustomer = (data) => {
        console.log('setCustomer', data)
        let lang = data.Language

        root.outside.headline.alpha = 0

        root.outside.headline.l1.txt.text = content.outsideView.title[lang].l1
        root.outside.headline.l2.txt.text = data.Name
        root.outside.headline.l3.txt.text = data.Company
        if (data.categories !== '') {
            root.outside.headline.l4.txt.text = (lang === 'de') ? 'Ihre Tour für heute' : 'Your tour today'
            root.outside.headline.l5.txt.text = data.categories
            root.outside.headline.linie.alpha = 1
        }

        cjs.Tween.get(root.outside.headline)
            .to({
                alpha: 1
            }, 500, cjs.Ease.quadOut);

        root.outside.logo_small.alpha = 0
        cjs.Tween.get(root.outside.logo_small)
            .to({
                alpha: 1
            }, 1000, cjs.Ease.quadOut);

        // root.outside.logo_small.alpha = 1
        // categeories kleiner
    }

    // Get QR->Name
    getCustomerData({ qrcode, setCustomer })
}

