import { resetScreensaver } from '../screensaver';
import { audioLib } from './../../assets/sound/audioLib'
import { audioUILib } from './../../assets/sound/audioLib'

export const audioPlayer = (root, audio) => {
    root.status.audioPlayer = new Audio(audioLib[audio])
    root.status.audioPlayer.volume = 0.1;
    console.log('audioPlayer', root.status.audioPlayer)
    audioPlayerControl(root, 'play')
}

export const audioPlayerControl = (root, todo) => {
    if (root.status.audioPlayer) {
        if (todo === 'play') {
            root.status.audioPlayer.play()
        }
        if (todo === 'stop') {
            root.status.audioPlayer.pause()
            root.status.audioPlayer.currentTime = 0;
        }
    }
}

export const uiSound = (root = null, audio) => {
    let a = new Audio(audioUILib[audio])
    if (a && audio !== 'intro') {
        a.volume = 0.05;
    }
    a.play()
    if (root) {
        console.log('uiSound', root)
        // Screensaver
        resetScreensaver(root, root.type)
    }
} 