import { hideLevel, showLevel, animateBtnHome, buttonAnimation, animateBtnBack, showHideNavi } from './navigationAnimation'
import { animateInduktion, setHeadlineContent, setHeadlineMain } from '../uiHelpers';
import { hideAnimation, showAnimation } from './../animation/animationHelper'
import { playLedAnimation } from './../led/ledHelper'
import { sendWS } from './../websocket'
import { audioPlayer, audioPlayerControl, uiSound } from './../audio/audioPlayer'
import { getContent } from './../../config/getContent'
import { fanApi } from '../fanApi';
import { setLamellenState, setWindowState } from '../window/windowHelper';
import { animateScreenLogo } from '../logoHelper';
import { resetScreensaver } from '../screensaver';

export const openPage = (root, menuId) => {

    root.clickTimeout = setTimeout(function () {
        root.lockHand = false
    }, 1500);
    if (root.lockHand) {
        return;
    }

    if (menuId === 'home') {
        audioPlayerControl(root, 'stop')
        goHome(root)
        return
    }

    const menu = getContent(menuId, false, true)

    if (root.status.currentPage && root.status.currentPage.id === menu.id) {
        return
    }

    if (root.status.level && root.status.level.id === menu.id && !root.status.level.animation) {
        return
    }

    // Screensaver
    resetScreensaver(root, root.type)

    // Sound
    uiSound(root, 'clack')

    // Submenu
    if (menu.content && menu.content.length > 0) {
        console.log('submenu')
        // buttonAnimation(root, menu, 0, 'hide')
        hideLevel(root, null, menu)
        showLevel(root, menu)
        animateBtnHome(root, 'in')

        // Lamellen mittig
        setWindowState(root, 'center')

        // root.animation.sub_ani_01_mc.play()
        root.status.level = menu
    } else {
        buttonAnimation(root, menu, 0, 'active')
        root.status.prevPage.push(root.status.currentPage)
        if (root.status.prevPage.length <= 1) {
            // back Button
            animateBtnBack(root, 'in')
        }
    }

    // right Headline
    if (menu.headline) {
        // Headline rechte Content Seite
        if (menu.content && menu.content.length > 0) {
            setHeadlineMain(root, menu.headline, true)
        } else {
            // Bei center Ani keinen rechten Titel, erst später bei TWIN
            if (!menu.animation || !menu.animation.center) {
                setHeadlineContent(root, menu.headline, 'navi openPage')
            }

        }
    }

    // alte Animation ausblenden
    if (root.status.currentAnimationRight !== '') {
        hideAnimation(root, root.status.currentAnimationRight)
    }
    if (root.status.currentAnimationCenter !== '') {
        hideAnimation(root, root.status.currentAnimationCenter)
    }

    // Animation
    if (menu.animation && menu.animation.right) {
        // loadAnimation(root, menu.animation)
        console.log('Animation', menu.animation)
        showAnimation(root, menu.animation.right, 'right', (menu.animation.center) ? menu.animation.center : '', menu.animation, menu)

        // Lamellen, mitte oder mitte rechts, wenn einzel Ani
        // if (!menu.logo) {
            setWindowState(root, menu.animation.center ? 'center' : 'center-right')
        // }

    }
    if (menu.animation && menu.animation.center) {
        showAnimation(root, menu.animation.center, 'center', (menu.animation.right) ? menu.animation.right : '', menu.animation, menu)
        showHideNavi(root, 'out')
        setHeadlineMain(root, menu.headline, true)
        setHeadlineContent(root, null, 'navi openPage center')

    } else {
        // showHideNavi(root, 'in')
    }

    // LED Playlist
    if (menu.led) {
        playLedAnimation(menu.led)
    }

    if (menu.audio) {
        audioPlayerControl(root, 'stop')
        audioPlayer(root, menu.audio)
    } else {
        audioPlayerControl(root, 'stop')
    }

    // if (menu.color) {
    //     fanApi(menu.color)
    // } else {
    fanApi('off')
    // }

    if (menu.window) {
        if (!menu.animation.center || !menu.animation) {
            menu.window.forEach((w) => {
                setLamellenState(root, w.state, w.window)
            });
        }

    }

    // if (menu.induktion) {
    //     animateInduktion(root, 'in')
    // } else {
    animateInduktion(root, 'out')
    // }

    // Websocket
    if (root.webSocket) {
        sendWS(root, menu.id);
    }

    if (root.status.currentPage) {
        // console.log(root.status.currentPage)
        buttonAnimation(root, root.status.currentPage, 0, 'inactive')
    }

    root.status.currentPage = menu
    root.status.speech = ''
    root.lockHand = true
}


// Home
export const goHome = (root) => {

    // console.log(root.status.level)
    const content = getContent('', true)

    // showHideNavi(root, 'in')

    audioPlayerControl(root, 'stop')

    // Screensaver
    resetScreensaver(root, root.type)

    if ((root.status.currentPage && root.status.currentPage.id) || root.status.level) {
        showLevel(root)
        hideLevel(root, root.status.level)
        animateBtnHome(root, 'out')
        animateBtnBack(root, 'out')
        setHeadlineMain(root, content.headline)
        setHeadlineContent(root, null, 'navi goHome')
        // setWindowState(root, 'center')
        fanApi('off')
        animateInduktion(root, 'out')
        animateScreenLogo(root, 'in')

        root.status.currentPage = null
        root.status.prevPage = []
        root.status.level = null
        root.status.speech = ''

        if (root.status.currentAnimationRight !== '') {
            hideAnimation(root, root.status.currentAnimationRight)
        }
        if (root.status.currentAnimationCenter !== '') {
            hideAnimation(root, root.status.currentAnimationCenter)
        }

        // Sound
        uiSound(root, 'clack')

        if (root.webSocket) {
            sendWS(root, 'home');
        }
    }

}

// BACK
export const goBack = (root) => {

    // console.log(root.status)

    if (root.status.currentAnimationRight !== '' && root.status.currentAnimationCenter !== '') {
        hideAnimation(root, root.status.currentAnimationRight)
        hideAnimation(root, root.status.currentAnimationCenter)
        showHideNavi(root, 'in')
        buttonAnimation(root, root.status.currentPage, 0, 'inactive')
        
        // headline Mitte
        setHeadlineMain(root, root.status.level.headline, true)

        // audio
        audioPlayerControl(root, 'stop')

        setWindowState(root, 'center')

        // Sound
        uiSound(root, 'clack')

        root.status.speech = ''

        // Screensaver
        resetScreensaver(root, root.type)

        // reset
        root.status.currentPage = null

        return
    }

    openPage(root, 'home')
    // goHome(root)

    // Sound
    // uiSound('clack')

    return;

    if (root.status.prevPage.length > 0) {
        var prePage = root.status.prevPage.pop();

        const content = getContent(prePage.id, false)

        if (prePage && prePage.id) {
            showLevel(root, prePage)
            hideLevel(root, root.status.level)

            setHeadlineMain(root, content.headline)


            if (root.status.currentAnimation !== '') {
                hideAnimation(root, root.status.currentAnimation)
            }
        }

    }
}