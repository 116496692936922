/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../actions/animateHelper'

export const headline_content = () => {

    var lib = {}
    var p

    (lib.headline_l2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.txt = new cjs.Text("Headline", "14px 'Brix Sans Regular'", "#FFFFFF");
        this.txt.name = "txt";
        this.txt.lineHeight = 18;
        this.txt.lineWidth = 400;
        this.txt.parent = this;
        this.txt.setTransform(3, 3);

        this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.headline_l2, new cjs.Rectangle(1, 1, 451, 63.099999999999994), null);


    (lib.headline_l1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.txt = new cjs.Text("STABILITÄT", "bold 39px 'Brix Sans Black'", "#FFFFFF");
        this.txt.name = "txt";
        this.txt.lineHeight = 51;
        this.txt.lineWidth = 494;
        this.txt.parent = this;
        this.txt.setTransform(3, 2.95);

        this.timeline.addTween(cjs.Tween.get(this.txt).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.headline_l1, new cjs.Rectangle(1, 1, 498, 53.1), null);


    (lib.headline = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.l2 = new lib.headline_l2();
        this.l2.name = "l2";
        this.l2.setTransform(139.95, 68.65, 1, 1, 0, 0, 0, 139.5, 19.8);

        this.l1 = new lib.headline_l1();
        this.l1.name = "l1";
        this.l1.setTransform(101.2, 26.5, 1, 1, 0, 0, 0, 101.2, 26.5);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.l1 }, { t: this.l2 }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.headline, new cjs.Rectangle(1, 1, 498, 111.9), null);


    return lib
}
