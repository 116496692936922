/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */


    (lib.einbrecher = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAAAeIAAg7");
            this.shape.setTransform(108.8726,74.4956,2.3699,2.3699);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f().s("#FFFFFF").ss(2,1,1).p("AATAAQAAAIgGAFQgFAGgIAAQgHAAgFgGQgFgFAAgIQAAgHAFgFQAFgFAHAAQAIAAAFAFQAGAFAAAHg");
            this.shape_1.setTransform(98.8003,68.0375,2.3699,2.3699);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f().s("#FFFFFF").ss(2,1,1).p("AASAAQAAAIgFAFQgFAGgIAAQgHAAgFgGQgFgFAAgIQAAgHAFgFQAFgFAHAAQAIAAAFAFQAFAFAAAHg");
            this.shape_2.setTransform(131.7426,68.0375,2.3699,2.3699);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f().s("#FFFFFF").ss(2,1,1).p("AhcAQQAAgNAbgJQAbgJAmAAQAmAAAcAJQAbAJAAAN");
            this.shape_3.setTransform(115.3307,98.0766,2.3699,2.3699);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAZgMQAAALgHAGQgIAIgKAAQgKAAgHgIQgIgGAAgL");
            this.shape_4.setTransform(114.916,84.9234,2.3699,2.3699);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAugWIhbAt");
            this.shape_5.setTransform(132.4536,61.5201,2.3699,2.3699);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAuAXIhbgt");
            this.shape_6.setTransform(97.6153,61.5201,2.3699,2.3699);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f().s("#FFFFFF").ss(2,1,1).p("AhqCFIDDi8IgOgSQgHgKABgLQACgMAKgHIAagT");
            this.shape_7.setTransform(61.4092,151.988,2.3699,2.3699);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f().s("#FFFFFF").ss(2,1,1).p("ABXjSIAbgUQAGgEAHABQAHABAGAEQAIAGAAARIAJgCQAJgBAFAGQAJAKgFAPIAKgDQALgCAGAGQAKAIgGAWIAIgCQAIgCAFAFQAJAIgHAMIkHE8QgpAxg/gHQhAgGgdg5");
            this.shape_8.setTransform(71.4411,166.7783,2.3699,2.3699);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f().s("#FFFFFF").ss(1,1).p("ABSBBIiSiSIgSAVICPCPg");
            this.shape_9.setTransform(135.0088,163.7612,2.3699,2.3699);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f("#FFFFFF").s().p("AhSg8IASgWICTCTIgWASg");
            this.shape_10.setTransform(134.9345,163.8373,2.3699,2.3699);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f().s("#FFFFFF").ss(1,1).p("ADLDGIjKjJQgLgMgRAAIhmAAQgUAAgOgOQgOgOAAgUQAAgUAOgOIBkhgIgWAAIAAgSIhhBeQgWAXAAAfQAAAfAWAXQAWAVAfAAIBsAAIDLDNg");
            this.shape_11.setTransform(48.5879,74.386,2.3699,2.3699);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f("#FFFFFF").s().p("AgUAJIhsAAQgfAAgWgVQgWgWAAgfQAAgfAWgXIBhheIAAARIAWAAIhkBgQgOAOABAVQgBAUAOAOQAPAOATAAIBmAAQARAAAMAMIDJDJIgVASg");
            this.shape_12.setTransform(48.3157,74.9671,2.3699,2.3699);
        
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f().s("#FFFFFF").ss(2,1,1).p("APdV1IAA5KQAAiBhdhdQhbhciCAAIkFAAIAAhrQAiAAAbgZQAYgYAAgkQAAgkgYgYQgbgZgiAAIAAixQAAish6h5Qh5h6irAAQirAAh5B6Qh5B5AACsIAACxQgkAAgZAZQgYAYAAAkQAAAkAYAYQAZAZAkAAIAABrIkFAAQiCAAhdBcQhbBcAACCIAAZK");
            this.shape_13.setTransform(115.325,139.65);
        
            this.shape_14 = new cjs.Shape();
            this.shape_14.graphics.f("#FFFFFF").s().p("AvWV4IgGgHIAA5JQAAiCBbhcQBdhcCCAAIEFAAIAAhrQgkAAgZgZQgYgYAAgkQAAgkAYgZQAZgZAkAAIAAiwQAAisB5h5QB5h6CrAAQCrAAB5B6QB6B5AACsIAACwQAiAAAbAZQAYAZAAAkIgYA8QgbAZgiAAIAABrIEFAAQCCAABbBcQBdBdAACBIAAZJIAAAHg");
            this.shape_14.setTransform(115.325,140);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.einbrecher, new cjs.Rectangle(-1,-1,216.2,281.3), null);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_0 = function() {
                this.stop();
            }
            this.frame_49 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(49).call(this.frame_49).wait(1));
        
            // Ebene_4
            this.instance = new lib.einbrecher();
            this.instance.setTransform(-116.75,248,1,1,0,0,0,107.7,140);
            this.instance._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(2).to({_off:false},0).to({x:240.25},47).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-225,0,572.8,388.3);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.goHome=function(){
                    this.sub_ani_01_mc.gotoAndPlay(0);
                }
                // this.goHome();
                
                this.startTwin = function(){
                
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(114.4,160.6,1,1,0,0,0,114.4,160.6);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(0,0,0,0), null);
        

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}