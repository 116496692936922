// local or online debugging
const isLocal = (process.env.REACT_APP_IS_LOCAL === 'true') ? true : false

// console.log(process.env)

const webserver = {
    url: (process.env.NODE_ENV === 'development')
        ? ((isLocal ? 'http://localhost:3000' : 'http://rh-rpi4-server.local'))
        : '',
    path: '',
    ip: (process.env.REACT_APP_WEBSERVER_IP)
        ? process.env.REACT_APP_WEBSERVER_IP
        : '192.168.22.10'
}

export const config = {
    appName: 'REHAU Future Window Vision',
    version: '20220420a',
    screensaver: 600, //300 Sekunden
    ip: webserver.ip,
    isLocal: isLocal,
    path: `${webserver.path}`,
    url: `${webserver.url}`,
    apiUrl: webserver.url + webserver.path + ((isLocal) ? '' : '/api'),
    webSocket: {
        url: (isLocal ? 'ws://' : 'wss://')
            + ((process.env.REACT_APP_WEBSOCKET_IP)
                ? process.env.REACT_APP_WEBSOCKET_IP
                : '192.168.22.10'),
        port: 5001
    },
    windowApi: {
        url: 'http://' + ((process.env.REACT_APP_WINDOWAPI_IP)
            ? process.env.REACT_APP_WINDOWAPI_IP
            : webserver.ip),
        port: 5000
    },
    ledApi: {
        url: 'http://' + ((process.env.REACT_APP_XLIGHTS_IP)
            ? process.env.REACT_APP_XLIGHTS_IP
            : webserver.ip),
    },
    radarTouch: {
        url: 'http://' + ((process.env.REACT_APP_RADARTOUCH_IP)
            ? process.env.REACT_APP_RADARTOUCH_IP
            : '192.168.22.2'),
        port: ((process.env.REACT_APP_RADARTOUCH_PORT)
            ? process.env.REACT_APP_RADARTOUCH_PORT
            : 5005)
    },
    fanApi: {
        url: 'http://' + ((process.env.REACT_APP_FANAPI_IP)
            ? process.env.REACT_APP_FANAPI_IP
            : '192.168.22.12'),
        port: ((process.env.REACT_APP_FANAPI_PORT)
            ? process.env.REACT_APP_FANAPI_PORT
            : 5000)
    },
    hotFanApi: {
        url: 'http://' + ((process.env.REACT_APP_HOTFANAPI_IP)
            ? process.env.REACT_APP_HOTFANAPI_IP
            : '192.168.22.13'),
        port: ((process.env.REACT_APP_HOTFANAPI_PORT)
            ? process.env.REACT_APP_HOTFANAPI_PORT
            : 5000)
    },
    projectors: {
        front: '192.168.22.21',
        back: '192.168.22.22',
    },
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'from-rhfw-202203'
    },
    screen: {
        width: 1920,
        height: 1200,
        fps: 60,
        mouseEvent: 'mousedown'
    },
    mobile: {
        width: 1024,
        height: 768,
        fps: 30,
        mouseEvent: 'click'
    }
};


