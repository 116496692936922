/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from './../../../actions/animateHelper'

const logoColor = "rgba(255,255,255,1)"

export const animation = () => {

    var lib = {}
    var p


    (lib.logo_u = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("Am5GfQgngFghgNQgsgUghgoQghgogMgvQgFgYgChGIAAk4QAChFAFgXQANgxAggoQAhgnAsgTQAhgOAogFQAggEAygBIPpABIAAFWIvgAAQgaAAgVARQgaAUAAAmIAAADQAAAnAaAUQAVAQAaAAIPgAAIAAFWIvpAAQgyABghgFg");
        this.shape.setTransform(155.4989, 101.4479, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_u, new cjs.Rectangle(0, 0, 311, 202.9), null);


    (lib.logo_r = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AqCGlIAAlZIIWAAIAAhVQAAgggQgOQgQgOgjgBInTAAIAAleIG6AAQBRAAA/A4QA5A0AHBBQAHhBA6g0QA9g4BRAAIDaAAQBWABA/A3QA9A2AABGIAAKVgACCg7QgQALgDAWIAABmIFHAAIABhVQAAg+hDABIjDAAIgDAAQgbAAgRALg");
        this.shape.setTransform(155.4989, 101.9163, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_r, new cjs.Rectangle(0, 0, 311, 203.9), null);


    (lib.logo_h = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AqCGqIAAleIIWAAIAAiWIoWgBIAAlfIUFABIAAFeIoUAAIAACXIIUAAIAAFeg");
        this.shape.setTransform(155.4989, 103.1604, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_h, new cjs.Rectangle(0, 0, 311, 206.3), null);


    (lib.logo_e = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AqCkmID4AAIABDvIEdAAIAAjMIDbAAIAADMIEbgBIAAjuID5AAIAAJMI0FABg");
        this.shape.setTransform(155.4989, 71.2679, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_e, new cjs.Rectangle(0, 0, 311, 142.6), null);


    (lib.logo_bg = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("rgba(255,255,255,0.8)").s().p("Egl4BclMAAAi5JMBLxAAAMAAAC5Jg");
        this.shape.setTransform(242.5, 592.5);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_bg, new cjs.Rectangle(0, 0, 485, 1185), null);


    (lib.logo_a = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f(logoColor).s().p("AGxGiIwzAAIAAldIIWAAIAAiNIoWAAIAAlZIUFAAIAAKIQgBBSg9A2Qg7AzhWAAIgDAAgABvBGIEFAAQAkAAAPgOQAOgOAAghIAAhPIlGAAg");
        this.shape.setTransform(155.5292, 101.16, 2.4183, 2.4183);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo_a, new cjs.Rectangle(0, 0, 311.1, 202.3), null);


    (lib.lamelle = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("rgba(255,255,255,0.8)").s().p("Egl4AFyIAArjMBLxAAAIAALjg");
        this.shape.setTransform(242.5, 37);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.lamelle, new cjs.Rectangle(0, 0, 485, 74), null);


    (lib.frame = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#666666").ss(0.8).p("EB4kBclIgEAAQgKAAgKAAIAAAAQgVAAgUAAMhMAAAAMAAAi5JMBLyAAAMAAAC5JEAqYhdvMBOIAAAMAAAC6UEAnEhdvIDUAAMAAAC7fIAAABEgqXhdvIDTAAMAAAC7fIjTAAMhOIAAAMAAAi7fMBOIAAAMAAAC7fEgriBclMhLyAAAMAAAi5JMBLyAAAgEArjBdxIAAgBIAAhLEArjBdwIhLAAIjUAAIAAABEAnEBdwIhLAAMhM9AAAEAl5BclIAABLIAAABEgnEhdvMBOIAAAMAAAC7fEgl4hckMBLxAAAEAl5BclMhLxAAAMAAAitlEAl5hRAMAAACtlEB4kBdwMhNBAAA");
        this.shape.setTransform(771.2, 600);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#CCCCCC").s().p("EAqWBdwIAAAAIBLAAIAAAAgEAqWBdwIjUAAIAAAAIhLAAIAAhLMAAAitlIAArkMhLxAAAIAALkMAAACtlMBLxAAAIAABLMhM8AAAMAAAi7fMAAAC7fIjUAAMAAAi7fIDUAAMBOHAAAMAAAC7fMAAAi7fIDUAAMAAAC7fIjUAAIDUAAIAAAAgEAnCBdwIhLAAIAAAAIBLAAIAAAAgEArhBdwIAAhLIAABLIhLAAMAAAi7fMBOIAAAMAAAC6UIgUAAIAAAAIgpAAIApAAIAAAAIAUAAIAEAAIAABLgEArhBclMBMAAAAIgOgBMAAAi5IMhLyAAAgEAqWBdwgEh4hBdwMAAAi7fMBOIAAAMAAAC7fgEh3WBclMBLyAAAMAAAi5JMhLyAAAgEAl3Bclg");
        this.shape_1.setTransform(771.375, 600.025);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_1 }, { t: this.shape }] }).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.frame, new cjs.Rectangle(-1, -1, 1544.8, 1202.1), null);


    (lib.logo = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Ebene_1
        this.r = new lib.logo_r();
        this.r.name = "r";
        this.r.setTransform(163.75, 1051.75, 1, 1, 0, 0, 0, 155.5, 101.9);

        this.e = new lib.logo_e();
        this.e.name = "e";
        this.e.setTransform(163.75, 841.85, 1, 1, 0, 0, 0, 155.5, 71.2);

        this.h = new lib.logo_h();
        this.h.name = "h";
        this.h.setTransform(163.75, 630.9, 1, 1, 0, 0, 0, 155.5, 103.2);

        this.a = new lib.logo_a();
        this.a.name = "a";
        this.a.setTransform(163.75, 390.1, 1, 1, 0, 0, 0, 155.5, 101.2);

        this.u = new lib.logo_u();
        this.u.name = "u";
        this.u.setTransform(163.75, 151, 1, 1, 0, 0, 0, 155.5, 101.5);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.u }, { t: this.a }, { t: this.h }, { t: this.e }, { t: this.r }] }).wait(1));

        // Ebene_2
        this.bg = new lib.logo_bg();
        this.bg.name = "bg";
        this.bg.setTransform(249.8, 600, 1, 1, 0, 0, 0, 242.5, 592.5);

        this.timeline.addTween(cjs.Tween.get(this.bg).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.logo, new cjs.Rectangle(7.3, 7.5, 485, 1185), null);


    (lib.safearea = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // lamelle
        this.lamelle = new lib.lamelle();
        this.lamelle.name = "lamelle";
        this.lamelle.setTransform(771.2, 44.5, 1, 1, 0, 0, 0, 242.5, 37);

        this.timeline.addTween(cjs.Tween.get(this.lamelle).wait(1));

        // logo
        this.logo = new lib.logo();
        this.logo.name = "logo";
        this.logo.setTransform(155.5, 552.1, 1, 1, 0, 0, 0, 155.5, 552.1);

        this.timeline.addTween(cjs.Tween.get(this.logo).wait(1));

        // frame
        this.frame = new lib.frame();
        this.frame.name = "frame";
        this.frame.setTransform(771.4, 600, 1, 1, 0, 0, 0, 771.4, 600);

        this.timeline.addTween(cjs.Tween.get(this.frame).wait(1));

        this.sprache = new cjs.Text("Sprache", "40px 'Brix Sans Regular'", "#FFFFFF");
        this.sprache.name = "sprache";
        this.sprache.lineHeight = 45;
        this.sprache.lineWidth = 600;
        this.sprache.alpha = 1;
        this.sprache.parent = this;
        this.sprache.setTransform(600,100);

        this.timeline.addTween(cjs.Tween.get(this.sprache).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.safearea, new cjs.Rectangle(-0.4, -0.4, 1543.6000000000001, 1200.9), null);

    return lib
}
