/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */



    (lib.molgenlöte = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // moon_svg
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#FFFFFF").s().p("EgPFAkuQnSjGlploQlolojGnSQjMnkAAoPQAAmtCLmWQCHmND9lQQD+lPFYjvQFhj0Gch1QAxgOAwATQAwASAbAsQAbArgEAzQgFAzgjAmQj5ETiFFZQiFFYAAFzQAAGUCcFyQCYFjETEUQEUETFjCYQFyCcGUAAQFyAAFZiFQFZiFETj5QAmgjAzgEQAzgFArAbQAsAbASAvQATAwgOAxQh1Gdj1FgQjuFZlQD9QlPD+mNCHQmWCLmtAAQoQAAnjjNgAJCBGQglgXgTgnIi1lpIlqi1QgmgUgXglQgXglAAgrQAAgsAXglQAXglAmgTIFqi1IC1lrQAnhNBiAAQBiAAAnBNIC1FrIFqC1QAnATAXAlQAXAlAAAsQAAArgXAlQgXAlgnAUIlqC1Ii1FpQgUAnglAXQglAXgrAAQgsAAglgXgAbu27QgtgtAAg/IAAiZIiZAAQhAAAgsgtQgtgtAAg/QAAhAAtgsQAsgtBAAAICZAAIAAiZQAAhAAtgsQAsgtBAAAQA/AAAtAtQAtAsAABAIAACZICZAAQA/AAAtAtQAtAsAABAQAABAgtAsQgsAthAAAIiZAAIAACZQAAA/gtAtQgtAtg/AAQhAAAgsgtg");
            this.shape.setTransform(255.5015,255.4735);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.molgenlöte, new cjs.Rectangle(0,0,511,511), null);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_444 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(444).call(this.frame_444).wait(1));
        
            // Ebene_2
            this.instance = new lib.molgenlöte();
            this.instance.setTransform(296.05,1117.65,0.2192,0.2191,0,0,0,261,135.1);
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({regX:259.8,regY:136.2,scaleX:0.366,scaleY:0.3659,x:281.8,y:596.5},444).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(186.4,546.7,187.4,653.3);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.goHome=function(){
                    this.sub_ani_01_mc.gotoAndPlay(0);
                }
                this.isAutoplay = true;
                
            }

            this.isRight=true;
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(114.4,160.6,1,1,0,0,0,114.4,160.6);
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(238.9,1088.1,111.99999999999997,111.90000000000009), null);
        
        

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}