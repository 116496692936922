import { config } from '../../config'

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,OPTIONS',
}

export const xLightApi = async (todo: string = '', playlistName: string = '') => {

    var url = `${config.ledApi.url}/api/xlights/playlist`
    var body = null

    if (todo === 'stop' || todo === 'play') {
        url = `${config.ledApi.url}/api/xlights/playlist/${todo}`
        body = {
            playlist: playlistName
        }
    }

    var options: RequestInit = {
        method: 'POST',
        headers: headers,
        mode: 'cors',
        body: (body) ? JSON.stringify(body) : ''
    }

    if (todo === 'playlist') {
        options = {
            method: 'GET',
            headers: headers,
            mode: 'cors'
        }
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return { error: true, text: `${error}` }
    })

    const data = await response;

    if (todo === 'playlist') {
        if (data.result && data.result === 'error') {
            console.log('xLightApi Error:', data)
        } else {
            console.log('xLightApi:', data.playlists)
        }
        return data
    }
}