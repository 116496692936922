/* eslint-disable no-new-object */
import * as cjs from 'createjs-module'
import { getMCSymbolPrototype } from '../../../../actions/animateHelper'

export const animation = () => {

    var lib = {}
    var p

    /* IMPORT START 
    function getMCSymbolPrototype ....
    */



    (lib.molgenlöte = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.lf(["#000000","#C60037","#000000","#000000"],[0,0.518,1,1],0.7,-135.4,-0.8,135.5).s().p("EgoZAVAMAAQgqVMBQjAAWMgAQAqUg");
            this.shape.setTransform(258.625,136.55);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.molgenlöte, new cjs.Rectangle(0,0,517.3,273.1), null);
        
        
        (lib.daemmerung = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f("#000000").s().p("EgmWAbbMAAAg21MBMsAAAMAAAA21g");
            this.shape.setTransform(245.45,175.475);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.daemmerung, new cjs.Rectangle(0,0,490.9,351), null);
        
        
        (lib.corona2 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(2,0,0,3.9).p("AAAl8IAAi1AC/lJIBaidAF+AAIC1AAAC/FKIBaCdAAAF+IAAC0AFLC/ICcBaAFLi9ICchcAi+lJIhaidAi+FKIhaCdAl9AAIi1AAAlKC/IicBaAlKi9Iichc");
            this.shape.setTransform(56.275,56.225);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.corona2, new cjs.Rectangle(-1,-1,114.6,114.5), null);
        
        
        (lib.corona1 = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(4,0,0,3.9).p("AB1m1IA3jPAE/lAICZiXAG1h0IDQg4AG1B2IDQA3AB1G2IA3DPAE/FAICZCYAk/lAIiZiXAh1m1Ig4jPAh1G2Ig4DPAk/FAIiZCYAm1B2IjPA3Am1h0IjPg4");
            this.shape.setTransform(64.5238,64.525);
        
            this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.corona1, new cjs.Rectangle(-2,-2,133.1,133.1), null);
        
        
        (lib.sonne_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // corona2
            this.instance = new lib.corona2();
            this.instance.setTransform(63.95,63.25,1,1,0,0,0,56.2,56.2);
        
            this.timeline.addTween(cjs.Tween.get(this.instance).to({regX:56.4,regY:56.1,scaleX:0.9999,scaleY:0.9999,rotation:28.9994,x:64.1},586).wait(1));
        
            // corona1
            this.instance_1 = new lib.corona1();
            this.instance_1.setTransform(64.5,64.5,1,1,0,0,0,64.5,64.5);
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).to({rotation:-29.0002,x:64.45},586).wait(1));
        
            // Ebene_1
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(6,0,0,3.9).p("AEgAAQAAh2hUhWQhVhUh3AAQh4AAhUBUQhUBWAAB2QAAB4BUBVQBVBTB3AAQB3AABVhTQBUhVAAh4g");
            this.shape.setTransform(63.05,64.05);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f("#DD0261").s().p("AhUBVQgjgjAAgyQAAgxAjgjQAjgjAxAAQAyAAAjAjQAjAjAAAxQAAAygjAjQgjAjgyAAQgxAAgjgjg");
            this.shape_1.setTransform(52.4981,64.5144,2.3699,2.3699);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(587));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-25.9,-25.9,180.9,180.8);
        
        
        (lib.sub_ani_01_mc = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            // timeline functions:
            this.frame_289 = function() {
                // Lamellen start
                if(this.parent.startRight) {
                    this.parent.startRight();
                }
                
            }
            this.frame_384 = function() {
                this.stop();
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).wait(289).call(this.frame_289).wait(95).call(this.frame_384).wait(1));
        
            // dmmerung
            this.instance = new lib.daemmerung();
            this.instance.setTransform(250.05,175.5,1.0185,1,0,0,0,245.5,175.5);
            this.instance.alpha = 0.5508;
        
            this.timeline.addTween(cjs.Tween.get(this.instance).wait(74).to({alpha:0},131).wait(180));
        
            // wald
            this.shape = new cjs.Shape();
            this.shape.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAOAOIgbgb");
            this.shape.setTransform(369.8281,304.4031,2.37,2.37);
        
            this.shape_1 = new cjs.Shape();
            this.shape_1.graphics.f().s("#FFFFFF").ss(2,1,1).p("AgRASIAjgj");
            this.shape_1.setTransform(377.3528,313.9424,2.37,2.37);
        
            this.shape_2 = new cjs.Shape();
            this.shape_2.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAABjIAAjF");
            this.shape_2.setTransform(373.0868,318.6823,2.37,2.37);
        
            this.shape_3 = new cjs.Shape();
            this.shape_3.graphics.f("#34A58C").s().p("AhQBuIAAiKQgBghAYgYQAZgYAgAAQAiAAAYAYQAYAYAAAhIgBCKg");
            this.shape_3.setTransform(373.0868,301.2036,2.37,2.37);
        
            this.shape_4 = new cjs.Shape();
            this.shape_4.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAXAXIgsgt");
            this.shape_4.setTransform(90.8204,280.6439,2.37,2.37);
        
            this.shape_5 = new cjs.Shape();
            this.shape_5.graphics.f().s("#FFFFFF").ss(2,1,1).p("AgcAdIA5g5");
            this.shape_5.setTransform(103.0852,296.1674,2.37,2.37);
        
            this.shape_6 = new cjs.Shape();
            this.shape_6.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAAChIAAlB");
            this.shape_6.setTransform(96.1529,303.9884,2.37,2.37);
        
            this.shape_7 = new cjs.Shape();
            this.shape_7.graphics.f("#34A58C").s().p("AhHCUQgegcgDgpIgBAAIAAiUQAAgsAfgfQAfgfArAAQAsAAAfAfQAfAfAAAsIAACUIgBAAQgDApgeAcQgeAcgqAAQgoAAgfgcg");
            this.shape_7.setTransform(96.0937,283.9027,2.37,2.37);
        
            this.shape_8 = new cjs.Shape();
            this.shape_8.graphics.f().s("#FFFFFF").ss(2,1,1).p("AATATIgmgl");
            this.shape_8.setTransform(413.6138,289.2352,2.37,2.37);
        
            this.shape_9 = new cjs.Shape();
            this.shape_9.graphics.f().s("#FFFFFF").ss(2,1,1).p("AgYAZIAxgx");
            this.shape_9.setTransform(424.2195,302.6256,2.37,2.37);
        
            this.shape_10 = new cjs.Shape();
            this.shape_10.graphics.f().s("#FFFFFF").ss(2,1,1).p("AAACKIAAkT");
            this.shape_10.setTransform(418.2353,309.2616,2.37,2.37);
        
            this.shape_11 = new cjs.Shape();
            this.shape_11.graphics.f("#34A58C").s().p("Ag9B/QgagYgDgjIAAAAIAAh/QAAgmAagaQAbgbAlAAQAmAAAaAbQAbAaAAAmIAAB/IgBAAQgCAjgbAYQgaAYgjAAQgjAAgagYg");
            this.shape_11.setTransform(418.2353,292.0199,2.37,2.37);
        
            this.shape_12 = new cjs.Shape();
            this.shape_12.graphics.f().s("#FFFFFF").ss(2).p("AsnAAIZPAA");
            this.shape_12.setTransform(247.6549,342.7378,2.37,2.37);
        
            this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(385));
        
            // terra
            this.shape_13 = new cjs.Shape();
            this.shape_13.graphics.f("#000000").s().p("EgqBALVIAA2pMBUDAAAIAAWpg");
            this.shape_13.setTransform(263.025,413.5);
        
            this.timeline.addTween(cjs.Tween.get(this.shape_13).wait(385));
        
            // sonne
            this.instance_1 = new lib.sonne_mc();
            this.instance_1.setTransform(249,411.5,1,1,0,0,0,64.5,64.5);
            this.instance_1._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(74).to({_off:false},0).to({y:81.5},310).wait(1));
        
            // Ebene_2
            this.instance_2 = new lib.molgenlöte();
            this.instance_2.setTransform(248.65,333.1,1,1,0,0,0,258.6,136.6);
            this.instance_2.alpha = 0.3008;
            this.instance_2._off = true;
        
            this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(23).to({_off:false},0).to({alpha:1},122).to({alpha:0},166).wait(74));
        
            this._renderFirstFrame();
        
        }).prototype = p = new cjs.MovieClip();
        p.nominalBounds = new cjs.Rectangle(-9.9,0,541.9,486);
        
        
        (lib.main_animation = function(mode,startPosition,loop,reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
            var props = new Object();
            props.mode = mode;
            props.startPosition = startPosition;
            props.labels = {};
            props.loop = loop;
            props.reversed = reversed;
            cjs.MovieClip.apply(this,[props]);
        
            this.isSingleFrame = false;
            // timeline functions:
            this.frame_0 = function() {
                if (this.isLoaded) {
                    return
                }
                if(this.isSingleFrame) {
                    return;
                }
                if(this.totalFrames == 1) {
                    this.isSingleFrame = true;
                }
                this.goHome=function(){
                    this.sub_ani_01_mc.gotoAndPlay(0);
                }
                this.startRight = function(){
                
                }
            }
        
            // actions tween:
            this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));
        
            // Ebene_2 (mask)
            var mask = new cjs.Shape();
            mask._off = true;
            mask.graphics.p("EgnDAfQMAAAg+fMBOHAAAMAAAA+fg");
            mask.setTransform(250,200.075);
        
            // subAnimation_mc
            this.sub_ani_01_mc = new lib.sub_ani_01_mc();
            this.sub_ani_01_mc.name = "sub_ani_01_mc";
            this.sub_ani_01_mc.setTransform(114.4,204.6,1,1,0,0,0,114.4,160.6);
        
            var maskedShapeInstanceList = [this.sub_ani_01_mc];
        
            for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
                maskedShapeInstanceList[shapedInstanceItr].mask = mask;
            }
        
            this.timeline.addTween(cjs.Tween.get(this.sub_ani_01_mc).wait(1));
        
            this._renderFirstFrame();
        
        }).prototype = getMCSymbolPrototype(lib.main_animation, new cjs.Rectangle(0,44,500,356.1), null);
        
        

    /* IMPORT END 
    // stage content: ...
    */

    return lib
}