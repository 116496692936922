
export const getContent = (id: string = '', getAll: boolean = false, getMenu: boolean = false) => {

    const data = {
        windowStatus: {
            scene: {
                logoOpaque: 5,
                bgOpaque: 1,
                bgClear: 0,
                opaque: 16,
                clear: 0
            }
        },
        checkinPage: {
            title: {
                de: {
                    l1: 'Willkommen',
                    l2: 'Future Window Vision'
                },
                en: {
                    l1: 'Welcome',
                    l2: 'Future Window Vision'
                }
            },
            info: {
                de: 'Um zu beginnen,\nscannen Sie Ihren QR-Code.',
                en: 'To get started,\nscan your QR code.'
            },
            welcome: {
                nameStr: {
                    de: 'Hallo {Gender} {Name},\nvielen Dank.\nSie können starten ...',
                    en: 'Hello {Gender} {Name},\nthank you.\nYou can start now ...',
                },
                gender: {
                    de: {
                        f: 'Frau',
                        m: 'Herr'
                    },
                    en: {
                        f: 'Ms',
                        m: 'Mr'
                    }
                }
            }
        },
        outsideView: {
            title: {
                de: {
                    l1: 'Willkommen',
                    l2: 'Future Window Vision'
                },
                en: {
                    l1: 'Welcome',
                    l2: 'Future Window Vision'
                }
            }
        },
        headline: {
            label: {
                de: {
                    l1: 'Willkommen',
                    l2: 'Future Window Vision'
                },
                en: {
                    l1: 'Welcome',
                    l2: 'Future Window Vision'
                }
            }
        },
        led: 'Welcome',
        content: [
            {
                id: 'lueften_heizen_kuehlen',
                btn: {
                    icon: 'icon_lueften_heizen_kuehlen',
                    label: {
                        de: {
                            l1: 'Lüftung',
                            l2: 'Heizung und Kühlung'
                        },
                        en: {
                            l1: 'Ventilation',
                            l2: 'Heating and Air condition'
                        }
                    }
                },
                headline: {
                    label: {
                        de: {
                            l1: 'Lüftung',
                            l2: 'Heizung und Kühlung'
                        },
                        en: {
                            l1: 'Ventilation',
                            l2: 'heating and cooling'
                        }
                    }
                },
                content: [
                    {
                        id: 'energieeffizienz',
                        btn: {
                            icon: 'icon_lueften_heizen_kuehlen_energieeffizienz',
                            label: {
                                de: {
                                    l1: 'Energie-',
                                    l2: 'effizienz'
                                },
                                en: {
                                    l1: 'Energy',
                                    l2: 'Efficiency'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Energieeffizienz',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Energy Efficiency',
                                    l2: ''
                                }
                            }
                        },
                        animation: {
                            right: 'lueften_heizen_kuehlen/energieeffizienz',
                            texte: {
                                'txt_mc_01.txt_01': {
                                    de: 'Reduzierung der Heizkosten durch Wärmerückgewinnung aus der Abluft',
                                    en: 'Reduction of heating costs through heat recovery from the exhaust air'
                                },
                                'txt_mc_02.txt_01': {
                                    de: 'Reduzierter Energieverbrauch durch Vermeidung von ineffektivem, zu langem Lüften durch geöffnete Fenster',
                                    en: 'Reduced energy consumption by avoiding ineffective, too long ventilation through open windows'
                                },
                                'txt_mc_03.txt_01': {
                                    de: 'Kontrollierte Be- und Entlüftung auch wenn man nicht zuhause ist - bei vollem Einbruchschutz',
                                    en: 'Controlled ventilation and ventilation even when you not at home - with full burglary protection'
                                },
                                'txt_mc_04.txt_01': {
                                    de: 'Querlüftung durch den gemeinsamen Betrieb mehrerer miteinander korrespondierender Lüftungsgeräte in unterschiedlichen Räumen',
                                    en: 'Cross ventilation due to the joint operation of several corresponding ventilation units in different rooms'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                    },
                    {
                        id: 'heizen',
                        btn: {
                            icon: 'icon_lueften_heizen_kuehlen_heizen',
                            label: {
                                de: {
                                    l1: 'Heizung',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Heating',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Heizung',
                                    l2: '• Hoher Komfort: Ganztägig frische und angenehm temperierte Luft ohne kalte Zugerscheinungen, auch im Winter\n• Flexibel in der Anwendung: Auch besonders gut in der Renovierung geeignet'
                                },
                                en: {
                                    l1: 'Heating',
                                    l2: '• High comfort: all day fresh and pleasant tempered air without cold drafts, even in winter\n• Flexible in application: also particularly well suited for renovation'
                                }
                            }
                        },
                        animation: {
                            right: 'lueften_heizen_kuehlen/heizen',
                            texte: {
                                'co2_high_txt': {
                                    de: 'hoch',
                                    en: 'high'
                                },
                                'co2_middle_txt': {
                                    de: 'mittel',
                                    en: 'middle'
                                },
                                'co2_low_txt': {
                                    de: 'niedrig',
                                    en: 'low'
                                },
                                'co2_txt': {
                                    de: 'hoch',
                                    en: 'high'
                                },
                                'action_btn.txt_01': {
                                    de: 'HEIZEN',
                                    en: 'HEATING'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                        speech: {
                            button: 'action_btn',
                            synonyms: [
                                'heizöl',
                                'heizen',
                                'heizung',
                                'sein',
                                'einzeln',
                                'nutzung',
                                'dreizehn',
                                'heiz',
                                'hei',
                                'heating',
                                'heat',
                                'hot',
                                'ding',
                                'indem',
                                'idee'
                            ]
                        },
                        color: 'red'
                    },
                    {
                        id: 'installation',
                        btn: {
                            icon: 'icon_lueften_heizen_kuehlen_installation',
                            label: {
                                de: {
                                    l1: 'Installation',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Installation',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Installation',
                                    l2: 'Konventionelle Lösung:\n\nHoher Installationsaufwand: Fenster und Lüftungsgerät müssen als getrennte Komponenten montiert werden\n\nReduzierte Transparenz: Das Lüftungsgerät reduziert den transparenten Teil des Fensters und damit den Lichteinfall'
                                },
                                en: {
                                    l1: 'Installation',
                                    l2: 'Conventional solution:\n\nHigh installation effort: windows and ventilation unit must be installed as separate components\n\nReduced transparency: the ventilation unit reduces the transparent part of the window and thus the incidence of light'
                                }
                            }
                        },
                        animation: {
                            right: 'lueften_heizen_kuehlen/installation',
                            texte: {
                                'standard_btn_txt': {
                                    de: 'Konventionelle Lösung:\n\nHoher Installationsaufwand: Fenster und Lüftungsgerät müssen als getrennte Komponenten montiert werden\n\nReduzierte Transparenz: Das Lüftungsgerät reduziert den transparenten Teil des Fensters und damit den Lichteinfall',
                                    en: 'Conventional solution:\n\nHigh installation effort: windows and ventilation unit must be installed as separate components\n\nReduced transparency: the ventilation unit reduces the transparent part of the window and thus the incidence of light'
                                },
                                'rehau_btn_txt': {
                                    de: 'REHAU Lösung:\n\nEinfache und schnelle Montage: Alle Lüftungskomponenten sind werkseitig in das Fenster integriert\n\nSchönes Design: Durch maximale Funktionsintegration sind keine Anbauteile erforderlich',
                                    en: 'REHAU solution:\n\nSimple and fast assembly: all ventilation components are integrated into the window ex works\n\nBeautiful design: due to maximum integration of functions no add-on parts are necessary',
                                },
                                'rehau_btn.txt_01': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },
                                'standard_btn.txt_01': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                    },
                    {
                        id: 'komfort',
                        btn: {
                            icon: 'icon_lueften_heizen_kuehlen_komfort',
                            label: {
                                de: {
                                    l1: 'Komfort',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Comfort',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Komfort',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Comfort',
                                    l2: ''
                                }
                            }
                        },
                        animation: {
                            right: 'lueften_heizen_kuehlen/komfort',
                            texte: {
                                'txt_mc_01.txt_01': {
                                    de: 'Frische und saubere Luft durch integrierten Pollenfilter',
                                    en: 'Fresh and clean air through integrated pollen filter'
                                },
                                'txt_mc_02.txt_01': {
                                    de: 'Schallschutz: Frische Luft auch bei geschlossenem Fenster',
                                    en: 'Sound insulation: fresh air even with closed window'
                                },
                                'txt_mc_03.txt_01': {
                                    de: 'Heiz- und Kühlfunktion: Immer die richtige Temperatur der Zuluft',
                                    en: 'Heating and cooling function: Always the right temperature of the supply air'
                                },
                                'txt_mc_04.txt_01': {
                                    de: 'Schutz vor Schimmel: Automatische Lüftung bei Überschreitung der Grenzwerte für Raumfeuchte',
                                    en: 'Protection against mold: automatic ventilation when exceeding the limit values for room humidity'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                        color: 'white'
                    },
                    {
                        id: 'kuehlen',
                        btn: {
                            icon: 'icon_lueften_heizen_kuehlen_kuehlen',
                            label: {
                                de: {
                                    l1: 'Kühlung',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Cooling',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Kühlung',
                                    l2: '• Angenehme Raumtemperaturen auch in heißen Sommern durch Kühlung der Zuluft\n\n• Optimales Raumklima und Schutz vor Schimmel: CO² und warme Raumluft mit hoher Luftfeuchtigkeit werden zuverlässig abgeführt\n\n• Flexibel in der Anwendung: Auch besonders gut in der Renovierung geeignet.'
                                },
                                en: {
                                    l1: 'Cooling',
                                    l2: '• Pleasant room temperatures even in hot summers due to cooling of the supply air\n\n• Optimum room climate and protection against mold: CO² and and the warm air with high humidity are reliably removed\n\n• Flexible in application: also particularly well suited for renovation'
                                }
                            }
                        },
                        animation: {
                            right: 'lueften_heizen_kuehlen/kuehlen',
                            texte: {
                                'co2_high_txt': {
                                    de: 'hoch',
                                    en: 'high'
                                },
                                'co2_middle_txt': {
                                    de: 'mittel',
                                    en: 'middle'
                                },
                                'co2_low_txt': {
                                    de: 'niedrig',
                                    en: 'low'
                                },
                                'co2_txt': {
                                    de: 'hoch',
                                    en: 'high'
                                },
                                'action_btn.txt_01': {
                                    de: 'KÜHLEN',
                                    en: 'COOLING'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                        speech: {
                            button: 'action_btn',
                            synonyms: [
                                'kühlen',
                                'kühl',
                                'kühlung',
                                'sein',
                                'können',
                                'kühler',
                                'kümmern',
                                'cooling',
                                'cool',
                                'cooler',
                                'cow',
                                'holen',
                                'pool',
                                'pudel',
                                'bügeln'
                            ]
                        },
                        color: 'blue'
                    },
                    {
                        id: 'lueften',
                        btn: {
                            icon: 'icon_lueften_heizen_kuehlen_lueften',
                            label: {
                                de: {
                                    l1: 'Lüftung',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Ventilation',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Lüftung',
                                    l2: '• Beste Raumluftqualität: Automatische Lüftung bei Überschreitung der Grenzwerte für CO² oder VOC\n\n• Schutz vor Schimmelpilz: Automatische Lüftung bei Überschreitung der Grenzwerte für Raumluftfeuchtigkeit\n\n• Gut für Allergiker: Frische und saubere Luft durch integrierten Pollenfilter\n\n• Energieeinsparung:\n  - Nachtauskühlung in den Sommermonaten\n  - Vorheizung der Zuluft in den Wintermonaten mittels Wärmetauscher'
                                },
                                en: {
                                    l1: 'Ventilation',
                                    l2: '• Best indoor air quality: automatic ventilation when exceeding the limit values for CO² or VOC\n\n• Protection against mold: automatic ventilation when exceeding the limit values for indoor humidity\n\n• Good for allergy sufferers: Fresh and clean air due to integrated pollen filter\n\n• Energy saving:\n  - night cooling in the summer \n  - preheating of the supply air in the winter by heat exchanger'
                                }
                            }
                        },
                        animation: {
                            right: 'lueften_heizen_kuehlen/lueften',
                            texte: {
                                'co2_high_txt': {
                                    de: 'hoch',
                                    en: 'high'
                                },
                                'co2_middle_txt': {
                                    de: 'mittel',
                                    en: 'middle'
                                },
                                'co2_low_txt': {
                                    de: 'niedrig',
                                    en: 'low'
                                },
                                'co2_txt': {
                                    de: 'hoch',
                                    en: 'high'
                                },
                                'action_btn.txt_01': {
                                    de: 'LÜFTEN',
                                    en: 'VENTILATION'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                        speech: {
                            button: 'action_btn',
                            synonyms: [
                                'lüften',
                                'lüfter',
                                'lifte',
                                'luft',
                                'sein',
                                'lüfte',
                                'dürfte',
                                'müsste',
                                'liste',
                                'möchte',
                                'lü',
                                'air',
                                'condition',
                                'wind',
                                'ventil',
                                'ventilation',
                                'ventilator',
                                'dürfte',
                                'mädchen',
                                'wenn',
                                'benötigen',
                                'möglichen',
                                'menschen'
                            ]
                        },
                        color: 'white'
                    }
                ]
            },
            {
                id: 'regenerative_energie',
                btn: {
                    icon: 'icon_regenerative_energie',
                    label: {
                        de: {
                            l1: 'Regenerative',
                            l2: 'Energieerzeugung'
                        },
                        en: {
                            l1: 'Regenerative',
                            l2: 'Power generation'
                        }
                    }
                },
                headline: {
                    label: {
                        de: {
                            l1: 'Regenerative',
                            l2: 'Energieerzeugung und Nutzung'
                        },
                        en: {
                            l1: 'Regenerative',
                            l2: 'Power generation and utilization'
                        }
                    }
                },
                // animation: {
                //     right: 'regenerative_energie/analyse'
                // },
                content: [
                    {
                        id: 'analyse',
                        btn: {
                            icon: 'icon_regenerative_energie_analyse',
                            label: {
                                de: {
                                    l1: 'Energie-',
                                    l2: 'werte'
                                },
                                en: {
                                    l1: 'Energie',
                                    l2: 'values'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Energiewerte',
                                    l2: 'Beispielrechnung bei Sonnenschein: Energie von der Glasscheibe und der Fensterbank'
                                },
                                en: {
                                    l1: 'Energy values',
                                    l2: 'Example calculation in sunshine: energy from the glass pane and the window sill'
                                }
                            }
                        },
                        animation: {
                            right: 'regenerative_energie/analyse_1',
                            texte: {
                                'mc_h2_1.txt_01': {
                                    de: 'Glasscheibe',
                                    en: 'Glass pane'
                                },
                                'mc_1.txt_mc.txt_01': {
                                    de: 'Max. Leistung 50 Watt pro m²',
                                    en: 'Max. power 50 watts per m²'
                                },
                                'mc_2.txt_mc.txt_01': {
                                    de: 'Glasfläche 7,2 m²',
                                    en: 'Active area of the window sill 7.2 m²'
                                },
                                'mc_3.txt_mc.txt_01': {
                                    de: 'Gesamtleistung 360 Watt',
                                    en: 'Total power 360 watt'
                                },
                                'mc_4.txt_mc.txt_01': {
                                    de: 'Ertrag bis zu 0,20 kWh/m² pro Tag',
                                    en: 'Output up to 0.20 kWh/ m² per day'
                                },
                                'mc_5.txt_mc.txt_01': {
                                    de: 'Gesamtertrag pro Tag 1,44 kWh',
                                    en: 'Total output per day 1.44 kWh'
                                },
                                'mc_6.txt_mc.txt_01': {
                                    de: 'Speicherkapazität der Batterie 1 kWh',
                                    en: 'Battery capacity 1 kWh'
                                },
                                'mc_h2_2.txt_01': {
                                    de: 'Fensterbank',
                                    en: 'Window sill'
                                },
                                'mc_7.txt_mc.txt_01': {
                                    de: 'Max. Leistung 200 Watt pro m²',
                                    en: 'Max. power 200 watts per m²'
                                },
                                'mc_8.txt_mc.txt_01': {
                                    de: 'Aktive Fläche der Fensterbank 0,25 m²',
                                    en: 'Active area of the window sill 0.25 m²'
                                },
                                'mc_9.txt_mc.txt_01': {
                                    de: 'Gesamtleistung 50 Watt',
                                    en: 'Total power 50 watt'
                                },
                                'mc_10.txt_mc.txt_01': {
                                    de: 'Ertrag bis zu 0,80 kWh/m² pro Tag',
                                    en: 'Output up to 0.80 kWh/ m² per day'
                                },
                                'mc_11.txt_mc.txt_01': {
                                    de: 'Gesamtertrag pro Tag 0,2 kWh',
                                    en: 'Total output per day 0.2 kWh'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                    },
                    {
                        id: 'energiegewinnung',
                        btn: {
                            icon: 'icon_regenerative_energie_energiegewinnung',
                            label: {
                                de: {
                                    l1: 'Energie-',
                                    l2: 'erzeugung'
                                },
                                en: {
                                    l1: 'Energy',
                                    l2: 'generation'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Energieerzeugung',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Power generation',
                                    l2: ''
                                }
                            }
                        },
                        animation: {
                            right: 'regenerative_energie/energiegewinnung',
                            texte: {
                                'gewinnung_glasscheibe_txt': {
                                    de: 'Stromerzeugung durch die Glasscheibe',
                                    en: 'Power generation using the glass pane'
                                },
                                'gewinnung_fensterscheibe_txt': {
                                    de: 'Stromerzeugung durch die Fensterbank',
                                    en: 'Power generation using the window sill'
                                },
                            }
                        },
                        led: 'Main',
                        // audio: 'sound/Stimmung/peacefulParadise_Mf_7752.mp3',
                        induktion: true,
                        window: [
                            {
                                state: 'clear',
                                window: 3
                            },
                            {
                                state: 'full',
                                window: 4
                            }
                        ],
                    },
                    {
                        id: 'nutzungsoptionen',
                        btn: {
                            icon: 'icon_regenerative_energie_nutzungsoptionen',
                            label: {
                                de: {
                                    l1: 'Energie-',
                                    l2: 'Nutzung'
                                },
                                en: {
                                    l1: 'Energy',
                                    l2: 'use'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Nutzung',
                                    l2: 'Betriebszustand:\n- Energiefluss bei Sonnenschein\n- Alle Verbraucher werden vom Fenster versorgt\n- Die Batterie wird geladen'
                                },
                                en: {
                                    l1: 'Energy use',
                                    l2: 'Operating condition: \n- Energy flow during sunshine \n- All consumers are supplied by the window \n- The battery is charged'
                                }
                            },
                            sonne1: {
                                label: {
                                    de: {
                                        l1: 'Nutzung',
                                        l2: 'Betriebszustand:\n- Energiefluss bei Sonnenschein\n- Alle Verbraucher werden vom Fenster versorgt\n- Die Batterie wird geladen'
                                    },
                                    en: {
                                        l1: 'Energy use',
                                        l2: 'Operating condition: \n- Energy flow during sunshine \n- All consumers are supplied by the window \n- The battery is charged'
                                    }
                                },
                            },
                            sonne2: {
                                label: {
                                    de: {
                                        l1: 'Nutzung',
                                        l2: 'Betriebszustand:\n- Energiefluss bei Sonnenschein\n- Die Batterie ist voll\n- Das Fenster versorgt alle Verbraucher\n- Die überschüssige Energie wird in das Stromnetz eingespeist.'
                                    },
                                    en: {
                                        l1: 'Energy use',
                                        l2: 'Operating condition: \n- Energy flow during sunshine \n- All consumers are supplied by the window \n- The battery is charged\n- The excess energy is fed into the power grid'
                                    }
                                },
                            },
                            wolke: {
                                label: {
                                    de: {
                                        l1: 'Nutzung',
                                        l2: 'Betriebszustand:\n- Energiefluss bei leichter Bewölkung\n- Verbraucher werden teilweise vom Fenster und teilweise von der Batterie versorgt.'
                                    },
                                    en: {
                                        l1: 'Energy use',
                                        l2: 'Operating condition:\n- Energy flow in light cloudy conditions\n- Consumers are supplied partly from the window and partly supplied by the battery.'
                                    }
                                },
                            },
                            mond1: {
                                label: {
                                    de: {
                                        l1: 'Nutzung',
                                        l2: 'Betriebszustand:\n- Energiefluss bei Nacht\n- Die Batterie ist zunächst voll\n- Alle Verbraucher werden zunächst von der Batterie versorgt und bei leerer Batterie vom Stromnetz'
                                    },
                                    en: {
                                        l1: 'Energy use',
                                        l2: 'Operating condition: \n- Energy flow at night \n- The battery is initially full \n- All consumers are initially supplied by the battery and, when the battery is empty, from the power grid'
                                    }
                                },
                            }
                        },
                        animation: {
                            right: 'regenerative_energie/nutzungsoptionen',
                            texte: {
                                'txt_01': {
                                    de: 'Smart Glass',
                                    en: 'Smart Glass'
                                },
                                'txt_02': {
                                    de: 'Induktives Laden\nSmartphones',
                                    en: 'Inductive charging'
                                },
                                'txt_03': {
                                    de: 'Smart Glass',
                                    en: 'Smart Glass'
                                },
                                'txt_04': {
                                    de: 'Sensoren',
                                    en: 'Sensors'
                                },
                                'txt_05': {
                                    de: 'Aktoren',
                                    en: 'Actuators'
                                },
                                'txt_06': {
                                    de: 'Licht',
                                    en: 'Light'
                                }
                            }
                        },
                        led: 'Laden',
                        logo: 'logo',
                    },
                    {
                        id: 'analyse3',
                        btn: {
                            icon: 'icon_regenerative_energie_analyse',
                            label: {
                                de: {
                                    l1: 'Energie-',
                                    l2: 'verläufe'
                                },
                                en: {
                                    l1: 'Energy',
                                    l2: 'curves'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Energieverläufe',
                                    l2: 'Typischer Tagesverlauf bei Sonnenschein und durchschnittliche Jahresbilanz des Energieertrags, des Energieverbrauchs und des Energiebezugs.'
                                },
                                en: {
                                    l1: 'Energy curves',
                                    l2: 'Typical daily course of sunshine and average annual balance of the energy output, the energy consumption and the energy supply.'
                                }
                            }
                        },
                        animation: {
                            right: 'regenerative_energie/analyse_3',
                            texte: {
                                'mc_1.txt_mc.txt_01': {
                                    de: 'Typischer Tagesverlauf der Leistung',
                                    en: 'Typical daily power curve'
                                },
                                'mc_1.txt_mc.txt_02': {
                                    de: 'Glasscheibe',
                                    en: 'Window pane'
                                },
                                'mc_1.txt_mc.txt_03': {
                                    de: 'Fensterbank',
                                    en: 'Window sill'
                                },
                                'mc_2.txt_mc.txt_01': {
                                    de: 'Energiegewinnung',
                                    en: 'Power generation'
                                },
                                'mc_2.txt_mc.txt_02': {
                                    de: 'Durchschnittlicher Energieverbrauch',
                                    en: 'Average energy consumption'
                                },
                                'mc_2.txt_mc.txt_03': {
                                    de: 'Energie aus dem Stromnetz',
                                    en: 'Energy from the power grid'
                                },
                                'mc_2.txt_mc.txt_04': {
                                    de: 'Energiegewinnung',
                                    en: 'Power generation'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                    }
                ]
            },
            {
                id: 'smart_privacy',
                btn: {
                    icon: 'icon_smart_privacy',
                    label: {
                        de: {
                            l1: 'Smart Privacy',
                            l2: ''
                        },
                        en: {
                            l1: 'Smart Privacy',
                            l2: ''
                        }
                    }
                },
                headline: {
                    label: {
                        de: {
                            l1: 'Smart Privacy',
                            l2: ''
                        },
                        en: {
                            l1: 'Smart Privacy',
                            l2: ''
                        }
                    }
                },
                content: [
                    {
                        id: 'praesenz',
                        btn: {
                            icon: 'icon_smart_privacy_praesenz',
                            label: {
                                de: {
                                    l1: 'Präsenz-',
                                    l2: 'sensor'
                                },
                                en: {
                                    l1: 'Presence',
                                    l2: 'sensor'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Privatsphäre',
                                    l2: 'Smart Privacy bietet Privatsphäre immer genau dann, wenn sie benötigt wird'
                                },
                                en: {
                                    l1: 'Presence',
                                    l2: 'Smart Privacy always provides privacy exactly when it is needed'
                                }
                            }
                        },
                        animation: {
                            center: 'smart_privacy/praesenz-center',
                            right: 'smart_privacy/praesenz-right'
                        },
                        led: 'Dusche',
                        audio: 'sound/Dusche/mf_SE-4947-taking_a_shower.mp3'
                    },
                    {
                        id: 'tageslichtsensor',
                        btn: {
                            icon: 'icon_smart_privacy_tageslichtsensor',
                            label: {
                                de: {
                                    l1: 'Tageslicht-',
                                    l2: 'sensor'
                                },
                                en: {
                                    l1: 'Daylight',
                                    l2: 'sensor'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Sonnenschein',
                                    l2: 'Smart Privacy reduziert spürbar die Blendwirkung bei direkter \nSonneneinstrahlung'
                                },
                                en: {
                                    l1: 'Sunshine',
                                    l2: 'Smart Privacy noticeably reduces glare in direct sunlight'
                                }
                            }
                        },
                        animation: {
                            center: 'smart_privacy/tageslichtsensor',
                            right: 'smart_privacy/tageslichtsensor-right'
                        },
                        led: 'Sonnenaufgang',
                        window: [
                            {
                                state: 'top-2/4',
                                window: 1
                            }
                        ],
                        audio: 'sound/Natur/mf_SE-5539-birds_in_the_morning.mp3'
                    },
                    {
                        id: 'daemmerungssensor',
                        btn: {
                            icon: 'icon_smart_privacy_tageslichtsensor',
                            label: {
                                de: {
                                    l1: 'Dämmerungs-',
                                    l2: 'sensor'
                                },
                                en: {
                                    l1: 'Twilight',
                                    l2: 'sensor'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Sonnenuntergang',
                                    l2: 'Bei Einbruch der Dämmerung entsteht mit Smart Privacy Geborgenheit in Ihrem Zuhause'
                                },
                                en: {
                                    l1: 'Sunset',
                                    l2: 'At dusk, Smart Privacy creates security in your home'
                                }
                            }
                        },
                        animation: {
                            center: 'smart_privacy/daemmerungssensor',
                            right: 'smart_privacy/daemmerungssensor-right',
                        },
                        led: 'Sonnenuntergang',
                        window: [
                            {
                                state: 'bottom-2/4',
                                window: 1
                            }
                        ],
                        audio: 'sound/Natur/mf_SE-6200-grasshopper_5.mp3'
                    },
                    {
                        id: 'security',
                        btn: {
                            icon: 'icon_smart_privacy_security',
                            label: {
                                de: {
                                    l1: 'Security',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Security',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Sicherheit',
                                    l2: 'Smart Privacy schützt vor neugierigen Blicken und spendet \nein Gefühl von Sicherheit'
                                },
                                en: {
                                    l1: 'Security',
                                    l2: 'Smart Privacy protects from prying eyes and gives a sense of security'
                                }
                            }
                        },
                        animation: {
                            center: 'smart_privacy/security-center',
                            right: 'smart_privacy/security-right'
                        },
                        led: 'Alarm',
                        logo: 'alarm',
                        audio: 'sound/Dieb/REHAU_Seq_Alarm_rot.mp3'
                    }
                ]
            },
            {
                id: 'innovatives',
                btn: {
                    icon: 'icon_innovatives',
                    label: {
                        de: {
                            l1: 'Innovativer',
                            l2: 'Profilwerkstoff'
                        },
                        en: {
                            l1: 'Innovative',
                            l2: 'profile material'
                        }
                    }
                },
                headline: {
                    label: {
                        de: {
                            l1: 'Innovativer',
                            l2: 'Profilwerkstoff'
                        },
                        en: {
                            l1: 'Innovative',
                            l2: 'profile material'
                        }
                    }
                },
                content: [
                    {
                        id: 'stabilitaet',
                        btn: {
                            icon: 'icon_innovatives_stabilitaet',
                            label: {
                                de: {
                                    l1: 'Stabilität',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Stability',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Stabilität',
                                    l2: 'Sehr hohe Verformungsstabilität gegenüber Gewichtslasten'
                                },
                                en: {
                                    l1: 'Stability',
                                    l2: 'Very high deformation stability against weight loads'
                                }
                            }
                        },
                        animation: {
                            right: 'innovatives/stabilitaet',
                            texte: {
                                'txt_slider': {
                                    de: 'Gewicht erhöhen',
                                    en: 'Increase weight'
                                },
                                'txt_01_01': {
                                    de: 'Material Evolution',
                                    en: 'Material Evolution'
                                },
                                'txt_01_02': {
                                    de: 'Langfasermaterial',
                                    en: 'Long fiber material'
                                },
                                'txt_02_01': {
                                    de: 'RAU-FIPRO X',
                                    en: 'RAU-FIPRO X'
                                },
                                'txt_02_02': {
                                    de: 'Kurzfasermaterial',
                                    en: 'Short fiber material'
                                },
                                'txt_03_01': {
                                    de: 'PVC',
                                    en: 'PVCu'
                                },
                                'txt_03_02': {
                                    de: 'mit Stahl',
                                    en: 'with steel'
                                },
                                'shrink_mc.txt_01': {
                                    de: 'Schrumpf',
                                    en: 'Shrink'
                                },
                                'stretch_mc.txt_01': {
                                    de: 'Ausdehnung',
                                    en: 'Extension'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                    },
                    {
                        id: 'modulation',
                        btn: {
                            icon: 'icon_innovatives_modulation',
                            label: {
                                de: {
                                    l1: 'Modulation',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Modulation',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Modulation',
                                    l2: 'Materialgerecht optimierte Konstruktionsprinzipien: stabiler, schlanker, wärmer'
                                },
                                en: {
                                    l1: 'Modulation',
                                    l2: 'Optimized for material construction principles: more stable, slimmer, warmer'
                                }
                            }
                        },
                        animation: {
                            right: 'innovatives/modulation',
                            texte: {
                                'pvc_ff_btn.txt_01': {
                                    de: 'PVC',
                                    en: 'PVCu'
                                },
                                'pvc_ff_btn.txt_02': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },
                                'pvc_red_mc.txt_01': {
                                    de: 'PVC',
                                    en: 'PVCu'
                                },
                                'pvc_red_mc.txt_02': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },
                                
                                'fipro_ff_btn.txt_01': {
                                    de: 'RAU-FIPRO X',
                                    en: 'RAU-FIPRO X'
                                },
                                'fipro_ff_btn.txt_02': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },
                                'fipro_red_mc.txt_01': {
                                    de: 'RAU-FIPRO X',
                                    en: 'RAU-FIPRO X'
                                },
                                'fipro_red_mc.txt_02': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },

                                'comwin_ff_btn.txt_01': {
                                    de: 'Mat. Evolution',
                                    en: 'Mat. Evolution'
                                },
                                'comwin_ff_btn.txt_02': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },

                                'comwin_red_mc.txt_01': {
                                    de: 'Mat. Evolution',
                                    en: 'Mat. Evolution'
                                },
                                'comwin_red_mc.txt_02': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },

                                'pvc_mc_01': {
                                    de: 'Breite Ansichten\nGroße Überschläge\nStahleinsatz\nBegrenzte Glasstärken\nGute Wärmedämmung',
                                    en: 'Wide views\nLarge overlaps\nSteel insert\nLimited glass thickness\nGood thermal insulation'
                                },
                                'fipro_mc_01': {
                                    de: 'Schlanke Überschläge\nWenig Stahleinsatz\nVereinfachte Fabrikation\nStarke Funktionsgläser\nSehr gute Wärmedämmung',
                                    en: 'Slim Overlaps\nLittle use of steel\nSimplified fabrication\nStrong functional glasses\nVery good thermal insulation'
                                },
                                'comwin_mc_01': {
                                    de: 'Sehr schlanke Ansichten\nMehr Licht\nKein Stahleinsatz\nAutomatisierte Fabrikation\nSehr hohe Glasstärken\nHohe Gewichte\nHohe Wärmedämmung',
                                    en: 'Very slim views\nMore light\nNo steel insert\nAutomated fabrication\nVery high glass thickness\nHigh glass weights\nHigh thermal insulation'
                                },
                            }


                        },
                        led: 'Main',
                        logo: 'logo',
                    },
                    {
                        id: 'thermik',
                        btn: {
                            icon: 'icon_innovatives_thermik',
                            label: {
                                de: {
                                    l1: 'Wärmeformbeständigkeit',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Heat resistance',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Wärmeformbeständigkeit',
                                    l2: 'Hohe Formstabilität durch starke Reduktion thermischer Längenänderung'
                                },
                                en: {
                                    l1: 'Heat resistance',
                                    l2: 'High dimensional stability due to strong reduction of thermal length variation'
                                }
                            }
                        },
                        animation: {

                            right: 'innovatives/thermik',
                            texte: {
                                // label: {
                                //     de: {
                                //         l1: 'Wärmeformbeständigkeit',
                                //         l2: 'Starke Reduktion thermisch bedingter Schrumpfverformung und Bi-Metall-Effekte'
                                //     },
                                //     en: {
                                //         l1: 'Heat resistance',
                                //         l2: 'Strong reduction of thermally induced shrinkage deformation and bi-metal effects'
                                //     }
                                // }
                                'txt_slider': {
                                    de: 'Temperatur erhöhen',
                                    en: 'Increase temperature'
                                },
                                'txt_01_01': {
                                    de: 'Material Evolution',
                                    en: 'Material Evolution'
                                },
                                'txt_01_02': {
                                    de: 'Langfasermaterial',
                                    en: 'Long fiber material'
                                },
                                'txt_02_01': {

                                    de: 'RAU-FIPRO X',
                                    en: 'RAU-FIPRO X'

                                },
                                'txt_02_02': {
                                    de: 'Kurzfasermaterial',
                                    en: 'Short fiber material'
                                },
                                'txt_03_01': {
                                    de: 'PVC',
                                    en: 'PVCu'
                                },
                                'txt_03_02': {

                                    de: 'mit Stahl',
                                    en: 'with steel'
                                },
                                'temp_txt': {

                                    de: '+60° C',
                                    en: '+60° C'
                                },
                                'shrink_mc.txt_01': {
                                    de: 'Schrumpf',
                                    en: 'Shrink'
                                },
                                'stretch_mc.txt_01': {
                                    de: 'Ausdehnung',
                                    en: 'Extension'
                                },
                            }


                        },
                        led: 'Main',
                        logo: 'logo',
                    },
                    {
                        id: 'dimensionen',
                        btn: {
                            icon: 'icon_innovatives_dimensionen',
                            label: {
                                de: {
                                    l1: 'Dimensionen',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Dimensions',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Dimensionen',
                                    l2: 'Massive Erweiterung des Größenspektrums für höchste \narchitektonische Ansprüche'
                                },
                                en: {
                                    l1: 'Dimensions',
                                    l2: 'Massive expansion of the range of sizes for the highest \narchitectural demands'
                                }
                            }
                        },
                        animation: {
                            right: 'innovatives/dimensionen',
                            texte: {
                                'comwin_ff_btn.txt_01': {
                                    de: 'Evolution Material',
                                    en: 'Evolution Material'
                                },
                                'comwin_ff_btn.txt_02': {
                                    de: 'Fenster',
                                    en: 'Window'
                                },

                                'btn_01.txt_01': {
                                    de: 'PVC',
                                    en: 'PVCu'
                                },
                                'btn_01.txt_02': {
                                    de: 'ohne Stahl',
                                    en: 'without steel'
                                },
                                'window_01.txt_01': {
                                    de: 'PVC[-]steel',
                                    en: 'PVCu[-]steel'
                                },
                                'btn_02.txt_01': {
                                    de: 'RAU-FIPRO X',
                                    en: 'RAU-FIPRO X'
                                },
                                'btn_02.txt_02': {
                                    de: 'ohne Stahl',
                                    en: 'without steel'
                                },
                                'window_02.txt_01': {
                                    de: 'RAU-FIPRO X[-]steel',
                                    en: 'RAU-FIPRO X[-]steel'
                                },
                                'btn_03.txt_01': {
                                    de: 'PVC',
                                    en: 'PVCu'
                                },
                                'btn_03.txt_02': {
                                    de: 'mit Stahl',
                                    en: 'with steel'
                                },
                                'window_03.txt_01': {
                                    de: 'PVC[+]steel',
                                    en: 'PVCu[+]steel'
                                },
                                'btn_04.txt_01': {
                                    de: 'RAU-FIPRO X',
                                    en: 'RAU-FIPRO X'
                                },
                                'btn_04.txt_02': {
                                    de: 'mit Stahl',
                                    en: 'with steel'
                                },
                                'window_04.txt_01': {
                                    de: 'RAU-FIPRO X[+]steel',
                                    en: 'RAU-FIPRO X[+]steel'
                                },
                                'btn_05.txt_01': {
                                    de: 'Mat. Evolution',
                                    en: 'Mat. Evolution'
                                },
                                'btn_05.txt_02': {
                                    de: 'ohne Stahl',
                                    en: 'without steel'
                                },
                                'window_05.txt_01': {
                                    de: 'Material Evolution[-]steel',
                                    en: 'Material Evolution[-]steel'
                                },
                            }
                        },
                        led: 'Main',
                        logo: 'logo',
                    },
                ]
            },
            {
                id: 'windowid',
                btn: {
                    icon: 'icon_windowid',
                    label: {
                        de: {
                            l1: 'Window.ID',
                            l2: ''
                        },
                        en: {
                            l1: 'Window.ID',
                            l2: ''
                        }
                    }
                },
                headline: {
                    label: {
                        de: {
                            l1: 'Window.ID',
                            l2: ''
                        },
                        en: {
                            l1: 'Window.ID',
                            l2: ''
                        }
                    }
                },
                animation: {
                    right: 'windowid/window_id'
                },
                        led: 'Main',
                        logo: 'logo',
                content: [
                    {
                        id: 'montage',
                        btn: {
                            icon: 'icon_windowid_montage',
                            label: {
                                de: {
                                    l1: 'Effiziente',
                                    l2: 'Montage'
                                },
                                en: {
                                    l1: 'Efficient',
                                    l2: 'installation'
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Effiziente Montage',
                                    l2: 'Alle Montagedaten sofort verfügbar.\n\nDigitale Dokumentation und Abnahme.'
                                },
                                en: {
                                    l1: 'Efficient installation',
                                    l2: 'All installation data instantly available\n\nDigital documentation and approval'
                                }
                            }
                        },
                        animation: {
                            right: 'windowid/montage'
                        },
                        led: 'BlauUni',
                        logo: 'logo',
                    },
                    {
                        id: 'schnelle_hilfe',
                        btn: {
                            icon: 'icon_windowid_schnelle_hilfe',
                            label: {
                                de: {
                                    l1: 'Schnelle Hilfe',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Quick support',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Schnelle Hilfe',
                                    l2: 'Kunden direkt zum richtigen Ansprechpartner leiten.\n\nAufwand reduzieren und Nachgeschäft sichern.'
                                },
                                en: {
                                    l1: 'Quick support',
                                    l2: 'Lead customers directly to the right contact person.\n\nReduce effort and secure follow-up business.'
                                }
                            }
                        },
                        animation: {
                            right: 'windowid/schnelle_hilfe'
                        },
                        led: 'BlauUni',
                        logo: 'logo',
                    },
                    {
                        id: 'urban_mining',
                        btn: {
                            icon: 'icon_windowid_urban_mining',
                            label: {
                                de: {
                                    l1: 'Urban Mining',
                                    l2: ''
                                },
                                en: {
                                    l1: 'Urban Mining',
                                    l2: ''
                                }
                            }
                        },
                        headline: {
                            label: {
                                de: {
                                    l1: 'Urban Mining',
                                    l2: 'Wertvolle Rohstoffe für die Zukunft sichern.\n\nDem Kunden jetzt die Rückführung in den Wertstoffkreislauf zusichern.'
                                },
                                en: {
                                    l1: 'Urban Mining',
                                    l2: 'Secure valuable raw materials for the future.\n\nAssure customers proper recycling to the material cycle.'
                                }
                            }
                        },
                        animation: {
                            right: 'windowid/urban_mining',
                            texte: {
                                'goByAni_mc.goBy_mc.goBy_txt': {
                                    de: '20 - 30 Jahre',
                                    en: '20 - 30 Years'
                                }
                            }
                        },
                        led: 'BlauUni',
                        logo: 'logo',
                    },
                ]
            }
        ]
    }

    // Alles
    if (getAll) {
        return data
    }

    // Mainmenu
    if (id === '') {
        return data.content
    }

    if (getMenu) {
        var menuData: any = {}
        data.content.forEach((menu) => {
            if (menu.id === id) {
                menuData = menu
            }
            menu.content.forEach((menuSub) => {
                if (menuSub.id === id) {
                    menuData = menuSub
                }
            })
        })
        return menuData
    }

    // Submenu
    var subData: any = []
    data.content.forEach((menu) => {
        if (menu.id === id) {
            subData = menu.content
        }
    })

    return subData
}