import { config } from '../../config'

interface apiProps {
    window: Number,
    lamellen: Number
}

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,OPTIONS',
}

export const rehauApi = async (props: apiProps[] = []) => {

    if (!config.isLocal) {
        return
    }

    var url = `${config.windowApi.url}:${config.windowApi.port}/window/`
    var body = props

    var options: RequestInit = {
        method: 'POST',
        headers: headers,
        mode: 'cors',
        body: (body) ? JSON.stringify(body) : ''
    }

    const response = await fetch(url, options).then(response => {
        return response.json()
    }).catch((error) => {
        return { error: true, text: `${error}` }
    })

    const data = await response;

    if (data.status && data.status === 'error') {
        console.log('xLightApi Error:', data)
    } else {
        console.log('rehauApi:', data)
    }
    return data

}