import { animateScreenLogo } from '../logoHelper';
import { rehauApi } from './rehauApi'

export const setWindow = (w = 1, pos = '') => {

    var lamellen = 0

    switch (pos) {
        case 'off':
            lamellen = 0
            break;

        case 'bg':
            lamellen = 16
            break;

        case 'r':
            lamellen = 4 // 15 // 3
            break;

        case 'e':
            lamellen = 8 // 12 // 6
            break;

        case 'h':
            lamellen = 12
            break;

        case 'a':
            lamellen = 14 // 12
            break;

        case 'u':
            lamellen = 16 // 15
            break;

        default:
            break;
    }

    // console.log(w, pos, lamellen)
    // console.log('setWindowSeqment', w, lamellen, pos)

    rehauApi([
        {
            "window": w,
            "lamellen": lamellen
        }
    ])
}


export const setWindowSeqment = (w = 1, pos = 0) => {
    // console.log('setWindowSeqment', w, pos)

    rehauApi([
        {
            "window": w,
            "lamellen": pos
        }
    ])
}

export const setWindowState = (root, state = 'full') => {

    if (root.type !== 'screen' && root.type !== 'mobile') {
        return
    }

    // console.log('setWindowState', state, root)

    const windowFullArr = [
        {
            window: 1,
            lamellen: 0
        },
        {
            window: 2,
            lamellen: 0
        },
        {
            window: 3,
            lamellen: 0
        },
        {
            window: 4,
            lamellen: 0
        }
    ]


    switch (state) {
        case 'full':
            rehauApi(windowFullArr)
            break;

        case 'center':
            animateScreenLogo(root, 'out')
            rehauApi([
                {
                    "window": 1,
                    "lamellen": 17
                },
                {
                    "window": 2,
                    "lamellen": 0
                },
                {
                    "window": 3,
                    "lamellen": 16
                },
                {
                    "window": 4,
                    "lamellen": 16
                }
            ])
            break;

        case 'center-right':
            // animateScreenLogo(root, 'out')
            rehauApi([
                {
                    "window": 1,
                    "lamellen": 0
                },
                {
                    "window": 2,
                    "lamellen": 0
                },
                {
                    "window": 3,
                    "lamellen": 16
                },
                {
                    "window": 4,
                    "lamellen": 16
                }
            ])
            break;

        case 'logo-center-right':
            rehauApi([
                {
                    "window": 1,
                    "lamellen": 0
                },
                {
                    "window": 2,
                    "lamellen": 0
                },
                {
                    "window": 3,
                    "lamellen": 0
                },
                {
                    "window": 4,
                    "lamellen": 16
                }
            ])
            break;

        case 'logo-center':
            rehauApi([
                {
                    "window": 1,
                    "lamellen": 17
                },
                {
                    "window": 2,
                    "lamellen": 0
                },
                {
                    "window": 3,
                    "lamellen": 0
                },
                {
                    "window": 4,
                    "lamellen": 16
                }
            ])
            break;

        case 'logoinvert-center-right':
            rehauApi([
                {
                    "window": 1,
                    "lamellen": 0
                },
                {
                    "window": 2,
                    "lamellen": 0
                },
                {
                    "window": 3,
                    "lamellen": 16
                },
                {
                    "window": 4,
                    "lamellen": 0
                }
            ])
            break;

        default:
            break;
    }

}


export const setLamellenState = (root, state = 'full', w = 1) => {

    // console.log('setLamellenState', state, w)

    // bei 17 voll
    // const lamellenCount = 16

    // clear timer
    if (root.timerId && root.timerId.length > 0) {
        root.timerId.forEach(t => {
            // console.log('clear timer')
            clearTimeout(t)
        });
    }

    root.timerId = []



    switch (state) {
        case 'bottom-3/4':
            let max = 17
            for (let l = 17; l >= 7; l--) {
                // console.log('set', w, l)
                root.timerId[l] = setTimeout(function () {
                    // console.log('timer', w, l)
                    rehauApi([
                        {
                            "window": w,
                            "lamellen": l
                        }
                    ])
                }, (17 - l) * 1350);


            }
            break;

        case 'bottom-2/4':
            for (let l = 17; l >= 10; l--) {
                // console.log('set', w, l)
                root.timerId[l] = setTimeout(function () {
                    // console.log('timer', w, l)
                    rehauApi([
                        {
                            "window": w,
                            "lamellen": l
                        }
                    ])
                }, (17 - l) * 1350);


            }
            break;

        case 'top-3/4':
            for (let l = 18; l <= 28; l++) {
                // console.log('top', w, l)
                root.timerId[l] = setTimeout(function () {
                    // console.log('timer', w, l)
                    rehauApi([
                        {
                            "window": w,
                            "lamellen": l
                        }
                    ])
                }, (l - 18) * 1350);


            }
            break;

        case 'top-3/4-invert':
            for (let l = 0; l <= 7; l++) {
                root.timerId[l] = setTimeout(function () {
                    rehauApi([
                        {
                            "window": w,
                            "lamellen": l
                        }
                    ])
                }, (l) * 400);
            }
            break;


        case 'top-bottom':
            for (let l = 18; l <= 32; l++) {
                // console.log('top', w, l)
                root.timerId[l] = setTimeout(function () {
                    // console.log('timer', w, l)
                    rehauApi([
                        {
                            "window": w,
                            "lamellen": l
                        }
                    ])
                }, (l - 18) * 500);


            }
            break;


        case 'left-logo-top':
            for (let l = 4; l <= 16; l += 2) {
                root.timerId[1] = setTimeout(function () {
                    rehauApi([
                        {
                            "window": 3,
                            "lamellen": l
                        },
                        {
                            "window": 4,
                            "lamellen": 0
                        }
                    ])
                }, (l - 3) * 500);
            }
            break;



        case 'left-logoinvert':

            rehauApi([
                {
                    "window": 3,
                    "lamellen": 0
                },
                {
                    "window": 4,
                    "lamellen": 16
                }
            ])

            break;

        case 'top-2/4':
            for (let l = 18; l <= 24; l++) {
                // console.log('top', w, l)
                root.timerId[l] = setTimeout(function () {
                    // console.log('timer', w, l)
                    rehauApi([
                        {
                            "window": w,
                            "lamellen": l
                        }
                    ])
                }, (l - 18) * 1000);
            }
            break;


        case 'full':
            rehauApi([
                {
                    "window": w,
                    "lamellen": 0
                }
            ])
            break;

        case 'clear':
            rehauApi([
                {
                    "window": w,
                    "lamellen": 17
                }
            ])
            break;

        case 'ehau':
            rehauApi([
                {
                    "window": w,
                    "lamellen": 4
                }
            ])
            break;

        default:
            break;
    }

}